import React from "react";
import config from "../../Config";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import LightenDarkenColor from "./ColourBrigthness";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "../Styles/toast.css";

import darkScrollbar from "@mui/material/darkScrollbar";

export const CustomThemeContext = React.createContext({
  currentTheme: "dark",
  setTheme: null,
});

const CustomThemeProvider = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;

  // Read current theme from localStorage default is dark, to change use light
  const currentTheme =
    localStorage.getItem("appTheme") || config.theme.DefaultTheme;
  // Theme specific notifications

  // State to hold the selected theme name
  const [themeName, _setThemeName] = React.useState(currentTheme);

  const defaultM = "#691FAB";
  const defaultS = "#00b0ff";

  const [MainColor, setMainColor] = React.useState(
    config.theme.MainColor || defaultM
  );
  const [SecondaryColor, setSecondaryColor] = React.useState(
    config.theme.SecondaryColor || defaultS
  );

  React.useEffect(() => {
    if (themeName === "dark") {
      if (config.theme.ColorContrast.MainContrast)
        setMainColor(
          LightenDarkenColor(
            config.theme.MainColor || defaultM,
            config.theme.ColorContrast.MainContrastAmount
          )
        );
      if (config.theme.ColorContrast.SecondaryContrast)
        setSecondaryColor(
          LightenDarkenColor(
            config.theme.SecondaryColor || defaultS,
            config.theme.ColorContrast.SecondaryContrastAmount
          )
        );
    } else {
      setMainColor(config.theme.MainColor || defaultM);
      setSecondaryColor(config.theme.SecondaryColor || defaultS);
    }
  }, [themeName, MainColor, SecondaryColor]);

  // Retrieve the theme object by theme name
  const theme = createTheme({
    palette: {
      mode: themeName,
      primary: {
        main: MainColor,
      },
      secondary: {
        main: SecondaryColor,
      },
      background: {
        ...(themeName === "dark"
          ? {
              default: config.theme.Dark.background || "#303030",
              paper: config.theme.Dark.paper || "#424242",
            }
          : {
              default: config.theme.Light.background || "#fafafa",
              paper: config.theme.Light.paper || "#fff",
            }),
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: (themeParam) => ({
          body: darkScrollbar(
            themeParam.palette.mode === "light"
              ? {
                  track: themeParam.palette.grey[200],
                  thumb: themeParam.palette.grey[400],
                  active: themeParam.palette.grey[400],
                }
              : undefined
          ),
        }),
      },
    },
  });

  React.useEffect(() => {
    const root = document.documentElement.style;
    root.setProperty("--themec", theme.palette.background.paper);
    root.setProperty("--default", theme.palette.background.default);
    root.setProperty("--main", theme.palette.secondary.dark);
    root.setProperty("--maincolor", theme.palette.primary.main);
    root.setProperty("--secondarycolor", theme.palette.secondary.main);

    root.setProperty("--success", theme.palette.success.dark);
    root.setProperty("--info", theme.palette.info.dark);
    root.setProperty("--warning", theme.palette.warning.dark);
    root.setProperty("--error", theme.palette.error.dark);
    root.setProperty("--contrastText", theme.palette.success.contrastText);
  }, [themeName, theme]);

  // Wrap _setThemeName to store new theme names in localStorage
  const setThemeName = (name) => {
    localStorage.setItem("appTheme", name);
    _setThemeName(name);
  };

  const contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName,
  };

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ToastContainer
        position={config.theme.Notification.Position}
        autoClose={config.theme.Notification.CloseTimer}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        icon={true}
        toastClassName={
          config.theme.Notification.ThemeNotifications
            ? config.theme.Notification.MaterialNotifications
              ? "custToast materialToast"
              : "custToast"
            : ""
        }
      />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;
