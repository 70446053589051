import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import {
  IconButton,
  Stack,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

import { get_users_extra } from "../../CustomAxios/Protected/AdminManagment";
import TableLoader from "../../Components/Loading/Table";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = props.headings;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span
                  style={{
                    border: 0,
                    clip: "rect(0 0 0 0)",
                    height: 1,
                    margin: -1,
                    overflow: "hidden",
                    padding: 0,
                    position: "absolute",
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const SearchArea = props.SearchArea;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        pt: 2,
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {props.TableName}
      </Typography>
      {SearchArea}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {};

function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const dense = true;

  const rows = props.data;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Typography variant="h5" color="primary" sx={{ m: 1, pt: 2 }}>
          {props.TableTitle}
        </Typography>
        <EnhancedTableToolbar
          SearchArea={props.children}
          TableName={props.name}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headings={props.headers}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={row.id}>
                      {Object.keys(row).map(function (key, index) {
                        if (
                          key !== "id" &&
                          key !== "active" &&
                          key !== "verified" &&
                          key !== "admin"
                        ) {
                          if (key === "FullName")
                            return (
                              <TableCell
                                align="left"
                                key={`TableCell ${index} ${key}`}
                              >
                                {`${row[key]}`}

                                {row.admin && (
                                  <Chip
                                    color="primary"
                                    icon={<AdminPanelSettingsIcon />}
                                    label="Admin"
                                    sx={{ ml: 1 }}
                                  />
                                )}
                              </TableCell>
                            );
                          else
                            return (
                              <TableCell
                                align="left"
                                key={`TableCell ${index} ${key}`}
                              >
                                {`${row[key]}`}
                              </TableCell>
                            );
                        } else return null;
                      })}
                      <TableCell align="left" key={`TableCell ${index} other`}>
                        <Stack direction="row" spacing={1}>
                          {row.active ? (
                            <Chip color="success" label="Active" />
                          ) : (
                            <Chip
                              variant="outlined"
                              color="error"
                              label="Disabled"
                            />
                          )}
                          {row.verified ? (
                            <Chip color="success" label="Verified" />
                          ) : (
                            <Chip
                              variant="outlined"
                              color="error"
                              label="Unverified"
                            />
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell key={`TableCell ${index} settings`}>
                        <Tooltip title="Settings">
                          <IconButton
                            aria-label="settings"
                            component={Link}
                            to={`/admin/users/manage?i=${row.id}`}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ overflow: "visible" }}
          />
        </TableContainer>
      </Paper>
    </Box>
  );
}

function ReloadBtn(setLoading) {
  return (
    <Tooltip title={"Reload"}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setLoading(true)}
      >
        <RefreshIcon />
      </Button>
    </Tooltip>
  );
}

function SearchArea(props) {
  const { current, setFinalVal } = props;
  const [searchVal, setSearchVal] = React.useState(current);

  const setDefault = () => {
    setSearchVal("");
    setFinalVal("");
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => setFinalVal(searchVal), 500);
    return () => clearTimeout(timeoutId);
  }, [searchVal, setFinalVal]);

  return (
    <div key="SearchArea">
      <TextField
        label="Search"
        variant="outlined"
        value={searchVal}
        color="secondary"
        onChange={(e) => setSearchVal(e.target.value)}
        InputProps={{
          endAdornment: (
            <Tooltip title={"Reset Search"}>
              <IconButton
                aria-label="Reset"
                color="secondary"
                onClick={() => setDefault()}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
    </div>
  );
}

export default function Main(props) {
  const head = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "details",
      numeric: false,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "created",
      numeric: false,
      disablePadding: false,
      label: "Created",
    },
    {
      id: "last modified",
      numeric: false,
      disablePadding: false,
      label: "Last Modified",
    },
    {
      id: "other",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "settings",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];
  const [rowMaster, setRowMaster] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [searchDets, setSearchDets] = React.useState("");
  const urlDets = new URLSearchParams(useLocation().search);
  const reload = urlDets.get("r");
  let history = useNavigate();

  React.useEffect(() => {
    if (reload) {
      setLoading(true);
      history("/admin/users", { replace: true });
    }
  }, [reload, history]);

  React.useEffect(() => {
    if (loading)
      get_users_extra().then((res) => {
        res.forEach((user) => {
          user.created = new Date(user.created).toLocaleString();
          user.last_modified = new Date(user.last_modified).toLocaleString();
        });
        setRows(res);
        setRowMaster(res);
        setLoading(false);
      });
  }, [loading]);

  React.useEffect(() => {
    if (searchDets) {
      const term = searchDets.toLocaleLowerCase();
      const filtered = rowMaster.filter(
        (val) =>
          val.email.toLocaleLowerCase().includes(term) ||
          val.FullName.toLocaleLowerCase().includes(term) ||
          val.phone.includes(term)
      );
      setRows(filtered);
    } else setRows(rowMaster);
  }, [searchDets, rowMaster]);

  return (
    <>
      {loading ? (
        <TableLoader base />
      ) : (
        <EnhancedTable
          headers={head}
          data={rows}
          name={ReloadBtn(setLoading)}
          TableTitle={"Recently Created Users"}
        >
          <SearchArea current={searchDets} setFinalVal={setSearchDets} />
        </EnhancedTable>
      )}
    </>
  );
}
