import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Skeleton,
  Dialog,
  Grow,
  Paper,
  DialogTitle,
} from "@mui/material";

import { editCheque, getCheque } from "../../API/Cheques&Pos";

import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";

import { useNavigate, useLocation } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

import AreYouSure from "../../../Components/common/AreYouSure";
import DateTimePicker from "../../Components/common/DateTimePicker";

export default function AddItemForm() {
  const [status, setStatus] = React.useState(null),
    [received, setReceived] = React.useState(null),
    [currency, setCurrency] = React.useState(null),
    [dated, setDated] = React.useState(null),
    [dateChanged, setDateChanged] = React.useState(false),
    [data, setData] = React.useState({
      status: null,
      receivedStatus: null,
      dateWritten: null,
      currency: null,
    });

  const [valid, setValid] = React.useState(false),
    [loading, setLoading] = React.useState(false);

  const [edited, setEdited] = React.useState(false);
  const [fetching, setFetching] = React.useState(true);

  const { handleChanged, origin } = useOutletContext();
  const history = useNavigate();

  const urlDets = new URLSearchParams(useLocation().search);
  const chequeNumber = urlDets.get("number");
  const urlCurrency = urlDets.get("cur");
  const bank = urlDets.get("bank");

  const goBack = () => {
    if (edited) handleChanged();
    history(origin);
  };

  React.useEffect(() => {
    const controller = new AbortController();
    getCheque({
      number: chequeNumber,
      currency: urlCurrency,
      bank: bank,
      signal: controller.signal,
    })
      .then((res) => {
        setStatus(res.status);
        setReceived(res.receivedStatus);
        setCurrency(res.currency);
        setDated(res.dateWritten);
        setData(res);
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Could not fetch data");
      });
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      status !== data?.status ||
      received !== data?.receivedStatus ||
      dated !== data?.dateWritten ||
      currency !== data?.currency
    ) {
      setValid(true);
    } else {
      setValid(false);
    }

    if (dated !== data?.dateWritten) setDateChanged(true);
    else setDateChanged(false);
  }, [status, received, dated, currency, data]);

  const handleSubmit = () => {
    const data = {
      received,
      status,
      dated,
      oldCurrency: urlCurrency,
      currency,
      bank,
      dateChanged,
      number: chequeNumber,
    };
    setLoading(true);
    editCheque(data)
      .then((res) => {
        toast[res.status](res.message);
        if (res.status === "success") {
          setEdited(true);
          setData((previous) => {
            previous.status = status;
            previous.receivedStatus = received;
            previous.dateWritten = dated;
            previous.currency = currency;
            return { ...previous };
          });
        }
      })
      .catch((err) => {
        toast.error(
          "something went wrong editing the status, contact an administrator is problem persists"
        );
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStatus = (event, newStatus) => {
    if (newStatus !== null) setStatus(newStatus);
  };
  const handleReceive = (event, newStatus) => {
    if (newStatus !== null) setReceived(newStatus);
  };
  const [openPopUp, setOpenPopUp] = React.useState(false);

  return (
    <>
      <AreYouSure
        open={openPopUp}
        onDecline={() => setOpenPopUp(false)}
        onAccept={() => {
          goBack();
        }}
        text={"Any changes made will be lost"}
      />

      <Dialog
        open={true}
        onClose={() => {
          if (valid) {
            setOpenPopUp(true);
            return;
          }
          goBack();
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "background.paper",
          }}
        >
          {chequeNumber} - {bank}
        </DialogTitle>
        <DialogContent sx={{ width: "400px" }} dividers>
          {fetching ? (
            <Stack sx={{ mt: 2 }}>
              <Skeleton height={70} />
              <Skeleton height={70} />
              <Skeleton height={70} />
              <Skeleton height={70} />
            </Stack>
          ) : (
            <Stack spacing={2} sx={{ mt: 2 }}>
              <Typography> Status:</Typography>
              <ToggleButtonGroup
                value={status}
                exclusive
                onChange={handleStatus}
              >
                <ToggleButton value="used" color={"success"}>
                  Used
                </ToggleButton>
                <ToggleButton value="cancelled" color={"warning"}>
                  Cancelled
                </ToggleButton>
              </ToggleButtonGroup>

              <Typography> Recieve Status:</Typography>
              <ToggleButtonGroup
                value={received}
                exclusive
                onChange={handleReceive}
              >
                <ToggleButton value="waiting" color={"success"}>
                  Waiting
                </ToggleButton>
                {data?.status !== "used" && (
                  <ToggleButton value="received" color={"warning"}>
                    Received
                  </ToggleButton>
                )}
                <ToggleButton value="banked" color={"warning"}>
                  Banked
                </ToggleButton>
              </ToggleButtonGroup>

              <DateTimePicker
                label="Date Written"
                value={dated}
                setChange={setDated}
              />

              <ToggleButtonGroup
                value={currency}
                exclusive
                onChange={(e, newVal) => {
                  if (newVal !== null) setCurrency(newVal);
                }}
              >
                <ToggleButton value="KES" color={"info"}>
                  KES
                </ToggleButton>
                <ToggleButton value="USD" color={"warning"}>
                  USD
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          )}
        </DialogContent>

        <Paper
          sx={{
            p: 1,
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1301,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DialogActions>
            <Grow in={valid}>
              <LoadingButton
                startIcon={<AddIcon />}
                variant="contained"
                onClick={handleSubmit}
                loading={loading}
              >
                {"Edit Cheque"}
              </LoadingButton>
            </Grow>
            <Button
              onClick={() => {
                if (valid) {
                  setOpenPopUp(true);
                  return;
                }
                goBack();
              }}
              color="warning"
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
}
