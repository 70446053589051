import { axiosAuth } from "../../../../CustomAxios/Protected";
import DownloadFile from "../../common/FileDownload";

export function getTonerPages() {
  return axiosAuth.get("/toners/pages_count").then((res) => {
    return res.data;
  });
}

export function getTonerSelloutPages() {
  return axiosAuth.get("/toners/sellout/pages_count").then((res) => {
    return res.data;
  });
}

export function getAllToners(data, signal) {
  return axiosAuth
    .get(`/toners/get_all`, {
      params: {
        page: data.currentPage,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function getAllTonerSellout(data, signal) {
  return axiosAuth
    .get(`/toners/sellout/get_all`, {
      params: {
        page: data.currentPage,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function createToner(data) {
  return axiosAuth.post("/toners/create", data).then((res) => {
    return res.data;
  });
}

export function deleteSellout(id) {
  return axiosAuth.post(`/toners/sellout/delete`, { id }).then((res) => {
    return res.data;
  });
}

export function getSelloutByID(id) {
  return axiosAuth
    .get(`/toners/sellout/get_by_id`, { params: { id } })
    .then((res) => {
      return res.data;
    });
}

export function updateSelloutProfit(data) {
  return axiosAuth.post(`/toners/sellout/update_profit`, data).then((res) => {
    return res.data;
  });
}

export function checkStockError() {
  return axiosAuth.get(`/toners/sellout/check_stock_error`).then((res) => {
    return res.data;
  });
}

export function getTonerStock() {
  return axiosAuth
    .get(`/toners/download_excel`, {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = `Toner Stock ${new Date()}.xlsx`;
      DownloadFile(response, fileName);
      return {
        status: 200,
        message: "Report generated",
      };
    });
}

export function getTonerRatio() {
  return axiosAuth
    .get(`/toners/download_ratio_excel`, {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = `Toner Stock Ratio ${new Date()}.xlsx`;
      DownloadFile(response, fileName);
      return {
        status: 200,
        message: "Report generated",
      };
    });
}

export function getTonerStockError() {
  return axiosAuth
    .get(`/toners/download_error_excel`, {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = `Toner Stock Error ${new Date()}.xlsx`;
      DownloadFile(response, fileName);
      return {
        status: 200,
        message: "Report generated",
      };
    });
}

export function getSalesByToner() {
  return axiosAuth
    .get(`/toners/sellout/byToner`, {
      responseType: "blob",
    })
    .then((response) => {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      const fileName = `Sales by Toner ${formattedDate}.xlsx`;
      DownloadFile(response, fileName);
      return {
        status: 200,
        message: "Report generated",
      };
    });
}

export function getTonerSelloutReport(expanded) {
  return axiosAuth
    .get(`/toners/sellout/download_excel`, {
      params: {
        expanded,
      },
      responseType: "blob",
    })
    .then((response) => {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      const fileName = `Toner Sellout ${
        expanded ? "(Expanded)" : ""
      } as of ${formattedDate}.xlsx`;
      DownloadFile(response, fileName);
      return {
        status: 200,
        message: "Report generated",
      };
    });
}
