import toTitleCase from "../../Components/common/toTitleCase";
import { axiosAuth } from "../../CustomAxios/Protected";
import DownloadFile from "../Components/common/FileDownload";

// Client Management
export function getClientPages() {
  return axiosAuth.get("/sales/clients/pages_count").then((res) => {
    return res.data;
  });
}
//with paginate
export function getClients(data, signal) {
  return axiosAuth
    .get(`/sales/clients/get_all_clients`, {
      params: {
        page: data.currentPage,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function queryClientEmail(searchVal) {
  return axiosAuth
    .post("/sales/clients/query_email", { email: searchVal })
    .then((res) => {
      return res.data;
    });
}

export function addClient(data) {
  return axiosAuth.post("/sales/clients/add", data).then((res) => {
    return res.data;
  });
}

export function sendNewClientEmail(id) {
  return axiosAuth
    .get("/sales/clients/send_new_client_email", {
      params: { clientID: id },
    })
    .then((res) => {
      console.log(res.data);
    });
}

export function getClientByID(id) {
  return axiosAuth
    .post(`/sales/clients/get_by_id`, { clientID: id })
    .then((res) => {
      return res.data;
    });
}

export function updateClient(data) {
  return axiosAuth.post("/sales/clients/change_detail", data).then((res) => {
    return res.data;
  });
}

export function recentlyContacted(clientID) {
  return axiosAuth
    .post("/sales/clients/recently_contacted", { clientID })
    .then((res) => {
      return res.data;
    });
}

export function delteOtherClientDetails(data) {
  return axiosAuth
    .post("/sales/clients/delete_other_detail", data)
    .then((res) => {
      return res.data;
    });
}

export function requestClientTransfer(data) {
  return axiosAuth.post("/sales/clients/request_transfer", data).then((res) => {
    return res.data;
  });
}

export function searchClient(data) {
  return axiosAuth
    .get("/sales/clients/get_all", {
      params: { searchKey: data.searchKey },
    })
    .then((res) => {
      return res.data;
    });
}

export function deleteClient(id) {
  return axiosAuth
    .get("/sales/clients/delete", { params: { clientID: id } })
    .then((res) => {
      return res.data;
    });
}

// Company Management
export function getAllComaniesNames(data) {
  return axiosAuth
    .get("/sales/clients/get_all_companies", {
      params: { searchKey: data.searchKey, limit: data.limit || 50 },
    })
    .then((res) => {
      return res.data;
    });
}

export function addNewCompany(data) {
  return axiosAuth.post("/sales/clients/add_company", data).then((res) => {
    return res.data;
  });
}

export function getCompanyByID(id) {
  return axiosAuth
    .post("/sales/clients/get_company_by_id", { companyID: id })
    .then((res) => {
      return res.data;
    });
}

export function updateCompany(data) {
  return axiosAuth
    .post("/sales/clients/change_company_detail", data)
    .then((res) => {
      return res.data;
    });
}

// Quote Management
export function getQuotePages(data) {
  return axiosAuth
    .get("/sales/quotes/pages_count", {
      params: {
        page: data.currentPage,
        currency: data.currency,
        company: data.companyName,
        part: data.part,
        exempt: data.exempt,
        to: data.to,
        from: data.from,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getAllQuotes(data, signal) {
  return axiosAuth
    .get(`/sales/quotes/get_all`, {
      params: {
        page: data.currentPage,
        currency: data.currency,
        company: data.companyName,
        part: data.part,
        exempt: data.exempt,
        to: data.to,
        from: data.from,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function getAllCompaniesQuotes(data) {
  return axiosAuth
    .get("/sales/quotes/get_all_companies", {
      params: { searchKey: data.searchKey },
    })
    .then((res) => {
      return res.data;
    });
}

export function createQuote(data) {
  return axiosAuth.post("/sales/quotes/create", data).then((res) => {
    return res.data;
  });
}

export function getQuotePDF(quote_no, companyName) {
  return axiosAuth
    .get(`/sales/quotes/get_pdf/${encodeURIComponent(quote_no)}`, {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = `${toTitleCase(companyName)} ${quote_no}`
        .replace(/[^a-z0-9]/gi, " ")
        .replace(/\s+/g, " ")
        .trim();
      DownloadFile(response, fileName);
      return {
        status: "success",
        message: "Quote PDF Downloaded Successfully",
      };
    });
}

export function editQuote(data) {
  return axiosAuth.post("/sales/quotes/edit", data).then((res) => {
    return res.data;
  });
}

export function getRecentQuotes() {
  return axiosAuth.get("/sales/quotes/get_recent").then((res) => {
    return res.data;
  });
}

export function getQuoteByID(id) {
  return axiosAuth
    .post("/sales/quotes/get_details", { quoteNo: id })
    .then((res) => {
      return res.data;
    });
}

export function getQuoteByIDTable(id) {
  return axiosAuth
    .post("/sales/quotes/get_tableview", { quoteNo: id })
    .then((res) => {
      return res.data;
    });
}

export function getUploadItemsTemplate() {
  return axiosAuth
    .get("/sales/quotes/get_upload_template", {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = "Upload Items Template.xlsx";
      DownloadFile(response, fileName);

      return {
        status: "success",
        message: "Template Downloaded Successfully",
      };
    });
}

export function uploadItems(data) {
  return axiosAuth.post("/sales/quotes/items_from_excel", data).then((res) => {
    return res.data;
  });
}

// RFQS

export function getRfqAccess() {
  return axiosAuth.get("/sales/rfqs/get_access").then((res) => {
    return res.data;
  });
}

export function getRfqPages(data, signal) {
  return axiosAuth
    .get("/sales/rfqs/pages_count", {
      params: {
        page: data.currentPage,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function getRfqs() {
  return axiosAuth.get("/sales/rfqs/get_all").then((res) => {
    return res.data;
  });
}

export function AddRfq(data) {
  return axiosAuth.post("/sales/rfqs/create", data).then((res) => {
    return res.data;
  });
}

export function getRfqByID(id) {
  return axiosAuth
    .get("/sales/rfqs/get_details", {
      params: { rfqID: id },
    })
    .then((res) => {
      return res.data;
    });
}

export function editRfq(data) {
  return axiosAuth.post("/sales/rfqs/update", data).then((res) => {
    return res.data;
  });
}

// Get daily summary
export function getDailySummary() {
  return axiosAuth.get("/sales/get_daily_summary").then((res) => {
    return res.data;
  });
}

// Get weekly summary
export function getWeeklySummary() {
  return axiosAuth.get("/sales/get_weekly_summary").then((res) => {
    return res.data;
  });
}

// Download Summary
export function downloadSummary(summary_type, name) {
  return axiosAuth
    .get(`/sales/download_summary/${summary_type}`, {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = `${name}.xlsx`;
      DownloadFile(response, fileName);

      return {
        status: "success",
        message: "Report Downloaded Successfully",
      };
    });
}

// Get current exchange rate
export function getExchangeRate() {
  return axiosAuth.get("/sales/get_exchange_rate").then((res) => {
    return res.data;
  });
}
