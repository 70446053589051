import React from "react";

import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Avatar,
  SvgIcon,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  DialogActions,
  DialogContentText,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Skeleton,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
} from "@mui/material";

import {
  ArrowBack,
  ArrowForward,
  CheckCircleRounded,
  Delete,
  DoneAll,
  NavigateNext,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import config from "../../Config";

import { getRecentQuotes } from "../../API/SalesAdmin";

import NumberFormat from "../../../Components/common/NumberFormater";
import TagField from "./TagField";
import QuoteTable from "../tables/QuoteSelect";
import ItemsArea from "./ItemArea";
import AsyncAutoComplete from "../../../Components/common/AsyncAutoComplete";
import CheckRepeatConfirmation from "./CheckRepeatConfirmation";
import {
  getClientByID,
  getQuoteByIDTable,
  getAllAccountManagers,
  searchClientsAdmin,
} from "../../API/SalesAdmin";
import DateTimePicker from "./DateTimePicker";

export default function Order({ orderData, onSubmit, isAdmin = false }) {
  const [step, setStep] = React.useState(0);
  const maxStep = 4;

  const [quoteID, setQuoteID] = React.useState(orderData?.quoteID || []),
    [accountManagerId, setAccountManagerId] = React.useState(""),
    [quoteSkipped, setQuoteSkipped] = React.useState(
      orderData?.quoteSkipped || false
    ),
    [currency, setCurrency] = React.useState(orderData?.currency || "KES"),
    [orderDetails, setOrderDetails] = React.useState(
      orderData?.orderDetails || ""
    ),
    [clientID, setClientID] = React.useState(orderData?.clientID || ""),
    [confirmationType, setConfirmationType] = React.useState(""),
    [confirmationDetails, setConfirmationDetails] = React.useState([]),
    [vatExempt, setVatExempt] = React.useState(orderData?.vat || false),
    [items, setItems] = React.useState(orderData?.items || []);

  React.useEffect(() => {
    if (quoteID.length > 0) setQuoteSkipped(false);
  }, [quoteID]);

  React.useEffect(() => {
    if (quoteID.length > 0) {
      setCurrency(quoteID[0].currency);
      setVatExempt(quoteID[0].vatExempt);
    }
  }, [quoteID]);

  const clearAndSkip = () => {
    setQuoteID([]);
    setQuoteSkipped(true);
  };

  const submitOrder = () => {
    const data = {
      quoteRefrences: quoteID,
      isAdmin: true,
      accountManagerId: accountManagerId.id,
      currency: currency,
      vatExempt: vatExempt,
      orderDetails: orderDetails,
      clientID: clientID.id,
      items: items,
      confirmationType: confirmationType,
      confirmationDetails: confirmationDetails,
    };
    onSubmit(data);
  };

  const nextStep = () => {
    if (step < maxStep) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const MoveStep = ({ nextStepAllowed, onChange }) => {
    const handlePrev = () => {
      if (onChange) onChange();
      prevStep();
    };

    const handleNext = () => {
      if (onChange) onChange();
      if (maxStep > step) {
        nextStep();
        return;
      }
      submitOrder();
    };

    return (
      <Stack spacing={2} direction="row" justifyContent="center" sx={{ my: 1 }}>
        {step > 0 && (
          <Button
            color="primary"
            onClick={handlePrev}
            startIcon={<ArrowBack />}
          >
            Back
          </Button>
        )}

        <Button
          color="primary"
          variant={maxStep > step ? "text" : "contained"}
          onClick={handleNext}
          disabled={!nextStepAllowed}
          endIcon={maxStep > step ? <ArrowForward /> : <DoneAll />}
        >
          {maxStep > step ? "Next" : "Submit Order"}
        </Button>
      </Stack>
    );
  };

  const currentStep = () => {
    switch (step) {
      case 0:
        return (
          <QuoteManager
            accountManagerId={accountManagerId}
            setAccountManagerId={setAccountManagerId}
            selectedQuotes={quoteID}
            setSelectedQuotes={setQuoteID}
            hasSkipped={quoteSkipped}
            handleSkip={setQuoteSkipped}
            clearAndSkip={clearAndSkip}
            setClientID={setClientID}
            MoveStep={MoveStep}
          />
        );
      case 1:
        return (
          <DetailsArea
            currency={currency}
            setCurrency={setCurrency}
            orderDetails={orderDetails}
            setOrderDetails={setOrderDetails}
            vatExempt={vatExempt}
            setVatExempt={setVatExempt}
            MoveStep={MoveStep}
          />
        );

      case 2:
        return (
          <ClientArea
            clientID={clientID}
            setClientID={setClientID}
            quoteClient={
              quoteID[0]
                ? {
                    id: quoteID[0]?.clientID,
                    name: quoteID[0]?.label,
                  }
                : ""
            }
            confirmationType={confirmationType}
            setConfirmationType={setConfirmationType}
            confirmationDetails={confirmationDetails}
            setConfirmationDetails={setConfirmationDetails}
            MoveStep={MoveStep}
            isAdmin={isAdmin}
          />
        );
      case 3:
        return (
          <OrderItemArea
            itemList={items}
            setItemList={setItems}
            quotes={quoteID}
            hasVat={!vatExempt}
            currency={currency}
            MoveStep={MoveStep}
          />
        );
      case 4:
        return (
          <Finalisation
            data={{
              quoteRefrences: quoteID,
              currency: currency,
              vatExempt: vatExempt,
              orderDetails: orderDetails,
              clientID: clientID,
              items: items,
              confirmationType: confirmationType,
              confirmationDetails: confirmationDetails,
            }}
            MoveStep={MoveStep}
          />
        );
      default:
        return null;
    }
  };

  const StepName = ({ text, stepVal, completed }) => {
    const theme = useTheme();
    return (
      <Stack spacing={1} direction="row" alignItems="center">
        {completed ? (
          <SvgIcon
            component={CheckCircleRounded}
            color="primary"
            sx={{ fontSize: "2.8rem" }}
          />
        ) : (
          <Avatar sx={{ background: theme.palette.secondary.main }}>
            {stepVal + 1}
          </Avatar>
        )}

        <Typography color={step === stepVal ? "secondary" : "inherit"}>
          {text}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack
      alignContent="center"
      justifyContent="space-between"
      alignItems="center"
      sx={{ minHeight: "calc( 100vh - 203px )" }}
    >
      <Stack
        spacing={2}
        direction={{ xs: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
        divider={<NavigateNext />}
        sx={{ my: 2 }}
      >
        <StepName
          text="Refrence Quote"
          stepVal={0}
          completed={quoteID.length > 0}
        />
        <StepName
          text="Order Details"
          stepVal={1}
          completed={currency && orderDetails}
        />
        <StepName
          text="Client Details"
          stepVal={2}
          completed={clientID && confirmationType && confirmationDetails}
        />
        <StepName text="Add Items" stepVal={3} completed={items.length > 0} />
        <StepName text="Finalise" stepVal={4} />
      </Stack>
      {currentStep()}
    </Stack>
  );
}

function QuoteManager({
  accountManagerId,
  setAccountManagerId,
  selectedQuotes,
  setSelectedQuotes,
  hasSkipped,
  handleSkip,
  clearAndSkip,
  MoveStep,
}) {
  const [quoteList, setQuoteList] = React.useState(selectedQuotes || []),
    [quotes, setQuotes] = React.useState([]),
    [loading, setLoading] = React.useState(false),
    [showingRecents, setShowingRecents] = React.useState(false),
    [showTextField, setShowTextField] = React.useState(false),
    [searchText, setSearchText] = React.useState(""),
    [foundQuote, setFoundQuotes] = React.useState([]),
    [showSkipped, setShowSkipped] = React.useState(false);

  const skipped = hasSkipped;

  React.useEffect(() => {
    if (loading)
      getRecentQuotes(accountManagerId.id).then((res) => {
        res.forEach((quote) => {
          quote.date_created = new Date(quote.date_created).toLocaleString();
          quote.date_quoted = new Date(quote.date_quoted).toLocaleString();
        });
        setQuotes(res);
        setLoading(false);
      });
  }, [loading, accountManagerId]);

  React.useEffect(() => {
    if (accountManagerId) setLoading(true);
  }, [accountManagerId]);

  const toggleShowRecents = () => {
    if (showingRecents) {
      setLoading(false);
      setShowingRecents(false);
      return;
    }
    setLoading(true);
    setShowingRecents(true);
  };

  const toggleShowTextField = () => {
    setShowTextField(!showTextField);
    setSearchText("");
    setFoundQuotes([]);
  };

  const manageSkip = () => {
    if (skipped) {
      handleSkip(false);
      return;
    }
    setShowSkipped(true);
  };

  React.useEffect(() => {
    setSelectedQuotes(quoteList);
  }, [quoteList, setSelectedQuotes]);

  const addQuote = (quote) => {
    if (quoteList.some((q) => q.quote_no === quote.quote_no)) {
      return;
    }
    setQuoteList([...quoteList, quote]);
  };

  const removeQuote = (quote) => {
    const current = quoteList;
    setQuoteList(current.filter((q) => q.quote_no !== quote.quote_no));
  };

  const searchQuote = () => {
    setFoundQuotes([]);
    getQuoteByIDTable(searchText, accountManagerId.id).then((res) => {
      if (res.status === "success") {
        const quote = res.data;
        quote.date_created = new Date(quote.date_created).toLocaleString();
        quote.date_quoted = new Date(quote.date_quoted).toLocaleString();
        setFoundQuotes([quote]);
        return;
      }
      toast.error(res.message);
    });
  };

  return (
    <>
      <Paper variant="outlined" sx={{ p: 1, maxWidth: "100%" }}>
        <AsyncAutoComplete
          label="Account Manager"
          defaultOption={accountManagerId}
          getData={getAllAccountManagers}
          setValue={setAccountManagerId}
          optionLabel="email"
          sx={{
            mb: 2,
          }}
        />
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          direction={{ xs: "column", md: "row" }}
          sx={{ my: 1 }}
        >
          <Button
            color={showingRecents ? "secondary" : "primary"}
            variant="contained"
            onClick={toggleShowRecents}
          >
            {showingRecents ? "Hide Recent Quotes" : "Recent Quotes (100)"}
          </Button>
          <Button
            color={showTextField ? "secondary" : "primary"}
            variant="contained"
            onClick={toggleShowTextField}
          >
            {showTextField ? "Hide Quote Number" : "Enter Quote Number"}
          </Button>
          <Button
            color={skipped ? "secondary" : "primary"}
            variant="contained"
            onClick={manageSkip}
          >
            {skipped
              ? "Cancel adding details manually"
              : "Add details manually"}
          </Button>
        </Stack>
        <Dialog open={showSkipped} onClose={() => setShowSkipped(false)}>
          <DialogTitle>Add details manually</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure? This means you will have to add items manually and
              will remove any quotes you have already added.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                clearAndSkip();
                setShowSkipped(false);
              }}
              color="success"
              variant="contained"
            >
              Confirm
            </Button>
            <Button
              onClick={() => setShowSkipped(false)}
              color="warning"
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Collapse in={showingRecents}>
          <QuoteTable
            quoteList={quotes}
            loading={loading}
            setLoading={setLoading}
            addRow={addQuote}
            addedQuotes={quoteList}
          />
        </Collapse>
        <Collapse in={showTextField}>
          <Paper variant="outlined" sx={{ p: 1, my: 2 }}>
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
              <TextField
                label="Quote Number"
                variant="outlined"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />

              <Button onClick={searchQuote} disabled={!searchText}>
                Search
              </Button>
            </Stack>
            <Collapse in={foundQuote.length > 0 ? true : false}>
              <QuoteTable
                quoteList={foundQuote}
                addRow={addQuote}
                addedQuotes={quoteList}
                disableBloat
              />
            </Collapse>
            <Stack alignItems="flex-end">
              <Button
                variant="outlined"
                color="warning"
                onClick={toggleShowTextField}
              >
                Cancel
              </Button>
            </Stack>
          </Paper>
        </Collapse>
        <Collapse in={quoteList.length > 0}>
          <Typography
            variant="h5"
            color="primary"
            sx={{ my: 1 }}
            align="center"
          >
            Quotes
          </Typography>
          <Stack divider={<Divider light />}>
            {quoteList.map((quote, index) => (
              <QuoteListItem key={index} quote={quote} remove={removeQuote} />
            ))}
          </Stack>
        </Collapse>
        <Collapse in={skipped}>
          <Typography
            variant="h6"
            color="primary"
            align="center"
            sx={{ my: 2 }}
          >
            No quote added, you can proceed to the next step.
          </Typography>
        </Collapse>
      </Paper>
      <MoveStep nextStepAllowed={quoteList.length > 0 || skipped} />
    </>
  );
}

function QuoteListItem({ quote, remove }) {
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Card>
      <Paper variant="outlined" sx={{ borderRadius: 0 }}>
        <Stack direction="row">
          <CardActionArea onClick={toggleOpen} sx={{ p: 1 }}>
            <Typography variant="h6">
              {quote.quote_no}{" "}
              <Typography
                variant="overline"
                sx={{ color: (theme) => theme.palette.grey[500] }}
              >
                (click to view details)
              </Typography>
            </Typography>
          </CardActionArea>
          <IconButton
            variant="outlined"
            color="warning"
            onClick={() => remove(quote)}
            startIcon={<CloseIcon />}
          >
            <Delete />
          </IconButton>
        </Stack>
      </Paper>
      <Collapse in={open}>
        <CardContent>
          <Typography variant="h6">Details</Typography>
          <Typography>Company: {quote.company_name}</Typography>
          <Typography>Date Created: {quote.date_created}</Typography>
          <Typography>Date Quoted: {quote.date_quoted}</Typography>
          <Typography>Total: {quote.total}</Typography>
          <Divider sx={{ my: 2 }} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Part Number</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Unit Price EXC</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quote.items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.partNumber}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{NumberFormat(item.price.toFixed(2))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button onClick={toggleOpen}>Hide details</Button>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => remove(quote)}
            startIcon={<CloseIcon />}
          >
            Remove from Order
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
}

function DetailsArea({
  currency,
  setCurrency,
  orderDetails,
  setOrderDetails,
  vatExempt,
  setVatExempt,
  MoveStep,
}) {
  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) setCurrency(newAlignment);
  };

  return (
    <>
      <Paper variant="outlined" sx={{ p: 1 }}>
        <Stack spacing={2}>
          <ToggleButtonGroup
            value={currency}
            onChange={handleChange}
            aria-label="Currency"
            color="info"
            exclusive
          >
            {config.Currencies.map((cur) => (
              <ToggleButton key={cur.value} value={cur.value}>
                {cur.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            value={vatExempt}
            exclusive
            onChange={(e, newVatExempt) => {
              if (newVatExempt !== null) setVatExempt(newVatExempt);
            }}
            aria-label="vat exempt"
            color="info"
          >
            <ToggleButton value={true} aria-label="vat exempt">
              VAT Exempt
            </ToggleButton>
            <ToggleButton value={false} aria-label="vat not exempt">
              VAT Included
            </ToggleButton>
          </ToggleButtonGroup>
          <TextField
            label="Order Details"
            value={orderDetails}
            onChange={(e) => setOrderDetails(e.target.value)}
            multiline
            rows={4}
            fullWidth
            margin="normal"
            helperText="Any details such as delivery time or any special requests from the customer."
          />
        </Stack>
      </Paper>
      <MoveStep nextStepAllowed={currency} />
    </>
  );
}

function ClientArea({
  clientID,
  setClientID,
  quoteClient,
  confirmationType,
  setConfirmationType,
  confirmationDetails,
  setConfirmationDetails,
  MoveStep,
  isAdmin,
}) {
  const [confirmationComplete, setConfirmationComplete] = React.useState(false);

  const [date, setDate] = React.useState(new Date());
  const [amount, setAmount] = React.useState(0);

  React.useEffect(() => {
    if (confirmationType !== "email") return;
    if (confirmationDetails[0] && confirmationDetails[0].includes("||")) {
      setDate(confirmationDetails[0].split("||")[0]);
      setAmount(confirmationDetails[0].split("||")[1]);
    }
  }, [confirmationDetails, confirmationType]);

  React.useEffect(() => {
    if (confirmationType) {
      if (confirmationType === "email") {
        if (date && amount) setConfirmationComplete(true);
        else setConfirmationComplete(false);
      } else if (confirmationDetails.length > 0) setConfirmationComplete(true);
      else setConfirmationComplete(false);
    } else {
      setConfirmationComplete(false);
    }
  }, [confirmationType, confirmationDetails, date, amount]);

  const showWarning = ["LPO", "MPESA"].includes(confirmationType.toUpperCase());
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (clientID) return;
    setClientID(quoteClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFinal = () => {
    const onlyOne = !["LPO", "MPESA"].includes(confirmationType.toUpperCase());
    if (onlyOne) {
      setConfirmationDetails((previous) => [previous[0]]);
    }
    if (confirmationType === "email") {
      setConfirmationDetails((previous) => {
        previous[0] = `${date}||${amount}`;
        return [...previous];
      });
    }
  };

  const GetClients = () => (
    <AsyncAutoComplete
      getData={searchClientsAdmin}
      setValue={setClientID}
      optionLabel="name"
      label="Customers"
      defaultOption={clientID?.name}
    />
  );

  return (
    <>
      <Paper variant="outlined" sx={{ p: 1 }}>
        <Stack spacing={2} sx={{ my: 2 }}>
          <GetClients />
          <FormControl variant="outlined" error={!confirmationType}>
            <InputLabel>Confirmation Type</InputLabel>
            <Select
              label="Confirmation Type"
              value={confirmationType}
              color="primary"
              onChange={(e) => setConfirmationType(e.target.value)}
              disabled={!clientID}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="LPO">LPO</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="mpesa">Mpesa</MenuItem>
              <MenuItem value="card">Credit/Debit Card</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            {!confirmationType && (
              <FormHelperText>Please select a confirmation type</FormHelperText>
            )}
          </FormControl>
        </Stack>

        <>
          {confirmationType === "other" && (
            <Typography gutterBottom>
              What confirmation type has the client provided
            </Typography>
          )}
          {confirmationType === "mpesa" && (
            <Typography gutterBottom>
              Please provide the confirmation ID. Seperater each confirmation ID
              with a semicolon eg
            </Typography>
          )}
          {confirmationType === "card" && (
            <Typography gutterBottom>
              Please provide the confirmation ID
            </Typography>
          )}
          {confirmationType === "LPO" && (
            <Typography gutterBottom>
              If there is more than one LPO seperate the LPO number with a comma
              eg. PO1,PO2
            </Typography>
          )}
          {confirmationType === "email" && (
            <Typography gutterBottom>
              Provide the date of the email and the total amount (inclusive of
              VAT if applicable) eg. 1/1/2022 10000
            </Typography>
          )}

          {confirmationType === "LPO" || confirmationType === "mpesa" ? (
            <Stack justifyContent="center" alignItems="flex-start" spacing={2}>
              <TagField
                label={
                  confirmationType === "LPO"
                    ? "LPO Number"
                    : "Mpesa confirmation ID"
                }
                value={confirmationDetails}
                setValue={(array) => setConfirmationDetails(array)}
                TextFieldProps={{ margin: "normal", disabled: !clientID }}
              />
            </Stack>
          ) : confirmationType === "email" ? (
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              sx={{ my: 2 }}
            >
              <DateTimePicker
                label={"Date"}
                value={date}
                setChange={(e) => setDate(e)}
              />
              <TextField
                label="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                type="number"
                onWheel={(e) => e.target.blur()}
                fullWidth
              />
            </Stack>
          ) : (
            <Stack justifyContent="center" alignItems="flex-start" spacing={2}>
              <TextField
                label="Confirmation Details"
                value={confirmationDetails[0]}
                onChange={(e) =>
                  setConfirmationDetails((previous) => {
                    previous[0] = e.target.value;
                    return [...previous];
                  })
                }
                margin="normal"
                multiline={confirmationType === "other"}
                rows={confirmationType === "other" ? "3" : "1"}
                fullWidth={confirmationType === "other"}
                error={!confirmationDetails}
              />
            </Stack>
          )}
        </>
        {showWarning && (
          <CheckRepeatConfirmation
            confirmations={confirmationDetails}
            confirmationType={confirmationType}
            client={clientID?.id}
            loading={loading}
            isLoading={setLoading}
          />
        )}
      </Paper>
      <MoveStep
        nextStepAllowed={clientID !== "" && confirmationComplete && !loading}
        onChange={setFinal}
      />
    </>
  );
}

function OrderItemArea({
  itemList,
  setItemList,
  quotes,
  hasVat,
  currency,
  MoveStep,
}) {
  const [itemisNotEditing, setItemisNotEditing] = React.useState(true);

  const handleItemEditing = (flag) => {
    setItemisNotEditing(!flag);
  };

  const addCreatedItem = (item) => {
    setItemList((previous) => [
      ...previous,
      {
        po: item.partNumber,
        description: item.description,
        quantity: item.quantity,
        cost: item.price,
        margin_type: "percent",
        margin: 0,
        editing: false,
      },
    ]);
  };

  const addAllItems = (quote) => {
    setItemList((previous) => {
      const newList = quote.items.map((item) => ({
        po: item.partNumber,
        description: item.description,
        quantity: item.quantity,
        cost: item.price,
        margin_type: "percent",
        margin: 0,
        editing: false,
      }));
      return [...previous, ...newList];
    });
  };

  return (
    <>
      <Paper variant="outlined" sx={{ p: 1, maxWidth: "100%" }}>
        <Typography variant="h5" color="primary" sx={{ mb: 1 }}>
          Quotes
        </Typography>
        <Stack spacing={2}>
          {quotes.map((quote, index) => (
            <ItemsAreaQuote
              key={index}
              quote={quote}
              addItem={addCreatedItem}
              addAllItems={() => addAllItems(quote)}
            />
          ))}
        </Stack>
        <ItemsArea
          items={itemList}
          setItems={setItemList}
          setEditingItem={handleItemEditing}
          hasVAT={hasVat}
          currency={currency}
        />
      </Paper>
      <MoveStep nextStepAllowed={itemList.length > 0 && itemisNotEditing} />
    </>
  );
}

function ItemsAreaQuote({ quote, addItem, addAllItems }) {
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Card>
      <Typography variant="h6" sx={{ p: 1 }}>
        {quote.quote_no}
      </Typography>
      <Collapse in={open}>
        <CardContent>
          <Typography variant="h6">Details</Typography>
          <Typography>Company: {quote.company_name}</Typography>
          <Typography>Date Created: {quote.date_created}</Typography>
          <Typography>Date Quoted: {quote.date_quoted}</Typography>
          <Typography>Total: {quote.total}</Typography>
          <Divider sx={{ my: 2 }} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Part Number</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Unit Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quote.items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => addItem(item)}
                      startIcon={<AddIcon />}
                    >
                      Add Item
                    </Button>
                  </TableCell>
                  <TableCell>{item.partNumber}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{NumberFormat(item.price.toFixed(2))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button onClick={toggleOpen}>
          {open ? "Hide Details" : "Show Details"}
        </Button>
        <Button variant="contained" onClick={addAllItems}>
          Add All Items
        </Button>
      </CardActions>
    </Card>
  );
}

function Finalisation({ data, MoveStep }) {
  // Handling item pricing
  const orderItems = data.items;
  const vatAmount = data.vatExempt ? 1 : config.VAT;
  const total = orderItems.reduce((acc, item) => {
    let totalP;
    if (item.margin_type === "percent") {
      totalP = item.cost * (item.margin / 100 + 1) * item.quantity;
    } else {
      totalP =
        (parseFloat(item.cost) + parseFloat(item.margin)) * item.quantity;
    }
    return acc + totalP * vatAmount;
  }, 0);

  // Get client details
  const [clientDetails, setClientDetails] = React.useState({}),
    [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    if (loading)
      getClientByID(data.clientID.id).then((res) => {
        setClientDetails(res);
        setLoading(false);
      });
  }, [data.clientID, loading]);

  const OrderLine = ({ name, text }) => {
    return (
      <Grid container item xs={12}>
        <Grid item xs>
          <strong>{name}</strong>
        </Grid>
        <Grid item xs>
          {text}
        </Grid>
      </Grid>
    );
  };

  const currencySymbol = data?.currency === "USD" ? "$" : "KSH";

  return (
    <>
      <Paper variant="outlined" sx={{ p: 1, mb: 2, maxWidth: "100%" }}>
        <Typography variant="h4" align="center" color="primary">
          Please make sure everything is correct before submitting.
        </Typography>
        <Paper sx={{ px: 2, pt: 1 }}>
          <Typography variant="h6" gutterBottom>
            Order Details
          </Typography>
          <Divider />
          <Box sx={{ pl: 2, my: 2 }}>
            <Grid container spacing={2}>
              {loading ? (
                <>
                  <OrderLine name="Company" text={<Skeleton />} />
                  <OrderLine name="Email" text={<Skeleton />} />
                  <OrderLine name="Name" text={<Skeleton />} />
                </>
              ) : (
                <>
                  <OrderLine name="Company" text={clientDetails?.companyName} />
                  <OrderLine name="Email" text={clientDetails?.email} />
                  <OrderLine name="Name" text={clientDetails?.fullName} />
                </>
              )}
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
              <OrderLine name="Currency" text={data.currency} />
              <OrderLine
                name="VAT"
                text={data.vatExempt ? "VAT is Exempt" : "VAT is included"}
              />
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
              <OrderLine name="Confirmation" text={data.confirmationType} />
              <OrderLine
                name="Confirmation Details"
                text={data.confirmationDetails}
              />
            </Grid>
          </Box>

          <Typography variant="h6">Items</Typography>
          <Divider />
          <Box sx={{ pl: 2, my: 2 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Part Number</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Unit Price</TableCell>
                    {data.vatExempt ? (
                      <TableCell>Total Price</TableCell>
                    ) : (
                      <TableCell>Total Price (INC)</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderItems.map((item, index) => {
                    let totalP;
                    if (item.margin_type === "percent") {
                      totalP =
                        item.cost * (item.margin / 100 + 1) * item.quantity;
                    } else {
                      totalP =
                        (parseFloat(item.cost) + parseFloat(item.margin)) *
                        item.quantity;
                    }
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.po}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>
                          {currencySymbol}{" "}
                          {NumberFormat(
                            parseFloat(totalP / item.quantity).toFixed(2)
                          )}
                        </TableCell>
                        <TableCell>
                          {currencySymbol}{" "}
                          {data.vatExempt
                            ? NumberFormat(totalP.toFixed(2))
                            : NumberFormat((totalP * config.VAT).toFixed(2))}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider />
            <Divider sx={{ my: 1 }} />

            {!data.vatExempt && (
              <Typography align="right" sx={{ mb: 2, pr: 2 }}>
                <strong>Total before Tax: </strong>
                {currencySymbol} {NumberFormat((total / config.VAT).toFixed(2))}
              </Typography>
            )}

            <Typography align="right" sx={{ mb: 2, pr: 2, pb: 2 }}>
              <strong>Total </strong>
              {currencySymbol} {NumberFormat(total.toFixed(2))}
            </Typography>
          </Box>
        </Paper>
      </Paper>
      <MoveStep nextStepAllowed={true} />
    </>
  );
}
