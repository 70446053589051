import axios from "axios";
import * as rax from "retry-axios";
import { toast } from "react-toastify";
import config from "../Config";
import getDeviceFingerprint from "../Components/common/FingerPrint";

// Checks if the server is development or production

export function Check() {
  var debug = false;

  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  )
    debug = true;
  if (config.server.ForceDebug) debug = true;
  // We need to point to either the development server or the production server
  return debug ? config.server.dev.url : config.server.prod.url;
}

const axiosMain = axios.create({
  baseURL: `${Check()}/main`,
});

axiosMain.defaults.raxConfig = {
  instance: axiosMain,
  retry: 10,
  noResponseRetries: 5,
  onRetryAttempt: (err) => {
    const cfg = rax.getConfig(err);
    console.log(
      `Retry Main attempt #${cfg.currentRetryAttempt} ${cfg.instance.defaults.baseURL}`
    );
  },
};
rax.attach(axiosMain);

axiosMain.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code === "ERR_CANCELED") {
      // aborted in useEffect cleanup
      return Promise.resolve({ status: 499 });
    }

    if (!error.response?.status) {
      toast.error(
        "The server is not responding, please reload or try again later.",
        { toastId: "ERR_CONNECTION_REFUSED" }
      );
    }
    throw error;
  }
);

export function ping() {
  if (
    window.location.protocol === "http:" &&
    (!window.location.hostname === "localhost" ||
      !window.location.hostname === "127.0.0.1")
  ) {
    window.location.href = window.location.href.replace("http:", "https:");
  }
  return axiosMain.get(`/ping`).then((res) => {
    return res.data;
  });
}

export function Test() {
  return axiosMain
    .get(`/test`, {
      params: {
        deviceUID: getDeviceFingerprint(),
      },
    })
    .then((res) => {
      toast.info(res.data.message, { autoClose: 6000 });
      return res;
    });
}

export function purgeDB() {
  return axiosMain.get(`/purge`).then((res) => {
    toast.info(res.data.message, { autoClose: 6000 });
    return res;
  });
}
