import React from "react";
import { Typography } from "@mui/material";

import AdminSummary from "../Components/common/AdminSummary";
import AdminPerformanceReport from "../Components/common/AdminPerformanceReport";
import {
  getAccountManagerAccess,
  getItemReportAccess,
} from "../API/SalesAdmin";
import DropDownArea from "../Components/common/DropDownArea";
import ItemReports from "../Components/common/ItemReports";
import OrderReports from "../Components/common/OrderReports";

export default function Reports() {
  return (
    <>
      <Typography variant="h5" color="primary">
        Reports
      </Typography>

      <CheckAccess
        label="Account Manager Summary"
        name="accManagerReport"
        getAccess={getAccountManagerAccess}
      >
        <AdminSummary />
      </CheckAccess>

      <CheckAccess
        label="Account Manager Performance"
        name="accPerfReport"
        getAccess={getAccountManagerAccess}
      >
        <AdminPerformanceReport />
      </CheckAccess>

      <CheckAccess
        label="Item Reports"
        name="itemReports"
        getAccess={getItemReportAccess}
      >
        <ItemReports />
      </CheckAccess>

      <CheckAccess
        label="Order Reports"
        name="orderReports"
        getAccess={getItemReportAccess}
      >
        <OrderReports />
      </CheckAccess>
    </>
  );
}

function CheckAccess({ label, name, getAccess, children }) {
  const [canAccess, setCanAccess] = React.useState(false),
    [open, setOpen] = React.useState(
      localStorage.getItem(name) === "true" || false
    );
  const [fullScreen, setFullScreen] = React.useState(false);

  React.useEffect(() => {
    localStorage.setItem(name, open);
  }, [name, open]);

  React.useEffect(() => {
    getAccess().then((res) => {
      if (res.access === true) {
        setCanAccess(true);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!canAccess) {
    return null;
  }

  return (
    <DropDownArea
      name={label}
      open={open}
      setOpen={setOpen}
      fullScreen={fullScreen}
      setFullScreen={setFullScreen}
    >
      {children}
    </DropDownArea>
  );
}
