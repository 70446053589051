import React from "react";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
  Dialog,
  Typography,
  Box,
  Slide,
  alpha,
  Tooltip,
  Stack,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Close,
  Create,
  Refresh,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { SessionManager } from "react-session.manager.sk";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "../Styles/PhoneNumber.css";

import {
  login,
  createAccount,
} from "../../CustomAxios/Protected/AccountManagment";
import config from "../../Config";

import { Link, useNavigate } from "react-router-dom";
import { PasswordValidator } from "../common/Validator";
import Logo from "../../logo";

export default function CreateAccountForm(props) {
  const { setHeader } = React.useContext(SessionManager);
  const [email, setEmail] = React.useState("");
  const [fname, setFName] = React.useState("");
  const [lname, setLName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [cpassword, setCPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorText, setEText] = React.useState("");
  const [errorText2, setEText2] = React.useState("");
  const [dis, setDis] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let history = useNavigate();

  const handleReset = () => {
    setEmail("");
    setFName("");
    setLName("");
    setPhone(config.phoneNumber.Value);
    setTitle("");
    setPassword("");
    setCPassword("");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (password || cpassword) {
      const Data = PasswordValidator(password, cpassword);
      setError(!Data.valid);
      setEText2(Data.message);
      setEText(Data.message2);
    }
  }, [password, cpassword]);

  React.useEffect(() => {
    if (!error && email && fname && lname && phone && password && title) {
      setDis(false);
    } else setDis(true);
  }, [password, cpassword, email, fname, lname, phone, title, error]);

  const { deviceUID } = React.useContext(SessionManager);

  const handleCreateAC = (event) => {
    event.preventDefault();
    setLoading(true);
    createAccount({
      email: email + "@tec-today.com",
      fname: fname,
      lname: lname,
      password: password,
      telephone: phone,
      title: title,
    }).then((data) => {
      setLoading(false);
      if (data) {
        login(
          {
            email: email + "@tec-today.com",
            password: password,
            remember: true,
            deviceUID: deviceUID,
          },
          setHeader,
          history
        );
        props.handleClose();
      }
    });
  };

  const sidePanelCSS = {
    position: "absolute",
    bgcolor: "background.paper",
    height: "100vh",
    minWidth: "40vw",
    right: 0,
    boxShadow: 5,
    maxWidth: { sm: "100%", md: "40vw" },
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "auto",
  };

  const sidePanelContentCSS = {
    p: 2,
  };

  return (
    <Dialog open={true} fullScreen>
      <Box
        sx={{
          background: (theme) =>
            `linear-gradient(135deg, ${alpha(
              theme.palette.primary.dark,
              0.5
            )}, ${theme.palette.background.default})`,
          display: "flex",
          height: "100%",
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            ml: 20,
            mt: 20,
            maxWidth: "50vw",
          }}
        >
          <Logo />
          <Typography
            variant="h5"
            sx={{
              mt: 3,
              ml: 3,
              color: "white",
            }}
          >
            Employee Portal
          </Typography>
        </Box>

        <Tooltip title="Close" placement="top" arrow>
          <IconButton
            component={Link}
            to="/"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: 0,
              padding: 2,
              zIndex: 100,
            }}
          >
            <Close />
          </IconButton>
        </Tooltip>

        <Slide
          direction="left"
          in={true}
          timeout={{ appear: 500, enter: 300, exit: 0 }}
          mountOnEnter
          unmountOnExit
        >
          <Box sx={sidePanelCSS}>
            <Box sx={sidePanelContentCSS}>
              <form>
                <Stack spacing={2} alignItems="center" sx={{ mb: 5 }}>
                  <Box
                    sx={{
                      bgcolor: "primary.main",
                      color: "primary.contrastText",
                      borderRadius: "50%",
                      height: 60,
                      width: 60,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: 2,
                    }}
                  >
                    <Create sx={{ fontSize: 40 }} />
                  </Box>
                  <Typography variant="h4" align="center">
                    Create Account
                  </Typography>
                </Stack>
                <Typography>
                  If you dont have an account, you can create one here.
                </Typography>
                <TextField
                  margin="normal"
                  required
                  label="Email"
                  type="email"
                  value={email}
                  onInput={(e) => setEmail(e.target.value)}
                  InputLabelProps={{ style: { paddingLeft: "15px" } }}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>@tec-today.com</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  label="First Name"
                  type="text"
                  value={fname}
                  onInput={(e) => setFName(e.target.value)}
                  InputLabelProps={{ style: { paddingLeft: "15px" } }}
                  fullWidth
                />
                <TextField
                  margin="normal"
                  required
                  label="Last Name"
                  value={lname}
                  onInput={(e) => setLName(e.target.value)}
                  InputLabelProps={{ style: { paddingLeft: "15px" } }}
                  fullWidth
                />
                <TextField
                  margin="normal"
                  required
                  label="Job Title"
                  value={title}
                  onInput={(e) => setTitle(e.target.value)}
                  InputLabelProps={{ style: { paddingLeft: "15px" } }}
                  fullWidth
                />
                <PhoneInput
                  country={config.phoneNumber.Default}
                  value={phone}
                  onChange={(e) => setPhone(e)}
                  specialLabel="Phone Number"
                  required
                />
                <FormControl required fullWidth error={error} margin="normal">
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          colour="secondary"
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {error && (
                    <FormHelperText>
                      {errorText2.map((val, index) => {
                        return (
                          <span key={`passhelp_${index}`}>
                            {val}
                            <br />
                          </span>
                        );
                      })}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl required fullWidth error={error} margin="normal">
                  <InputLabel htmlFor="Confirm-Password">
                    Confirm Password
                  </InputLabel>
                  <Input
                    id="Confirm-Password"
                    type={showPassword ? "text" : "password"}
                    value={cpassword}
                    onChange={(e) => setCPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          colour="secondary"
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {error && <FormHelperText>{errorText}</FormHelperText>}
                </FormControl>

                <Stack spacing={2} direction="column" sx={{ mt: 2 }}>
                  <LoadingButton
                    onClick={handleCreateAC}
                    variant="contained"
                    type="submit"
                    color="primary"
                    startIcon={<Create />}
                    autoFocus={true}
                    disabled={dis}
                    loading={loading}
                  >
                    Create Account
                  </LoadingButton>

                  <Button
                    onClick={handleReset}
                    color="warning"
                    variant="outlined"
                    startIcon={<Refresh />}
                  >
                    Reset
                  </Button>
                </Stack>
              </form>
            </Box>
          </Box>
        </Slide>
      </Box>
    </Dialog>
  );
}
