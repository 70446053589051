import React from "react";
import {
  Typography,
  Stack,
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Box,
} from "@mui/material";
import { Chip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import UniversalTable from "../../Components/common/UniversalTable";

import FilterArea from "../../Components/common/FilterArea";
import {
  getOrders,
  getOrderPages,
  getUserCompanies,
  editOrderNote,
} from "../API/Cheques&Pos";
import CreateOrder from "../Components/Forms/CreateOrder";
import FloatingFormButton from "../../Components/common/FloatingFormButton";

import { dateRange, convertToBool } from "../Components/common/FilterUtility";
import {
  LoadingProvider,
  LoadingContext,
} from "../Components/common/LoadingProvider";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import NumberFormat from "../../Components/common/NumberFormater";
import { History, ListAltOutlined, ThumbUp } from "@mui/icons-material";

export default function Main() {
  // advanced search
  const [currency, setCurrency] = React.useState(""),
    [companyName, setCompanyName] = React.useState(""),
    [status, setStatus] = React.useState(""),
    [exempt, setExempt] = React.useState(""),
    [confirmation, setConfirmation] = React.useState(""),
    [date, setDate] = React.useState("");

  const getFilterData = (filter) => {
    const from = new Date(date[1]);
    const to = new Date(date[2]);

    const data = filter
      ? {
          currency,
          companyName,
          confirmation,
          status: convertToBool(status, "Completed", [true, false]),
          exempt: convertToBool(exempt, "Yes", [true, false]),
          from:
            date[0] === "less" || date[0] === ""
              ? null
              : from.toLocaleString("en-GB", { timeZone: "UTC" }),
          to:
            date[0] === "greater" || date[0] === ""
              ? null
              : to.toLocaleString("en-GB", { timeZone: "UTC" }),
          currentPage: 1,
        }
      : {
          currency: null,
          companyName: null,
          confirmation: null,
          status: null,
          exempt: null,
          from: null,
          to: null,
          currentPage: 1,
        };
    return data;
  };
  return (
    <LoadingProvider
      getFilterData={getFilterData}
      getData={getOrders}
      getPages={getOrderPages}
    >
      <LoadingWrapper
        setCurrency={setCurrency}
        currency={currency}
        setCompanyName={setCompanyName}
        companyName={companyName}
        setStatus={setStatus}
        status={status}
        setExempt={setExempt}
        exempt={exempt}
        setDate={setDate}
        date={date}
        setConfirmation={setConfirmation}
        confirmation={confirmation}
      />
    </LoadingProvider>
  );
}

function LoadingWrapper({
  setCurrency,
  currency,
  setCompanyName,
  companyName,
  setStatus,
  status,
  setExempt,
  exempt,
  setDate,
  date,
  setConfirmation,
  confirmation,
}) {
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loading,
    data,
    gotAll,
    allData,
    setData,
    loadFiltered,
  } = React.useContext(LoadingContext);

  const [openModal, setOpenModal] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [value, setValue] = React.useState({});

  const startEdit = (note) => {
    const data = {
      id: value.orderID,
      note,
    };

    setEditing(true);
    editOrderNote(data)
      .then((res) => {
        toast.success(res);
        setEditing(false);
        setOpenModal(false);
        loadFiltered();
      })
      .catch((err) => {
        console.log(err);
        setEditing(false);
        toast.error(
          "There was an issue updating the order, if this persists please contact support"
        );
      });
  };

  // Table components
  const ChipStatus = ({ value }) => (
    <Chip
      label={value ? "Completed" : "Pending"}
      color={value ? "success" : "warning"}
    />
  );
  const EditButton = ({ value }) => (
    <Tooltip title="Edit Order Note">
      <IconButton
        onClick={() => {
          setValue(value);
          setOpenModal(true);
        }}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
  //sub table data
  const items = [
    {
      id: "partNumber",
      label: "Part Number",
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "quantity",
      label: "Quantity",
    },
    {
      id: "price",
      label: "Unit Price EXC",
      component: (value) => NumberFormat(Number(value.price).toFixed(2)),
    },
  ];
  const statusInfo = [
    {
      id: "date",
      searchable: true,
      label: "Date Updated",
      date: true,
    },
    {
      id: "status",
      searchable: true,
      label: "Status",
    },
    {
      id: "note",
      searchable: true,
      label: "Note",
    },
  ];
  const confirmations = [
    {
      id: "type",
      searchable: true,
      label: "Type",
    },
    {
      id: "value",
      searchable: true,
      label: "Details",
    },
  ];
  //Table headers
  const headers = [
    {
      id: "items",
      subRow: true,
      label: "",
      iconColor: "success",
      headers: items,
      subTitle: "Items",
      openIcon: <ListAltOutlined />,
    },
    {
      id: "history",
      subRow: true,
      label: "",
      iconColor: "info",
      headers: statusInfo,
      subTitle: "Status",
      openIcon: <History />,
    },
    {
      id: "confirmations",
      subRow: true,
      label: "",
      iconColor: "info",
      headers: confirmations,
      subTitle: "Confirmations",
      openIcon: <ThumbUp />,
    },
    {
      id: "orderID",
      label: "Order Number",
      searchable: true,
    },
    {
      id: "company_name",
      label: "Company",
      searchable: true,
    },
    {
      id: "date_created",
      label: "Date Created",
      searchable: true,
      date: true,
    },
    {
      id: "total",
      label: "Total",
      searchable: true,
    },
    {
      id: "completedStatus",
      label: "Completed",
      component: ({ completed }) => <ChipStatus value={completed} />,
    },
    {
      id: "edit",
      label: "",
      component: (values) => <EditButton value={values} />,
    },
  ];

  const [open, setOpen] = React.useState(false);

  const [clear, setClear] = React.useState(false);

  // advanced search functions

  const handleAdvancedSearch = () => {
    const findConfirmation = (item) => {
      return [item.value.toLowerCase(), ""].includes(
        confirmation.toLowerCase()
      );
    };
    if (gotAll) {
      const filtered = allData.filter(
        (data) =>
          data.company_name
            .toLowerCase()
            .includes(companyName.toLocaleLowerCase()) &&
          [data.completed, null].includes(convertToBool(status, "Completed")) &&
          [data.exempt, null].includes(convertToBool(exempt, "Yes")) &&
          data.currency.includes(currency) &&
          data.confirmations.find(findConfirmation) &&
          dateRange(date[1], date[2], data.date_created)
      );
      setData(filtered);
      return;
    }
    loadFiltered();
  };
  const clearAdvancedSearch = () => {
    setCompanyName("");
    setCurrency("");
    setStatus("");
    setExempt("");
    setDate(["", null, null]);
    setConfirmation("");
    setClear(true);
  };

  return (
    <>
      <Dialog
        maxWidth={"lg"}
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ p: 2 }}
      >
        <EditNote
          data={value}
          editing={editing}
          startEdit={startEdit}
          setOpenModal={setOpenModal}
        />
      </Dialog>

      <Typography variant="h4" color="primary" gutterBottom>
        Orders
      </Typography>

      <FloatingFormButton
        title="Create Order"
        open={open}
        setOpen={setOpen}
        DialogFullScreen
      >
        <CreateOrder
          onComplete={() => {
            setOpen(false);
            loadFiltered();
          }}
        />
      </FloatingFormButton>
      <Box>
        <FilterArea
          categories={[
            {
              label: "Company",
              type: "combo",
              value: companyName,
              setValue: setCompanyName,
              getData: getUserCompanies,
              clear,
              setClear,
            },
            {
              label: "Currency",
              type: "select",
              options: ["KES", "USD"],
              value: currency,
              setValue: setCurrency,
            },
            {
              label: "Order Status",
              type: "select",
              options: ["Completed", "Pending"],
              value: status,
              setValue: setStatus,
            },
            {
              label: "Vat Exempt",
              type: "select",
              options: ["Yes", "No"],
              value: exempt,
              setValue: setExempt,
            },
            {
              label: "Confirmations",
              type: "search",
              value: confirmation,
              setValue: setConfirmation,
            },
            {
              label: "Date Created",
              type: "date",
              options: [],
              value: date,
              setValue: setDate,
            },
          ]}
          startFilter={handleAdvancedSearch}
          clearFilter={clearAdvancedSearch}
          isLoading={loading}
        />
      </Box>

      <UniversalTable
        name="All Orders"
        headers={headers}
        data={data}
        loading={loading}
        lazyloading={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
        setLoading={loadFiltered}
      />
    </>
  );
}

function EditNote({ data, editing, startEdit, setOpenModal }) {
  const [note, setNote] = React.useState(data?.note),
    [disabled, setDisabled] = React.useState(true);

  const startNote = data?.note;

  React.useEffect(() => {
    if (note === startNote) setDisabled(true);
    else setDisabled(false);
  }, [note, startNote]);

  return (
    <DialogContent sx={{ width: "400px", p: 2 }}>
      <Stack spacing={2}>
        <Typography color="primary" variant="h5">
          Edit Order Note
        </Typography>
        <TextField
          label="Note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          multiline
          rows={5}
        />
      </Stack>
      <DialogActions>
        <LoadingButton
          loading={editing}
          variant="contained"
          onClick={() => startEdit(note)}
          disabled={disabled}
        >
          Edit Note
        </LoadingButton>
        <Button
          variant="outlined"
          color="warning"
          onClick={() => setOpenModal(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </DialogContent>
  );
}
