import React from "react";

import {
  Divider,
  Stack,
  TextField,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Fade,
} from "@mui/material";
import { Box } from "@mui/system";
import NumberFormater from "../../../Components/common/NumberFormater";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";

export default function ItemInfo({
  property,
  name,
  value,
  setValue,
  type,
  id,
  noTitle,
  currency,
  onComplete,
  styleProps,
  inputProps,
  hover = true,
}) {
  const [holder, setHolder] = React.useState(value);
  const [edit, setEdit] = React.useState(false);

  React.useEffect(() => {
    setHolder(value);
  }, [value]);

  const submitValue = () => {
    if (checkIsNumber()) return;
    setValue(holder);
    setEdit(false);
    onComplete(property, holder, id);
  };
  const checkKey = (event) => {
    if (checkIsNumber()) return;
    if (event.key === "Enter") {
      submitValue();
    }
  };

  const checkIsNumber = () => {
    let invalid = false;
    // when a non number is passed to a number-input then the input value
    //becomes an empty string

    if (type === "number" && (holder === "" || holder < 0)) {
      invalid = true;
    }
    return invalid;
  };

  const cancelEdit = () => {
    setHolder(value);
    setEdit(false);
  };

  return (
    <Box sx={{ p: 1, pr: 2, ...styleProps }}>
      {!noTitle && (
        <Divider textAlign="left">
          <Typography varaint="h6" sx={{ fontWeight: "bold" }}>
            {name}
          </Typography>
        </Divider>
      )}
      {edit ? (
        <>
          <TextField
            variant="filled"
            value={holder}
            label={name}
            sx={{ mb: 1, ml: 1 }}
            onKeyDown={checkKey}
            onChange={(e) => setHolder(e.target.value)}
            multiline={type === "text"}
            type={type === "number" ? "number" : "text"}
            inputProps={
              type === "number" ? { min: 0, ...inputProps } : inputProps
            }
            error={type === "number" ? checkIsNumber() : false}
            helperText={
              type === "number"
                ? checkIsNumber()
                  ? "Must be a Number"
                  : ""
                : ""
            }
            fullWidth
          />
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={submitValue}
              variant="contained"
              color="success"
              startIcon={<CheckIcon />}
              disabled={checkIsNumber()}
            >
              Save
            </Button>
            <Button
              onClick={cancelEdit}
              variant="outlined"
              color="warning"
              startIcon={<ClearIcon />}
            >
              Cancel
            </Button>
          </Stack>
        </>
      ) : (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            pl: 2,
            my: 0.5,
          }}
        >
          <Typography>
            {property === "price"
              ? ` ${currency} ${NumberFormater(Number(value).toFixed(2))}`
              : value}
          </Typography>

          <Fade in={hover}>
            <Tooltip title="Edit">
              <IconButton onClick={() => setEdit(true)} sx={{ ml: 1 }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Fade>
        </Stack>
      )}
    </Box>
  );
}
