import React from "react";
import { toast } from "react-toastify";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Stack,
  Collapse,
  Alert,
  AlertTitle,
} from "@mui/material";
import SalesQuoter from "../common/SalesQuoter";
import { createQuote, getQuotePDF } from "../../API/Sales";
import { Link } from "react-router-dom";

import NoteAddIcon from "@mui/icons-material/NoteAdd";

export default function CreateQuote({
  onComplete,
  duplicateData,
  handleClose = () => {},
}) {
  const [handlingSubmit, setHandlingSubmit] = React.useState(false),
    [createText, setCreateText] = React.useState("Creating Quote"),
    [quoteCreated, setQuoteCreated] = React.useState(false),
    [quoteRef, setQuoteRef] = React.useState("");

  const [verificationFailed, setVerificationFailed] = React.useState(false);

  const handleSubmit = (Data) => {
    setCreateText("Creating Quote");
    setHandlingSubmit(true);

    createQuote(Data).then((res) => {
      if (res.status === "success") {
        setCreateText(res.message);
        setQuoteRef(res.quote_no);
        getQuotePDF(res.quote_no, res.company_name)
          .then((res) => {
            toast.success(res.message);
            setHandlingSubmit(false);
            setQuoteCreated(true);
          })
          .catch((err) => {
            if (err.response.status === 405) setVerificationFailed(true);
          });
      } else if (res.status === "partial") {
        setCreateText(res.message);
        setQuoteRef(res.quote_no);
        getQuotePDF(res.quote_no, res.company_name).then((res) => {
          toast.success(res.message);
          setHandlingSubmit(false);
          setQuoteCreated(true);
        });
      } else {
        toast.error(res.message);
        setHandlingSubmit(false);
        setQuoteCreated(false);
        setQuoteRef("");
      }
    });
  };

  return (
    <Box p={5}>
      <Typography variant="h4" color="primary" sx={{ mb: 5 }}>
        Create New Quote
      </Typography>

      <SalesQuoter
        onSubmit={handleSubmit}
        submitText="Create Quote"
        submitIcon={<NoteAddIcon />}
        canRecoverQuote
        quoteData={duplicateData}
        handleClose={handleClose}
      />

      <Dialog open={handlingSubmit} PaperProps={{ elevation: 0 }}>
        <DialogTitle sx={{ whiteSpace: "pre-wrap" }}>
          Creating Quote
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{createText}</DialogContentText>
          <DialogContentText>
            {!verificationFailed &&
              "A PDF will be generated and downloaded shortly."}
          </DialogContentText>
          <Collapse in={!verificationFailed}>
            <Stack direction="row" justifyContent={"center"} sx={{ my: 2 }}>
              <CircularProgress />
            </Stack>
          </Collapse>
        </DialogContent>
        <Collapse in={verificationFailed}>
          <Alert severity="error" sx={{ borderRadius: 0 }}>
            <AlertTitle>Quote Created</AlertTitle>
            <Typography>PDF not generated</Typography>
            <Typography>Client is not verified</Typography>
            <Typography>
              Please ask an admin to verify the client In order to get a pdf
            </Typography>
          </Alert>
          <DialogActions>
            <Button
              variant="outlined"
              color="warning"
              onClick={onComplete}
              component={Link}
              to={`/quoteManager?r=true`}
            >
              Close
            </Button>
          </DialogActions>
        </Collapse>
      </Dialog>

      <Dialog open={quoteCreated} PaperProps={{ elevation: 0 }}>
        <DialogTitle>Your quote has been created</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you would like to change anything in the quote or there was an
            issue, you can edit the quote by clicking the edit button.
          </DialogContentText>
          <DialogContentText>{quoteRef}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="primary"
              onClick={onComplete}
              component={Link}
              to={`/quoteManager/edit?qno=${encodeURIComponent(quoteRef)}`}
            >
              Edit Quote {quoteRef}
            </Button>
            <Button
              variant="outlined"
              color="warning"
              onClick={onComplete}
              component={Link}
              to={`/quoteManager?r=true`}
            >
              Close
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
