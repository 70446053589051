import React from "react";

import OrderReports from "../Components/common/OrderReports";
import { useLocation } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Analytics, Fullscreen } from "@mui/icons-material";

// Get  start and end date from url params

export default function OrderReport() {
  const urlDets = new URLSearchParams(useLocation().search);
  const from = urlDets.get("f");
  const to = urlDets.get("t");
  const fullScreenURL = urlDets.get("fs");

  const [fullScreen, setFullScreen] = React.useState(
    fullScreenURL === "1" || false
  );

  React.useEffect(() => {
    const rootElement = document.getElementById("root");
    if (fullScreen) {
      rootElement.style.display = "none";
    } else {
      rootElement.style.display = "";
    }
  }, [fullScreen]);

  if (fullScreen)
    return (
      <Dialog
        fullScreen
        open={fullScreen}
        onClose={() => setFullScreen(false)}
        scroll="paper"
      >
        <DialogTitle sx={{ p: 0 }} component={"div"}>
          <IconButton
            onClick={() => setFullScreen(!fullScreen)}
            sx={{
              float: "right",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: 0,
            }}
            size="large"
          >
            <Fullscreen />
          </IconButton>
          <Typography variant="h4" align="center" sx={{ pt: 2 }} gutterBottom>
            <Analytics fontSize="large" sx={{ mb: -1 }} /> Weekly Order Report
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ bgcolor: "background.paper" }}>
          <OrderReports from={from} to={to} lowPrivledge />
        </DialogContent>
      </Dialog>
    );

  return (
    <Paper elevation={0}>
      <IconButton
        onClick={() => setFullScreen(!fullScreen)}
        sx={{
          float: "right",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 0,
        }}
        size="large"
      >
        <Fullscreen />
      </IconButton>
      <Typography variant="h4" align="center" sx={{ pt: 2 }} gutterBottom>
        <Analytics fontSize="large" sx={{ mb: -1 }} /> Weekly Order Report
      </Typography>

      <OrderReports from={from} to={to} lowPrivledge />
    </Paper>
  );
}
