import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

export default function AddItemForm({ onClose, addPendingItem }) {
  const [description, setDescription] = React.useState(""),
    [disabled, setDisabled] = React.useState(true),
    [quantity, setQuantity] = React.useState(1);

  React.useEffect(() => {
    if (description && quantity !== "" && quantity > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [quantity, description]);

  const handleSubmit = () => {
    const Data = {
      description,
      quantity,
    };
    addPendingItem(Data);
  };

  return (
    <>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            label="Name"
            value={description}
            fullWidth
            onChange={(e) => setDescription(e.target.value)}
            maxRows={5}
            multiline
            required
            autoFocus
          />
          <TextField
            label="Quantity"
            value={quantity}
            type="number"
            onWheel={(e) => e.target.blur()}
            required
            onChange={(e) => setQuantity(e.target.value)}
            inputProps={{ min: 1 }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          startIcon={<AddIcon />}
          variant="contained"
          onClick={handleSubmit}
          disabled={disabled}
        >
          Add Item
        </LoadingButton>
        <Button onClick={onClose} color="warning" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}
