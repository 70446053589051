import React from "react";
import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import {
  Badge,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Alert,
  AlertTitle,
  Slide,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import { SessionManager } from "react-session.manager.sk";
import { Link } from "react-router-dom";
import ThemeSlider from "./ThemeSlider";
import Refresh from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { DoNotTouch, TouchApp } from "@mui/icons-material";

export default function ProfileCard(props) {
  const { children } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userInfo, setRefreshData, refreshData } =
    React.useContext(SessionManager);
  const notifCount = userInfo?.notifCount || 0;

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

  const [hidden, setHidden] = React.useState(true);

  // use this to change the badge color
  const badgeColour = "secondary";

  const initials = `${userInfo?.firstName?.charAt(
    0
  )}${userInfo?.lastName?.charAt(0)}`;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "ProfileCard" : undefined;
  const [notifAlert, setNotifAlert] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      if (notifCount > 9) {
        setNotifAlert(true);
      }
    }, 2000);
  }, [notifCount]);

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        sx={{ padding: 0 }}
        size="large"
      >
        <Badge badgeContent={notifCount} color={badgeColour}>
          <Avatar sx={{ background: theme.palette.primary.main }}>
            {initials}
          </Avatar>
        </Badge>
      </IconButton>

      <Slide direction="down" in={notifAlert}>
        <Alert
          severity="info"
          onClose={() => setNotifAlert(false)}
          sx={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 1000 }}
        >
          <AlertTitle>You have a lot of Notifications</AlertTitle>
          Check your notifications for more information{" "}
          <Button
            component={Link}
            to="/notifications"
            size="small"
            onClick={() => setNotifAlert(false)}
          >
            View Notifications
          </Button>
        </Alert>
      </Slide>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isMobile ? "right" : "center",
        }}
      >
        <Card sx={{ maxWidth: "300px", minWidth: "300px" }}>
          <CardContent
            sx={{
              background: (theme) => theme.palette.background.default,
            }}
          >
            <Box sx={{ ml: 1, p: 1, position: "absolute", top: 0, right: 0 }}>
              {refreshData ? (
                <Tooltip
                  title="Cancel"
                  onMouseEnter={() => setHidden(false)}
                  onMouseLeave={() => setHidden(true)}
                >
                  <IconButton
                    onClick={() => setRefreshData(false)}
                    size="small"
                    color="primary"
                  >
                    <CircularProgress
                      size={20}
                      sx={{ display: !hidden ? "none" : "block" }}
                    />
                    <CloseIcon sx={{ display: hidden ? "none" : "block" }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Refresh Data">
                  <IconButton
                    onClick={() => setRefreshData(true)}
                    size="small"
                    color="primary"
                  >
                    <Refresh />
                  </IconButton>
                </Tooltip>
              )}
            </Box>

            <Grid container alignContent="center" justifyContent="center">
              <Grid item xs={3}>
                <Avatar
                  sx={{
                    background: (theme) => theme.palette.primary.main,
                    width: (theme) => theme.spacing(7),
                    height: (theme) => theme.spacing(7),
                    margin: "5px",
                  }}
                >
                  {initials}
                </Avatar>
              </Grid>
            </Grid>
            <Typography align="center" gutterBottom>
              {userInfo?.firstName} {userInfo?.lastName}
              <br />
              {userInfo?.email}
            </Typography>
            <Typography align="center">
              <Button
                component={Link}
                variant="outlined"
                to="/settings/profile"
                startIcon={<ManageAccountsIcon />}
              >
                Manage account
              </Button>
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Badge badgeContent={notifCount} color={badgeColour}>
              <Button
                variant="contained"
                component={Link}
                to="/notifications"
                startIcon={
                  notifCount ? (
                    <NotificationsActiveIcon />
                  ) : (
                    <NotificationsIcon />
                  )
                }
              >
                Notifications
              </Button>
            </Badge>
            <Divider sx={{ my: 2 }} />
            <ThemeSlider buttonVariant />
            <AppBarHoverToggle />
          </CardContent>
          <CardActions>
            <div style={{ marginLeft: "auto" }}>{children}</div>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
}

function AppBarHoverToggle() {
  const [disableHover, setDisableHover] = React.useState(
    localStorage.getItem("disableHover") === "true"
  );

  const handleToggle = () => {
    localStorage.setItem("disableHover", !disableHover);
    setDisableHover(!disableHover);
    window.dispatchEvent(new Event("storage"));
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Typography variant="overline" sx={{ display: "block" }}>
        App Bar Hover
      </Typography>
      <ToggleButtonGroup
        value={disableHover}
        exclusive
        onChange={handleToggle}
        aria-label="text alignment"
        size="small"
      >
        <ToggleButton value={false} aria-label="left aligned">
          <TouchApp />
          Enabled
        </ToggleButton>
        <ToggleButton value={true} aria-label="centered">
          <DoNotTouch />
          Disabled
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
