import React from "react";
import { SessionManager } from "react-session.manager.sk";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, AlertTitle, Collapse, LinearProgress } from "@mui/material";

export default function GetHighestPage() {
  const { userInfo } = React.useContext(SessionManager);

  const urlDets = new URLSearchParams(useLocation().search); // eslint-disable-line
  const navigate = useNavigate();

  const [redirectError, setRedirectError] = React.useState(false);

  React.useEffect(() => {
    setRedirectError(false);
    class pageWeight {
      constructor(category, name, weight, urlFormat, addExtra = false) {
        this.category = category;
        this.name = name;
        this.weight = weight;
        this.urlFormat = urlFormat;
        this.addExtra = addExtra;
      }
    }

    const pages = [
      new pageWeight("order", "Orders", 1, "/orders"),
      new pageWeight(
        "order",
        "Logistics",
        2,
        "/logistics/manage_status?i=",
        true
      ),
      new pageWeight(
        "order",
        "Admin Orders",
        3,
        "/adminOrderManager/overview?i=",
        true
      ),
      new pageWeight("quotepdf", "Quotes", 1, "/quoteManager/edit?qno=", true),
      new pageWeight(
        "quotepdf",
        "Quote Viewer",
        2,
        "/quoteViewer?dwn=true&qno=",
        true
      ),
    ];

    let cat = pages.filter((page) => page.category === urlDets.get("c"));
    let found = cat.filter((page) => userInfo.pages.includes(page.name));

    if (found.length === 0) setRedirectError(true);

    if (found.length > 0) {
      let highest = found.reduce((prev, current) =>
        prev.weight > current.weight ? prev : current
      );
      let url = highest.urlFormat;
      if (highest.addExtra) {
        url += urlDets.get("i");
      }

      navigate(url);
    }
  }, [urlDets, userInfo, navigate]);

  return (
    <>
      <Alert severity="info">
        <AlertTitle>Redirecting...</AlertTitle>
        Please wait while we redirect you to the highest page you have access
        to.
        <LinearProgress sx={{ my: 2 }} />
      </Alert>

      <Collapse in={redirectError}>
        <Alert severity="error" sx={{ mt: 2 }}>
          <AlertTitle>Access Denied</AlertTitle>
          No pages found for this category. Please contact your administrator.
        </Alert>
      </Collapse>
    </>
  );
}
