import React from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Skeleton,
  Stack,
  DialogTitle,
  DialogContentText,
  CircularProgress,
  IconButton,
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

import SalesQuoter from "../common/SalesQuoter";
import { Box } from "@mui/system";
import { editQuote, getQuoteByID, getQuotePDF } from "../../API/Sales";
import { toast } from "react-toastify";

import SaveIcon from "@mui/icons-material/Save";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import { Close } from "@mui/icons-material";

export default function Main() {
  const [quoteData, setQuoteData] = React.useState({}),
    [loading, setLoading] = React.useState(true),
    [handlingSubmit, setHandlingSubmit] = React.useState(false),
    [handlingText, setHandlingText] = React.useState(""),
    [getPDF, setGetPDF] = React.useState(false),
    [companyName, setCompanyName] = React.useState("");
  const [edited, setEdited] = React.useState(false);

  let history = useNavigate();
  const { loadFiltered, origin } = useOutletContext();

  const goBack = () => {
    if (edited) loadFiltered();
    history(origin);
  };

  const urlDets = new URLSearchParams(useLocation().search),
    QuoteNo = urlDets.get("qno");

  React.useEffect(() => {
    if (loading)
      getQuoteByID(QuoteNo)
        .then((res) => {
          if (res.status === "success") {
            setQuoteData(res.data);
            setLoading(false);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(
            "There was an issue loading the quote, if this persists please contact support"
          );
        });
  }, [loading, QuoteNo]);

  const downloadPDF = () => {
    setHandlingText("Downloading PDF");
    setGetPDF(false);
    getQuotePDF(QuoteNo, companyName)
      .then((res) => {
        toast.success(res.message);
        goBack();
      })
      .catch((err) => {
        toast.error(
          "There was an issue downloading the PDF, if this persists please contact support"
        );
      })
      .finally(() => {
        setHandlingSubmit(false);
      });
  };

  const handleSubmit = (Data) => {
    const FinalData = { ...Data, quote_no: QuoteNo };

    const MD = MainDifference(FinalData, quoteData);
    const ItD = ItemDifference(FinalData, quoteData);

    let changeTracker = false;
    let changeType = "";
    if (MD && ItD) {
      changeType = "both";
      changeTracker = true;
    } else if (MD && !ItD) {
      changeType = "main";
      changeTracker = true;
    } else if (!MD && ItD) {
      changeType = "item";
      changeTracker = true;
    } else {
      changeTracker = false;
    }

    if (changeTracker) {
      setHandlingSubmit(true);
      setHandlingText("Saving Changes");
      editQuote({ ...FinalData, changeType: changeType }).then((res) => {
        if (res.status === "success") {
          setHandlingText("Changes Saved");
          setCompanyName(res.company_name);
          setGetPDF(true);
          setEdited(true);
        } else {
          toast.error(res.message);
          setHandlingSubmit(false);
        }
      });
    } else toast.warning("You have not made any changes to the quote");
  };

  const MainDifference = (obj1, obj2) => {
    let mainDif = false;

    const keyList = Object.keys(obj1);
    var index = keyList.indexOf("itemList");
    if (index !== -1) {
      keyList.splice(index, 1);
    }

    keyList.forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        mainDif = true;
      }
    });

    return mainDif;
  };

  const ItemDifference = (obj1, obj2) => {
    let itemDif = false;

    if (obj1.itemList.length !== obj2.itemList.length) {
      return true;
    }

    const keyList = Object.keys(obj1.itemList[0]);
    var index = keyList.indexOf("editing");
    if (index !== -1) {
      keyList.splice(index, 1);
    }
    obj1.itemList.forEach((item, index) => {
      keyList.forEach((key) => {
        if (obj1.itemList[index][key] !== obj2.itemList[index][key]) {
          itemDif = true;
        }
      });
    });

    return itemDif;
  };

  return (
    <Dialog
      open={true}
      PaperProps={{
        elevation: 0,
        sx: { minWidth: "90vw" },
      }}
      onClose={goBack}
      fullScreen
    >
      <Typography variant="h4" color="primary" sx={{ p: 2 }}>
        Edit Quote {QuoteNo}
      </Typography>

      <IconButton
        onClick={goBack}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          m: 1,
        }}
      >
        <Close />
      </IconButton>

      <DialogContent>
        <Box sx={{ pt: 3 }}>
          {loading ? (
            <LoadingArea />
          ) : (
            <SalesQuoter
              quoteData={quoteData}
              onSubmit={handleSubmit}
              submitText="Save Changes"
              submitIcon={<SaveIcon />}
              scrollToTop
            />
          )}
        </Box>
      </DialogContent>

      <Dialog open={handlingSubmit}>
        <DialogTitle sx={{ whiteSpace: "pre-wrap" }}>
          {handlingText}
        </DialogTitle>
        {getPDF ? (
          <DialogContent>
            <Stack spacing={2}>
              <Button
                variant="contained"
                color="success"
                startIcon={<PictureAsPdf />}
                onClick={downloadPDF}
              >
                Download PDF
              </Button>
              <Button variant="outlined" color="warning" onClick={goBack}>
                Close
              </Button>
            </Stack>
          </DialogContent>
        ) : (
          <DialogContentText
            sx={{ display: "flex", justifyContent: "center", minHeight: 100 }}
          >
            <CircularProgress />
          </DialogContentText>
        )}
      </Dialog>
    </Dialog>
  );
}

function LoadingArea() {
  return (
    <Stack
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Skeleton variant="rectangular" width="95%" height={"400px"} />
      <Skeleton variant="rectangular" width="80%" height={"400px"} />
    </Stack>
  );
}
