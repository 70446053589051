import React from "react";
import { Typography } from "@mui/material";
import RecentUsersTable from "../../Components/CustomTables/RecentUsers";
import DangerZone from "../../TecToday/Components/common/DangerZone";
import NotificationRole from "../../TecToday/Components/common/NotificationRole";

export default function Main(props) {
  return (
    <>
      <Typography variant="h4" color="primary">
        Admin Dashboard
      </Typography>
      <RecentUsersTable />
      <NotificationRole />
      <DangerZone />
    </>
  );
}
