import {
  Email,
  Folder,
  Handshake,
  Inventory,
  RequestQuote,
  SafetyCheck,
} from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  Grid,
  Paper,
  Stack,
  SvgIcon,
  Typography,
  Link,
} from "@mui/material";
import React from "react";

export default function Rescources() {
  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems={"center"}
        sx={{ mb: 2 }}
      >
        <Inventory color="primary" />
        <Typography variant="h5" color="primary">
          Rescources
        </Typography>
      </Stack>
      <Grid container spacing={2} sx={{ justifyContent: "flex-start", mb: 2 }}>
        <LinkCard
          name="HP Warranty Finder"
          url="https://cpc2.ext.hp.com/?countries=KE"
          icon={SafetyCheck}
        />
        <LinkCard
          name="HP IQ"
          url="https://partner.hp.com/group/upp-emea/integrated-quoting"
          icon={RequestQuote}
        />
        <LinkCard
          name="HP Partner"
          url="https://partner.hp.com/group/upp-emea/home"
          icon={Handshake}
        />
        <LinkCard
          name="Email Signature"
          url="/genEmailSignature"
          icon={Email}
          newTab={false}
        />
        <LinkCard
          name="Company Documents"
          url="https://drive.google.com/drive/folders/1JyLVFoU-DXwcqmdr8gdC-r5A3Z0hBrHo?usp=drive_link"
          icon={Folder}
          newTab={true}
        />
      </Grid>
    </Paper>
  );
}

function LinkCard({ name, url, icon, newTab = true }) {
  return (
    <Grid item xs={12} md={4} lg={3}>
      <Card
        sx={{
          textAlign: "center",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <CardActionArea
          sx={{ p: 2 }}
          component={Link}
          href={url}
          target={newTab ? "_blank" : ""}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <SvgIcon component={icon} />
            <Typography>{name}</Typography>
          </Stack>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
