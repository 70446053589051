import React from "react";

import { Box, TextField, Stack, Zoom, Button } from "@mui/material";

import { SessionManager } from "react-session.manager.sk";
import { changeTitle } from "../../CustomAxios/Protected/AccountManagment";
import { toast } from "react-toastify";

export default function Main(props) {
  const { setRefreshData, userInfo } = React.useContext(SessionManager),
    [ctitle, setCTitle] = React.useState(userInfo?.title || ""),
    [title, setTitle] = React.useState(""),
    [show, setShow] = React.useState(false),
    [reset, setReset] = React.useState(false);

  React.useEffect(() => {
    setCTitle(userInfo?.title || "");
    setTitle(userInfo?.title || "");
  }, [userInfo]);

  React.useEffect(() => {
    if (ctitle !== title && title) setShow(true);
    else setShow(false);
    if (ctitle !== title) setReset(true);
    else setReset(false);
  }, [title, ctitle]);

  const handleReset = () => {
    setTitle(ctitle);
  };

  const handleTitleChange = () => {
    changeTitle({ title: title }).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        setRefreshData(true);
      } else toast.error(res.message);
    });
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
      >
        <TextField
          label="Job Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
        />
        <Zoom in={reset}>
          <Button variant="outlined" color="secondary" onClick={handleReset}>
            Reset
          </Button>
        </Zoom>
        <Zoom in={show}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleTitleChange}
          >
            Save Changes
          </Button>
        </Zoom>
      </Stack>
    </Box>
  );
}
