import React from "react";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

export default function InvoicesPage() {
  let location = useLocation();
  let navigate = useNavigate();

  React.useEffect(() => {
    let lastPath = localStorage.getItem("lastInvoice");
    if (lastPath) {
      if (lastPath === "customer") navigate("/invoice/customer");
      else navigate("/invoice/supplier");
    }
  }, [navigate]);

  return (
    <>
      <Typography variant="h5" color="primary">
        Invoices
      </Typography>

      <ToggleButtonGroup
        value={location.pathname}
        color="primary"
        sx={{ my: 2 }}
      >
        <ToggleButton
          value="/invoice/customer"
          component={Link}
          to="/invoice/customer"
          onClick={() => {
            localStorage.setItem("lastInvoice", "customer");
          }}
        >
          Customer
        </ToggleButton>
        <ToggleButton
          value="/invoice/supplier"
          component={Link}
          to="/invoice/supplier"
          onClick={() => {
            localStorage.setItem("lastInvoice", "supplier");
          }}
        >
          Supplier
        </ToggleButton>
      </ToggleButtonGroup>

      <Outlet />
    </>
  );
}
