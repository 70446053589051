import { Close, WarningAmber } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Paper,
  Stack,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
  deleteSupplierCheck,
  deleteSupplier as deleteSupplierApi,
} from "../../API/Cheques&Pos";
import { toast } from "react-toastify";

export default function DeleteSupplierForm() {
  const [loading, setLoading] = React.useState(true);
  const [allowDelete, setAllowDelete] = React.useState(false);
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);

  const urlDets = new URLSearchParams(useLocation().search);
  const supplierID = urlDets.get("id");

  const { loadFiltered, origin } = useOutletContext();
  const history = useNavigate();

  const goBack = React.useCallback(
    (edited = false) => {
      if (edited) loadFiltered();
      history(origin);
    },
    [loadFiltered, history, origin]
  );

  React.useEffect(() => {
    if (loading) {
      deleteSupplierCheck(supplierID)
        .then((res) => {
          if (res.data.status === "success") setAllowDelete(true);
          else setAllowDelete(false);
        })
        .finally(() => setLoading(false));
    }
  }, [loading, supplierID]);

  const deleteSupplier = () => {
    setDeleteInProgress(true);
    deleteSupplierApi(supplierID)
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(res.data.message);
          goBack(true);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setDeleteInProgress(false));
  };

  return (
    <Dialog open={true}>
      <DialogContent sx={{ mt: 3 }}>
        <IconButton
          sx={{
            position: "absolute",
            top: 1,
            right: 1,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              color: (theme) => theme.palette.grey[100],
            },
          }}
          onClick={goBack}
        >
          <Close />
        </IconButton>
        <Collapse in={loading}>
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        </Collapse>
        <Collapse in={!loading && allowDelete}>
          <DialogContentText>
            Are you sure you want to do this? There are no POS transactions or
            Cheques associated with this supplier.
          </DialogContentText>
        </Collapse>
        <Collapse in={!loading && !allowDelete}>
          <DialogContentText>
            There are POS transactions or Cheques associated with this supplier.
            Please change them to another supplier before deleting this
            supplier.
          </DialogContentText>
        </Collapse>
      </DialogContent>
      <Paper elevation={0}>
        <DialogActions>
          <LoadingButton
            color="warning"
            variant="contained"
            startIcon={<WarningAmber />}
            disabled={loading || !allowDelete}
            loading={deleteInProgress}
            onClick={deleteSupplier}
          >
            Delete Supplier
          </LoadingButton>
          <Button variant="outlined" color="error" onClick={goBack}>
            Cancel
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
}
