import React from "react";
import {
  Stack,
  Typography,
  TextField,
  Paper,
  Grow,
  ToggleButton,
  ToggleButtonGroup,
  Collapse,
  Button,
  Grid,
  Tooltip,
  IconButton,
  DialogActions,
  DialogContent,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteStore,
  editStoreQuantity,
  getCompaniesInformation,
} from "../../API/Cheques&Pos";

import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

import AsyncAutoComplete from "../../../Components/common/AsyncAutoComplete";
import ItemInfo from "../common/ItemInfo";
import { TransitionGroup } from "react-transition-group";
import { Cancel } from "@mui/icons-material";
import StoreChangeQty from "./StoreChangeQty";

export default function StoreForm({
  onComplete,
  title,
  onSubmit,
  storeID = null,
  data,
  setChanged = () => null,
  handleClose,
  edit,
  goBack,
}) {
  const [partNumber, setPartNumber] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [quantity, setQuantity] = React.useState(1);
  const [cost, setCost] = React.useState(1);
  const [currency, setCurrency] = React.useState("KES");
  const [reserveList, setReserveList] = React.useState([]);

  const [client, setClient] = React.useState(null);
  const [reserveQuantity, setReserveQuantity] = React.useState(1);

  const [loading, setLoading] = React.useState(false);

  const [valid, setValid] = React.useState(true);

  React.useEffect(() => {
    if (edit) {
      setPartNumber(data?.partNumber);
      setDescription(data?.description);
      setQuantity(data?.quantity);
      setCost(data?.cost);
      setCurrency(data?.currency);
      setReserveList(
        (data?.reserveList || []).map((reserve) => ({ ...reserve }))
      );
    }
  }, [data, edit]);

  // check if user changed initial form values or if vales are valid
  React.useEffect(() => {
    const reservedChanges = () => {
      let changed = false;
      if (reserveList.length !== data?.reserveList?.length) {
        return true;
      }

      reserveList.forEach((reserve, index) => {
        changed =
          changed || reserve.quantity !== data?.reserveList[index]?.quantity;
      });

      return changed;
    };

    const changed =
      Number(data?.quantity) !== Number(quantity) ||
      data?.description !== description ||
      data?.partNumber !== partNumber ||
      data?.currency !== currency ||
      Number(data?.cost) !== Number(cost) ||
      reservedChanges();

    setChanged(changed);
    if (
      partNumber &&
      description &&
      quantity &&
      quantity > 0 &&
      cost &&
      cost > 0 &&
      changed
    ) {
      setValid(true);
      return;
    }
    setValid(false);
  }, [
    cost,
    data,
    description,
    partNumber,
    quantity,
    reserveList,
    currency,
    setChanged,
  ]);

  const handleSubmit = () => {
    const postData = {
      partNumber,
      description,
      quantity,
      id: storeID,
      currency,
      cost,
      reserveList,
    };
    setLoading(true);
    onSubmit(postData)
      .then((res) => {
        toast.success(res.message);
        onComplete({
          description,
          quantity,
          partNumber,
          currency,
          cost,
          reserveList: [
            ...(res?.reserveList || []).map((reserve) => ({ ...reserve })),
          ],
        });

        setReserveList(res?.reserveList || []);
      })
      .catch((err) => {
        console.log(err);

        toast.error(
          "Something went wrong, contact an administrator if the problem persists"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDelete = () => {
    deleteStore({ id: storeID, description: data?.description })
      .then((res) => {
        if (res.status === "success") toast.success(res.message);
        if (res.status === "error") toast.error(res.message);

        goBack(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "Something went wrong, contact an administrator if the problem persists"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStatus = (event, value) => {
    if (value === null) return;
    setCurrency(value);
  };

  const createReserve = () => {
    setReserveList((previous) => {
      const exists = previous.findIndex((item) => item.client === client?.id);
      if (exists !== -1) {
        toast.error("Client already on reserve List for this store item");
        return previous;
      }
      const newClient = {
        id: Date.now(),
        name: client?.name,
        client: client?.id,
        quantity: reserveQuantity,
      };
      return [...previous, newClient];
    });
    setClient(null);
    setReserveQuantity(1);
  };

  const editReserve = (property, value, id) => {
    setReserveList((previous) => {
      const reserve = previous.find((item) => item.id === id);
      reserve[property] = Number(value);
      return [...previous];
    });
  };
  const deleteReserve = (id) => {
    setReserveList((previous) => {
      const reserve_list = previous.filter((item) => item.id !== id);

      return [...reserve_list];
    });
  };

  const handleQtyChange = (storeID, newQty) => {
    editStoreQuantity({ storeID: storeID, newQty: newQty }).then((res) => {
      if (res.status === "success") toast.success(res.message);
      if (res.status === "error") toast.error(res.message);
      goBack(true);
    });
  };

  return (
    <>
      <DialogContent dividers>
        <Grid container sx={{ minWidth: "50vw", gap: "15px", p: 1 }}>
          <Grid item md={5.7} xs={12}>
            <Paper variant="outlined" sx={{ p: 1 }}>
              <Stack spacing={2} justifyContent="center" sx={{ p: 2 }}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" color="primary">
                    Store Item Details
                  </Typography>
                  {storeID && (
                    <Tooltip title="Delete Store item">
                      <IconButton
                        variant="outlined"
                        color="error"
                        onClick={onDelete}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>

                <TextField
                  label="Part Number"
                  value={partNumber}
                  onChange={(e) => setPartNumber(e.target.value)}
                  required
                  error={!partNumber}
                  onClick={(e) => e.target.select()}
                />

                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={4}
                  multiline
                  required
                  error={!description}
                  onClick={(e) => e.target.select()}
                />

                <TextField
                  label="Quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  inputProps={{ min: 1 }}
                  error={quantity === "" || quantity <= 0}
                  helperText={
                    (quantity === "" || quantity <= 0) &&
                    "Enter a valid number Greater than 0"
                  }
                  onClick={(e) => e.target.select()}
                  disabled={edit}
                />

                <TextField
                  label="Cost"
                  value={cost}
                  onChange={(e) => setCost(e.target.value)}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  inputProps={{ min: 1 }}
                  error={cost === "" || cost <= 0}
                  helperText={
                    (cost === "" || cost <= 0) && "Enter a valid number "
                  }
                  onClick={(e) => e.target.select()}
                />
                <ToggleButtonGroup
                  value={currency}
                  exclusive
                  onChange={handleStatus}
                >
                  <ToggleButton value="KES" color={"primary"}>
                    KES
                  </ToggleButton>
                  <ToggleButton value="USD" color={"primary"}>
                    USD
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Paper>
            {edit && (
              <StoreChangeQty
                itemID={storeID}
                currentQty={quantity}
                onSubmit={handleQtyChange}
              />
            )}
          </Grid>

          <Grid item md={5.7} xs={12}>
            <Paper variant="outlined" sx={{ p: 1 }}>
              <Stack spacing={2} justifyContent="center" sx={{ p: 2 }}>
                <Typography variant="h5" color="primary">
                  Reserve Information
                </Typography>

                <AsyncAutoComplete
                  getData={getCompaniesInformation}
                  setValue={setClient}
                  optionLabel={"name"}
                  label="Clients"
                  defaultOption={client?.name}
                />
                <Collapse in={client ? true : false}>
                  <Grid container sx={{ gap: "15px" }}>
                    <TextField
                      label="Reserve Quantity"
                      value={reserveQuantity}
                      onChange={(e) => setReserveQuantity(e.target.value)}
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      inputProps={{ min: 1 }}
                      error={quantity === "" || quantity <= 0}
                      helperText={
                        (quantity === "" || quantity <= 0) &&
                        "Enter a valid number Greater than 0"
                      }
                      onClick={(e) => e.target.select()}
                    />

                    <Button variant="outlined" onClick={createReserve}>
                      Add Client
                    </Button>
                  </Grid>
                </Collapse>

                <TransitionGroup>
                  {reserveList.map((item, index) => (
                    <Collapse key={item.client}>
                      <ReserveArea
                        item={item}
                        index={index}
                        onComplete={editReserve}
                        onDelete={deleteReserve}
                      />
                    </Collapse>
                  ))}
                </TransitionGroup>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          bgcolor: "background.default",
        }}
      >
        <Grow in={valid}>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleSubmit}
          >
            {title} Item
          </LoadingButton>
        </Grow>
        {edit && (
          <Button
            variant="outlined"
            color="warning"
            startIcon={<Cancel />}
            sx={{ height: "fit-content", ml: "auto" }}
            onClick={handleClose}
          >
            Close
          </Button>
        )}
      </DialogActions>
    </>
  );
}

function ReserveArea({ item, index, onComplete, onDelete }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        position: "relative",
        p: 1,
        borderRadius: 0,
        pt: 2,
        pr: 2,
      }}
    >
      <Tooltip title="Delete Reserve">
        <IconButton
          onClick={() => onDelete(item.id)}
          color="error"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Stack direction="column" alignItems="flex-start" spacing={2}>
        <Typography
          sx={{ ml: 2.1 }}
        >{` Reserved for  ${item.name}`}</Typography>

        <ItemInfo
          property="quantity"
          name="Reserve Quantity"
          value={item.quantity}
          setValue={() => console.log("Nothing to set")}
          type="number"
          onWheel={(e) => e.target.blur()}
          id={item.id}
          onComplete={onComplete}
          noTitle
        />
      </Stack>
    </Paper>
  );
}
