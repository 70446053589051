import React from "react";

import { Box, TextField, Stack, Zoom, Button } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";

import config from "../../Config";
import {
  changeUserNames,
  changeUserPhoneNumber,
  changeUserTitle,
} from "../../CustomAxios/Protected/AdminManagment";

export function ChangeUserNames({ firstName, lastName, userId, Changed }) {
  const [fname, setFname] = React.useState(firstName),
    [lname, setLname] = React.useState(lastName),
    [show, setShow] = React.useState(false),
    [reset, setReset] = React.useState(false);

  React.useEffect(() => {
    if ((fname !== firstName || lname !== lastName) && fname && lname)
      setShow(true);
    else setShow(false);
    if (fname !== firstName || lname !== lastName) setReset(true);
    else setReset(false);
  }, [fname, lname, firstName, lastName]);

  const handleReset = () => {
    setFname(firstName);
    setLname(lastName);
  };

  const handleNameChange = () => {
    changeUserNames({
      first_name: fname,
      last_name: lname,
      userId: userId,
    }).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        Changed();
      } else toast.error(res.message);
    });
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={2}
      >
        <TextField
          label="First Name"
          value={fname}
          onChange={(e) => setFname(e.target.value)}
        />
        <TextField
          label="Last Name"
          value={lname}
          onChange={(e) => setLname(e.target.value)}
        />
        <Zoom in={reset}>
          <Button variant="outlined" color="secondary" onClick={handleReset}>
            Reset
          </Button>
        </Zoom>
        <Zoom in={show}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNameChange}
          >
            Save Changes
          </Button>
        </Zoom>
      </Stack>
    </Box>
  );
}

export function ChangeUserPhoneNumber({ Telephone, userId, Changed }) {
  const [phone, setPhone] = React.useState(Telephone),
    [show, setShow] = React.useState(false),
    [reset, setReset] = React.useState(false);

  React.useEffect(() => {
    if (phone !== Telephone && phone) setShow(true);
    else setShow(false);
    if (phone !== Telephone) setReset(true);
    else setReset(false);
  }, [phone, Telephone]);

  const handleReset = () => {
    setPhone(Telephone);
  };

  const handlePhoneNumberChange = () => {
    changeUserPhoneNumber({ phone_number: phone, userId: userId }).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message);
          Changed();
        } else toast.error(res.message);
      }
    );
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={2}
      >
        <PhoneInput
          country={config.phoneNumber.Default}
          value={phone}
          onChange={(e) => setPhone(e)}
          specialLabel="Phone Number"
          required
        />
        <Zoom in={reset}>
          <Button variant="outlined" color="secondary" onClick={handleReset}>
            Reset
          </Button>
        </Zoom>
        <Zoom in={show}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePhoneNumberChange}
          >
            Save Changes
          </Button>
        </Zoom>
      </Stack>
    </Box>
  );
}

export function ChangeUserTitle({ ctitle, userId, Changed }) {
  const [title, setTitle] = React.useState(ctitle),
    [show, setShow] = React.useState(false),
    [reset, setReset] = React.useState(false);

  React.useEffect(() => {
    if (title !== ctitle && title) setShow(true);
    else setShow(false);
    if (title !== ctitle) setReset(true);
    else setReset(false);
  }, [title, ctitle]);

  const handleReset = () => {
    setTitle(ctitle);
  };

  const handleTitleChange = () => {
    changeUserTitle({
      title: title,
      userId: userId,
    }).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        Changed();
      } else toast.error(res.message);
    });
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={2}
      >
        <TextField
          label="Job Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Zoom in={reset}>
          <Button variant="outlined" color="secondary" onClick={handleReset}>
            Reset
          </Button>
        </Zoom>
        <Zoom in={show}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleTitleChange}
          >
            Save Changes
          </Button>
        </Zoom>
      </Stack>
    </Box>
  );
}
