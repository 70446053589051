import React from "react";
import {
  Paper,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Divider,
  Tooltip,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Popover,
  Slide,
  Collapse,
  InputAdornment,
  Alert,
  useMediaQuery,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Chip,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import config from "../../Config";

import AddIcon from "@mui/icons-material/Add";

import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

import {
  Close,
  CloudUpload,
  Delete,
  Download,
  Edit,
  Upload,
  UploadFile,
  Warning,
} from "@mui/icons-material";
import { arrayMoveImmutable as moveArray } from "array-move";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CurrencyConverter from "./CurrencyConverter";

import NumberFormat from "../../../Components/common/NumberFormater";
import { LoadingButton } from "@mui/lab";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { getUploadItemsTemplate, uploadItems } from "../../API/Sales";

export default function ItemsArea({
  items,
  setItems,
  setEditingItem,
  disableScroll,
  hasVAT = true,
  currency,
}) {
  const [itemList, setItemList] = React.useState(items),
    [itemEditOpen, setItemEditOpen] = React.useState(false);

  React.useEffect(() => {
    setItemList(items);
  }, [items]);

  // Used to handle smooth scroll
  const myRef = React.useRef(null);

  React.useEffect(() => {
    if (disableScroll) return;
    if (itemList.length === 0) return;
    const emptyNewItem = {
      po: "",
      description: "",
      quantity: 0,
      cost: 0,
      margin_type: "percent",
      margin: 0,
      editing: true,
    };
    const lastItem = itemList[itemList.length - 1] || {};
    let isNew = true;
    Object.keys(lastItem).forEach((key) => {
      if (lastItem[key] !== emptyNewItem[key]) {
        isNew = false;
        return;
      }
    });
    if (isNew) {
      setTimeout(() => {
        myRef.current.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  }, [itemList, disableScroll]);

  const addItem = () => {
    setItemList((previous) => [
      ...previous,
      {
        po: "",
        description: "",
        quantity: 0,
        cost: 0,
        margin_type: "percent",
        margin: 0,
        editing: true,
      },
    ]);
  };

  const removeItem = (index) => {
    setItemList((previous) => previous.filter((_, i) => i !== index));
  };

  const updateItem = (
    index,
    po,
    description,
    quantity,
    cost,
    margin_type,
    margin
  ) => {
    setItemList((previous) => {
      previous[index] = {
        po: po,
        description: description,
        quantity: quantity,
        cost: cost,
        margin_type: margin_type,
        margin: margin,
        editing: false,
      };
      return [...previous];
    });
  };

  const changeToEdit = (index) => {
    setItemList((previous) => {
      previous[index].editing = true;
      return [...previous];
    });
  };

  const cancelEdit = (index) => {
    setItemList((previous) => {
      previous[index].editing = false;
      return [...previous];
    });
  };

  React.useEffect(() => {
    const editOpen = itemList.find((item) => item.editing === true);
    setItemEditOpen(editOpen);

    setItems(itemList);
  }, [itemList, setItems]);

  // If any item is being edited, set ItemIsEditing to true
  React.useEffect(() => {
    setEditingItem(itemEditOpen);
  }, [itemEditOpen, setEditingItem]);

  // Swap animation

  const [moveUp, setMoveUp] = React.useState(null),
    [moveDown, setMoveDown] = React.useState(null);

  const moveItem = (itemPosition, newPosition) => {
    // Sets which item is being moved and the direction
    setMoveUp(newPosition);
    setMoveDown(itemPosition);
    if (itemPosition < newPosition) {
      setMoveUp(itemPosition);
      setMoveDown(newPosition);
    }

    setItemList((previous) => {
      const newArray = moveArray([...previous], itemPosition, newPosition);
      return newArray;
    });
  };

  const [slideIn, setSlideIn] = React.useState(true);

  const flickerSlideIn = () => {
    setSlideIn(false);
    setTimeout(() => {
      setSlideIn(true);
    }, 100);
  };

  const isNotMobile = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [uploadExcel, setUploadExcel] = React.useState(false);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h5" color="primary" sx={{ mb: 1 }}>
            Items
          </Typography>
          <Typography variant="body1" color="primary" sx={{ mb: 2 }}>
            (Item Count: {itemList.length})
          </Typography>
        </Box>
        {isNotMobile && (
          <Tooltip title="Upload Items from excel">
            <IconButton color="info" onClick={() => setUploadExcel(true)}>
              <UploadFile />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <UploadExcel
        open={uploadExcel}
        setOpen={setUploadExcel}
        setItems={setItemList}
      />
      <Button
        variant="outlined"
        color="success"
        startIcon={<AddIcon />}
        onClick={addItem}
        sx={{ mb: 2 }}
      >
        Add New Item
      </Button>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Order</TableCell>
              <TableCell>PO</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Price</TableCell>
              {hasVAT ? (
                <>
                  <TableCell>Total Exc</TableCell>
                  <TableCell>Total INC</TableCell>
                </>
              ) : (
                <TableCell>Total</TableCell>
              )}
              <TableCell>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemList.length > 0 ? (
              itemList.map((itemDetails, index) => (
                <ItemInput
                  key={index}
                  itemDetails={itemDetails}
                  position={index}
                  updateItem={updateItem}
                  removeItem={removeItem}
                  changeToEdit={changeToEdit}
                  cancelEdit={cancelEdit}
                  moveItem={moveItem}
                  lastIndex={itemList.length - 1}
                  moveUp={moveUp}
                  slideIn={
                    index === moveUp || index === moveDown ? slideIn : true
                  }
                  flickerSlideIn={flickerSlideIn}
                  hasVAT={hasVAT}
                  currency={currency}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8}>No items added yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div ref={myRef} />
    </>
  );
}

function ItemInput({
  itemDetails,
  position,
  updateItem,
  removeItem,
  changeToEdit,
  cancelEdit,
  moveItem,
  lastIndex,
  moveUp,
  slideIn,
  flickerSlideIn,
  hasVAT,
  currency,
}) {
  const [editing, setEditing] = React.useState(itemDetails?.editing || true);

  React.useEffect(() => {
    // update editing state
    setEditing(itemDetails?.editing);
  }, [itemDetails]);

  const [unitPrice, setUnitPrice] = React.useState(0),
    [totalPriceExc, setTotalPriceExc] = React.useState(0),
    [totalPriceInc, setTotalPriceInc] = React.useState(0);

  React.useEffect(() => {
    //Calculate prices for item
    let up = 0;
    if (itemDetails.margin_type === "percent") {
      up = itemDetails.cost * (itemDetails.margin / 100 + 1);
    } else {
      up = parseFloat(itemDetails.cost) + parseFloat(itemDetails.margin);
    }
    const tpe = up * itemDetails.quantity;
    const tpi = up * itemDetails.quantity * config.VAT;

    setUnitPrice(NumberFormat(up.toFixed(2)));
    setTotalPriceExc(NumberFormat(tpe.toFixed(2)));
    setTotalPriceInc(NumberFormat(tpi.toFixed(2)));
  }, [itemDetails]);

  const handleItemSave = (itemInfo) => {
    updateItem(
      position,
      itemInfo.po,
      itemInfo.description,
      itemInfo.quantity,
      itemInfo.cost,
      itemInfo.margin_type,
      itemInfo.margin
    );
  };

  const handleRemove = () => {
    removeItem(position);
  };

  const handleEdit = () => {
    changeToEdit(position);
    setEditing(true);
  };

  const handleCancelEdit = () => {
    cancelEdit(position);
    setEditing(false);
  };

  const handleMoveUp = () => {
    moveItem(position, position - 1);
    flickerSlideIn();
  };

  const handleMoveDown = () => {
    moveItem(position, position + 1);
    flickerSlideIn();
  };

  if (editing) {
    return (
      <TableRow>
        <TableCell colSpan={8}>
          <EditItem
            index={position}
            currentPo={itemDetails.po}
            currentDescription={itemDetails.description}
            currentQuantity={itemDetails.quantity}
            currentCost={itemDetails.cost}
            currentMarginType={itemDetails.margin_type}
            currentMargin={itemDetails.margin}
            saveItem={handleItemSave}
            cancelEdit={handleCancelEdit}
            deleteItem={handleRemove}
            hasVAT={hasVAT}
            currency={currency}
          />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <DisplayItem
      position={position}
      lastIndex={lastIndex}
      po={itemDetails.po}
      description={itemDetails.description}
      quantity={itemDetails.quantity}
      unitPrice={unitPrice}
      totalPriceExc={totalPriceExc}
      totalPriceInc={totalPriceInc}
      moveUp={moveUp}
      slideIn={slideIn}
      handleMoveUp={handleMoveUp}
      handleMoveDown={handleMoveDown}
      editItem={handleEdit}
      deleteItem={handleRemove}
      hasVAT={hasVAT}
      currency={currency}
    />
  );
}

function EditItem({
  index,
  currentPo,
  currentDescription,
  currentQuantity,
  currentCost,
  currentMargin,
  currentMarginType,
  saveItem,
  cancelEdit,
  deleteItem,
  hasVAT,
  currency,
}) {
  const [po, setPo] = React.useState(currentPo || ""),
    [description, setDescription] = React.useState(currentDescription || ""),
    [quantity, setQuantity] = React.useState(currentQuantity || 0),
    [cost, setCost] = React.useState(currentCost || 0),
    [margin, setMargin] = React.useState(currentMargin || 0),
    [margin_type, setMarginType] = React.useState(
      currentMarginType || "percent"
    );

  const [dis, setDis] = React.useState(true),
    [same, setSame] = React.useState(false),
    [notNew, setNotNew] = React.useState(false);

  const [characterCount, setCharacterCount] = React.useState(0);

  const [unitPrice, setUnitPrice] = React.useState(0),
    [totalPriceExc, setTotalPriceExc] = React.useState(0),
    [totalPriceInc, setTotalPriceInc] = React.useState(0);

  // For the currency converter
  const [currencyOpen, setCurrencyOpen] = React.useState(false),
    [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCurrencyOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrencyOpen(false);
  };

  React.useEffect(() => {
    // Check if item has any blank fields
    if (po.trim() !== "" && description.trim() !== "" && quantity && cost >= 0)
      setDis(false);
    else setDis(true);

    // Updates character count for description
    setCharacterCount(description.length);

    //Calculate prices for item
    let up = 0;
    if (margin_type === "percent") {
      up = cost * (margin / 100 + 1);
    } else {
      up = parseFloat(cost) + parseFloat(margin);
    }
    const tpe = up * quantity;
    const tpi = up * quantity * config.VAT;

    setUnitPrice(NumberFormat(up.toFixed(2)));
    setTotalPriceExc(NumberFormat(tpe.toFixed(2)));
    setTotalPriceInc(NumberFormat(tpi.toFixed(2)));
  }, [po, description, quantity, cost, margin_type, margin]);

  // Compares current values to initial values
  React.useEffect(() => {
    if (
      currentPo === po &&
      currentDescription === description &&
      currentCost === cost &&
      currentQuantity === quantity &&
      currentMargin === margin &&
      currentMarginType === margin_type
    ) {
      setSame(true);
    } else {
      setSame(false);
    }

    if (
      currentPo === "" &&
      currentDescription === "" &&
      currentQuantity === 0 &&
      currentCost === 0
    )
      setNotNew(false);
    else setNotNew(true);
  }, [
    po,
    description,
    quantity,
    cost,
    margin,
    margin_type,
    currentPo,
    currentDescription,
    currentCost,
    currentQuantity,
    currentMargin,
    currentMarginType,
  ]);

  const handleSubmit = () => {
    const data = {
      po: po,
      description: description,
      quantity: quantity,
      cost: cost,
      margin: margin,
      margin_type: margin_type,
    };
    saveItem(data);
  };

  const handleDelete = () => {
    deleteItem(index);
  };

  const [collapseOpen, setCollapseOpen] = React.useState(false),
    [animate, setAnimate] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setCollapseOpen(true);
    }, 100);
    setTimeout(() => {
      setAnimate(false);
    }, 300);
  }, []);

  const [overLimit, setOverLimit] = React.useState(false);

  React.useEffect(() => {
    if (characterCount > 1000) setOverLimit(true);
    else setOverLimit(false);
  }, [characterCount]);

  const DescriptionHelperText = () => (
    <Typography variant="overline">{characterCount}/1000</Typography>
  );

  return (
    <Collapse in={collapseOpen} appear={animate}>
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          maxWidth: { xs: "70vw", md: "100vw" },
          position: "relative",
        }}
      >
        <Typography variant="body1" color="primary" sx={{ m: 1 }}>
          Item No.{index + 1}
        </Typography>
        <Tooltip title="Currency Converter">
          <IconButton
            color="primary"
            size="large"
            onClick={handleClick}
            sx={{
              width: "fit-content",
              height: "fit-content",
              ml: "auto",
              position: "absolute",
              right: 2,
              top: 2,
            }}
          >
            <CurrencyExchangeIcon />
          </IconButton>
        </Tooltip>
        <Stack direction={{ xs: "column-reverse", md: "row" }}>
          <TextField
            label="Part Number"
            value={po}
            onChange={(e) => setPo(e.target.value)}
            helperText="If no part number is given try give a short unique name to the item description."
            sx={{ maxWidth: { xs: "100%", md: "40%" } }}
            color={po.trim() === "" ? "error" : "success"}
            error={po.trim() === ""}
            required
          />
          <Popover
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={currencyOpen}
            onClose={handleClose}
          >
            <CurrencyConverter input={cost} setOutput={(e) => setCost(e)} />
          </Popover>
        </Stack>

        <Collapse in={overLimit}>
          <Alert severity="warning" timeout={1000}>
            Please note, going over the character count may lower the visual
            appeal of your quote.
          </Alert>
        </Collapse>
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          color={description.trim() === "" ? "error" : "success"}
          helperText={<DescriptionHelperText />}
          error={description.trim() === ""}
          fullWidth
          required
          multiline
          rows={4}
          sx={{ my: 1 }}
        />
        <Stack
          spacing={2}
          direction={{ xs: "column", md: "row" }}
          sx={{ my: 2 }}
        >
          <TextField
            label="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            variant="filled"
            size="small"
            type="number"
            onWheel={(e) => e.target.blur()}
            color={quantity <= 0 ? "error" : "success"}
            error={quantity <= 0}
            helperText={!quantity ? "*Required" : ""}
            onClick={(e) => e.target.select()}
            required
          />
          <TextField
            label="Cost"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            color="primary"
            variant="filled"
            size="small"
            type="number"
            onWheel={(e) => e.target.blur()}
            error={cost < 0}
            helperText={cost ? "*Required" : ""}
            onClick={(e) => e.target.select()}
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{currency}</InputAdornment>
              ),
            }}
          />
          <FormControl>
            <InputLabel>Margin Type</InputLabel>
            <Select
              label="Margin Type"
              value={margin_type}
              onChange={(e) => setMarginType(e.target.value)}
            >
              <MenuItem value="percent">Percent</MenuItem>
              <MenuItem value="flat">Add flat amount</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Margin"
            value={margin}
            onChange={(e) => setMargin(e.target.value)}
            color="primary"
            variant="filled"
            size="small"
            type="number"
            onWheel={(e) => e.target.blur()}
            onClick={(e) => e.target.select()}
          />
        </Stack>
        <Divider />
        <Stack
          spacing={2}
          direction={{ xs: "column", md: "row" }}
          sx={{ my: 2 }}
        >
          <TextField
            label="Unit Price Exc"
            value={unitPrice}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{currency}</InputAdornment>
              ),
            }}
            disabled
          />
          {hasVAT ? (
            <>
              <TextField
                label="Total Price Exc"
                value={totalPriceExc}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Total Price Inc"
                value={totalPriceInc}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </>
          ) : (
            <>
              <TextField
                label="Total Price "
                value={totalPriceExc}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </>
          )}
        </Stack>
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            color="success"
            onClick={handleSubmit}
            disabled={dis || same}
            startIcon={<AddIcon />}
          >
            Add to Quote
          </Button>
          {notNew && (
            <Button
              variant="contained"
              color="error"
              onClick={cancelEdit}
              startIcon={<Close />}
            >
              Cancel Edit
            </Button>
          )}
          <Button
            variant="outlined"
            color="error"
            onClick={handleDelete}
            startIcon={<Delete />}
          >
            Remove Item
          </Button>
        </Stack>
      </Paper>
    </Collapse>
  );
}

function DisplayItem({
  position,
  lastIndex,
  po,
  description,
  quantity,
  unitPrice,
  totalPriceExc,
  totalPriceInc,
  moveUp,
  slideIn,
  handleMoveUp,
  handleMoveDown,
  editItem,
  deleteItem,
  hasVAT,
  currency,
}) {
  const [descriptionText, setDescriptionText] = React.useState(description),
    [showFull, setShowFull] = React.useState(false);

  React.useEffect(() => {
    if (!showFull)
      setDescriptionText(
        description.length > 200
          ? `${description.slice(0, 200)}...`
          : description
      );
    else setDescriptionText(description);
  }, [description, showFull]);

  return (
    <Slide
      in={slideIn}
      direction={moveUp === position ? "up" : "down"}
      appear={false}
    >
      <TableRow>
        <TableCell>
          <Stack
            spacing={0}
            direction="column"
            sx={{ width: "fit-content" }}
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              size="small"
              disabled={position === 0 ? true : false}
              onClick={handleMoveUp}
              color="secondary"
            >
              <Tooltip title="Move Up">
                <ArrowDropUpIcon />
              </Tooltip>
            </IconButton>

            <Typography variant="body1">{position + 1}</Typography>

            <IconButton
              size="small"
              disabled={position === lastIndex ? true : false}
              onClick={handleMoveDown}
              color="secondary"
            >
              <Tooltip title="Move Down">
                <ArrowDropDownIcon />
              </Tooltip>
            </IconButton>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography color="primary">{po}</Typography>
        </TableCell>
        <TableCell
          onMouseEnter={() => setShowFull(true)}
          onMouseLeave={() => setShowFull(false)}
        >
          <Collapse in={showFull} collapsedSize={"3rem"}>
            {descriptionText}
          </Collapse>
        </TableCell>
        <TableCell>{quantity}</TableCell>
        <TableCell>
          {currency} {NumberFormat(unitPrice)}
        </TableCell>
        {hasVAT ? (
          <>
            <TableCell>
              {currency} {NumberFormat(totalPriceExc)}
            </TableCell>
            <TableCell>
              {currency} {NumberFormat(totalPriceInc)}
            </TableCell>
          </>
        ) : (
          <TableCell>
            {currency} {NumberFormat(totalPriceExc)}
          </TableCell>
        )}

        <TableCell>
          <Tooltip title="Edit">
            <IconButton color="primary" onClick={editItem} size="small">
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove Item">
            <IconButton color="warning" onClick={deleteItem} size="small">
              <Delete />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Slide>
  );
}

function UploadExcel({ open, setOpen, setItems }) {
  const [file, setFile] = React.useState(null);
  const [gettingTemplate, setGettingTemplate] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [itemHolder, setItemHolder] = React.useState([]);

  const handleUpload = (file) => {
    setFile(file);
  };

  const getTemplate = () => {
    setGettingTemplate(true);
    getUploadItemsTemplate().finally(() => setGettingTemplate(false));
  };

  const handleFileUpload = () => {
    let formData = new FormData();
    formData.append("file", file);

    setUploading(true);
    uploadItems(formData)
      .then((res) => {
        if (res.length === 0) return toast.error("No items were uploaded");
        setItemHolder(res);
        setFile(null);
      })
      .finally(() => setUploading(false));
  };

  const handleFinalise = () => {
    let itemsToAdd = [];
    itemHolder.forEach((item) => {
      if (typeof item["Part Number"] === "number")
        item["Part Number"] = item["Part Number"].toString();
      if (typeof item["Description"] === "number")
        item["Description"] = item["Description"].toString();
      if (
        item["Part Number"].trim() !== "" &&
        item["Description"].trim() !== "" &&
        item["Quantity"]
      )
        itemsToAdd.push({
          po: item["Part Number"],
          description: item["Description"],
          quantity: item["Quantity"],
          cost: parseFloat(item["Unit Price Before VAT"]) || 0,
          margin_type: "percent",
          margin: 0,
          editing: false,
        });
    });

    setItems((prev) => {
      return [...prev, ...itemsToAdd];
    });
    setItemHolder([]);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={2}>
          <UploadFile /> <span>Upload Items from Excel</span>
        </Stack>
      </DialogTitle>
      <IconButton
        onClick={() => setOpen(false)}
        sx={{ position: "absolute", top: 6, right: 6 }}
      >
        <Close />
      </IconButton>
      <DialogContent
        sx={{
          bgcolor: "background.default",
        }}
        dividers
      >
        <Alert severity="info" sx={{ width: "100%" }}>
          <Stack
            direction="column"
            spacing={2}
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Typography>
              To upload items from excel, download the template below and fill
              in the details. Then upload the filled template.
            </Typography>
            <LoadingButton
              variant="outlined"
              color="info"
              startIcon={<Download />}
              sx={{ mb: 2 }}
              onClick={getTemplate}
              loading={gettingTemplate}
            >
              Download Template
            </LoadingButton>
          </Stack>
        </Alert>
        <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
          <FileUploader
            handleChange={handleUpload}
            name="file"
            types={["xlsx"]}
            onTypeError={() => toast.error("Only xlsx files are allowed")}
          >
            <Paper variant="outlined" sx={{ p: 2, py: 5, cursor: "pointer" }}>
              <Stack direction="column" spacing={5} alignItems="center">
                <Typography variant="overline">
                  Drag and drop a <Chip label=".XLXS" /> file here or click
                </Typography>
                <CloudUpload fontSize="large" />
              </Stack>
            </Paper>
          </FileUploader>
          {file && (
            <Card sx={{ m: 2, width: "max-content", position: "relative" }}>
              <IconButton
                onClick={() => setFile(null)}
                sx={{ position: "absolute", top: 6, right: 6 }}
              >
                <Close />
              </IconButton>
              <CardContent
                sx={{
                  mr: 5,
                }}
              >
                <Stack direction="column" spacing={1}>
                  <Typography variant="title">{file.name}</Typography>
                  <Typography variant="overline">{file.size} bytes</Typography>
                </Stack>
              </CardContent>
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <LoadingButton
                  disabled={!file}
                  startIcon={<Upload />}
                  color="info"
                  onClick={handleFileUpload}
                  loading={uploading}
                >
                  Upload
                </LoadingButton>
              </CardActions>
            </Card>
          )}
        </Paper>
        <Collapse in={itemHolder.length > 0}>
          <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Part Number</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price before VAT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemHolder.map((item, index) => (
                    <Tooltip
                      title={
                        item["Part Number"].trim() === "" ||
                        item["Description"].trim() === "" ||
                        item["Quantity"] <= 0 ||
                        item["Unit Price Before VAT"] < 0
                          ? "There is an issue with this item it will not be added to quote"
                          : item["error"] &&
                            " There was an issue with the item and some value were set to 0"
                      }
                      key={index}
                    >
                      <TableRow
                        selected={
                          item["Part Number"].trim() === "" ||
                          item["Description"].trim() === "" ||
                          item["Quantity"] <= 0 ||
                          item["Unit Price Before VAT"] < 0 ||
                          item["error"]
                        }
                      >
                        <TableCell>{item["error"] && <Warning />}</TableCell>
                        <TableCell>{item["Part Number"]}</TableCell>
                        <TableCell>{item["Description"]}</TableCell>
                        <TableCell>{item["Quantity"]}</TableCell>
                        <TableCell>
                          {NumberFormat(
                            item["Unit Price Before VAT"].toFixed(2)
                          )}
                        </TableCell>
                      </TableRow>
                    </Tooltip>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button disabled={itemHolder.length <= 0} onClick={handleFinalise}>
          Add items to Quote
        </Button>
      </DialogActions>
    </Dialog>
  );
}
