import {
  ArrowBack,
  Check,
  Clear,
  DoNotDisturb,
  Edit,
  ListAlt,
  Lock,
  LockOpen,
  Start,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  DialogContent,
  DialogTitle,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import NumberFormater from "../../../../Components/common/NumberFormater";
import DateTimePicker from "../../../Components/common/DateTimePicker";
import React from "react";
import { LoadingButton } from "@mui/lab";
import { createToner } from "../API/tonerApi";
import { toast } from "react-toastify";
import { Decimal } from "decimal.js";

export default function CreateSelloutForm({ onComplete }) {
  const [info, setInfo] = React.useState(
    JSON.parse(sessionStorage.getItem("sellout-info")) || {
      name: "",
      date: new Date(),
      transactionType: "credit",
      rate: 0,
    }
  );

  const [toners, setToners] = React.useState(
    JSON.parse(sessionStorage.getItem("sellout-toners")) || []
  );
  const [totals, setTotals] = React.useState(
    JSON.parse(sessionStorage.getItem("sellout-totals")) || {
      revenue: 0,
      profit: 0,
      profitPercent: 0,
    }
  );

  React.useEffect(() => {
    sessionStorage.setItem("sellout-info", JSON.stringify(info));
    sessionStorage.setItem("sellout-toners", JSON.stringify(toners));
    sessionStorage.setItem("sellout-totals", JSON.stringify(totals));
  }, [info, toners, totals]);

  const [handlingSubmit, setHandlingSubmit] = React.useState(false);

  const [customDate, setCustomDate] = React.useState("");

  React.useEffect(() => {
    if (info.date !== null && info.date !== "Invalid Date")
      setCustomDate(() => {
        let rawDate = new Date(info.date);

        let year = rawDate.getFullYear();
        let month = rawDate.getMonth() + 1;
        let day = rawDate.getDate();

        return `${year}-${month}-${day}`;
      });
  }, [info.date]);

  const handleFinalSubmit = () => {
    setHandlingSubmit(true);
    let fixedInfo = {
      name: info.name,
      date: customDate,
      transactionType: info.transactionType,
      rate: info.rate,
    };

    createToner({
      info: fixedInfo,
      toners,
      totals,
    })
      .then((res) => {
        if (res.status === "success") {
          toast.success("Toner report created successfully");
          sessionStorage.removeItem("sellout-info");
          sessionStorage.removeItem("sellout-toners");
          sessionStorage.removeItem("sellout-totals");
          sessionStorage.removeItem("raw");
          onComplete();
        } else {
          toast.error("Failed to create toner report");
        }
      })
      .finally(() => setHandlingSubmit(false));
  };

  const [step, setStep] = React.useState(0);
  const steps = ["Report info", "Add toners", "Summary"];

  const nextStep = (skip = false) => {
    if (skip) return setStep((prev) => prev + 2);
    setStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  return (
    <>
      <DialogTitle>Create Toner Report | {steps[step]}</DialogTitle>
      <DialogContent dividers>
        <Collapse in={step === 0}>
          <ReportInfo
            info={info}
            setInfo={setInfo}
            totals={totals}
            setTotals={setTotals}
            nextStep={nextStep}
          />
        </Collapse>
        <Collapse in={step === 1}>
          <CreateData
            info={info}
            toners={toners}
            setToners={setToners}
            setTotals={setTotals}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        </Collapse>
        <Collapse in={step === 2}>
          <Summary
            data={{
              info,
              toners,
              totals,
            }}
            setStep={setStep}
            handleFinalSubmit={handleFinalSubmit}
            handlingSubmit={handlingSubmit}
          />
        </Collapse>
      </DialogContent>
    </>
  );
}

function ReportInfo({ info, setInfo, totals, setTotals, nextStep }) {
  const [name, setName] = React.useState(info.name);
  const [date, setDate] = React.useState(info.date);
  const [transactionType, setTransactionType] = React.useState(
    info.transactionType
  );
  const [currency, setCurrency] = React.useState(info.rate > 0 ? "USD" : "KES");
  const [rate, setRate] = React.useState(info.rate);

  const [addingToners, setAddingToners] = React.useState(true);
  const [revenue, setRevenue] = React.useState(totals.revenue);
  const [profit, setProfit] = React.useState(totals.profit);
  const [profitPercent, setProfitPercent] = React.useState(0);

  React.useEffect(() => {
    setInfo({ name, date, transactionType, rate });
  }, [name, date, transactionType, rate, setInfo]);

  React.useEffect(() => {
    setTotals({
      revenue: parseFloat(revenue),
      profit: parseFloat(profit),
      profitPercent: parseFloat(profitPercent),
    });
  }, [revenue, profit, profitPercent, setTotals]);

  React.useEffect(() => {
    setProfitPercent(NumberFormater(((profit / revenue) * 100).toFixed(2)));
  }, [profit, revenue]);

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <DateTimePicker
            label="Date"
            value={date}
            setChange={(date) => setDate(date)}
          />
          <ToggleButtonGroup
            value={transactionType}
            onChange={(e) => {
              if (e) setTransactionType(e.target.value);
            }}
            color={transactionType === "debit" ? "error" : "success"}
            help
            exclusive
          >
            <ToggleButton value="debit">Debit</ToggleButton>
            <ToggleButton value="credit">Credit</ToggleButton>
          </ToggleButtonGroup>
          <Typography variant="body2">
            {transactionType === "debit"
              ? "Toners are being added to storage"
              : "Toners are gonna be removed from the storage"}
          </Typography>
          <ToggleButtonGroup
            value={currency}
            onChange={(e) => {
              if (e) {
                setCurrency(e.target.value);
                setRate(0);
              }
            }}
            exclusive
          >
            <ToggleButton value="KES">KES</ToggleButton>
            <ToggleButton value="USD">USD</ToggleButton>
          </ToggleButtonGroup>
          <Collapse in={currency === "USD"}>
            <TextField
              fullWidth
              label="Rate"
              type="number"
              value={rate}
              onChange={(e) => setRate(e.target.value)}
            />
          </Collapse>
          <ToggleButtonGroup
            value={addingToners}
            onChange={(e, newValue) => {
              if (newValue !== null) setAddingToners(newValue);
            }}
            color={addingToners ? "success" : "warning"}
            exclusive
          >
            <ToggleButton value={true}>
              <ListAlt sx={{ mr: 1 }} />
              Items in report
            </ToggleButton>
            <ToggleButton value={false}>
              <DoNotDisturb sx={{ mr: 1 }} />
              No items
            </ToggleButton>
          </ToggleButtonGroup>

          <Collapse in={!addingToners}>
            <Stack direction="column" spacing={2}>
              <TextField
                label="Revenue"
                type="number"
                value={revenue}
                onChange={(e) => setRevenue(e.target.value)}
                inputProps={{ min: 0 }}
              />
              <TextField
                label="Profit"
                type="number"
                value={profit}
                onChange={(e) => setProfit(e.target.value)}
                inputProps={{ min: 0 }}
              />
              <TextField
                label="Profit %"
                type="number"
                value={profitPercent}
                disabled
              />
            </Stack>
          </Collapse>

          <Button
            variant="contained"
            color="info"
            startIcon={<Start />}
            onClick={() => nextStep(!addingToners)}
            disabled={
              name.trim() === "" ||
              (currency === "USD" && rate <= 0) ||
              (!addingToners && revenue <= 0)
            }
          >
            Next
          </Button>
        </Stack>
      </form>
    </>
  );
}

function CreateData({ info, setToners, setTotals, nextStep, prevStep }) {
  const [raw, setRaw] = React.useState(sessionStorage.getItem("raw") || "");
  const [lockRaw, setLockRaw] = React.useState(false);
  const [headersIncluded, setHeadersIncluded] = React.useState(true);

  const [definedHeaders, setDefinedHeaders] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [proccessingData, setProccessingData] = React.useState(false);

  // Convert raw string to dictionary
  React.useEffect(() => {
    const convertRawToDictionary = () => {
      if (raw === "") return [];
      const lines = raw.trim().split("\n");
      const headers = headersIncluded
        ? lines[0].split("\t")
        : lines[0].split("\t").map((_, index) => `Column ${index + 1}`);

      const data = lines.slice(headersIncluded ? 1 : 0).map((line) => {
        const values = line.split("\t");
        return headers.reduce((obj, header, index) => {
          obj[header] = values[index];
          return obj;
        }, {});
      });
      return data;
    };
    setData(convertRawToDictionary());
  }, [raw, headersIncluded]);

  const defaultHeaders = React.useMemo(() => {
    return [
      "Description",
      "Partnumber",
      "Quantity",
      "Amount",
      "Cost",
      "Profit",
    ];
  }, []);

  React.useEffect(() => {
    const lines = raw.split("\n");
    const headers = headersIncluded
      ? lines[0].split("\t")
      : lines[0].split("\t").map((_, index) => `Column ${index + 1}`);

    const compare = (word) => {
      // Split the word into individual words
      const wordArray = word.split(" ");

      // Iterate through the array
      for (let i = 0; i < defaultHeaders.length; i++) {
        // Check if any part of the word matches the current array item
        for (let j = 0; j < wordArray.length; j++) {
          if (
            wordArray[j].toLowerCase().includes(defaultHeaders[i].toLowerCase())
          ) {
            // If a match is found, return the array item
            return defaultHeaders[i];
          }
        }
      }
      // If no similar word is found, return null or handle as per requirement
      return "";
    };

    const newHeaders = headers.map((header, index) => {
      return compare(header);
    });

    setDefinedHeaders(newHeaders);
  }, [raw, headersIncluded, defaultHeaders]);

  // Call the convertRawToDictionary function
  const dictionary = data;

  // Display the dictionary as a table
  const renderTable = () => {
    if (dictionary.length === 0) return null;
    return (
      <TableContainer>
        <Table padding="none">
          <TableHead>
            <TableRow>
              {definedHeaders.map((header, index) => (
                <TableCell key={`${index}_select`} sx={{ px: 0.5 }}>
                  {
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id={`${index}_select`}>
                        Column Name
                      </InputLabel>
                      <Select
                        labelId={`${index}_select`}
                        id={`${index}_select`}
                        label={header}
                        value={definedHeaders[index]}
                        onChange={(e) => {
                          setDefinedHeaders((prev) => {
                            const newHeaders = [...prev];
                            newHeaders[index] = e.target.value;
                            return newHeaders;
                          });
                        }}
                      >
                        <MenuItem value="">None</MenuItem>
                        {defaultHeaders.map((name) => (
                          <MenuItem value={name}>{name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {headersIncluded
                ? Object.keys(dictionary[0]).map((header, index) => (
                    <TableCell key={index}>{header}</TableCell>
                  ))
                : Object.keys(dictionary[0]).map((header, index) => (
                    <TableCell key={index}>column_{index}</TableCell>
                  ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {dictionary.map((row, index) => (
              <TableRow key={index}>
                {Object.values(row).map((value, index) => (
                  <TableCell key={index}>{value}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleLockRaw = () => {
    setLockRaw((prev) => {
      sessionStorage.setItem("raw", raw);
      return !prev;
    });
  };

  const [headersCorrect, setHeadersCorrect] = React.useState(false);

  React.useEffect(() => {
    setHeadersCorrect(() => {
      if (info.transactionType === "credit") {
        if (
          definedHeaders.includes("Description") &&
          definedHeaders.includes("Quantity") &&
          definedHeaders.includes("Amount") &&
          definedHeaders.includes("Cost") &&
          definedHeaders.includes("Profit")
        )
          return false;
      } else if (
        definedHeaders.includes("Partnumber") &&
        definedHeaders.includes("Description") &&
        definedHeaders.includes("Quantity") &&
        definedHeaders.includes("Cost")
      )
        return false;

      return true;
    });
  }, [definedHeaders, info]);

  return (
    <>
      <Collapse in={!proccessingData}>
        <Box
          sx={{
            position: "relative",
            mb: 1,
          }}
        >
          <Button
            startIcon={<ArrowBack />}
            onClick={prevStep}
            sx={{
              mb: 2,
            }}
          >
            Back
          </Button>
          <TextField
            fullWidth
            label="Raw"
            variant="outlined"
            value={raw}
            onChange={(e) => setRaw(e.target.value)}
            multiline
            rows={5}
            disabled={lockRaw}
          />
          <IconButton
            onClick={handleLockRaw}
            sx={{
              position: "absolute",
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              borderRadius: "4px 0 4px 0",
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderLeft: "none",
              borderTop: "none",
            }}
          >
            {lockRaw ? <Lock color="warning" /> : <LockOpen />}
          </IconButton>
        </Box>

        <FormGroup
          sx={{
            width: "fit-content",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                value={headersIncluded}
                onChange={(e) => setHeadersIncluded(e.target.checked)}
                checked={headersIncluded}
              />
            }
            label="Headers Included"
          />
        </FormGroup>

        <Collapse in={raw.length > 0}>
          <Paper variant="outlined" sx={{ p: 2, my: 2 }}>
            {renderTable()}
          </Paper>

          <Button
            variant="contained"
            color="info"
            startIcon={<Start />}
            disabled={headersCorrect}
            onClick={() => setProccessingData(true)}
          >
            Proccess Data
          </Button>
        </Collapse>
      </Collapse>
      <Collapse in={proccessingData}>
        <ProccessedData
          definedHeaders={definedHeaders}
          data={data}
          proccessingData={proccessingData}
          setProccessingData={setProccessingData}
          setToners={setToners}
          setTotals={setTotals}
          nextStep={nextStep}
          rate={info?.rate}
          transactionType={info?.transactionType}
        />
      </Collapse>
    </>
  );
}

function ProccessedData({
  definedHeaders,
  data,
  proccessingData,
  setProccessingData,
  setToners,
  setTotals,
  nextStep,
  rate,
  transactionType,
}) {
  const [loading, setLoading] = React.useState(true);
  const [newData, setNewData] = React.useState([]);

  React.useEffect(() => {
    if (proccessingData) {
      if (transactionType === "credit") {
        if (
          !definedHeaders.includes("Description") ||
          !definedHeaders.includes("Quantity") ||
          !definedHeaders.includes("Amount") ||
          !definedHeaders.includes("Cost") ||
          !definedHeaders.includes("Profit")
        )
          setProccessingData(false);
      } else {
        if (
          !definedHeaders.includes("Partnumber") ||
          !definedHeaders.includes("Description") ||
          !definedHeaders.includes("Quantity") ||
          !definedHeaders.includes("Cost")
        )
          setProccessingData(false);
      }
    }
  }, [
    proccessingData,
    setProccessingData,
    definedHeaders,
    transactionType,
    newData,
  ]);

  React.useEffect(() => {
    const regex = /[^0-9.-]/g;
    if (proccessingData) {
      const newData = data.map((row) => {
        const newRow = {};
        Object.keys(row).forEach((key, index) => {
          if (definedHeaders[index]) {
            if (definedHeaders[index] === "Quantity") {
              newRow[definedHeaders[index]] = parseInt(
                row[key]?.replace(regex, "")
              );
              return;
            }
            if (["Amount", "Cost", "Profit"].includes(definedHeaders[index])) {
              let amount = new Decimal(row[key]?.replace(regex, "") || 0);
              if (rate > 0) amount = amount.mul(rate);
              newRow[definedHeaders[index]] = amount.toNumber();
              return;
            }

            newRow[definedHeaders[index]] = row[key];
          }
        });
        if (!("Partnumber" in newRow)) newRow["Partnumber"] = "N/A";

        if (!("Amount" in newRow))
          newRow["Amount"] = new Decimal(newRow["Cost"])
            .mul(newRow["Quantity"])
            .toNumber();

        if (!("Profit" in newRow))
          newRow["Profit"] = new Decimal(newRow["Amount"])
            .sub(new Decimal(newRow["Cost"]).mul(newRow["Quantity"]))
            .toNumber();

        return newRow;
      });

      setNewData(newData);
      setLoading(false);
    }
  }, [proccessingData, data, definedHeaders, rate]);

  const [profitCheck, setProfitCheck] = React.useState(false);
  const [expectedProfit, setExpectedProfit] = React.useState(0);

  React.useEffect(() => {
    if (newData.length > 0) {
      let expProfit = newData.reduce((acc, row) => acc + row.Profit, 0);
      let calcRevenue = newData.reduce((acc, row) => acc + row.Amount, 0);

      let calcCost = newData.reduce(
        (acc, row) => acc + row.Cost * row.Quantity,
        0
      );

      setExpectedProfit(calcRevenue - calcCost);
      if (expProfit.toFixed(2) !== (calcRevenue - calcCost).toFixed(2))
        setProfitCheck(false);
      else setProfitCheck(true);
    }
  }, [newData]);

  const handleSubmit = () => {
    setToners(newData);
    setTotals({
      revenue: newData.reduce((acc, row) => acc + row.Amount, 0),
      profit: newData.reduce((acc, row) => acc + row.Profit, 0),
      profitPercent:
        (newData.reduce((acc, row) => acc + row.Profit, 0) /
          newData.reduce((acc, row) => acc + row.Amount, 0)) *
        100,
    });
    nextStep(false);
  };

  return (
    <>
      <Button
        startIcon={<ArrowBack />}
        onClick={() => {
          setLoading(true);
          setProccessingData(false);
        }}
      >
        Edit Data
      </Button>
      <Collapse in={loading}>
        <Skeleton variant="rectangular" width="100%" height={200} />
      </Collapse>
      <Collapse in={!loading}>
        <Paper variant="outlined" sx={{ p: 2, my: 2 }}>
          <TableContainer>
            <Table padding="none">
              {newData.length > 0 && (
                <TableHead>
                  <TableRow>
                    {Object.keys(newData[0]).map((header, index) => (
                      <TableCell key={index}>{header}</TableCell>
                    ))}
                    <TableCell>Check</TableCell>
                    <TableCell>Profit %</TableCell>
                  </TableRow>
                </TableHead>
              )}

              <TableBody>
                {newData.map((row, index) => (
                  <TableRow key={index}>
                    {Object.values(row).map((value, index) => (
                      <TableCell key={index}>{value}</TableCell>
                    ))}
                    <TableCell>
                      {row.Profit === row.Amount - row.Cost * row.Quantity ? (
                        <Check color="success" />
                      ) : (
                        <Clear color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      {NumberFormater(
                        (
                          ((row.Amount - row.Cost * row.Quantity) /
                            row.Amount) *
                          100
                        ).toFixed(2)
                      )}
                      %
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Typography variant="h5">Totals</Typography>
        <Paper variant="outlined" sx={{ p: 2, my: 2 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h6" sx={{ pr: 2 }}>
              Revenue
            </Typography>
            {NumberFormater(
              newData.reduce((acc, row) => acc + row.Amount, 0).toFixed(2)
            )}
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h6" sx={{ pr: 2 }}>
              Profit
            </Typography>
            {NumberFormater(
              newData.reduce((acc, row) => acc + row.Profit, 0).toFixed(2)
            )}
            {profitCheck ? (
              <Chip label="Correct" color="success" />
            ) : (
              <Chip
                label={`Incorrect (${NumberFormater(
                  expectedProfit.toFixed(2)
                )})`}
                color="error"
              />
            )}
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h6" sx={{ pr: 2 }}>
              Profit %
            </Typography>
            {NumberFormater(
              (
                (newData.reduce((acc, row) => acc + row.Profit, 0) /
                  newData.reduce((acc, row) => acc + row.Amount, 0)) *
                100
              ).toFixed(2)
            )}
            %
          </Stack>
        </Paper>
        <LoadingButton
          variant="contained"
          color="success"
          startIcon={<Check />}
          onClick={handleSubmit}
        >
          Save Toners
        </LoadingButton>
      </Collapse>
    </>
  );
}

function Summary({ data, setStep, handleFinalSubmit, handlingSubmit }) {
  const InfoDisplay = ({ title, step, children }) => {
    const [hover, setHover] = React.useState(false);

    return (
      <Paper
        variant="outlined"
        sx={{ p: 2, my: 2, position: "relative" }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Fade in={hover}>
          <IconButton
            onClick={() => setStep(step)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <Edit />
          </IconButton>
        </Fade>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        {children}
      </Paper>
    );
  };

  return (
    <>
      <InfoDisplay title="Report Info" step={0}>
        <Stack direction="column" spacing={2}>
          <Typography variant="body1">Name: {data?.info?.name}</Typography>
          <Typography variant="body1">
            Date: {new Date(data?.info?.date).toLocaleDateString()}
          </Typography>
          <Typography variant="body1">
            Type: {data?.info?.transactionType}
          </Typography>
          <Typography variant="body1">
            Conversion Rate: {data?.info?.rate}
          </Typography>
        </Stack>
      </InfoDisplay>
      <InfoDisplay title="Toners" step={data?.toners.length > 0 ? 1 : 0}>
        {data.toners.length > 0 ? (
          <TableContainer>
            <Table padding="none">
              <TableHead>
                <TableRow>
                  {Object.keys(data.toners[0]).map((header, index) => (
                    <TableCell key={index}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.toners.map((toner, index) => (
                  <TableRow key={index}>
                    {Object.keys(data.toners[0]).map((header, index) => (
                      <TableCell key={index}>{toner[header]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body1">No toners added</Typography>
        )}
      </InfoDisplay>
      <InfoDisplay title="Totals" step={data?.toners.length > 0 ? 1 : 0}>
        <Typography variant="body1">
          Revenue: {data?.info?.transactionType === "debit" && "("}
          {NumberFormater(parseFloat(data?.totals?.revenue).toFixed(2))}
          {data?.info?.transactionType === "debit" && ")"}
        </Typography>
        <Typography variant="body1">
          Profit: {NumberFormater(parseFloat(data?.totals?.profit).toFixed(2))}
        </Typography>
        <Typography variant="body1">
          Profit:{" "}
          {NumberFormater(parseFloat(data?.totals?.profitPercent).toFixed(2))}%
        </Typography>
      </InfoDisplay>
      <LoadingButton
        variant="contained"
        color="success"
        startIcon={<Check />}
        loading={handlingSubmit}
        onClick={handleFinalSubmit}
      >
        Save Report
      </LoadingButton>
    </>
  );
}
