import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

export default function AreYouSure({
  open,
  text,
  acceptText,
  declineText,
  onAccept,
  onDecline,
}) {
  return (
    <Dialog open={open} onClose={onDecline}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text ||
            "If you close this form all data that you have entered will be lost."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAccept} color="success" variant="contained">
          {acceptText || "Confirm"}
        </Button>
        <Button onClick={onDecline} color="warning" variant="outlined">
          {declineText || "Cancel"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
