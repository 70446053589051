import { axiosAuth } from "../Protected";
import { toast } from "react-toastify";
import getDeviceFingerprint from "../../Components/common/FingerPrint";

// Account Management
export function login(data, setHeader, history) {
  const r = data.remember;
  data.deviceUID = getDeviceFingerprint();

  return axiosAuth
    .post(`/auth/login`, data)
    .then((res) => {
      if (res.data.status === "success") {
        if (r)
          localStorage.setItem(
            "Authorization",
            `Bearer ${res.data.access_token}`
          );
        sessionStorage.setItem(
          "Authorization",
          `Bearer ${res.data.access_token}`
        );
        setHeader(`Bearer ${res.data.access_token}`);
        toast.success(res.data.message, { toastId: "Login_message" });
      } else if (res.data.status === "unverified") {
        history(`/verify?e=${data.email}&h=${res.data.key}`, { replace: true });
      } else if (res.data.status === "newDevice") {
        return res.data.key;
      } else {
        toast.warning(res.data.message);
      }
    })
    .catch((err) => {
      if (err?.response?.status === 429)
        toast.error("Too many requests please wait.");
      else toast.error("Ohh no something went wrong");
    })
    .finally(() => {
      return false;
    });
}

export function logout(setHeader, setLoggedin) {
  toast.info("You have been logged out");
  setLoggedin(false);
  setHeader("");
  localStorage.removeItem("Authorization");
  sessionStorage.removeItem("Authorization");
}

export function createAccount(data) {
  return axiosAuth
    .post(`/auth/create`, data)
    .then((res) => {
      toast.success(res.data.message);
      return true;
    })
    .catch((err) => {
      if (err?.response.data) {
        toast.error(err.response.data.message, { autoClose: 10000 });
        return false;
      }
    });
}

export function verify(data, history) {
  return axiosAuth
    .post(`/auth/verify`, data)
    .then((res) => {
      if (res.data.status === "success") {
        toast.success(res.data.message);
        history(`/login`, { replace: true });
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((err) => {
      if (err?.response.data) {
        toast.error(err.response.data.message);
      }
    });
}

export function verifyDevice(data) {
  return axiosAuth.post(`/auth/verifyDevice`, data).then((res) => {
    return res.data;
  });
}

export function forgotpassword(data, history) {
  return axiosAuth
    .post(`/auth/forgot_password`, data)
    .then((res) => {
      if (res.data.status === "success") {
        toast.success(res.data.message);
        return { stat: "success", key: res.data.key };
      } else if (res.data.status === "unverified") {
        history(`/verify?e=${data.email}&h=${res.data.key}`, { replace: true });
        return { stat: "unver" };
      } else {
        toast.error(res.data.message);
        return { stat: "fail" };
      }
    })
    .catch((err) => {
      if (err?.response?.status === 429)
        toast.error("Too many requests please wait.");
      if (err?.response.data) {
        toast.error(err.response.data.message);
      }
    });
}

export function createpassword_token(data) {
  return axiosAuth
    .post(`/auth/token_pass_reset`, data)
    .then((res) => {
      toast.success(res.data.message);
      return true;
    })
    .catch((err) => {
      if (err?.response.data) {
        toast.error(err.response.data.message, { autoClose: 10000 });
        return false;
      }
    });
}

export function changePassword(data) {
  return axiosAuth
    .post(`/auth/changepassword`, data)
    .then((res) => {
      if (res.data.status === "success") {
        toast.success(res.data.message);
        return true;
      } else {
        toast.error(res.data.message);
        return false;
      }
    })
    .catch((err) => {
      if (err?.response.data) {
        toast.error(err.response.data.message, { autoClose: 10000 });
        return false;
      }
    });
}

export function changeNames(data) {
  return axiosAuth.post(`auth/changeNames`, data).then((res) => {
    return res.data;
  });
}

export function changePhoneNumber(data) {
  return axiosAuth.post(`/auth/changePhoneNumber`, data).then((res) => {
    return res.data;
  });
}

export function changeTitle(data) {
  return axiosAuth.post(`/auth/changeTitle`, data).then((res) => {
    return res.data;
  });
}

// Notifications

export function getNotifications() {
  return axiosAuth.get(`/protected/notifications`).then((res) => {
    return res.data;
  });
}

export function markAsRead(id) {
  return axiosAuth
    .post(`/protected/notifications/read`, { notifId: id })
    .then((res) => {
      return res.data;
    });
}

export function markAsUnRead(id) {
  return axiosAuth
    .post(`/protected/notifications/unread`, { notifId: id })
    .then((res) => {
      return res.data;
    });
}

export function markAllAsRead() {
  return axiosAuth.get(`/protected/notifications/read_all`).then((res) => {
    return res.data;
  });
}

// Danger Zone

export function uploadExcel(file) {
  let formData = new FormData();
  formData.append("file", file);
  return axiosAuth
    .post(`/protected/danger/upload_db`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res.data;
    });
}

// Notification Roles

export function getNotificationRoles() {
  return axiosAuth.get(`/protected/notification_roles/get`).then((res) => {
    return res.data;
  });
}

export function updateNotificationRoles(notifName, roleID) {
  return axiosAuth
    .post(`/protected/notification_roles/update`, { notifName, roleID })
    .then((res) => {
      return res.data;
    });
}

export function createNotificationRoles() {
  return axiosAuth.get(`/protected/notification_roles/innit`).then((res) => {
    return res.data;
  });
}
