import React from "react";
import {
  Skeleton,
  Typography,
  Dialog,
  DialogTitle,
  Card,
  CardContent,
  Button,
  Tooltip,
  Grid,
  Chip,
  CardActions,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Paper,
  List,
  ListItem,
  TextField,
} from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";
import {
  ChangeRoleStatus,
  get_roles,
  RoleGetAllInfo,
  edit_role,
  delete_role,
} from "../../CustomAxios/Protected/AdminManagment";

import AddRoleForm from "../../Components/Forms/RoleAdd";

import { toast } from "react-toastify";
import SearchArea from "../../Components/common/SearchArea";
import { Box } from "@mui/system";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import FloatingFormButton from "../../Components/common/FloatingFormButton";

function RoleCard(props) {
  const Data = props.data;
  const [expanded, setExpanded] = React.useState(false);

  const handleDetailOpen = () => {
    setExpanded(true);
  };
  const handleDetailClose = () => {
    setExpanded(false);
    props.setLoadingTrue();
  };

  return (
    <Grid item xs={12} md={4} lg={3}>
      <Card variant={Data.active ? "elevation" : "outlined"}>
        <CardContent>
          <Typography variant="h5" sx={{ minHeight: "80px", mt: 1 }}>
            {Data.name}{" "}
            {Data.active ? (
              <Chip color="success" label="Active" />
            ) : (
              <Chip color="secondary" variant="outlined" label="Inactive" />
            )}
          </Typography>
          <Typography sx={{ minHeight: "100px", mt: 1 }}>
            {Data.description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Button
            color="primary"
            onClick={handleDetailOpen}
            sx={{ ml: "auto" }}
          >
            More details
          </Button>
        </CardActions>
        <Dialog
          onClose={handleDetailClose}
          open={expanded}
          PaperProps={{
            elevation: 0,
            sx: { minWidth: "60vw" },
          }}
        >
          <RoleMoreDetails Data={Data} handleDetailClose={handleDetailClose} />
        </Dialog>
      </Card>
    </Grid>
  );
}

function RoleMoreDetails({ Data, handleDetailClose }) {
  const [loading, setLoading] = React.useState(true),
    [role, setRole] = React.useState({}),
    [pages, setPages] = React.useState([]),
    [users, setUsers] = React.useState([]),
    [characterCount, setCharacterCount] = React.useState(0),
    [name, setName] = React.useState(""),
    [description, setDescription] = React.useState(""),
    [dis, setDis] = React.useState(true),
    [deleteOpen, setDeleteOpen] = React.useState(false);

  React.useEffect(() => {
    if (loading)
      RoleGetAllInfo(Data.id).then((res) => {
        setRole(res.roleData);
        setUsers(res.users);
        setPages(res.pages);
        setLoading(false);
      });
  }, [loading, Data]);

  React.useEffect(() => {
    setName(role.name || Data.name);
    setDescription(role.description || Data.description);
  }, [role, Data]);

  React.useEffect(() => {
    if (name === role.name && description === role.description) setDis(true);
    else setDis(false);
  }, [name, role, description, dis]);

  React.useEffect(() => {
    setCharacterCount(description.length);
  }, [description]);

  const changeStatus = (id) => {
    ChangeRoleStatus({ roleId: id }).then((res) => {
      if (res.status === "success") {
        setLoading(true);
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  const editRole = () => {
    edit_role({ roleId: Data.id, name: name, description: description }).then(
      (res) => {
        if (res.status === "success") {
          setLoading(true);
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  const handleDeleteRole = () => {
    delete_role({ roleId: Data.id }).then((res) => {
      if (res.status === "success") {
        handleDetailClose();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  const handledeleteClose = () => {
    setDeleteOpen(false);
  };

  return (
    <>
      <DialogTitle>{role.name || Data.name}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ReloadBtn setLoading={() => setLoading(true)} />
            <TextField
              required
              variant="standard"
              margin="normal"
              label="Name"
              type="text"
              value={name}
              onInput={(e) => setName(e.target.value)}
              fullWidth
            />
            <TextField
              required
              variant="standard"
              margin="normal"
              label="Description"
              type="text"
              value={description}
              multiline
              rows={4}
              onInput={(e) => setDescription(e.target.value)}
              fullWidth
              helperText={`${characterCount}/100`}
            />

            <Button
              color="primary"
              variant="contained"
              disabled={dis}
              onClick={editRole}
            >
              save changes
            </Button>
            <Divider sx={{ my: 1 }} />
            <Chip
              icon={role.active ? <CheckCircleOutlineIcon /> : <CancelIcon />}
              label={role.active ? "Role is active" : "Role is inactive"}
              color={role.active ? "success" : "default"}
              variant="outlined"
              sx={{ my: 1, mr: 1 }}
            />
            <Button color="secondary" onClick={() => changeStatus(Data.id)}>
              {role.active ? "Disable Role" : "Enable Role"}
            </Button>

            <Divider sx={{ my: 1 }} />

            <Paper variant="outlined" sx={{ p: 1 }}>
              <Typography variant="h6">Pages</Typography>
              <Divider sx={{ mt: 1 }} />
              {pages.length > 0 ? (
                <List sx={{ maxHeight: "50vh", overflow: "auto" }}>
                  {pages.map((page) => (
                    <ListItem key={page.id}>{page.name}</ListItem>
                  ))}
                </List>
              ) : (
                "No pages assigned to this role"
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper variant="outlined" sx={{ p: 1 }}>
              <Typography variant="h6">Users</Typography>
              <Divider sx={{ mt: 1 }} />
              {users.length > 0 ? (
                <List sx={{ maxHeight: "50vh", overflow: "auto" }}>
                  {users.map((users) => (
                    <ListItem key={users.id}>{users.email}</ListItem>
                  ))}
                </List>
              ) : (
                "No users have been assigned this role"
              )}
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={() => setDeleteOpen(true)}
        >
          Delete Role
        </Button>
        <Button onClick={handleDetailClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
      <Dialog
        open={deleteOpen}
        onClose={handledeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-title">
            Do you want to delete the {role.name || Data.name} role, this cannot
            be undone.
          </DialogContentText>
          <DialogActions>
            <Button onClick={handledeleteClose} color="secondary" autoFocus>
              Cancel
            </Button>
            <Button
              onClick={handleDeleteRole}
              color="primary"
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

function ReloadBtn(props) {
  return (
    <Tooltip title={"Reload"}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => props.setLoading(true)}
      >
        <RefreshIcon />
      </Button>
    </Tooltip>
  );
}

export default function RoleManager() {
  const [loading, setLoading] = React.useState(true);
  const [roles, setRoles] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [final, setFinal] = React.useState([]);

  React.useEffect(() => {
    if (loading)
      get_roles().then((res) => {
        setRoles(res);
        setFinal(res);
        setLoading(false);
      });
  }, [loading]);

  const onComplete = () => {
    setLoading(true);
    setOpen(false);
  };

  const changeStatus = (id) => {
    ChangeRoleStatus({ roleId: id }).then((res) => {
      if (res.status === "success") {
        setLoading(true);
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  const setLoadingTrue = () => {
    setLoading(true);
  };

  return (
    <>
      <Typography variant="h4" color="primary" sx={{ mb: 2 }}>
        Role Manager
      </Typography>

      <FloatingFormButton title="Create New Role" open={open} setOpen={setOpen}>
        <DialogTitle>Add Role</DialogTitle>
        <AddRoleForm onComplete={onComplete} />
      </FloatingFormButton>

      {loading ? (
        <LoadingSkeleton />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              pt: 2,
              mb: 2,
            }}
          >
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              <ReloadBtn setLoading={setLoading} />
            </Typography>
            <SearchArea current={roles} setFinalArray={setFinal} />
          </Box>
          <Grid container spacing={2}>
            {final.map((role) => {
              return (
                <RoleCard
                  data={role}
                  key={`Role_${role.id}`}
                  changeStatus={changeStatus}
                  setLoadingTrue={setLoadingTrue}
                />
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
}

function SkeletonCard() {
  return (
    <Grid item xs={12} md={4} lg={3}>
      <Skeleton variant="rectangular" width={276} height={270} />
    </Grid>
  );
}

function LoadingSkeleton() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          pt: 2,
          mb: 2,
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <Skeleton width={80} height={80} />
        </Typography>
        <Skeleton width={220} />
      </Box>
      <Grid container spacing={2}>
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </Grid>
    </>
  );
}
