import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogContent,
  TextField,
  Stack,
  Alert,
  AlertTitle,
  Typography,
  Collapse,
  DialogActions,
  Dialog,
  DialogTitle,
  IconButton,
  Divider,
  CircularProgress,
  CardActionArea,
  Fade,
  List,
  ListItem,
} from "@mui/material";
import { toast } from "react-toastify";

import config from "../../../Config";

import AddIcon from "@mui/icons-material/Add";
import PhoneInput from "react-phone-input-2";
import {
  addClient,
  requestClientTransfer,
  getAllComaniesNames,
  addNewCompany,
  sendNewClientEmail,
} from "../../API/Sales";
import { Box } from "@mui/system";
import { Cancel, Close } from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";

export default function AddClientForm({ onClose, onComplete }) {
  const [companyID, setCompanyID] = React.useState(""),
    [firstName, setFirstName] = React.useState(""),
    [lastName, setLastName] = React.useState(""),
    [email, setEmail] = React.useState(""),
    [phone, setPhone] = React.useState(""),
    [jobTitle, setJobTitle] = React.useState(""),
    [disabled, setDisabled] = React.useState(true),
    [loading, setLoading] = React.useState(false),
    [request, setRequest] = React.useState(false),
    [requestClient, setRequestClient] = React.useState(""),
    [sendingRequest, setSendingRequest] = React.useState(false);

  React.useEffect(() => {
    if (companyID && firstName.trim() && lastName.trim() && email.trim()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [companyID, firstName, lastName, email, phone]);

  const handleSubmit = () => {
    setLoading(true);
    const Data = {
      companyID,
      firstName,
      lastName,
      email,
      phone,
      jobTitle,
    };

    addClient(Data)
      .then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
          sendNewClientEmail(res.data.id);
          if (onComplete) onComplete(res.data);
          else onClose();
        } else if (res.status === "exists") {
          if (res.same) toast.warning(res.message);
          else {
            setRequest(true);
            setRequestClient(res.clientID);
            toast.warning(res.message);
          }
        } else if (res.status === "warning") {
          toast.warning(res.message);
          sendNewClientEmail(res.data.id);
          if (onComplete) onComplete(res.data);
          else onClose();
        } else {
          toast.error(res.message);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const sendTransferRequest = () => {
    setSendingRequest(true);
    const Data = {
      clientID: requestClient,
    };
    requestClientTransfer(Data).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        if (onComplete) onComplete();
        else onClose();
      } else toast.error(res.message);
    });
    setSendingRequest(false);
  };

  return (
    <>
      <DialogContent dividers>
        {request && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: " 100%",
              height: "100%",
              zIndex: 100,
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
            onClick={() => {
              setRequest(false);
            }}
          >
            <Alert severity="info" onClick={(e) => e.stopPropagation()}>
              <AlertTitle>Request Transfer</AlertTitle>
              <Typography sx={{ mb: 1 }}>
                Would you like to request that the client is transfered to you?
              </Typography>
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <LoadingButton
                  variant="contained"
                  color="success"
                  loading={sendingRequest}
                  onClick={sendTransferRequest}
                >
                  Request transfer
                </LoadingButton>
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={() => {
                    setRequest(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Alert>
          </Box>
        )}
        <CompanyManger companyID={companyID} setCompanyID={setCompanyID} />
        <Collapse in={companyID !== ""}>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
              <TextField
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <TextField
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Stack>

            <PhoneInput
              country={config.phoneNumber.Default}
              value={phone}
              onChange={(e) => setPhone(e)}
            />
            <TextField
              label="Job Title"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </Stack>
        </Collapse>
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <LoadingButton
          startIcon={<AddIcon />}
          variant="contained"
          loading={loading}
          disabled={disabled}
          onClick={handleSubmit}
          fullWidth
          sx={{ borderRadius: 0 }}
        >
          Add Client
        </LoadingButton>
        {onClose && (
          <Button onClick={onClose} color="warning" variant="outlined">
            Cancel
          </Button>
        )}
      </DialogActions>
    </>
  );
}

function CompanyManger({ companyID, setCompanyID }) {
  const [searchVal, setSearchVal] = React.useState(""),
    [companyName, setCompanyName] = React.useState(""),
    [KRA, setKRA] = React.useState(""),
    [address, setAddress] = React.useState(""),
    [note, setNote] = React.useState(""),
    [companyDisabled, setCompanyDisabled] = React.useState(true),
    [companyLoading, setCompanyLoading] = React.useState(false);

  const [cantFindCompany, setCantFindCompany] = React.useState(false);
  const [openCompany, setOpenCompany] = React.useState(false);

  const [results, setResults] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (companyName.trim()) setCompanyDisabled(false);
    else setCompanyDisabled(true);
  }, [companyName]);

  React.useEffect(() => {
    //wait for user to stop typing
    const timeout = setTimeout(() => {
      if (searchVal.trim()) {
        setLoading(true);
        getAllComaniesNames({ searchKey: searchVal, limit: 20 })
          .then((res) => {
            if (res.length > 0) setCantFindCompany(false);
            else setCantFindCompany(true);
            setResults(res);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchVal]);

  const handleAddNewCompany = () => {
    setCompanyLoading(true);
    const Data = {
      companyName,
      KRA,
      address,
      note,
    };
    addNewCompany(Data)
      .then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
          setCompanyID(res.id);
          setSearchVal(res.name);
          setOpenCompany(false);
        } else if (res.status === "exists") {
          toast.info(res.message);
          setCompanyID(res.id);
          setSearchVal(res.name);
        } else toast.error(res.message);
      })
      .finally(() => {
        setCompanyLoading(false);
      });
  };

  return (
    <>
      <Collapse in={!companyID}>
        <Alert severity="info">
          <AlertTitle>Is the client an individual?</AlertTitle>
          <Button
            color="inherit"
            variant=""
            onClick={() => {
              getAllComaniesNames({ searchKey: "individual" }).then((res) => {
                setSearchVal("Individual");
                setResults([res]);
                setCompanyID(res[0].id);
              });
            }}
          >
            Yes client is an individual
          </Button>
        </Alert>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            label={"Company Name"}
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="info" size={20} /> : null}
                </React.Fragment>
              ),
            }}
            required
          />
        </Stack>
      </Collapse>
      <Stack spacing={2} sx={{ mt: 2 }}>
        <Box
          sx={{
            ...(!companyID && !cantFindCompany && { height: "50vh" }),
            overflowY: "auto",
          }}
        >
          <Collapse in={results.length > 0}>
            <List disablePadding>
              <TransitionGroup>
                {results.map((company) => (
                  <Collapse key={company.id}>
                    <ListItem
                      key={company.id}
                      sx={{
                        p: 0,
                        position: "relative",
                        bgcolor: (theme) => theme.palette.background.default,
                        overflow: "visible",
                        borderRadius: 2,
                        mb: 0.5,
                        ...(companyID === company.id && {
                          border: (theme) =>
                            `1px solid ${theme.palette.success.main}`,
                        }),
                      }}
                    >
                      <Fade in={companyID === company.id}>
                        <IconButton
                          onClick={() => {
                            setCompanyID("");
                            setSearchVal("");
                          }}
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1,
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </Fade>
                      <CardActionArea
                        sx={{ p: 2 }}
                        onClick={() => {
                          setCompanyID(company.id);
                          setSearchVal(company.name);
                        }}
                      >
                        {company.name}
                      </CardActionArea>
                    </ListItem>
                  </Collapse>
                ))}
              </TransitionGroup>
            </List>
          </Collapse>
        </Box>
        <Collapse in={cantFindCompany}>
          <Button
            onClick={() => {
              setOpenCompany(true);
            }}
            color="info"
          >
            Can't find company?
          </Button>
        </Collapse>

        <Dialog open={openCompany}>
          <DialogTitle>Add New Company</DialogTitle>
          <IconButton
            onClick={() => setOpenCompany(false)}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Close />
          </IconButton>
          <DialogContent
            dividers
            sx={{ bgcolor: (theme) => theme.palette.background.paper }}
          >
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                fullWidth
                required
              />
              <Divider>Optional</Divider>
              <TextField
                label="KRA pin"
                value={KRA}
                onChange={(e) => setKRA(e.target.value)}
                fullWidth
              />
              <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                <TextField
                  label="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  multiline
                  rows={4}
                  fullWidth
                />
                <TextField
                  label="Note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  multiline
                  rows={4}
                  fullWidth
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              disabled={companyDisabled}
              loading={companyLoading}
              onClick={handleAddNewCompany}
            >
              Add Company
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  );
}
