import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
} from "@mui/material";
import { toast } from "react-toastify";

import config from "../../../Config";

import AddIcon from "@mui/icons-material/Add";
import PhoneInput from "react-phone-input-2";

import { addSupplier } from "../../API/Cheques&Pos";

export default function AddClientForm({ onClose, onComplete }) {
  const [supplierName, setSupplierName] = React.useState(""),
    [paymentDays, setPaymentDays] = React.useState(0),
    [phone, setPhone] = React.useState(""),
    [address, setAddress] = React.useState(""),
    [note, setNote] = React.useState(""),
    [disabled, setDisabled] = React.useState(true),
    [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (supplierName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [supplierName]);

  const handleSubmit = () => {
    setLoading(true);
    const Data = {
      supplierName: supplierName,
      phone: phone,
      address: address,
      paymentDays: paymentDays,
      note: note,
    };

    addSupplier(Data)
      .then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
          onComplete(res.data);
        } else {
          toast.error(res.message);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <DialogContent>
        If there is a * the field is required
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            label="Supplier"
            value={supplierName}
            onChange={(e) => setSupplierName(e.target.value)}
            required
          />
          <TextField
            label="Payment Days"
            value={paymentDays}
            onChange={(e) => setPaymentDays(e.target.value)}
            type="number"
            onWheel={(e) => e.target.blur()}
          />
          <PhoneInput
            country={config.phoneNumber.Default}
            value={phone}
            onChange={(e) => setPhone(e)}
          />
          <TextField
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            maxRows={5}
            multiline
          />
          <TextField
            label="Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            maxRows={5}
            multiline
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          startIcon={<AddIcon />}
          variant="contained"
          loading={loading}
          disabled={disabled}
          onClick={handleSubmit}
        >
          Add Supplier
        </LoadingButton>
        <Button onClick={onClose} color="warning" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}
