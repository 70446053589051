import { LoadingButton } from "@mui/lab";
import {
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
} from "@mui/material";

import { Stack } from "@mui/system";
import React from "react";
import { toast } from "react-toastify";
import AsyncAutoComplete from "../../../Components/common/AsyncAutoComplete";
import { AddRfq, searchClient } from "../../API/Sales";
import DateTimePicker from "../common/DateTimePicker";

export default function AddRfqForm({ onClose, onNew }) {
  const [clientID, setClientID] = React.useState(""),
    [description, setDescription] = React.useState(""),
    [date, setDate] = React.useState(new Date());

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    AddRfq({ clientID: clientID.id, description, date })
      .then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
          onNew();
        } else toast.error(res.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Paper elevation={0} sx={{ borderRadius: 0 }}>
        <DialogTitle>New RFQ</DialogTitle>
      </Paper>
      <DialogContent>
        <Stack spacing={2} mt={1}>
          <AsyncAutoComplete
            getData={searchClient}
            value={clientID}
            setValue={setClientID}
            optionLabel="name"
            label="Customers"
          />
          <Collapse in={clientID ? true : false}>
            <Stack spacing={2}>
              <DateTimePicker value={date} setChange={setDate} />
              <TextField
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4}
                fullWidth
              />
            </Stack>
          </Collapse>
        </Stack>
      </DialogContent>
      <Paper elevation={0} sx={{ borderRadius: 0 }}>
        <DialogActions>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <LoadingButton
              variant="contained"
              color="success"
              loading={loading}
              onClick={handleSubmit}
            >
              Save
            </LoadingButton>
            <Button variant="outlined" color="warning" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Paper>
    </>
  );
}
