import React from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Paper,
  Stack,
  Select,
  InputLabel,
  TextField,
  Grow,
  CircularProgress,
} from "@mui/material";
import { getExchangeRate } from "../../API/Sales";
import NumberFormat from "../../../Components/common/NumberFormater";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function CurrencyConverter({ input, setOutput }) {
  const [amount, setAmount] = React.useState(input),
    [currencyOne, setCurrencyOne] = React.useState("USD"),
    [currencyTwo, setCurrencyTwo] = React.useState("KES"),
    [rate, setRate] = React.useState(0),
    [final, setFinal] = React.useState(0),
    [loading, setLoading] = React.useState(true),
    [exhangeRates, setExhangeRates] = React.useState([]),
    [confirm, setConfirm] = React.useState(false);

  const currencyList = [
    {
      name: "Kenya Shilling",
      value: "KES",
    },
    {
      name: "American Dollar",
      value: "USD",
    },
    {
      name: "Euro",
      value: "EUR",
    },
  ];

  React.useEffect(() => {
    if (loading)
      getExchangeRate().then((res) => {
        setExhangeRates(res);
        setLoading(false);
      });
  }, [loading]);

  React.useEffect(() => {
    const r1 = exhangeRates.find((e) => e.name === currencyOne)?.rate;
    const r2 = exhangeRates.find((e) => e.name === currencyTwo)?.rate;

    const calc = r2 / r1 || 0;

    setRate(calc);
  }, [currencyOne, currencyTwo, exhangeRates]);

  React.useEffect(() => {
    setFinal((amount * rate).toFixed(2));
  }, [amount, rate]);

  const convertClicked = () => {
    setOutput(final);
    setConfirm(true);
    setTimeout(() => setConfirm(false), 1100);
  };

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack spacing={2} direction="row" justifyContent="center">
          <TextField
            label="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            sx={{ maxWidth: "40%" }}
          />
          <FormControl>
            <InputLabel>From</InputLabel>
            <Select
              label="From"
              value={currencyOne}
              onChange={(e) => setCurrencyOne(e.target.value)}
            >
              {currencyList.map((currency) => (
                <MenuItem value={currency.value} key={currency.name}>
                  {currency.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Divider>TO</Divider>
        <Stack spacing={2} direction="row" justifyContent="center">
          <TextField
            label="Rate"
            type="number"
            onWheel={(e) => e.target.blur()}
            value={rate}
            onChange={(e) => setRate(e.target.value)}
            sx={{ maxWidth: "40%" }}
          />
          <FormControl>
            <InputLabel>To</InputLabel>
            <Select
              label="To"
              value={currencyTwo}
              onChange={(e) => setCurrencyTwo(e.target.value)}
            >
              {currencyList.map((currency) => (
                <MenuItem value={currency.value} key={currency.name}>
                  {currency.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Divider>=</Divider>
        <Stack spacing={2} direction="row" justifyContent="center">
          {NumberFormat(final)}
        </Stack>
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={convertClicked}>
            Convert
          </Button>
        </Stack>
      </Stack>
      {
        //Confirm Message
      }
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          display: confirm ? "flex" : "none",
          justifyContent: "center",
          alignItems: " center",
        }}
      >
        <Grow in={confirm} timeout={1000}>
          <CheckCircleOutlineIcon sx={{ fontSize: 100 }} color="success" />
        </Grow>
      </Box>
      {
        //Loading
      }
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          display: loading ? "flex" : "none",
          justifyContent: "center",
          alignItems: " center",
        }}
      >
        <Grow in={loading} timeout={500}>
          <CircularProgress />
        </Grow>
      </Box>
    </Paper>
  );
}
