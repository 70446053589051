import React from "react";
import { Tooltip, IconButton, Popover } from "@mui/material";

import { MoreVert } from "@mui/icons-material";

export default function MoreTableButton({
  id,
  children,
  selectRows = false,
  ...props
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const uniqueId = open ? `${id}-popover` : undefined;

  return (
    <>
      <Tooltip title="More">
        <IconButton aria-describedby={uniqueId} onClick={handleClick}>
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={uniqueId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {children}
      </Popover>
    </>
  );
}
