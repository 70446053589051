export default function lazyLoad(
  data,
  totalPages,
  pages,
  setData,
  setCurrentPage,
  getData,
  stopLoading,
  stop,
  signal,
  finishedLoading,
  setGotAll,
  gotAll
) {
  if (stop.cancel) {
    return;
  }
  getData(data, signal)
    .then((rows) => {
      setData((previous) => [...previous, ...rows]);
      setCurrentPage((previous) => previous + 1);
      stopLoading();
      if (data.currentPage === pages) {
        setGotAll(gotAll);

        finishedLoading();
      }

      if (data.currentPage < pages)
        lazyLoad(
          { ...data, currentPage: data.currentPage + 1 },
          totalPages,
          pages,
          setData,
          setCurrentPage,
          getData,
          stopLoading,
          stop,
          signal,
          finishedLoading,
          setGotAll,
          gotAll
        );
    })
    .catch((err) => {
      if (err?.code !== "ERR_CANCELED") console.log(err);
    });
}
