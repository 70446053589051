import React from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog({ onDelete, onClose, title, body }) {
  return (
    <div>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning" onClick={onDelete}>
          Yes
        </Button>
        <Button variant="text" onClick={onClose}>
          No
        </Button>
      </DialogActions>
    </div>
  );
}
