import React from "react";

import { Typography, IconButton, Tooltip, Chip } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { Link } from "react-router-dom";

import { getRfqs, getRfqPages } from "../API/Sales";

import { Outlet } from "react-router-dom";

import UniversalTable from "../../Components/common/UniversalTable";

import {
  LoadingProvider,
  LoadingContext,
} from "../Components/common/LoadingProvider";
import AddRfqForm from "../Components/Forms/AddRfq";
import DescriptionBubble from "../Components/common/DescriptionBubble";
import FloatingFormButton from "../../Components/common/FloatingFormButton";

export default function Main() {
  return (
    <>
      <LoadingProvider
        getFilterData={() => ({ currentPage: 1 })}
        getData={getRfqs}
        getPages={getRfqPages}
      >
        <RfqWrapper />
      </LoadingProvider>
    </>
  );
}

function RfqWrapper() {
  const [rfqAdd, setRfqAdd] = React.useState(false);

  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loading,
    loadFiltered,
    data,
  } = React.useContext(LoadingContext);

  const MoreRfqButton = ({ value }) => (
    <Tooltip title="Manage RFQ">
      <IconButton
        component={Link}
        to={`/rfqs/edit?id=${value.id}`}
        sx={{ float: "right" }}
      >
        <MoreVert />
      </IconButton>
    </Tooltip>
  );

  const headers = [
    {
      id: "clientName",
      label: "Company",
      searchable: true,
    },
    {
      id: "description",
      label: "Description",
      component: (value) => (
        <DescriptionBubble
          bubbleID={`desc-popover-${value.id}`}
          text={value.description}
        />
      ),
    },
    {
      id: "dateReceived",
      label: "Date Received",
      searchable: true,
      date: true,
    },
    {
      id: "dateCreated",
      label: "Date Created",
      searchable: true,
      date: true,
    },
    {
      id: "quoted",
      label: "Quoted",
      searchable: true,
      component: ({ quoted }) => (
        <Chip
          label={quoted ? "Yes" : "No"}
          color={quoted ? "success" : "error"}
          variant="outlined"
        />
      ),
    },
    {
      id: "edit",
      label: "",
      component: (value) => <MoreRfqButton value={value} />,
    },
  ];

  return (
    <>
      <Typography variant="h5" color="primary" gutterBottom>
        RFQ
      </Typography>
      <FloatingFormButton title="Add new RFQ" open={rfqAdd} setOpen={setRfqAdd}>
        <AddRfqForm
          onClose={() => setRfqAdd(false)}
          onNew={() => {
            //load data again
            loadFiltered();
            setRfqAdd(false);
          }}
        />
      </FloatingFormButton>

      <UniversalTable
        headers={headers}
        loading={loading}
        lazyloading={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
        setLoading={loadFiltered}
        data={data}
        name={"Rfqs"}
      />

      <Outlet context={{ loadFiltered, origin: "/rfqs" }} />
    </>
  );
}
