import React from "react";
import {
  Box,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  SvgIcon,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";

import ComputerIcon from "@mui/icons-material/Computer";
import TabletIcon from "@mui/icons-material/Tablet";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import TokenInput from "../common/TokenInput";
import { Add } from "@mui/icons-material";
import { verifyDevice } from "../../CustomAxios/Protected/AccountManagment";
import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import getDeviceFingerprint from "../common/FingerPrint";

export default function NewDevice({ open, onComplete }) {
  const urlDets = new URLSearchParams(useLocation().search);
  const key = urlDets.get("k");
  const email = urlDets.get("email");

  const [deviceName, setDeviceName] = React.useState("");
  const [token, setToken] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const onSubmit = () => {
    setLoading(true);
    const data = {
      email: email,
      token: token,
      key: key,
      deviceName: deviceName,
      deviceUID: getDeviceFingerprint(),
    };
    verifyDevice(data)
      .then((res) => {
        if (res.status === "success") {
          console.log(res);
          toast.success(res.message);
          onComplete();
        } else {
          toast.error(res.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Register New Device</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.palette.primary.main,
            borderRadius: "100%",
            aspectRatio: "1",
            width: "100%",
            maxWidth: "150px",
            mx: "auto",
          }}
        >
          <SvgIcon
            component={
              isMobile ? PhoneIphoneIcon : isTablet ? TabletIcon : ComputerIcon
            }
            sx={{ fontSize: "5rem" }}
          />
        </Box>
        <Divider sx={{ my: 2 }} />

        <>
          <DialogContentText>
            A token was sent to your email please use it to verify the device.
          </DialogContentText>
          <TokenInput
            onTokenChange={(e) => setToken(e)}
            PaperProps={{ mx: "auto" }}
          />
        </>

        <Collapse in={token.length === 6}>
          <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
            <TextField
              label="Device Name (optional)"
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
            />
          </Box>
        </Collapse>
      </DialogContent>
      <Collapse in={token.length === 6}>
        <Paper elevation={0}>
          <DialogActions>
            <LoadingButton
              startIcon={<Add />}
              variant="contained"
              color="success"
              onClick={onSubmit}
              loading={loading}
            >
              Verify Device
            </LoadingButton>
          </DialogActions>
        </Paper>
      </Collapse>
    </Dialog>
  );
}
