import React from "react";

import { Box, Collapse, Grow, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function LazyLoadingIndicator({
  show,
  currentPage,
  totalPages,
}) {
  return (
    <Collapse in={show}>
      <Grow in={show} direction="right">
        <Box
          sx={{ display: "flex", alignItems: "baseline", flexDirection: "row" }}
        >
          <LoadingButton loading={show} disabled />
          <Typography variant="body1" color="text.disabled" gutterBottom>
            Loading page {currentPage}/{totalPages}
          </Typography>
        </Box>
      </Grow>
    </Collapse>
  );
}
