import React from "react";
import { Chip, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Box } from "@mui/system";

export default function ChipSelect({
  value,
  setValue,
  label,
  options,
  chipColour,
}) {
  // Takes an array as input and returns an array using setValue
  const handleChange = (event) => {
    const evalue = event.target.value;
    setValue(
      // On autofill we get a stringified value.
      typeof evalue === "string" ? evalue.split(",") : evalue
    );
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`${label}-chip-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-chip-select-label`}
        value={value}
        label={label}
        onChange={handleChange}
        multiple
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((selectedValue) => (
              <Chip
                key={selectedValue}
                label={selectedValue}
                color={chipColour || "primary"}
              />
            ))}
          </Box>
        )}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
