import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import {
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Collapse,
} from "@mui/material";
import { Box } from "@mui/system";

import TableLoader from "../../../Components/Loading/Table";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import ClearIcon from "@mui/icons-material/Clear";
import { AddCircle, RemoveCircle } from "@mui/icons-material";

import NumberFormat from "../../../Components/common/NumberFormater";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = props.headings;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span
                  style={{
                    border: 0,
                    clip: "rect(0 0 0 0)",
                    height: 1,
                    margin: -1,
                    overflow: "hidden",
                    padding: 0,
                    position: "absolute",
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const SearchArea = props.SearchArea;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        pt: 2,
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {props.TableName}
      </Typography>
      {SearchArea}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {};

function EnhancedTable(props) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const dense = true;

  const rows = props.data;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  if (props.disableBloat) {
    return (
      <Box sx={{ width: "100%", mt: 2 }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headings={props.headers}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <QuoteRow
                        key={index}
                        row={row}
                        index={index}
                        headers={props.headers}
                        addRow={props.addRow}
                        addedToQuote={props.addedQuotes.some(
                          (q) => q.quote_no === row.quote_no
                        )}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Typography variant="h5" color="primary" sx={{ m: 1, pt: 2 }}>
          {props.TableTitle}
        </Typography>
        <EnhancedTableToolbar
          SearchArea={props.children}
          TableName={props.name}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headings={props.headers}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <QuoteRow
                      key={index}
                      row={row}
                      index={index}
                      headers={props.headers}
                      addRow={props.addRow}
                      addedToQuote={props.addedQuotes.some(
                        (q) => q.quote_no === row.quote_no
                      )}
                    />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ overflow: "visible" }}
          />
        </TableContainer>
      </Paper>
    </Box>
  );
}

function QuoteRow(props) {
  const [open, setOpen] = React.useState(false);
  const row = props.row,
    index = props.index;

  const itemsAreaClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <TableRow tabIndex={-1}>
        <TableCell key={`TableCell ${index} settings`}>
          <Button
            variant="contained"
            color="success"
            onClick={() => props.addRow(row)}
            startIcon={<AddCircle />}
            disabled={props.addedToQuote}
          >
            Add
          </Button>
        </TableCell>
        <TableCell key={`TableCell ${index} showItems`}>
          <Tooltip title="Show Items">
            <IconButton color="info" onClick={itemsAreaClick}>
              {open ? <RemoveCircle /> : <AddCircle />}
            </IconButton>
          </Tooltip>
        </TableCell>
        {Object.keys(row).map(function (key, index) {
          if (props.headers.some((e) => e.id === key)) {
            return (
              <TableCell align="left" key={`TableCell ${index} ${key}`}>
                {`${row[key]}`}
              </TableCell>
            );
          } else return null;
        })}
      </TableRow>

      <TableRow>
        <TableCell
          colSpan={props.headers.length + 1}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ px: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Part Number</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Unit Price EXC</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.partNumber}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>
                        {NumberFormat(item.price.toFixed(2))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function ReloadBtn(setLoading) {
  return (
    <Tooltip title={"Reload"}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setLoading(true)}
      >
        <RefreshIcon />
      </Button>
    </Tooltip>
  );
}

function SearchArea(props) {
  const { current, setFinalVal } = props;
  const [searchVal, setSearchVal] = React.useState(current);

  const setDefault = () => {
    setSearchVal("");
    setFinalVal("");
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => setFinalVal(searchVal), 500);
    return () => clearTimeout(timeoutId);
  }, [searchVal, setFinalVal]);

  return (
    <div key="SearchArea">
      <TextField
        label="Search"
        variant="outlined"
        value={searchVal}
        color="secondary"
        onChange={(e) => setSearchVal(e.target.value)}
        InputProps={{
          endAdornment: (
            <Tooltip title={"Reset Search"}>
              <IconButton
                aria-label="Reset"
                color="secondary"
                onClick={() => setDefault()}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
    </div>
  );
}

export default function Main({
  quoteList,
  loading,
  setLoading,
  addRow,
  addedQuotes,
  disableBloat,
}) {
  const head = [
    {
      id: "addToOrder",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    { id: "showItems", numeric: false, disablePadding: true, label: "" },
    {
      id: "quote_no",
      numeric: false,
      disablePadding: true,
      label: "Quote Number",
    },
    {
      id: "label",
      numeric: false,
      disablePadding: false,
      label: "Company",
    },
    {
      id: "date_created",
      numeric: false,
      disablePadding: false,
      label: "Date Created",
    },
    {
      id: "date_quoted",
      numeric: false,
      disablePadding: false,
      label: "Date Quoted",
    },
    {
      id: "total",
      numeric: false,
      disablePadding: false,
      label: "Total",
    },
    {
      id: "settings",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  const [rows, setRows] = React.useState(quoteList);
  const [searchDets, setSearchDets] = React.useState("");

  React.useEffect(() => {
    if (searchDets) {
      const term = searchDets.toLocaleLowerCase();
      const filtered = quoteList.filter(
        (val) =>
          val.quote_no.toLocaleLowerCase().includes(term) ||
          val.company_name.toLocaleLowerCase().includes(term) ||
          val.date_created.toLocaleLowerCase().includes(term) ||
          val.date_quoted.toLocaleLowerCase().includes(term) ||
          val.total.toLocaleLowerCase().includes(term)
      );
      setRows(filtered);
    } else setRows(quoteList);
  }, [searchDets, quoteList]);

  if (disableBloat) {
    return (
      <>
        {loading ? (
          <TableLoader />
        ) : (
          <EnhancedTable
            headers={head}
            data={rows}
            addRow={addRow}
            addedQuotes={addedQuotes}
            disableBloat
          />
        )}
      </>
    );
  }

  return (
    <>
      {loading ? (
        <TableLoader />
      ) : (
        <EnhancedTable
          headers={head}
          data={rows}
          name={ReloadBtn(setLoading)}
          TableTitle={"Recent Quotes"}
          addRow={addRow}
          addedQuotes={addedQuotes}
        >
          <SearchArea current={searchDets} setFinalVal={setSearchDets} />
        </EnhancedTable>
      )}
    </>
  );
}
