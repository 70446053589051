import React from "react";

import { Grid, Paper, Typography } from "@mui/material";

import UniversalTable from "../../../Components/common/UniversalTable";

export default function ReferencePoTable({ data, setLoading, loading }) {
  const items = [
    {
      id: "order",
      label: "Order ID",
    },
    {
      id: "partnumber",
      label: "Part Number",
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "quantity",
      label: "Quantity",
    },
    {
      id: "cost",
      label: "Cost",
    },
  ];

  const headers = [
    {
      id: "items",
      subRow: true,
      label: "",
      iconColor: "success",
      headers: items,
      subTitle: "Items",
    },
    {
      id: "id",
      label: "PO Number",
      searchable: true,
    },
    {
      id: "supplier",
      label: "Supplier",
      searchable: true,
    },
    {
      id: "date",
      label: "Date Created",
      searchable: true,
    },
    {
      id: "amount",
      label: "Amount",
      searchable: true,
    },
    {
      id: "status",
      label: "Status",
      searchable: true,
    },
  ];

  return (
    <Grid container justifyContent={"center"}>
      {data.length === 0 ? (
        <Paper elevation={0} sx={{ width: "100%", p: 1 }}>
          <Typography textAlign={"center"} sx={{ fontWeight: "bold" }}>
            No References found
          </Typography>
        </Paper>
      ) : (
        <UniversalTable
          loading={loading}
          setLoading={() => setLoading(true)}
          data={data}
          headers={headers}
          name={"Reference POS"}
        />
      )}
    </Grid>
  );
}
