import React, { useState, useRef } from "react";
import { TextField, Box, Tooltip, Fab, Zoom } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const TokenInput = ({ charCount, onTokenChange, PaperProps, CustomLabel }) => {
  const length = charCount || 6;
  const [code, setCode] = useState([...Array(length)].map(() => "")),
    [empty, setEmpty] = useState(true);
  const inputs = useRef([]);

  const label = CustomLabel?.split("") || ["T", "O", "K", "E", "N"];

  React.useEffect(() => {
    const fullText = code.join("");
    onTokenChange(fullText);
    if (fullText) setEmpty(false);
    else setEmpty(true);
  }, [code, onTokenChange]);

  const onKeyUp = (e, slot) => {
    if (e.keyCode !== 8 || e.keyCode !== 46) {
      if (slot !== length - 1 && code[slot]) {
        inputs.current[slot + 1].focus();
      }
    }
  };

  const onKeyDown = (e, slot) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (slot !== 0 && !code[slot]) inputs.current[slot - 1].focus();
    }
  };

  const updateCode = (e, position) => {
    const newCode = [...code];
    newCode[position] = e.toUpperCase();
    setCode(newCode);
  };

  const codePasted = (e) => {
    if (e.length === length) {
      setCode(e.toUpperCase().split(""));
      inputs.current[5].focus();
    }
  };

  return (
    <Box sx={{ width: "fit-content", mt: 1 }} {...PaperProps}>
      <Box>
        {code.map((num, idx) => {
          return (
            <TextField
              key={idx}
              label={label[idx]}
              inputProps={{
                maxLength: "1",
              }}
              sx={{
                width: { xs: 43, md: 43 },
                m: 0.5,
                textAlign: "center",
              }}
              value={num}
              onChange={(e) => updateCode(e.target.value, idx)}
              onKeyUp={(e) => onKeyUp(e, idx)}
              onKeyDown={(e) => onKeyDown(e, idx)}
              inputRef={(el) => (inputs.current[idx] = el)}
              autoFocus={idx === 0}
              onPaste={(e) => codePasted(e.clipboardData.getData("text/plain"))}
            />
          );
        })}
        <Zoom in={!empty} timeout={300}>
          <Tooltip title="Clear Token">
            <Fab
              color="primary"
              onClick={() => setCode([...Array(length)].map(() => ""))}
              sx={{
                height: 25,
                width: 25,
                minWidth: 25,
                minHeight: 25,
                position: "absolute",
                marginLeft: "-16px",
                marginTop: "-8px",
              }}
            >
              <ClearIcon sx={{ fontSize: "1rem" }} />
            </Fab>
          </Tooltip>
        </Zoom>
      </Box>
    </Box>
  );
};

export default TokenInput;
