import React from "react";
import { LoadingButton } from "@mui/lab";
import { Button, TextField, Stack, Grid, Paper } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import ItemArea from "../common/ItemArea";
import NumberFormater from "../../../Components/common/NumberFormater";
import calcUnitPrice from "../common/calcUnitPrice";

export default function AddManualDetails({ onClose, addOrder, currency }) {
  const [note, setNote] = React.useState(""),
    [amount, setAmount] = React.useState(0),
    [client, setClient] = React.useState(""),
    [items, setItems] = React.useState([]),
    [disabled, setDisabled] = React.useState(true);
  const [itemIsEditing, setItemIsEditing] = React.useState(false);

  React.useEffect(() => {
    const validateItems = () => {
      let valid = true;
      items.forEach((item) => {
        valid =
          valid &&
          item.description !== "" &&
          item.quantity !== "" &&
          item.cost !== "";
      });
      return valid;
    };
    if (amount !== "" && client && items.length !== 0 && validateItems()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [client, amount, items]);

  const calculateAmount = () => {
    let amount = 0;
    items.forEach((item) => {
      amount += Number(item.cost) * Number(item.quantity);
    });

    return amount;
  };

  const handleSubmit = () => {
    const date = new Date();
    const hexString = date.getTime().toString(16).toUpperCase();
    const day = date.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const month = (date.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const year = date.getFullYear();
    const orderID =
      "MANUAL" +
      "OR" +
      day +
      month +
      year +
      hexString.slice(hexString.length - 4, hexString.length);

    const data = {
      orderID,
      company_name: client,
      amount: calculateAmount(),
      total: `${currency} ${NumberFormater(calculateAmount().toFixed(2))}`,
      note,
      currency,
      budgetSpent: 0,
      date_created: String(date),
      isManual: true,
      items: items.map((item, index) => ({
        id: Date.now() + index,
        itemID: index,
        partNumber: item.po,
        description: item.description,
        price: Number(calcUnitPrice(item.cost, item.margin_type, item.margin)),
        quantity: item.quantity,
        aquired: 0,
        pending: [],
        poItems: [],
        isPending: false,
      })),
    };

    addOrder(data);
    handleCancel();
  };

  const handleCancel = () => {
    setClient("");
    setNote("");
    setAmount(0);
    setItems([]);
    onClose();
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2} sx={{ mt: 2 }}>
        <TextField
          label="Client"
          value={client}
          fullWidth
          onChange={(e) => setClient(e.target.value)}
          maxRows={5}
          multiline
          required
        />
        <TextField
          label="Note"
          value={note}
          fullWidth
          onChange={(e) => setNote(e.target.value)}
          maxRows={5}
          multiline
        />
        <ItemArea
          items={items}
          setItems={setItems}
          setEditingItem={setItemIsEditing}
          itemIsEditing={itemIsEditing}
        />
      </Stack>

      <Grid container justifyContent={"flex-end"} sx={{ gap: "10px" }}>
        <LoadingButton
          startIcon={<AddIcon />}
          variant="contained"
          onClick={handleSubmit}
          disabled={disabled}
        >
          Add Order
        </LoadingButton>
        <Button onClick={handleCancel} color="warning" variant="outlined">
          Cancel
        </Button>
      </Grid>
    </Paper>
  );
}
