import React from "react";
import { LoadingButton } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";

import { editOrderStatus, getAdminOrderByID } from "../../API/Cheques&Pos";
import { useLocation, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

import {
  Stack,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Divider,
  TextField,
  DialogContent,
  Paper,
  ListItem,
  Tooltip,
  IconButton,
  ListItemText,
  Collapse,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  Grow,
  Button,
  Skeleton,
  DialogTitle,
  Alert,
  AlertTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Cancel, DoneAll, Info } from "@mui/icons-material";
import Switch from "@mui/material/Switch";

import { TransitionGroup } from "react-transition-group";

import { toast } from "react-toastify";
import AreYouSure from "../../../Components/common/AreYouSure";

export default function EditForm() {
  const [status, setStatus] = React.useState("submited");
  const [note, setNote] = React.useState("");
  const [history, setHistory] = React.useState([]);
  const [data, setData] = React.useState(null);
  const [checked, setChecked] = React.useState(false);

  const [valid, setValid] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const [edited, setEdited] = React.useState(false);
  const [changed, setChanged] = React.useState(false);
  const [fetching, setFetching] = React.useState(true);
  const [editing, setEditing] = React.useState(false);

  const urlParameters = new URLSearchParams(useLocation().search);

  const matches = useMediaQuery("(min-width:600px)");

  const orderID = urlParameters.get("i");

  let browseHistory = useNavigate();
  const { loadFiltered, origin } = useOutletContext();

  const goBack = () => {
    browseHistory(origin);
    if (edited) {
      loadFiltered();
    }
  };

  const [autoComplete, setAutoComplete] = React.useState(false);

  React.useEffect(() => {
    const controller = new AbortController();
    if (fetching || editing) {
      getAdminOrderByID(orderID, controller.signal)
        .then((res) => {
          setStatus(res?.status);
          setHistory(res?.history?.map((item) => ({ ...item })));
          setData(res);
          setFetching(false);
          setAutoComplete(
            !res?.completed &&
              res?.history[res.history.length - 1].status === "delivered"
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setEditing(false);
        });
    }
    return () => controller.abort();
  }, [fetching, editing, orderID]);

  //check if valid amount or if there are any changes
  React.useEffect(() => {
    const historyChanges = () => {
      let changed = false;
      if (history.length !== data?.history.length) {
        return true;
      }
      history.forEach((status, index) => {
        changed = changed || status.share !== data?.history[index]?.share;
      });

      return changed;
    };

    const changed = status !== data?.status || note !== "" || historyChanges();

    setChanged(changed);

    if (changed) {
      setValid(true);
      return;
    }
    setValid(false);
  }, [data, history, status, note]);

  const onStatusChange = () => {
    setLoading(true);
    editOrderStatus({
      share: checked,
      status,
      statusDetail: note,
      id: orderID,
      history,
    })
      .then((res) => {
        setLoading(false);
        setEdited(true);
        setChanged(false);
        setEditing(true);
        setNote("");
        setChecked(false);

        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(
          "There was an issue updating the order, if this persists please contact support"
        );
      });
  };

  const handleAutoComplete = () => {
    setLoading(true);
    editOrderStatus({
      share: checked,
      status: "completed",
      statusDetail: "Order Completed",
      id: orderID,
      history,
    })
      .then((res) => {
        setLoading(false);
        setEdited(true);
        setChanged(false);
        setEditing(true);
        setNote("");
        setChecked(false);
        setAutoComplete(false);
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(
          "There was an issue updating the order, if this persists please contact support"
        );
      });
  };

  const handleStatus = (event, newStatusn) => {
    if (newStatusn !== null) setStatus(newStatusn);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [closePopUp, setClosePopUp] = React.useState(false);

  const [infoOpen, setInfoOpen] = React.useState(false);

  return (
    <>
      <AreYouSure
        open={closePopUp}
        onDecline={() => setClosePopUp(false)}
        onAccept={() => {
          goBack();
        }}
        text={"Any changes made will be lost"}
      />

      <Dialog
        open={true}
        onClose={() => setClosePopUp(true)}
        PaperProps={{ elevation: 0 }}
        maxWidth={"lg"}
      >
        <DialogTitle>
          {orderID}
          <Tooltip title="Order Info">
            <IconButton onClick={() => setInfoOpen(!infoOpen)}>
              <Info />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Collapse in={infoOpen}>
            <Paper sx={{ p: 2, m: 1, ml: 0 }} variant="outlined">
              <Typography>{data?.company_name}</Typography>
              <Typography>
                {new Date(data?.date_created).toLocaleDateString("en-GB")}{" "}
                {new Date(data?.date_created).toLocaleTimeString()}
              </Typography>
              <Typography>{data?.salesPerson}</Typography>
            </Paper>
          </Collapse>
          <Grid container sx={{ minWidth: "50vw", gap: "5px" }}>
            <Grid item lg={5.7} sm={12}>
              {fetching ? (
                <Skeleton animation="wave" variant="rectangular" height={439} />
              ) : (
                <Paper
                  variant="outlined"
                  sx={{
                    p: 1,
                    ...(!valid &&
                      status === history[history.length - 1].status && {
                        filter: "brightness(0.7)",
                      }),
                  }}
                >
                  <Stack spacing={3}>
                    <Stack direction={"column"} spacing={0.5}>
                      <ToggleButtonGroup
                        value={status}
                        exclusive
                        onChange={handleStatus}
                        orientation={`${matches ? `horizontal` : `vertical`}`}
                      >
                        <ToggleButton value="issue" color={"primary"}>
                          Issue
                        </ToggleButton>
                        <ToggleButton value="info" color={"primary"}>
                          Info
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <ToggleButtonGroup
                        value={status}
                        exclusive
                        onChange={handleStatus}
                        orientation={`${matches ? `horizontal` : `vertical`}`}
                      >
                        <ToggleButton
                          value="out for delivery"
                          color={"primary"}
                        >
                          Out for Delivery
                        </ToggleButton>
                        <ToggleButton
                          value="partially delivery"
                          color={"primary"}
                        >
                          partially delivery
                        </ToggleButton>
                        <ToggleButton value="delivered" color={"primary"}>
                          delivered
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <ToggleButtonGroup
                        value={status}
                        exclusive
                        onChange={handleStatus}
                        orientation={`${matches ? `horizontal` : `vertical`}`}
                      >
                        <ToggleButton value="cancelled" color={"primary"}>
                          Cancelled
                        </ToggleButton>
                        <ToggleButton value="completed" color={"primary"}>
                          Completed
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                    <TextField
                      label="Status Note"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      multiline
                      rows={5}
                    />

                    <FormControlLabel
                      control={<Checkbox onChange={handleChange} />}
                      label="Share status with Client?"
                    />
                  </Stack>
                </Paper>
              )}
            </Grid>
            <Grid item lg={6} sm={12}>
              <Grid item>
                {fetching ? (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={439}
                  />
                ) : (
                  <Paper variant="outlined" sx={{ p: 1 }}>
                    <Stack spacing={3}>
                      <Typography variant="h5" color="primary">
                        Status History
                      </Typography>
                      <TransitionGroup>
                        {history.map((status, index) => (
                          <Collapse key={status.id}>
                            <StatusHistory
                              data={status}
                              setHistory={setHistory}
                            />
                            {index !== history.length - 1 && <Divider />}
                          </Collapse>
                        ))}
                      </TransitionGroup>
                      <Collapse in={autoComplete}>
                        <Alert severity="info">
                          <AlertTitle>Order Completed</AlertTitle>
                          This order can be marked as completed
                          <LoadingButton
                            variant="contained"
                            loading={loading || editing}
                            onClick={handleAutoComplete}
                            color="inherit"
                            startIcon={<DoneAll />}
                            sx={{ mt: 1, float: "right" }}
                          >
                            Mark as Completed
                          </LoadingButton>
                        </Alert>
                      </Collapse>
                    </Stack>
                  </Paper>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <Paper
          sx={{
            p: 1,
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1301,
          }}
        >
          <DialogActions>
            <Grow in={valid} direction="up">
              <LoadingButton
                variant="contained"
                loading={loading || editing}
                onClick={onStatusChange}
              >
                Edit Order status
              </LoadingButton>
            </Grow>
            <Button
              variant="outlined"
              color="warning"
              onClick={() => {
                if (changed || note !== "" || checked === true) {
                  setClosePopUp(true);
                  return;
                }
                goBack();
              }}
              startIcon={<Cancel />}
            >
              Close
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
}

function StatusHistory({ data, setHistory }) {
  const handleShare = (event) => {
    setHistory((previous) => {
      const status = previous.find((status) => status.id === data.id);
      status.share = event.target.checked;
      return [...previous];
    });
  };
  const handleDelete = () => {
    setHistory((previous) => {
      previous = previous.filter((status) => status.id !== data.id);
      return [...previous];
    });
  };

  const localisedDate = new Date(data?.date).toLocaleString("en-GB", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true, // Add this line to display time in 12-hour format
  });

  return (
    <ListItem
      secondaryAction={
        <Tooltip title="Delete Record">
          <span>
            <IconButton edge="end" aria-label="add" onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      }
    >
      <ListItemText
        primary={
          <Grid>
            <Typography color="secondary" variant="h6">
              {data.status.toUpperCase()}
            </Typography>
            <FormControlLabel
              control={<Switch checked={data.share} onChange={handleShare} />}
              label="Shared with Client"
            />
            <Typography variant="body1"> {data.note}</Typography>
          </Grid>
        }
        secondary={`Updated by ${data.user} on ${localisedDate}`}
      />
    </ListItem>
  );
}
