import React from "react";
import {
  Paper,
  CardActionArea,
  Stack,
  Typography,
  SvgIcon,
  Collapse,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Close, ExpandMore, Fullscreen } from "@mui/icons-material";

export default function DropDownArea({
  name,
  children,
  open,
  setOpen,
  fullScreen,
  setFullScreen,
}) {
  const ChildrenDisplay = () => {
    if (fullScreen) {
      return (
        <Dialog
          fullScreen
          open={fullScreen}
          onClose={() => setFullScreen(false)}
        >
          <DialogTitle>{name}</DialogTitle>
          <IconButton
            onClick={() => setFullScreen(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <Close />
          </IconButton>
          <DialogContent
            dividers
            sx={{
              bgcolor: "background.paper",
              pb: 0,
            }}
          >
            {children}
          </DialogContent>
        </Dialog>
      );
    }
    return <Collapse in={open}>{children}</Collapse>;
  };

  return (
    <Paper variant="outlined" sx={{ my: 2 }}>
      <Paper
        elevation={1}
        sx={{
          borderRadius: 0,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <CardActionArea onClick={() => setOpen(!open)} sx={{ p: 1 }}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography variant="h6" color="primary">
                {name}
              </Typography>

              <SvgIcon
                sx={{
                  transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease-in-out",
                }}
              >
                <ExpandMore />
              </SvgIcon>
            </Stack>
          </CardActionArea>
          <Collapse in={open} orientation="horizontal">
            <IconButton onClick={() => setFullScreen(true)}>
              <Fullscreen />
            </IconButton>
          </Collapse>
        </Stack>
      </Paper>
      <ChildrenDisplay />
    </Paper>
  );
}
