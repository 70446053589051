import { Stack, Button, Icon, Collapse, Box } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";

import StorageIcon from "@mui/icons-material/Storage";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";

import { Test, purgeDB, Check } from "../../CustomAxios/Main";
import { Restore } from "@mui/icons-material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <Icon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Main() {
  const [expanded, setExpanded] = React.useState(false),
    [debug, setDebug] = React.useState(false),
    [loading, setLoading] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    setDebug(Check());
  }, []);

  const PurgeAndCreate = () => {
    setLoading(true);
    purgeDB().then(() => {
      Test().then(() => setLoading(false));
    });
  };

  return (
    <>
      <Box sx={{ margin: "auto", maxWidth: "fit-content" }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleExpandClick}
          startIcon={<StorageIcon />}
          endIcon={
            <ExpandMore
              expand={expanded}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
        >
          Database Management
        </Button>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Stack
            spacing={2}
            direction={{ xs: "column", md: "row" }}
            sx={{ mt: 2 }}
          >
            {debug && (
              <LoadingButton
                variant="contained"
                color="warning"
                loading={loading}
                onClick={PurgeAndCreate}
                startIcon={<Restore />}
              >
                Reset Database
              </LoadingButton>
            )}
            <Stack spacing={2} direction="column">
              <LoadingButton
                variant="contained"
                color="primary"
                loading={loading}
                onClick={() => {
                  setLoading(true);
                  Test().then(() => setLoading(false));
                }}
                startIcon={<CreateNewFolderIcon />}
              >
                Create Database
              </LoadingButton>
              {debug && (
                <LoadingButton
                  variant="outlined"
                  color="error"
                  loading={loading}
                  onClick={() => {
                    setLoading(true);
                    purgeDB().then(() => setLoading(false));
                  }}
                  startIcon={<DeleteSweepIcon />}
                >
                  Purge Database
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        </Collapse>
      </Box>
    </>
  );
}
