import React from "react";

import {
  Stack,
  Collapse,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";

import { checkConfirmations } from "../../API/Cheques&Pos";

export default function CheckRepeatConfirmation({
  confirmations,
  confirmationType,
  client,
  loading,
  isLoading,
  isError,
  isSafe,
}) {
  const [warning, setWarning] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const [company, setCompany] = React.useState("");

  React.useEffect(() => {
    let check;
    const controller = new AbortController();
    const checkRepeat = () => {
      setWarning(false);
      isLoading(true);
      checkConfirmations(
        {
          client,
          confirmations,
          confirmationType,
        },
        controller.signal
      )
        .then((res) => {
          setErrorText(res.confirmations);
          setWarning(res.confirmations !== "");
          isError && isError(res.confirmations !== "");
          isSafe && isSafe(res.confirmations === "");
          setCompany(res.company);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          isLoading(false);
        });
    };
    if (confirmations.length === 0 || !client) {
      setWarning(false);
      return;
    }

    check = setTimeout(checkRepeat, 400);

    return () => {
      clearTimeout(check);
      controller.abort();
    };
  }, [confirmationType, confirmations, client, isLoading, isError, isSafe]);
  return (
    <>
      <Collapse in={loading}>
        <Stack justifyContent={"center"} alignItems={"center"} sx={{ my: 2 }}>
          <CircularProgress />
        </Stack>
      </Collapse>
      <Collapse in={warning}>
        <Alert severity="warning">
          <AlertTitle>{confirmationType} already exists</AlertTitle>
          {`The following confirmations have already been used for ${company.toUpperCase()}: ${errorText}`}
        </Alert>
      </Collapse>
    </>
  );
}
