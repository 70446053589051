import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
  Typography,
  Skeleton,
  Paper,
  Chip,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Collapse,
  Grid,
  IconButton,
  TextField,
  Divider,
  Alert,
  SvgIcon,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import {
  changeAccess,
  get_userById,
  get_roles,
  add_role,
  remove_role,
  changeAdminStatus,
  delete_user,
} from "../../CustomAxios/Protected/AdminManagment";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import SendNotification from "./SendNotification";
import { Box } from "@mui/system";
import {
  ChangeUserNames,
  ChangeUserPhoneNumber,
  ChangeUserTitle,
} from "./ChangeUserDetails";
import ChipSelect from "../common/ChipSelect";

export default function VerifyForm() {
  const urlDets = new URLSearchParams(useLocation().search);
  const userId = urlDets.get("i");
  const [userDets, setUserDets] = React.useState({});
  const [roles, setRoles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [btnload, setBtnLoad] = React.useState(false);
  const [changed, setChanged] = React.useState(false);
  const [mainLink, setMainLink] = React.useState("");

  const [basic, setBasic] = React.useState(false),
    [rolearea, setRoleArea] = React.useState(false),
    [dangerarea, setDangerArea] = React.useState(false);

  let history = useNavigate();

  const changeUserAccess = () => {
    setBtnLoad(true);
    changeAccess(userId).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        setChanged(true);
        setLoading(true);
      } else toast.warn(res.message);
      setBtnLoad(false);
    });
  };

  React.useEffect(() => {
    if (loading)
      get_userById(userId).then((res) => {
        setUserDets(res);
        get_roles().then((res) => {
          setRoles(res);
          setLoading(false);
        });
      });
  }, [loading, userDets, userId]);

  React.useEffect(() => {
    if (changed) setMainLink("/admin/users?r=true");
    else setMainLink("/admin/users");
  }, [changed]);

  return (
    <Dialog open={true} onClose={() => history(mainLink, { replace: true })}>
      <DialogTitle>Manager User {userDets.email}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Edit the user roles as well as their personal information.
        </DialogContentText>
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <UserInfo
            uid={userId}
            dets={userDets}
            changeA={changeUserAccess}
            btnload={btnload}
            setBtnLoad={setBtnLoad}
            loading={loading}
            setLoading={setLoading}
            roles={roles}
            basic={basic}
            setBasic={setBasic}
            rolearea={rolearea}
            setRoleArea={setRoleArea}
            dangerarea={dangerarea}
            setDangerArea={setDangerArea}
            ChangedTrue={() => setChanged(true)}
            closeMe={() => history("/admin/users?r=true", { replace: true })}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ bgcolor: "background.default" }}>
        <Button component={Link} to={mainLink}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function UserInfo(props) {
  const user = props.dets;
  const btnload = props.btnload;

  const [open, setOpen] = React.useState(false),
    [makeadminOpen, setMakeAdminOpen] = React.useState(false),
    [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleAdminStatus = () => {
    changeAdminStatus(props.uid).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        props.setLoading(true);
        props.ChangedTrue();
      } else toast.warn(res.message);
    });
  };

  const handleDeleteUser = () => {
    delete_user(props.uid).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        props.closeMe();
      } else toast.warn(res.message);
    });
  };

  const DetailsChanged = () => {
    props.setLoading(true);
    props.ChangedTrue();
  };

  return (
    <>
      {user.admin ? (
        <Chip color="primary" icon={<AdminPanelSettingsIcon />} label="Admin" />
      ) : (
        <Chip color="secondary" icon={<PersonIcon />} label="Basic" />
      )}
      <Stack direction="row" spacing={1} sx={{ my: 2 }}>
        {user.active ? (
          <Chip color="success" label="Active" />
        ) : (
          <Chip variant="outlined" color="error" label="Disabled" />
        )}
        {user.verified ? (
          <Chip color="success" label="Verified" />
        ) : (
          <Chip variant="outlined" color="error" label="Unverified" />
        )}
      </Stack>
      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
        <Chip color="info" label={`Created ${user.date_created}`} />
        <Chip color="info" label={`Modified ${user.last_modified}`} />
      </Stack>
      <Chip
        color="info"
        label={`Last Seen ${user.last_login}`}
        sx={{ mt: 2 }}
      />

      <CollapseArea
        title={"Basic Information"}
        open={props.basic}
        setOpen={props.setBasic}
      >
        <AccItem name="Email" description={user.email} noExpand>
          <Typography variant="body1">
            You can not change your email, if it needs to be changed please
            contact an admin
          </Typography>
        </AccItem>
        <AccItem name="Name" description={`${user.first} ${user.last}`}>
          <ChangeUserNames
            firstName={user.first}
            lastName={user.last}
            userId={props.uid}
            Changed={DetailsChanged}
          />
        </AccItem>
        <AccItem name="Phone Number" description={user.phone}>
          <ChangeUserPhoneNumber
            Telephone={user.phone}
            userId={props.uid}
            Changed={DetailsChanged}
          />
        </AccItem>
        <AccItem name="Job Description" description={user.title}>
          <ChangeUserTitle
            Telephone={user.phone}
            userId={props.uid}
            Changed={DetailsChanged}
          />
        </AccItem>
      </CollapseArea>
      <CollapseArea
        title={"Roles"}
        open={props.rolearea}
        setOpen={props.setRoleArea}
      >
        <Box sx={{ m: 2 }}>
          <RoleArea
            uid={props.uid}
            roles={user.roles}
            uroles={props.roles}
            loading={props.loading}
            setLoading={props.setLoading}
          />
        </Box>
      </CollapseArea>
      <CollapseArea
        title={"Danger Zone"}
        open={props.dangerarea}
        setOpen={props.setDangerArea}
        AlertStyle
      >
        <Box sx={{ m: 2 }}>
          <Stack
            direction={{ xs: "column", md: "column" }}
            spacing={2}
            sx={{ my: 2 }}
          >
            <LoadingButton
              variant={user.active ? "contained" : "outlined"}
              color={user.active ? "primary" : "secondary"}
              onClick={props.changeA}
              loading={btnload}
            >
              {user.active ? "Deactivate User" : "Activate User"}
            </LoadingButton>

            <Button
              variant={user.admin ? "contained" : "outlined"}
              color="warning"
              startIcon={<WarningAmberIcon />}
              onClick={() => setMakeAdminOpen(true)}
            >
              {user.admin ? "Make User Basic" : "Make User Admin"}
            </Button>
            <AreYouSure
              message={`Are you sure that you want to make ${user.email} ${
                user.admin
                  ? "a basic user?"
                  : "an admin? With great power comes great responsibility."
              }`}
              email={user.email}
              OnAccept={handleAdminStatus}
              open={makeadminOpen}
              onClose={() => setMakeAdminOpen(false)}
              BtnText={user.admin ? "Make User Basic" : "Make User Admin"}
            />

            <Button
              variant="outlined"
              color="error"
              startIcon={<WarningAmberIcon />}
              onClick={() => setDeleteOpen(true)}
            >
              Delete User
            </Button>
            <AreYouSure
              message={`Deleteing ${user.email} will delete all of their data and cannot be undone. Are you sure you want to delete this user?`}
              email={user.email}
              OnAccept={handleDeleteUser}
              open={deleteOpen}
              onClose={() => setDeleteOpen(false)}
              BtnText="Delete User"
            />
          </Stack>
        </Box>
      </CollapseArea>
      <Stack direction={{ xs: "column", md: "row" }} spacing={2} sx={{ my: 2 }}>
        <Button variant="contained" onClick={() => setOpen(true)}>
          Send Notification
        </Button>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <SendNotification
            userId={props.uid}
            email={user.email}
            handleClose={() => setOpen(false)}
          />
        </Dialog>
      </Stack>
    </>
  );
}

function AreYouSure({ message, email, OnAccept, open, onClose, BtnText }) {
  const [confirmText, setConfirmText] = React.useState("");
  const [dis, setDis] = React.useState(true);

  React.useEffect(() => {
    if (email === confirmText) {
      setDis(false);
    } else {
      setDis(true);
    }
  }, [email, confirmText]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <DialogContentText sx={{ my: 1 }}>
          Please type the following to confirm:{" "}
          <Typography
            variant="boby1"
            backgroundColor="background.paper"
            sx={{ p: 1, m: 1 }}
          >
            {email}
          </Typography>
        </DialogContentText>
        <TextField
          variant="outlined"
          color="secondary"
          label="Users email"
          value={confirmText}
          onChange={(e) => setConfirmText(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" autoFocus>
          Cancel
        </Button>
        <Button
          onClick={OnAccept}
          color="primary"
          variant="contained"
          disabled={dis}
        >
          {BtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RoleArea(props) {
  const setLoading = props.setLoading;
  const uroles = props.uroles;
  const [selectedRoles, setSelectedRoles] = React.useState([]),
    [nonAssignedRoles, setNonAssignedRoles] = React.useState([]);

  const addRoleToUser = () => {
    add_role({
      userId: props.uid,
      roleNames: selectedRoles,
    }).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        setSelectedRoles([]);
        setLoading(true);
      } else toast.warn(res.message);
    });
  };

  const handleDelete = (rolename) => {
    remove_role({
      userId: props.uid,
      roleName: rolename,
    }).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        setLoading(true);
      } else toast.warn(res.message);
    });
  };

  React.useEffect(() => {
    const availableRoles = uroles.filter((role) => {
        return !props.roles.includes(role.name);
      }),
      newRoleList = [];

    availableRoles.forEach((role) => {
      newRoleList.push(role.name);
    });

    setNonAssignedRoles(newRoleList);
  }, [uroles, props.roles]);

  return (
    <>
      {props.roles?.length > 0 ? (
        props.roles.map((role) => (
          <Chip
            label={role}
            key={role}
            onDelete={() => handleDelete(role)}
            sx={{ mr: 1, mb: 1 }}
            color={"secondary"}
            variant="outlined"
          />
        ))
      ) : (
        <Button disabled>No roles</Button>
      )}
      <Divider sx={{ my: 2 }} />
      <ChipSelect
        label="Select Role"
        value={selectedRoles}
        setValue={setSelectedRoles}
        options={nonAssignedRoles}
      />
      <Stack
        spacing={1}
        direction="row"
        justifyContent="flex-end"
        sx={{ my: 1 }}
      >
        <Button
          variant="contained"
          onClick={() => addRoleToUser()}
          disabled={selectedRoles.length > 0 ? false : true}
        >
          Add Role
          {selectedRoles.length > 0 ? `s (${selectedRoles.length})` : ""}
        </Button>
        {selectedRoles.length > 0 && (
          <Button
            variant="outlined"
            color="warning"
            onClick={() => setSelectedRoles([])}
          >
            reset
          </Button>
        )}
      </Stack>
    </>
  );
}

function LoadingSkeleton() {
  return (
    <>
      <Skeleton variant="text" height={50} width={60} />
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Skeleton variant="text" height={50} width={60} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" height={50} width={60} />
        </Grid>
      </Grid>
      <Skeleton variant="text" height={50} width={120} />

      <Skeleton variant="text" height={100} />
      <Skeleton variant="text" height={100} />
      <Skeleton variant="text" height={100} />
    </>
  );
}

function AccItem(props) {
  const [expanded, setExpanded] = React.useState(false);
  if (props.noExpand)
    return (
      <Accordion disableGutters={true}>
        <AccordionSummary style={{ cursor: "default" }}>
          <Typography sx={{ width: "40%", flexShrink: 0 }} color="secondary">
            {props.name}
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            {props.description}
          </Typography>
        </AccordionSummary>
      </Accordion>
    );
  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      disableGutters={true}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: "40%", flexShrink: 0 }} color="secondary">
          {props.name}
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          {props.description}
        </Typography>
      </AccordionSummary>
      <Paper elevation={0}>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Paper>
    </Accordion>
  );
}

function CollapseArea(props) {
  const open = props.open;
  const setOpen = props.setOpen;
  const children = props.children;

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  if (props.AlertStyle) {
    return (
      <Paper
        variant="outlined"
        sx={{ mt: 2, maxWidth: 400, cursor: "pointer" }}
      >
        <Alert
          severity="warning"
          variant="standard"
          p={1}
          onClick={() => setOpen(!open)}
          icon={false}
          action={
            <ExpandMore expand={open} sx={{ float: "right" }}>
              <ExpandMoreIcon />
            </ExpandMore>
          }
        >
          <Typography
            variant="h6"
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <SvgIcon sx={{ pb: 0, mr: 1 }}>
              <WarningAmberIcon />
            </SvgIcon>
            {props.title}
          </Typography>
        </Alert>
        <Collapse in={open}>{children}</Collapse>
      </Paper>
    );
  }
  return (
    <Paper variant="outlined" sx={{ mt: 2, maxWidth: 400, cursor: "pointer" }}>
      <Typography
        variant="h6"
        color="secondary"
        m={1}
        p={1}
        onClick={() => setOpen(!open)}
      >
        {props.title}
        <ExpandMore expand={open} sx={{ float: "right" }}>
          <ExpandMoreIcon />
        </ExpandMore>
      </Typography>
      <Collapse in={open}>{children}</Collapse>
    </Paper>
  );
}
