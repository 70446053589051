import React from "react";

import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import HelpIcon from "@mui/icons-material/Help";

// More info can be a string or a component
export default function ToggleSubscribe({
  label,
  options,
  value,
  onChange,
  stylingProps,
  moreInfo,
  loadingData,
}) {
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      setLoading(true);
      onChange(newValue).then(() => setLoading(false));
    }
  };

  const currentColor =
    options.find((option) => option.value === value)?.color || "standard";

  return (
    <Box sx={stylingProps}>
      <Typography>{label}</Typography>
      {loadingData ? (
        <Skeleton variant="rectangular" height={73} />
      ) : (
        <ToggleButtonGroup
          value={value}
          exclusive
          onChange={handleChange}
          color={currentColor}
          disabled={loading}
        >
          {options.map((option) => (
            <ToggleButton key={option.value} value={option.value}>
              <Stack direction="row" spacing={2} alignItems="center">
                {option.icon}
                {option.label}
              </Stack>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}

      {moreInfo && (
        <Tooltip title={moreInfo}>
          <IconButton>
            <HelpIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
