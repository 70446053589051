import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import { Container, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AuthButtons from "../../Auth/AuthButtons";
import { RenderRoutes } from "../../Pages";
import { BaseRoutes, RoleRoutes, FooterRoutes } from "../../Routes/Base";
import { Copyright } from "../../App";
import Logo from "../../logo";
import { Link } from "react-router-dom";

import { SessionManager } from "react-session.manager.sk";
import AdminList from "../../Routes/Admin";

import useMediaQuery from "@mui/material/useMediaQuery";
import config from "../../Config";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  maxBlockSize: "100vh",
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function CustomLogo() {
  return (
    <Box
      component={Link}
      to="/"
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        textDecoration: "none",
      }}
    >
      <Logo variant={"h6"} />
    </Box>
  );
}

export default function Main(props) {
  const { children } = props;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { isAdmin } = React.useContext(SessionManager);
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));
  const [hover, setHover] = React.useState(false);

  const [disableHover, setDisableHover] = React.useState(
    localStorage.getItem("disableHover") === "true"
  );

  window.addEventListener("storage", () => {
    setDisableHover(localStorage.getItem("disableHover") === "true");
  });

  const startHover = () => {
    if (disableHover) return;
    setHover(true);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  if (isMobile)
    // if the device is mobile it will render the mobile version of the appbar and drawer
    return (
      <Box sx={{ display: "flex" }}>
        <MuiAppBar color="inherit" component="nav">
          <AppBarContent open={false} handleDrawerOpen={handleDrawerOpen} />
        </MuiAppBar>
        <MuiDrawer
          variant="temporary"
          open={open}
          onClose={handleDrawerClose}
          elevation={0}
          sx={{ overflowY: "hidden" }}
        >
          <DrawerHeader>
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                color: "text.secondary",
              }}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <DrawerContent
            open={true}
            handleDrawerClose={handleDrawerClose}
            isAdmin={isAdmin}
            isMobile
          />
          <DrawerFooter open={open} isMobile />
        </MuiDrawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }} maxWidth={"100vw"}>
          <DrawerHeader />
          <Container>
            <RenderRoutes />
          </Container>
          {children}
        </Box>
      </Box>
    );
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar color="inherit" position="fixed" open={open}>
        <AppBarContent open={open} handleDrawerOpen={handleDrawerOpen} />
      </AppBar>
      <Drawer
        variant="permanent"
        open={open || hover}
        onMouseEnter={startHover}
        onMouseLeave={() => setHover(false)}
      >
        <DrawerHeader>
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              color: "text.secondary",
            }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <DrawerContent open={open} hover={hover} isAdmin={isAdmin} />
        <DrawerFooter open={open} hover={hover} />
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        maxWidth={
          open ? `calc(100vw - ${drawerWidth}px)` : `calc(100vw - 57px)`
        }
        onMouseEnter={() => setHover(false)}
      >
        <DrawerHeader />
        <Container maxWidth="lg">
          <RenderRoutes />
        </Container>
        {children}
      </Box>
    </Box>
  );
}

function AppBarContent({ open, handleDrawerOpen }) {
  return (
    <Toolbar sx={{ marginRight: 1 }}>
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{
          color: "text.secondary",
          marginRight: "36px",
          ...(open && { display: "none" }),
        }}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" noWrap component="div" color="primary">
        <CustomLogo />
      </Typography>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
      <AuthButtons isLoggedIn={true} />
    </Toolbar>
  );
}

function DrawerContent({ open, hover, isAdmin, isMobile = false }) {
  const topDrawerHeight = isMobile ? "56px" : "64px";
  const bottomDrawerHeight = open || hover ? "126.05px" : "48px";
  return (
    <>
      <Divider />
      <Box
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight:
            open || hover
              ? `calc( 100% - ${topDrawerHeight} - ${bottomDrawerHeight} )`
              : `calc( 100% - ${topDrawerHeight} - ${bottomDrawerHeight} )`,
        }}
      >
        <List>
          <BaseRoutes />
          <RoleRoutes />
        </List>
        <Divider />
        {isAdmin && (
          <>
            <AdminList />
            <Divider />
          </>
        )}
      </Box>
    </>
  );
}

function DrawerFooter({ open, hover, isMobile }) {
  const theme = useTheme();
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (open || hover) setVisible(true);
    else setVisible(false);
  }, [open, hover]);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        borderRight: "1px solid rgba(0, 0, 0, 0)",
        ...(visible && {
          ...openedMixin(theme),
        }),
        ...(!visible && {
          ...closedMixin(theme),
        }),
        ...(isMobile && {
          maxWidth: "201.14px",
        }),
      }}
    >
      <Paper elevation={0}>
        <Divider />
        <FooterRoutes />
        <Divider />
        {visible && (
          <>
            <Copyright wrap />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                float: "right",
                pr: 1,
              }}
            >
              V {config.appVersion}
            </Typography>
          </>
        )}
      </Paper>
    </Box>
  );
}
