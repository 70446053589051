import * as React from "react";
import CreateLink from "../Components/common/CreateLink";
import { AdminRoutes as AR } from "../Pages";

// These are the links that appear in the appdrawer

export default function AdminList() {
  return (
    <>
      {AR.map((route) => {
        if (route.link)
          return CreateLink(
            route.name,
            route.url,
            route.icon,
            false,
            route?.children && route.children
          );
        else return null;
      })}
    </>
  );
}
