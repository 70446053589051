import React from "react";
import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Slide,
  Alert,
  AlertTitle,
  Dialog,
  Button,
  Stack,
  IconButton,
} from "@mui/material";
import { Chip } from "@mui/material";
import {
  CheckCircleOutline,
  ChildFriendly,
  DoneAll,
  Elderly,
  History,
  ListAltOutlined,
  Man,
  MoreVert,
} from "@mui/icons-material";

import UniversalTable from "../../Components/common/UniversalTable";

import FilterArea from "../../Components/common/FilterArea";
import {
  getAdminOrders,
  getAdminOrdersPending,
  getAdminOrderPages,
  getAdminOrderPendingPages,
  getAllCompanies,
  batchEditOrder,
} from "../API/Cheques&Pos";

import { dateRange, convertToBool } from "../Components/common/FilterUtility";

import {
  LoadingProvider,
  LoadingContext,
} from "../Components/common/LoadingProvider";
import NumberFormat from "../../Components/common/NumberFormater";

import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

export default function Main() {
  const [table, setTable] = React.useState("incomplete");

  const handleTableChange = (event, newTable) => {
    if (newTable !== null) setTable(newTable);
  };

  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>
        Logistics
      </Typography>

      <ToggleButtonGroup
        color={"primary"}
        value={table}
        exclusive
        onChange={handleTableChange}
      >
        <ToggleButton value="incomplete">Incomplete orders</ToggleButton>
        <ToggleButton value="all">All Orders</ToggleButton>
      </ToggleButtonGroup>

      <Box sx={{ mb: 2 }} />

      {table === "all" ? <All /> : <Pending />}
    </>
  );
}

function Pending() {
  // advanced search
  const [currency, setCurrency] = React.useState(""),
    [companyName, setCompanyName] = React.useState(""),
    [status, setStatus] = React.useState(""),
    [exempt, setExempt] = React.useState(""),
    [confirmation, setConfirmation] = React.useState(""),
    [date, setDate] = React.useState("");

  const getFilterData = (filter) => {
    const from = new Date(date[1]);
    const to = new Date(date[2]);

    const data = filter
      ? {
          currency,
          companyName,
          confirmation,
          status: convertToBool(status, "Completed", [true, false]),
          exempt: convertToBool(exempt, "Yes", [true, false]),
          from:
            date[0] === "less" || date[0] === ""
              ? null
              : from.toLocaleString("en-GB", { timeZone: "UTC" }),
          to:
            date[0] === "greater" || date[0] === ""
              ? null
              : to.toLocaleString("en-GB", { timeZone: "UTC" }),
          currentPage: 1,
        }
      : {
          currency: null,
          confirmation: null,
          companyName: null,
          status: null,
          exempt: null,
          from: null,
          to: null,
          currentPage: 1,
        };
    return data;
  };
  return (
    <>
      <LoadingProvider
        getFilterData={getFilterData}
        getData={getAdminOrdersPending}
        getPages={getAdminOrderPendingPages}
      >
        <LoadingWrapper
          tableName="Pending Orders"
          setCurrency={setCurrency}
          currency={currency}
          setCompanyName={setCompanyName}
          companyName={companyName}
          setStatus={setStatus}
          status={status}
          setExempt={setExempt}
          exempt={exempt}
          setDate={setDate}
          date={date}
          setConfirmation={setConfirmation}
          confirmation={confirmation}
        />
      </LoadingProvider>
    </>
  );
}

function All() {
  // advanced search
  const [currency, setCurrency] = React.useState(""),
    [companyName, setCompanyName] = React.useState(""),
    [status, setStatus] = React.useState(""),
    [exempt, setExempt] = React.useState(""),
    [confirmation, setConfirmation] = React.useState(""),
    [date, setDate] = React.useState("");

  const getFilterData = (filter) => {
    const from = new Date(date[1]);
    const to = new Date(date[2]);

    const data = filter
      ? {
          currency,
          companyName,
          confirmation,
          status: convertToBool(status, "Completed", [true, false]),
          exempt: convertToBool(exempt, "Yes", [true, false]),
          from:
            date[0] === "less" || date[0] === ""
              ? null
              : from.toLocaleString("en-GB", { timeZone: "UTC" }),
          to:
            date[0] === "greater" || date[0] === ""
              ? null
              : to.toLocaleString("en-GB", { timeZone: "UTC" }),
          currentPage: 1,
        }
      : {
          currency: null,
          confirmation: null,
          companyName: null,
          status: null,
          exempt: null,
          from: null,
          to: null,
          currentPage: 1,
        };
    return data;
  };
  return (
    <>
      <LoadingProvider
        getFilterData={getFilterData}
        getData={getAdminOrders}
        getPages={getAdminOrderPages}
      >
        <LoadingWrapper
          setCurrency={setCurrency}
          currency={currency}
          setCompanyName={setCompanyName}
          companyName={companyName}
          setStatus={setStatus}
          status={status}
          setExempt={setExempt}
          exempt={exempt}
          setDate={setDate}
          date={date}
          setConfirmation={setConfirmation}
          confirmation={confirmation}
        />
      </LoadingProvider>
    </>
  );
}

function LoadingWrapper({
  tableName,
  setCurrency,
  currency,
  setCompanyName,
  companyName,
  setStatus,
  status,
  setExempt,
  exempt,
  setDate,
  date,
  setConfirmation,
  confirmation,
}) {
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loading,
    data,
    gotAll,
    allData,
    setData,
    loadFiltered,
  } = React.useContext(LoadingContext);

  // Table components
  const ChipStatus = ({ value }) => (
    <Tooltip title={value.details}>
      <Chip
        label={value.status.toUpperCase()}
        color={
          value.status === "completed"
            ? "success"
            : value.status === "cancelled"
            ? "warning"
            : "info"
        }
        onClick={(e) => e.stopPropagation()}
        variant="outlined"
        component={Link}
        to={`/logistics/manage_status?i=${value.orderID}`}
      />
    </Tooltip>
  );

  const OrderAge = ({ value }) => {
    const date = new Date(value.date_created);
    const now = new Date();
    const diff = now - date;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (value.completed) return <CheckCircleOutline color="success" />;

    return (
      <Tooltip title={`Order Age: ${days} days`}>
        {days < 2 ? (
          <ChildFriendly color="success" />
        ) : days < 4 ? (
          <Man color="warning" />
        ) : (
          <Elderly color="error" />
        )}
      </Tooltip>
    );
  };

  const MoreButton = ({ value }) => (
    <IconButton
      component={Link}
      to={`/logistics/manage_status?i=${value.orderID}`}
      onClick={(e) => e.stopPropagation()}
    >
      <MoreVert />
    </IconButton>
  );

  //sub table data
  const items = [
    {
      id: "partNumber",
      searchable: true,
      label: "Part Number",
    },
    {
      id: "description",
      searchable: true,
      label: "Description",
    },
    {
      id: "quantity",
      searchable: true,
      label: "Quantity",
    },
    {
      id: "price",
      searchable: true,
      label: "Unit Price EXC",
      component: (value) => NumberFormat(Number(value.price).toFixed(2)),
    },
  ];

  const statusInfo = [
    {
      id: "date",
      searchable: true,
      label: "Date Updated",
      date: true,
    },
    {
      id: "status",
      searchable: true,
      label: "Status",
    },
    {
      id: "note",
      searchable: true,
      label: "Note",
    },
    {
      id: "user",
      searchable: true,
      label: "Update By",
    },
  ];

  //Table headers
  const headers = [
    {
      id: "items",
      subRow: true,
      label: "",
      iconColor: "success",
      headers: items,
      subTitle: "Items",
      openIcon: <ListAltOutlined />,
    },
    {
      id: "history",
      subRow: true,
      label: "",
      iconColor: "info",
      headers: statusInfo,
      subTitle: "Status",
      openIcon: <History />,
    },
    {
      id: "orderID",
      label: "Order Number",
      searchable: true,
    },
    {
      id: "salesPerson",
      label: "Sales Person",
      searchable: true,
    },
    {
      id: "company_name",
      label: "Company",
      searchable: true,
    },
    {
      id: "date_created",
      label: "Date Created",
      searchable: true,
      date: true,
    },
    {
      id: "total",
      label: "Total",
      searchable: true,
    },
    {
      id: "completedStatus",
      label: "Status",
      component: (value) => <ChipStatus value={value} />,
    },
    {
      id: "orderAge",
      label: "",
      component: (value) => <OrderAge value={value} />,
    },
    {
      id: "more",
      label: "",
      component: (values) => <MoreButton value={values} />,
    },
  ];

  const [clear, setClear] = React.useState(false);

  // advanced search functions

  const handleAdvancedSearch = () => {
    const findConfirmation = (item) => {
      return [item.value.toLowerCase(), ""].includes(
        confirmation.toLowerCase()
      );
    };
    if (gotAll) {
      const filtered = allData.filter(
        (data) =>
          data.company_name
            .toLowerCase()
            .includes(companyName.toLocaleLowerCase()) &&
          [data.completed, null].includes(convertToBool(status, "Completed")) &&
          [data.exempt, null].includes(convertToBool(exempt, "Yes")) &&
          data.currency.includes(currency) &&
          data.confirmations.find(findConfirmation) &&
          dateRange(date[1], date[2], data.date_created)
      );
      setData(filtered);
      return;
    }
    loadFiltered();
  };

  const clearAdvancedSearch = () => {
    setCompanyName("");
    setCurrency("");
    setStatus("");
    setExempt("");
    setDate(["", null, null]);
    setConfirmation("");
    setClear(true);
  };

  const [shouldReload, setShouldReload] = React.useState(false);

  const startReload = () => {
    setShouldReload(false);
    loadFiltered();
  };

  const handleChange = () => {
    setShouldReload(true);
  };

  const [selectedItem, setSelectedItem] = React.useState([]);
  const [batchEdit, setBatchEdit] = React.useState(false);
  const [loadingBatchEdit, setLoadingBatchEdit] = React.useState(false);

  const handleSelectedClick = (selected) => {
    setSelectedItem(selected);
    setBatchEdit(true);
  };

  const handelBatchEdit = () => {
    setLoadingBatchEdit(true);
    batchEditOrder(selectedItem)
      .then((data) => {
        setLoadingBatchEdit(false);
        setBatchEdit(false);
        setSelectedItem([]);
        loadFiltered();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <FilterArea
        categories={[
          {
            label: "Company",
            type: "combo",
            value: companyName,
            setValue: setCompanyName,
            getData: getAllCompanies,
            clear,
            setClear,
          },
          {
            label: "Currency",
            type: "select",
            options: ["KES", "USD"],
            value: currency,
            setValue: setCurrency,
          },
          {
            label: "Order Status",
            type: "select",
            options: ["Completed", "Pending"],
            value: status,
            setValue: setStatus,
          },
          {
            label: "Vat Exempt",
            type: "select",
            options: ["Yes", "No"],
            value: exempt,
            setValue: setExempt,
          },
          {
            label: "Confirmations",
            type: "search",
            value: confirmation,
            setValue: setConfirmation,
          },
          {
            label: "Date Created",
            type: "date",
            options: [],
            value: date,
            setValue: setDate,
          },
        ]}
        startFilter={handleAdvancedSearch}
        clearFilter={clearAdvancedSearch}
        isLoading={loading}
      />

      <UniversalTable
        name={tableName}
        headers={headers}
        data={data}
        loading={loading}
        lazyloading={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
        setLoading={loadFiltered}
        selectRows={tableName === "Pending Orders" ? true : false}
        selectID={"orderID"}
        onSelection={handleSelectedClick}
        selectIcon={
          <Tooltip title="Mark as delivered">
            <DoneAll />
          </Tooltip>
        }
      />

      <Slide direction="up" in={shouldReload}>
        <Alert
          severity="info"
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            mr: 10,
            mb: 2,
          }}
        >
          <AlertTitle>Changes Made</AlertTitle>
          Would you like to reload?{" "}
          <LoadingButton onClick={startReload} loading={loading}>
            Reload
          </LoadingButton>
        </Alert>
      </Slide>

      <Dialog open={batchEdit}>
        <Alert severity="info">
          <AlertTitle>Batch Complete</AlertTitle>
          <Typography>
            You have selected {selectedItem.length} items to mark as delivered.
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ mt: 2 }}
          >
            <LoadingButton
              loading={loadingBatchEdit}
              variant="contained"
              color="success"
              onClick={handelBatchEdit}
            >
              Mark as Delivered
            </LoadingButton>
            <Button
              variant="contained"
              color="warning"
              onClick={() => setBatchEdit(false)}
            >
              Cancel
            </Button>
          </Stack>
        </Alert>
      </Dialog>

      <Outlet context={{ loadFiltered: handleChange, origin: "/logistics" }} />
    </>
  );
}
