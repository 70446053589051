import React from "react";
import { Dialog, Skeleton, DialogContent } from "@mui/material";

import StoreForm from "./StoreForm";
import AreYouSure from "../../../Components/common/AreYouSure";

import { toast } from "react-toastify";

import { useNavigate, useLocation } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

import { getStoreItem, editStore } from "../../API/Cheques&Pos";

export default function StoreEdit() {
  const [changed, setChanged] = React.useState(true);
  const [data, setData] = React.useState({});
  const [edited, setEdited] = React.useState(false);
  const [fetching, setFetching] = React.useState(true);

  const { handleChanged, origin } = useOutletContext();
  const history = useNavigate();

  const urlDets = new URLSearchParams(useLocation().search);
  const storeID = urlDets.get("item");

  const goBack = (forceReload = false) => {
    if (edited || forceReload) handleChanged();
    history(origin);
  };

  React.useEffect(() => {
    getStoreItem({ id: storeID })
      .then((res) => {
        setData({
          partNumber: res.partNumber,
          description: res.description,
          quantity: res.quantity,
          cost: res.cost,
          currency: res.currency,
          reserveList: res.reserveList,
        });
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "Could not retrieve data, contact an administrator of the problem persists"
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [openPopUp, setOpenPopUp] = React.useState(false);

  const handleClose = () => {
    if (changed) {
      setOpenPopUp(true);
      return;
    }
    goBack();
  };

  return (
    <>
      <AreYouSure
        open={openPopUp}
        onDecline={() => setOpenPopUp(false)}
        onAccept={() => {
          goBack();
        }}
        text={"Any changes made will be lost"}
      />

      <Dialog
        open={true}
        onClose={handleClose}
        maxWidth={false}
        PaperProps={{ elevation: 0 }}
      >
        {fetching ? (
          <DialogContent>
            <Skeleton sx={{ width: "50vw", height: "50vh", m: 1 }} />
          </DialogContent>
        ) : (
          <StoreForm
            onComplete={(newData, reload = false) => {
              setData(newData);
              setEdited(true);
            }}
            onSubmit={editStore}
            title="edit"
            storeID={storeID}
            data={data}
            setChanged={setChanged}
            handleClose={handleClose}
            edit
            goBack={goBack}
          />
        )}
      </Dialog>
    </>
  );
}
