import React from "react";
import {
  Alert,
  AlertTitle,
  Button,
  IconButton,
  Typography,
  Collapse,
  Badge,
  Tooltip,
  Paper,
} from "@mui/material";
import {
  getNotifications,
  markAllAsRead,
  markAsRead,
  markAsUnRead,
} from "../../CustomAxios/Protected/AccountManagment";
import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RefreshIcon from "@mui/icons-material/Refresh";
import { SessionManager } from "react-session.manager.sk";
import { MarkAsUnread, MarkEmailRead } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

function Notification(props) {
  const data = props.data;

  return (
    <Paper
      elevation={0}
      sx={
        !data.read
          ? {
              borderLeft: "5px solid #f44336",
              borderRadius: "4px",
              mb: 1,
            }
          : { mb: 1 }
      }
    >
      <Alert
        severity={data.type}
        action={
          <>
            {!data.read ? (
              <Tooltip title={"Mark as read"}>
                <IconButton
                  onClick={() => props.markAsRead(data.id, true)}
                  type="text"
                  color="inherit"
                  sx={{ m: "auto" }}
                >
                  <MarkEmailRead />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={"Mark as unread"}>
                <IconButton
                  onClick={() => props.markAsUnRead(data.id)}
                  type="text"
                  color="inherit"
                  sx={{ m: "auto" }}
                >
                  <MarkAsUnread />
                </IconButton>
              </Tooltip>
            )}

            {data.link && (
              <Button
                component={Link}
                to={data.link}
                onClick={() => {
                  if (!data.read) props.markAsRead(data.id);
                }}
                type="text"
                color="inherit"
                sx={{ m: "auto" }}
              >
                Go to
              </Button>
            )}
          </>
        }
      >
        <AlertTitle>{data.title}</AlertTitle>
        <Typography>{data.message}</Typography>
      </Alert>
      <Button disabled>{data.dateCreated}</Button>
    </Paper>
  );
}

function ReloadBtn(props) {
  return (
    <Tooltip title={"Reload"}>
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={() => props.setLoading(true)}
        loading={props.loading}
      >
        <RefreshIcon />
      </LoadingButton>
    </Tooltip>
  );
}

export default function Main(props) {
  const [loading, setLoading] = React.useState(true);
  const [notifs, setNotifs] = React.useState([]);
  const [readNotif, setReadNotif] = React.useState([]);
  const [unreadNotif, setUnreadNotif] = React.useState([]);
  const [empty, setEmpty] = React.useState(false);
  const { setRefreshData } = React.useContext(SessionManager);

  const [open, setOpen] = React.useState(false);
  const [readopen, setReadOpen] = React.useState(false);

  const NotifMArkAsRead = (id, stay) => {
    markAsRead(id).then((res) => {
      setRefreshData(true);
      if (stay) setLoading(true);
    });
  };

  const NotifMarkAsUnRead = (id) => {
    markAsUnRead(id).then((res) => {
      setRefreshData(true);
      setLoading(true);
    });
  };

  const AllClick = () => {
    markAllAsRead().then((res) => {
      setRefreshData(true);
      setLoading(true);
    });
  };

  React.useEffect(() => {
    if (loading) {
      setNotifs([]);
      getNotifications().then((res) => {
        setNotifs(res);
        setLoading(false);
      });
    }
  }, [loading, setRefreshData]);

  React.useEffect(() => {
    const read = notifs.filter((n) => n.read);
    const unread = notifs.filter((n) => !n.read);

    setReadNotif(read);
    setUnreadNotif(unread);

    if (unread.length > 0) {
      setOpen(true);
      setEmpty(true);
    } else {
      setOpen(false);
      setEmpty(false);
    }
  }, [notifs, setReadNotif, setUnreadNotif]);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <>
      <Typography variant="h4" color="primary" sx={{ mb: 2 }}>
        Notifications <ReloadBtn loading={loading} setLoading={setLoading} />
      </Typography>
      <Typography
        onClick={() => setOpen(!open)}
        sx={{ mb: 2, cursor: "pointer" }}
        variant="h5"
      >
        <Badge badgeContent={unreadNotif.length} color="secondary">
          <span>Unread</span>
        </Badge>
        <ExpandMore expand={open} aria-expanded={open} aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore>
      </Typography>

      <Collapse in={open}>
        <Button
          variant="contained"
          color="primary"
          sx={{ mb: 1 }}
          onClick={AllClick}
          startIcon={<MarkEmailRead />}
          disabled={!empty}
        >
          Mark all as read
        </Button>
        {unreadNotif.map((notif) => {
          return (
            <Notification
              data={notif}
              key={`alert_${notif.id}`}
              markAsRead={NotifMArkAsRead}
            />
          );
        })}
      </Collapse>
      {!empty && <Button disabled>You have no unread notifications</Button>}
      <Typography
        variant="h5"
        onClick={() => setReadOpen(!readopen)}
        sx={{ mb: 2, cursor: "pointer" }}
      >
        Read
        <ExpandMore
          expand={readopen}
          aria-expanded={readopen}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Typography>

      <Collapse in={readopen}>
        {readNotif.map((notif) => {
          return (
            <Notification
              data={notif}
              key={`alert_${notif.id}`}
              markAsUnRead={NotifMarkAsUnRead}
            />
          );
        })}
      </Collapse>
    </>
  );
}
