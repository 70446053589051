import React from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import Order from "../common/Order";
import OrderAdmin from "../common/OrderAdmin";
import { createOrder, createOrderAdmin } from "../../API/Cheques&Pos";

export default function Main({ onComplete, isAdmin = false }) {
  const [creatingOrder, setCreatingOrder] = React.useState(false),
    [orderID, setOrderID] = React.useState(null);

  const onSubmit = (data) => {
    setOrderID(null);
    setCreatingOrder(true);
    createOrder(data).then((res) => {
      if (res.status === "error") {
        toast.error(res.message);
        setCreatingOrder(false);
        return;
      }

      setOrderID(res.orderID);
    });
  };

  const onSubmitAdmin = (data) => {
    setOrderID(null);
    setCreatingOrder(true);
    createOrderAdmin(data).then((res) => {
      if (res.status === "error") {
        toast.error(res.message);
        setCreatingOrder(false);
        return;
      }

      setOrderID(res.orderID);
    });
  };

  const CreateDialog = () => {
    if (orderID)
      return (
        <Dialog open={creatingOrder}>
          <DialogTitle>Order Created</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your Order has been created. Your Order ID is {orderID}
            </DialogContentText>
            <DialogContentText sx={{ fontWeight: "bold" }}>
              An email was sent please go to your email to proceed.
            </DialogContentText>
            <DialogActions>
              <Button
                variant="contained"
                onClick={onComplete}
                component={Link}
                to={isAdmin ? `/adminOrderManager?r=true` : `/orders?r=true`}
              >
                Go to order
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      );
    return (
      <Dialog open={creatingOrder}>
        <DialogTitle>Creating Order</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please wait while we create your order.
          </DialogContentText>
          <Typography align="center" sx={{ mt: 2 }}>
            <CircularProgress />
          </Typography>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box p={2}>
      <Typography variant="h4" color="primary" gutterBottom>
        Create new order
      </Typography>
      {isAdmin ? (
        <OrderAdmin onSubmit={onSubmitAdmin} />
      ) : (
        <Order onSubmit={onSubmit} />
      )}
      <CreateDialog />
    </Box>
  );
}
