import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Dialog,
  Divider,
  IconButton,
  Collapse,
  CircularProgress,
  Paper,
} from "@mui/material";
import { toast } from "react-toastify";

import config from "../../../Config";

import PhoneInput from "react-phone-input-2";

import { editSupplier, getSupplierByID } from "../../API/Cheques&Pos";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Close, Edit } from "@mui/icons-material";

export default function EditSupplierForm() {
  const [supplierName, setSupplierName] = React.useState(""),
    [paymentDays, setPaymentDays] = React.useState(0),
    [phone, setPhone] = React.useState(""),
    [address, setAddress] = React.useState(""),
    [kraPin, setKraPin] = React.useState(""),
    [note, setNote] = React.useState(""),
    [disabled, setDisabled] = React.useState(true),
    [edited, setEdited] = React.useState(false),
    [masterData, setMasterData] = React.useState([]),
    [loading, setLoading] = React.useState(true),
    [submitting, setSubmitting] = React.useState(false);

  const urlDets = new URLSearchParams(useLocation().search);
  const supplierID = urlDets.get("id");

  const { loadFiltered, origin } = useOutletContext();
  const history = useNavigate();

  const goBack = React.useCallback(() => {
    if (edited) loadFiltered();
    history(origin);
  }, [edited, loadFiltered, history, origin]);

  React.useEffect(() => {
    if (supplierName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [supplierName]);

  React.useEffect(() => {
    if (loading) {
      getSupplierByID(supplierID)
        .then((res) => {
          setMasterData(res.data);
          setSupplierName(res.data.name);
          setPaymentDays(res.data.paymentDays);
          setPhone(res.data.phone);
          setAddress(res.data.address);
          setKraPin(res.data.kraPin);
          setNote(res.data.note);

          setLoading(false);
        })
        .catch((err) => {
          toast.warning(
            "There was an error fetching supplier details, Please try again if the error persists contact support",
            {
              toastId: "fetchSupplierError",
              autoClose: false,
            }
          );
          goBack();
        });
    }
  }, [loading, supplierID, goBack]);

  const handleSubmit = () => {
    setSubmitting(true);
    const Data = {
      id: supplierID,
      supplierName: supplierName,
      phone: phone,
      address: address,
      paymentDays: paymentDays,
      note: note,
    };

    editSupplier(Data)
      .then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
          setSubmitting(false);
          goBack();
        } else toast.error(res.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleReset = () => {
    setSupplierName(masterData.name);
    setPaymentDays(masterData.paymentDays);
    setPhone(masterData.phone);
    setAddress(masterData.address);
    setKraPin(masterData.kraPin);
    setNote(masterData.note);
  };

  React.useEffect(() => {
    if (
      supplierName !== masterData?.name ||
      paymentDays !== masterData?.paymentDays ||
      phone !== masterData?.phone ||
      address !== masterData?.address ||
      kraPin !== masterData?.kraPin ||
      note !== masterData?.note
    )
      setEdited(true);
    else setEdited(false);
  }, [masterData, supplierName, paymentDays, phone, address, kraPin, note]);

  return (
    <Dialog open={true}>
      <DialogContent>
        <IconButton
          sx={{
            position: "absolute",
            top: 1,
            right: 1,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              color: (theme) => theme.palette.grey[100],
            },
          }}
          onClick={goBack}
        >
          <Close />
        </IconButton>
        <Collapse in={loading}>
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        </Collapse>
        <Collapse in={!loading}>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <TextField
              label="Supplier"
              value={supplierName}
              onChange={(e) => setSupplierName(e.target.value)}
              required
            />
            <PhoneInput
              country={config.phoneNumber.Default}
              value={phone}
              onChange={(e) => setPhone(e)}
            />
            <TextField
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              maxRows={5}
              multiline
            />
            <TextField
              label="Payment days"
              value={paymentDays}
              onChange={(e) => setPaymentDays(e.target.value)}
              type="number"
              onWheel={(e) => e.target.blur()}
            />
            <Divider />
            <TextField
              label="KRA pin"
              value={kraPin}
              onChange={(e) => setKraPin(e.target.value)}
            />
            <TextField
              label="Note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              maxRows={5}
              multiline
            />
          </Stack>
        </Collapse>
      </DialogContent>
      <Paper elevation={0}>
        <DialogActions>
          <LoadingButton
            startIcon={<Edit />}
            variant="contained"
            loading={submitting}
            disabled={disabled}
            onClick={handleSubmit}
          >
            Edit
          </LoadingButton>
          <Button
            onClick={handleReset}
            color="warning"
            variant="outlined"
            disabled={edited ? false : true}
          >
            Reset
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
}
