import React from "react";
import { DialogContent, DialogContentText, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { createRole } from "../../CustomAxios/Protected/AdminManagment";
import { toast } from "react-toastify";

export default function AddRoleForm(props) {
  const [name, setName] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [dis, setDis] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [characterCount, setCharacterCount] = React.useState(0);

  React.useEffect(() => {
    if (name && desc && characterCount <= 100) setDis(false);
    else setDis(true);
    setCharacterCount(desc.length);
  }, [name, desc, characterCount]);

  const handleCreateRole = () => {
    setLoading(true);
    createRole({ name: name, description: desc }).then((res) => {
      if (res.status === "fail") toast.error(res.message);
      else {
        toast.success(res.message);
        props.onComplete();
      }
      setLoading(false);
    });
  };

  return (
    <>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Create new roles which can be added to users
        </DialogContentText>
        <TextField
          required
          variant="standard"
          margin="normal"
          label="Name"
          type="text"
          value={name}
          onInput={(e) => setName(e.target.value)}
          fullWidth
        />
        <TextField
          required
          variant="standard"
          margin="normal"
          label="Description"
          type="text"
          value={desc}
          multiline
          rows={4}
          onInput={(e) => setDesc(e.target.value)}
          fullWidth
          helperText={`${characterCount}/100`}
        />
      </DialogContent>

      <LoadingButton
        onClick={handleCreateRole}
        type="submit"
        variant="contained"
        color="primary"
        autoFocus={true}
        disabled={dis}
        loading={loading}
        fullWidth
      >
        Create Role
      </LoadingButton>
    </>
  );
}
