import { ExpandMore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import {
  createNotificationRoles,
  getNotificationRoles,
  updateNotificationRoles,
} from "../../../CustomAxios/Protected/AccountManagment";
import { get_roles } from "../../../CustomAxios/Protected/AdminManagment";

export default function NotificationRole() {
  const [open, setOpen] = React.useState(false),
    [loading, setLoading] = React.useState(false),
    [notifRoles, setNotifRoles] = React.useState([]),
    [rolesList, setRolesList] = React.useState([]);

  React.useEffect(() => {
    if (open) {
      setLoading(true);
    }
  }, [open]);

  React.useEffect(() => {
    if (loading)
      getNotificationRoles()
        .then((res) => {
          setNotifRoles(res);
        })
        .finally(() => setLoading(false));
  }, [loading]);

  React.useEffect(() => {
    get_roles().then((res) => {
      setRolesList(res);
    });
  }, []);

  const [creating, setCreating] = React.useState(false);

  const handleInnitRoles = () => {
    setCreating(true);
    createNotificationRoles().finally(() => {
      setLoading(true);
      setCreating(false);
    });
  };

  return (
    <Paper>
      <CardActionArea onClick={() => setOpen(!open)} sx={{ p: 2 }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="h5">Notification Roles</Typography>
            <Typography variant="body1">
              These roles are used to notify the user of a change in the system.
            </Typography>
          </Box>
          <Box
            sx={{
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <ExpandMore />
          </Box>
        </Stack>
      </CardActionArea>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {notifRoles.map((role, index) => (
              <Grid item key={index}>
                <NotificationRoleCard
                  name={role.name}
                  description={role.description}
                  assignedRoleID={role.assignedRoleID}
                  rolesList={rolesList}
                  reload={() => setLoading(true)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Stack alignItems="flex-end">
          <LoadingButton onClick={handleInnitRoles} loading={creating}>
            Innit roles
          </LoadingButton>
        </Stack>
      </Collapse>
    </Paper>
  );
}

function NotificationRoleCard({
  name,
  description,
  assignedRoleID,
  rolesList,
  reload,
}) {
  const originalRole = assignedRoleID || null;

  const [hover, setHover] = React.useState(false),
    [edit, setEdit] = React.useState(false),
    [newRole, setNewRole] = React.useState(originalRole),
    [loading, setLoading] = React.useState(false);

  const onSave = () => {
    setLoading(true);
    updateNotificationRoles(name, newRole)
      .then((res) => {
        if (res.status === "success") {
          setEdit(false);
          toast.success(res.message);
          reload();
        } else {
          toast.error(res.message);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Card
      variant="outlined"
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <CardContent>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="body1">{description}</Typography>
        <Collapse in={!edit}>
          <Typography variant="body1">
            Assigned Role:{" "}
            {assignedRoleID
              ? rolesList.find((role) => role.id === originalRole).name
              : "None"}
          </Typography>
        </Collapse>
      </CardContent>
      <Collapse in={edit}>
        <Divider />
        <CardContent>
          <TextField
            label="Change Role"
            fullWidth
            select
            value={newRole}
            onChange={(e) => setNewRole(e.target.value)}
          >
            <MenuItem value={null}>None</MenuItem>
            {rolesList.map((role, index) => (
              <MenuItem key={index} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </TextField>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ mt: 1 }}
          >
            <LoadingButton
              color="success"
              variant="contained"
              disabled={newRole === originalRole}
              loading={loading}
              onClick={onSave}
            >
              Save
            </LoadingButton>
            <Button
              color="warning"
              variant="contained"
              disabled={newRole === originalRole}
              onClick={() => setNewRole(originalRole)}
            >
              Reset
            </Button>
          </Stack>
        </CardContent>
        <Divider />
      </Collapse>
      <Collapse in={hover || edit}>
        <Button onClick={() => setEdit(!edit)} color="warning" fullWidth>
          {edit ? "Cancel change Role" : "Change Role"}
        </Button>
      </Collapse>
    </Card>
  );
}
