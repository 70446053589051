import { axiosAuth } from "../Protected";

// Admin Management
// Users
export function get_users() {
  return axiosAuth.get(`/admin/users`).then((res) => {
    return res.data;
  });
}

export function get_users_extra() {
  return axiosAuth.get(`/admin/usersExtra`).then((res) => {
    return res.data;
  });
}

export function get_userById(id) {
  return axiosAuth.post(`/admin/users/ID`, { userId: id }).then((res) => {
    return res.data;
  });
}

export function changeAccess(id) {
  return axiosAuth.post(`/admin/changeAccess`, { userId: id }).then((res) => {
    return res.data;
  });
}

export function changeUserNames(data) {
  return axiosAuth.post(`/admin/changeUserNames`, data).then((res) => {
    return res.data;
  });
}

export function changeUserPhoneNumber(data) {
  return axiosAuth.post(`/admin/changeUserPhoneNumber`, data).then((res) => {
    return res.data;
  });
}

export function changeUserTitle(data) {
  return axiosAuth.post(`/admin/changeUserTitle`, data).then((res) => {
    return res.data;
  });
}

export function changeAdminStatus(id) {
  return axiosAuth
    .post(`/admin/users/changeAdminStatus`, { userId: id })
    .then((res) => {
      return res.data;
    });
}

export function delete_user(id) {
  return axiosAuth.post(`/admin/users/delete`, { userId: id }).then((res) => {
    return res.data;
  });
}

// Roles
export function get_roles() {
  return axiosAuth.get(`/admin/roles`).then((res) => {
    return res.data;
  });
}

export function createRole(data) {
  return axiosAuth.post(`/admin/roles/create`, data).then((res) => {
    return res.data;
  });
}

export function ChangeRoleStatus(data) {
  return axiosAuth.post(`/admin/roles/changeStatus`, data).then((res) => {
    return res.data;
  });
}

export function sendNotification(data) {
  return axiosAuth.post(`/admin/sendNotification`, data).then((res) => {
    return res.data;
  });
}

export function add_role(data) {
  return axiosAuth.post(`/admin/roles/add`, data).then((res) => {
    return res.data;
  });
}

export function remove_role(data) {
  return axiosAuth.post(`/admin/roles/remove`, data).then((res) => {
    return res.data;
  });
}

export function edit_role(data) {
  return axiosAuth.post(`/admin/roles/edit`, data).then((res) => {
    return res.data;
  });
}

export function delete_role(data) {
  return axiosAuth.post(`/admin/roles/delete`, data).then((res) => {
    return res.data;
  });
}

export function RoleGetAllInfo(name) {
  return axiosAuth
    .post(`/admin/roles/getAllInfo`, { roleName: name })
    .then((res) => {
      return res.data;
    });
}

// Pages

export function get_pages() {
  return axiosAuth.get(`/admin/pages`).then((res) => {
    return res.data;
  });
}

export function createPage(data) {
  return axiosAuth.post(`/admin/pages/create`, data).then((res) => {
    return res.data;
  });
}

export function addpage_role(data) {
  return axiosAuth.post(`/admin/pages/addRole`, data).then((res) => {
    return res.data;
  });
}

export function removepage_role(data) {
  return axiosAuth.post(`/admin/pages/removeRole`, data).then((res) => {
    return res.data;
  });
}

export function remove_page(data) {
  return axiosAuth.post(`/admin/pages/delete`, data).then((res) => {
    return res.data;
  });
}
