import React from "react";
import { Button, Dialog, DialogTitle, Stack } from "@mui/material";

import { logout } from "../CustomAxios/Protected/AccountManagment";
import { SessionManager } from "react-session.manager.sk";

import ProfileCard from "../Components/common/ProfileCard";
import LoginForm from "../Components/Forms/login";
import CreateACForm from "../Components/Forms/createAC";

import CreateIcon from "@mui/icons-material/Create";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";

function Login() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        startIcon={<LoginIcon />}
      >
        Login
      </Button>
      <Dialog
        open={open}
        aria-labelledby="Login-Form"
        PaperProps={{
          elevation: 0,
        }}
        fullScreen
      >
        <DialogTitle id="Login-Form">Login</DialogTitle>
        <LoginForm handleClose={handleClose} />
      </Dialog>
    </>
  );
}

function SignUp() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        startIcon={<CreateIcon />}
      >
        <span nowrap="true">Sign up</span>
      </Button>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        PaperProps={{
          elevation: 0,
        }}
      >
        <DialogTitle>Create Account</DialogTitle>
        <CreateACForm handleClose={handleClose} />
      </Dialog>
    </div>
  );
}

function Logout() {
  const { setHeader, setLoggedin } = React.useContext(SessionManager);
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => logout(setHeader, setLoggedin)}
      startIcon={<LogoutIcon />}
    >
      Logout
    </Button>
  );
}

function AuthButtons({ isLoggedIn }) {
  if (isLoggedIn)
    return (
      <ProfileCard>
        <Logout />
      </ProfileCard>
    );
  else
    return (
      <Stack direction="row" spacing={1}>
        <SignUp />
        <Login />
      </Stack>
    );
}

export default AuthButtons;
