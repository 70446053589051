import React from "react";

import {
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  TextField,
  Stack,
  Collapse,
  Skeleton,
  Paper,
  Button,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

import AreYouSure from "../../../Components/common/AreYouSure";
import AsyncAutoComplete from "../../../Components/common/AsyncAutoComplete";
import { editRfq, getRfqByID, searchClient } from "../../API/Sales";

import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import { toast } from "react-toastify";
import DateTimePicker from "../common/DateTimePicker";

export default function EditRfqForm() {
  const [loading, setLoading] = React.useState(true),
    [savingChanges, setSavingChanges] = React.useState(false);

  const [changed, setChanged] = React.useState(false),
    [areYouSure, setAreYouSure] = React.useState(false);

  const [client, setClient] = React.useState(""),
    [description, setDescription] = React.useState(""),
    [dateReceived, setDateReceived] = React.useState(""),
    [quoted, setQuoted] = React.useState(false);

  const [originalDetails, setOriginalDetails] = React.useState({});

  const { loadFiltered, origin } = useOutletContext();
  const history = useNavigate();

  const urlDets = new URLSearchParams(useLocation().search);
  const id = urlDets.get("id");

  const goBack = (edited) => {
    if (edited) loadFiltered();
    history(origin);
  };

  const handleCancel = () => {
    if (changed) setAreYouSure(true);
    else goBack();
  };

  React.useEffect(() => {
    if (loading)
      getRfqByID(id)
        .then((res) => {
          res.dateReceived = new Date(res.dateReceived);
          setOriginalDetails(res);
          setDescription(res.description);
          setDateReceived(res.dateReceived);
          setClient({ value: res.clientID, name: res.clientName });
          setQuoted(res.quoted);
        })
        .finally(() => setLoading(false));
  }, [id, loading]);

  React.useEffect(() => {
    if (
      originalDetails.description !== description ||
      originalDetails.dateReceived !== dateReceived ||
      originalDetails.clientID !== client.value ||
      originalDetails.quoted !== quoted
    )
      setChanged(true);
    else setChanged(false);
  }, [originalDetails, description, dateReceived, client, quoted]);

  const handleSave = () => {
    setSavingChanges(true);
    // function to save changes
    editRfq({
      rfqID: id,
      clientID: client.value,
      description: description,
      dateReceived: dateReceived,
      quoted: quoted,
    })
      .then((res) => {
        if (res.status === "success") {
          toast.success("Changes saved successfully");
          goBack(true);
        } else {
          toast.error(res.message);
          toast.error("Error updating Rfq ");
        }
      })
      .finally(() => setSavingChanges(false));
  };

  return (
    <Dialog open={true} onClose={handleCancel} fullWidth>
      <Paper elevation={0} sx={{ borderRadius: 0 }}>
        <DialogTitle>Edit RFQ</DialogTitle>

        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={handleCancel}
        >
          <Close />
        </IconButton>
      </Paper>
      <DialogContent>
        <Collapse in={loading} timeout={{ appear: 0, enter: 0, exit: 200 }}>
          <Skeleton variant="rectangular" height={269} />
        </Collapse>
        <Collapse in={!loading} timeout={{ appear: 0, enter: 200, exit: 0 }}>
          <Stack spacing={2} mt={1}>
            <AsyncAutoComplete
              getData={searchClient}
              value={client}
              setValue={setClient}
              optionLabel="name"
              label="Customers"
              defaultOption={client}
            />
            <Collapse in={client ? true : false}>
              <Stack spacing={2}>
                <DateTimePicker
                  value={dateReceived}
                  setChange={setDateReceived}
                />
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  rows={4}
                  fullWidth
                />
                <ToggleButtonGroup
                  value={quoted}
                  exclusive
                  onChange={(e, val) => {
                    if (val !== null) setQuoted(val);
                  }}
                  color={quoted ? "success" : "warning"}
                >
                  <ToggleButton value={true}>Quote Sent to Client</ToggleButton>
                  <ToggleButton value={false}>Quote not sent</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Collapse>
          </Stack>
        </Collapse>
      </DialogContent>
      <Paper elevation={0} sx={{ borderRadius: 0 }}>
        <DialogActions>
          <LoadingButton
            variant="contained"
            color="success"
            disabled={!changed}
            loading={savingChanges}
            onClick={handleSave}
          >
            Save Changes
          </LoadingButton>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => {
              setClient({
                value: originalDetails.clientID,
                name: originalDetails.clientName,
              });
              setDescription(originalDetails.description);
              setDateReceived(originalDetails.dateReceived);
              setQuoted(originalDetails.quoted);
            }}
            disabled={!changed}
          >
            Reset
          </Button>
        </DialogActions>
      </Paper>
      <AreYouSure
        open={areYouSure}
        onAccept={goBack}
        onDecline={() => setAreYouSure(false)}
      />
    </Dialog>
  );
}
