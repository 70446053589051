import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// styling
import CssBaseline from "@mui/material/CssBaseline";
import CustomThemeProvider from "./Components/common/CustomThemeProvider";
// Routing
import { BrowserRouter as Router } from "react-router-dom";
//Session Mananger
import SessionManagerProvider from "react-session.manager.sk";
import { who, refresh } from "./CustomAxios/Protected";
import { axiosAuth } from "./CustomAxios/Protected";

import App from "./App";
import config from "./Config";

document.title = config.appName;

const root = createRoot(document.getElementById("root"));
console.log("Current App version: ", config.appVersion);

root.render(
  <React.StrictMode>
    <Router>
      <CustomThemeProvider>
        <CssBaseline />
        <SessionManagerProvider
          userLoader={who}
          refreshToken={refresh}
          AuthenticatedAxiosObject={axiosAuth}
          refreshTimer={config.server.tokenRefreshTimer}
          dataRefresh={config.server.dataRefreshTimer}
          appVersion={config.appVersion}
          toastOptions={{
            icon: true,
            toastClassName: config.theme.Notification.ThemeNotifications
              ? config.theme.Notification.MaterialNotifications
                ? "custToast materialToast"
                : "custToast"
              : "",
          }}
        >
          <App />
        </SessionManagerProvider>
      </CustomThemeProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
