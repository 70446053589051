import React from "react";

import { Typography } from "@mui/material";

export default function Logo({ variant, sx }) {
  return (
    <Typography
      variant={variant || "h3"}
      color="primary"
      align="center"
      sx={{
        fontFamily: "Times New Roman",
        color: "white",
        fontWeight: "bold",
        width: "fit-content",
        borderRadius: "10px",
        mx: "auto",
        py: "5px",
        px: "10px",
        backgroundColor: "var(--maincolor)",
        ...sx,
      }}
    >
      technology TODAY
    </Typography>
  );
}
