import React from "react";
import {
  Alert,
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { downloadSummary } from "../../API/Sales";
import { toast } from "react-toastify";
import {
  clearReports,
  getAdminDailySummary,
  getAdminWeeklySummary,
  getAllAccountManagers,
} from "../../API/SalesAdmin";
import AsyncAutoComplete from "../../../Components/common/AsyncAutoComplete";
import DateTimePicker from "./DateTimePicker";

import { RfqListItem } from "./Summary";
import PerformanceReports from "./AdminPerformanceReport";

export default function Reports() {
  const [dailyLoading, setDailyLoading] = React.useState(true),
    [weeklyLoading, setWeeklyLoading] = React.useState(true),
    [accManager, setAccManager] = React.useState("");

  return (
    <Box p={2}>
      <AsyncAutoComplete
        label="Account Manager"
        defaultOption={accManager}
        getData={getAllAccountManagers}
        setValue={setAccManager}
        optionLabel="email"
      />
      <Collapse in={accManager ? true : false}>
        <PerformanceReports accManager={accManager} />
        <TodaysSummary
          loading={dailyLoading}
          setLoading={setDailyLoading}
          accID={accManager.id}
        />
        <ThisWeeksSummary
          loading={weeklyLoading}
          setLoading={setWeeklyLoading}
          accID={accManager.id}
          accName={accManager.email}
        />
      </Collapse>
      <Collapse in={!accManager ? true : false}>
        <Alert severity="info" sx={{ mt: 1 }}>
          Please select an account manager
        </Alert>
      </Collapse>
    </Box>
  );
}

function Section({ title, children }) {
  return (
    <Grid item xs={12} md={6} sx={{ mb: 2 }}>
      <Paper variant="outlined">
        <Typography
          backgroundColor="background.paper"
          sx={{
            position: "relative",
            mt: "-1.2rem",
            width: "fit-content",
            p: 1,
            ml: 2,
          }}
        >
          {title}
        </Typography>
        {children}
      </Paper>
    </Grid>
  );
}

function DownloadReportButton({
  type,
  accountName,
  startDate,
  endDate = null,
}) {
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setLoading(true);

    let formattedEndDate = null;
    if (type !== "daily") {
      function formatDate(date) {
        const endD = date;
        const yyyy = endD.getFullYear();
        let mm = endD.getMonth() + 1; // Months start at 0!
        let dd = endD.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        return dd + "_" + mm + "_" + yyyy;
      }
      formattedEndDate = formatDate(endDate);
    }

    const startD = startDate;
    const yyyy = startD.getFullYear();
    let mm = startD.getMonth() + 1; // Months start at 0!
    let dd = startD.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedStartDate = dd + "_" + mm + "_" + yyyy;
    const name =
      type === "daily"
        ? `Summary for ${accountName}_${formattedStartDate}`
        : `Summary for ${accountName}_${formattedStartDate}_to_${formattedEndDate}`;

    downloadSummary(type, name)
      .catch(() => toast.error("Error downloading report"))
      .finally(() => {
        setLoading(false);
        clearReports();
      });
  };

  return (
    <LoadingButton
      startIcon={<Download />}
      onClick={handleClick}
      loading={loading}
    >
      Download Report
    </LoadingButton>
  );
}

function TodaysSummary({ loading, setLoading, accID }) {
  const [data, setData] = React.useState({}),
    [date, setDate] = React.useState(new Date());

  React.useEffect(() => {
    if (loading && accID) {
      getAdminDailySummary(accID, date)
        .then((res) => {
          setData(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading, setLoading, accID, date]);

  React.useEffect(() => {
    setLoading(true);
  }, [setLoading, date]);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems={"center"}
        sx={{ mb: 2 }}
      >
        <Typography>Todays Summary</Typography>
        <DownloadReportButton type="daily" startDate={date} />
      </Stack>
      <DateTimePicker label="Date" value={date} setChange={setDate} />
      {loading ? (
        <Skeleton variant="rectangular" height={200} sx={{ my: 2 }} />
      ) : (
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Section title="RFQS recieved">
            <List>
              {data?.RFQS?.map((rfq, index) => (
                <RfqListItem
                  key={rfq.id}
                  rfq={rfq}
                  index={index}
                  rfqLength={data?.RFQS.length - 1}
                />
              ))}
            </List>
          </Section>
          <Section title="Quotations Done">
            <List>
              {data?.QUOTES?.map((rfq, index) => (
                <ListItem
                  key={rfq.id}
                  divider={index !== data?.QUOTES.length - 1}
                >
                  <ListItemText primary={rfq.client} secondary={rfq.id} />
                </ListItem>
              ))}
            </List>
          </Section>
          <Section title="Orders Placed">
            <List>
              {data?.ORDERS?.map((rfq, index) => (
                <ListItem
                  key={rfq.id}
                  divider={index !== data?.ORDERS.length - 1}
                >
                  <ListItemText primary={rfq.client} secondary={rfq.id} />
                </ListItem>
              ))}
            </List>
          </Section>
          <Section title="Pending Quotes">
            <List>
              {data?.PENDING?.map((rfq, index) => (
                <RfqListItem
                  key={rfq.id}
                  rfq={rfq}
                  index={index}
                  rfqLength={data?.PENDING.length - 1}
                />
              ))}
            </List>
          </Section>
        </Grid>
      )}
    </>
  );
}

function ThisWeeksSummary({ loading, setLoading, accID, accName }) {
  const [data, setData] = React.useState({}),
    [startDate, setStartDate] = React.useState(new Date()),
    [endDate, setEndDate] = React.useState(new Date());

  React.useEffect(() => {
    if (loading && accID) {
      getAdminWeeklySummary(accID, startDate, endDate)
        .then((res) => {
          setData(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading, setLoading, accID, startDate, endDate]);

  React.useEffect(() => {
    setLoading(true);
  }, [setLoading, startDate, endDate]);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems={"center"}
        sx={{ mb: 2 }}
      >
        <Typography>This Weeks Summary</Typography>
        <DownloadReportButton
          accountName={accName}
          type="weekly"
          startDate={startDate}
          endDate={endDate}
        />
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="flex-start">
        <DateTimePicker
          label="Start Date"
          value={startDate}
          setChange={setStartDate}
        />
        <DateTimePicker
          label="End Date"
          value={endDate}
          setChange={setEndDate}
        />
      </Stack>
      {loading ? (
        <Skeleton variant="rectangular" height={200} sx={{ my: 2 }} />
      ) : (
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Section title="RFQS recieved">
            <List>
              {data?.RFQS?.map((rfq, index) => (
                <RfqListItem
                  key={rfq.id}
                  rfq={rfq}
                  index={index}
                  rfqLength={data?.RFQS.length - 1}
                />
              ))}
            </List>
          </Section>
          <Section title="Quotations Done">
            <List>
              {data?.QUOTES?.map((rfq, index) => (
                <ListItem
                  key={rfq.id}
                  divider={index !== data?.QUOTES.length - 1}
                >
                  <ListItemText
                    primary={rfq.client}
                    secondary={`${rfq.id} ${rfq.date}`}
                  />
                </ListItem>
              ))}
            </List>
          </Section>
          <Section title="Orders Placed">
            <List>
              {data?.ORDERS?.map((rfq, index) => (
                <ListItem
                  key={rfq.id}
                  divider={index !== data?.ORDERS.length - 1}
                >
                  <ListItemText
                    primary={rfq.client}
                    secondary={`${rfq.id} ${rfq.date}`}
                  />
                </ListItem>
              ))}
            </List>
          </Section>
          <Section title="Pending Quotes">
            <List>
              {data?.PENDING?.map((rfq, index) => (
                <RfqListItem
                  key={rfq.id}
                  rfq={rfq}
                  index={index}
                  rfqLength={data?.PENDING.length - 1}
                />
              ))}
            </List>
          </Section>
        </Grid>
      )}
    </>
  );
}
