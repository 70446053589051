import React from "react";

import {
  Paper,
  CardActionArea,
  Stack,
  Typography,
  Collapse,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Button,
} from "@mui/material";

import {
  AllInclusive,
  Assessment,
  Download,
  ExpandMore,
  ImageNotSupported,
} from "@mui/icons-material";
import DateTimePicker from "./DateTimePicker";
import { LoadingButton } from "@mui/lab";
import { getWitholdingReport } from "../../API/Cheques&Pos";

export default function POSReports() {
  const [reportAreaOpen, setReportAreaOpen] = React.useState(false);

  const [dateType, setDateType] = React.useState("less");
  const [date1, setDate1] = React.useState(new Date());
  const [date2, setDate2] = React.useState(new Date());

  const [ignoreCheque, setIgnoreCheque] = React.useState(true);

  const [gettingReport, setGettingReport] = React.useState(false);

  const handleDateTypeChange = (event, newDateType) => {
    if (newDateType !== null) {
      setDateType(newDateType);
    }

    if (newDateType === "less" || newDateType === "greater") {
      setDate1(new Date());
      setDate2(new Date());
    }

    if (newDateType === "between") {
      setDate1(new Date("1/1/2000"));
      setDate2(new Date());
    }
  };

  const handelDownload = () => {
    const data = {
      dateType,
      date1: `${date1.getFullYear()}_${
        date1.getMonth() + 1
      }_${date1.getDate()}`,
      date2: `${date2.getFullYear()}_${
        date2.getMonth() + 1
      }_${date2.getDate()}`,
      ignoreCheque,
    };

    setGettingReport(true);
    getWitholdingReport(data)
      .catch(() => {
        setGettingReport(false);
      })
      .finally(() => {
        setGettingReport(false);
      });
  };

  return (
    <Paper variant="outlined" sx={{ my: 2 }}>
      <Paper
        elevation={1}
        sx={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <CardActionArea
          onClick={() => setReportAreaOpen(!reportAreaOpen)}
          sx={{ p: 2 }}
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Stack direction={"row"} spacing={1} alignItems="center">
              <Assessment color="info" />
              <Typography variant="h6" color="info">
                POS Report
              </Typography>
            </Stack>
            <ExpandMore
              sx={{
                transform: reportAreaOpen ? "rotate(180deg)" : "rotate(0deg)",
                transition: "0.3s",
              }}
            />
          </Stack>
        </CardActionArea>
      </Paper>
      <Collapse in={reportAreaOpen}>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="column" spacing={2}>
            <Stack direction="row" alignItems="center">
              <ToggleButtonGroup
                value={dateType}
                exclusive
                onChange={(e, value) => handleDateTypeChange(e, value)}
              >
                <ToggleButton value="less">Less than</ToggleButton>
                <ToggleButton value="greater">Greater than</ToggleButton>
                <ToggleButton value="between">Between</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <DateTimePicker
                key={`date1_${dateType}`}
                label="Date"
                value={date1}
                setChange={setDate1}
              />
              <Collapse in={dateType === "between"} orientation="horizontal">
                <DateTimePicker
                  key={`date2_${dateType}`}
                  label="Date"
                  value={date2}
                  setChange={setDate2}
                />
              </Collapse>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button
                variant="outlined"
                color={ignoreCheque ? "success" : "warning"}
                startIcon={
                  ignoreCheque ? <ImageNotSupported /> : <AllInclusive />
                }
                onClick={() => setIgnoreCheque(!ignoreCheque)}
              >
                {ignoreCheque
                  ? "Ignoring PO's without Cheque"
                  : "Include All PO's"}
              </Button>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"flex-end"}
              spacing={2}
            >
              <LoadingButton
                startIcon={<Download />}
                variant="outlined"
                color="info"
                loading={gettingReport}
                onClick={handelDownload}
                sx={{
                  alignSelf: "flex-end",
                }}
              >
                Download Report
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </Collapse>
    </Paper>
  );
}
