import lazyLoad from "./lazyLoading";

export default function fetchData(
  getData,
  getPages,
  filter_options,
  setData,
  setPage,
  setTotalPages,
  stopLoading,
  stop,
  controller,
  doneLoading,
  setGotAll
) {
  setData([]);
  setPage(0);
  getPages(filter_options).then((pages) => {
    const num_of_pages = Math.ceil(pages.filterCount);
    const totalPages = Math.ceil(pages.totalCount);
    const gotAll = pages.filterCount === pages.totalCount;
    setTotalPages(num_of_pages);

    if (num_of_pages === 0) {
      stopLoading();
      return;
    }
    lazyLoad(
      filter_options,
      totalPages,
      num_of_pages,
      setData,
      setPage,
      getData,
      stopLoading,
      stop,
      controller.signal,
      doneLoading,
      setGotAll,
      gotAll
    );
  });
}
