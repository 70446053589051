import { Cancel } from "@mui/icons-material";
import {
  Chip,
  Grow,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";

export default function TagField({
  value = [],
  setValue,
  label,
  TextFieldProps,
  ChipProps,
  seperator = ",",
}) {
  // Takes an array as input and returns an array using setValue

  const [textVal, setTextVal] = React.useState("");

  const removeTag = (tag) => {
    setValue(value.filter((t) => t !== tag));
  };

  const addTag = (newTag) => {
    if (newTag && !value.includes(newTag.toUpperCase())) {
      setValue([...value, newTag.toUpperCase()]);
    }
    setTextVal("");
  };

  const waitForSeparator = (e) => {
    if (e.key === "Enter") {
      addTag(textVal.trim());
    }

    if (e.key === "Backspace" && textVal === "") {
      removeTag(value[value.length - 1]);
    }

    if (e.key === seperator) {
      if (seperator === " ") {
        const newTag = textVal.trim();
        addTag(newTag);
      } else {
        const newTag = textVal.trim().slice(0, -1);
        addTag(newTag);
      }
    }
  };

  const ClearAll = () => {
    setValue([]);
    setTextVal("");
  };

  return (
    <TextField
      label={label}
      value={textVal}
      onChange={(e) => setTextVal(e.target.value)}
      onKeyUp={(e) => waitForSeparator(e)}
      autoFocus={true}
      onBlur={() => addTag(textVal.trim())}
      {...TextFieldProps}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {value.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                onDelete={() => removeTag(tag)}
                color="primary"
                sx={{ mr: 1 }}
                {...ChipProps}
              />
            ))}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Grow in={value.length > 0}>
              <Tooltip title="Clear All">
                <IconButton onClick={ClearAll}>
                  <Cancel />
                </IconButton>
              </Tooltip>
            </Grow>
          </InputAdornment>
        ),
      }}
    />
  );
}
