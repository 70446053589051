import React from "react";
import {
  Skeleton,
  Typography,
  DialogTitle,
  Card,
  CardContent,
  Button,
  Tooltip,
  Grid,
  IconButton,
  Chip,
  Popover,
  Stack,
  CardActionArea,
  Collapse,
  Divider,
  SvgIcon,
  Link,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  addpage_role,
  get_pages,
  get_roles,
  removepage_role,
  remove_page,
} from "../../CustomAxios/Protected/AdminManagment";
import { Link as RLink } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import RemoveIcon from "@mui/icons-material/Remove";
import AddPageForm from "../../Components/Forms/PageAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

import { toast } from "react-toastify";
import SearchArea from "../../Components/common/SearchArea";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import FloatingFormButton from "../../Components/common/FloatingFormButton";

import ChipSelect from "../../Components/common/ChipSelect";
import AreYouSure from "../../Components/common/AreYouSure";

function AccessCard(props) {
  const Data = props.data;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addRoleArea, setAddRoleArea] = React.useState(false),
    [selected, setSelected] = React.useState([]),
    [loading, setLoading] = React.useState(false),
    [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDelete = (data) => {
    removepage_role(data).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        props.setLoading(true);
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleAddRole = () => {
    setLoading(true);
    addpage_role({ page: Data.name, roles: selected }).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        setLoading(false);
        setAddRoleArea(false);
        props.setLoading(true);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    });
  };

  const handleDeletePage = () => {
    remove_page({ pageId: Data.id }).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        props.setLoading(true);
      } else {
        toast.error(res.message);
      }
    });
  };

  const handledeleteClose = () => {
    setDeleteOpen(false);
  };

  return (
    <Grid item xs={12} md={4} lg={3}>
      <Card variant="outlined">
        <Tooltip title="settings">
          <IconButton
            sx={{ float: "right", p: 1 }}
            aria-describedby={id}
            onClick={handleClick}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Stack spacing={1} direction="column">
            <Button color="inherit" onClick={setDeleteOpen}>
              Delete
            </Button>
          </Stack>
        </Popover>
        <AreYouSure
          open={deleteOpen}
          onDecline={handledeleteClose}
          onAccept={handleDeletePage}
          acceptText={"Delete Page"}
          text={
            "Do you want to delete the selected page, this cannot be undone. This will only work if the page has no assigned roles. The page can always be added again."
          }
        />
        <CardContent>
          <Typography variant="h5" sx={{ minHeight: "50px", mt: 1 }}>
            {Data.name}
          </Typography>

          {Data.roles.map((role) => {
            return (
              <Tooltip title={role.description} key={role.id}>
                <Chip
                  icon={
                    role.active ? <CheckCircleOutlineIcon /> : <CancelIcon />
                  }
                  label={role.name}
                  color={role.active ? "success" : "default"}
                  variant="outlined"
                  onDelete={() =>
                    handleDelete({ page: Data.name, role: role.name })
                  }
                  sx={{ mb: 1 }}
                />
              </Tooltip>
            );
          })}
          <Collapse in={addRoleArea} sx={{ mt: 1 }}>
            <AddRoleToPage
              selectedRoles={selected}
              setSelectedRoles={setSelected}
              allRoles={props.allRoles}
              currentRoles={Data.roles}
            />
            <Stack
              spacing={1}
              direction="row"
              justifyContent="flex-end"
              sx={{ my: 1 }}
            >
              <LoadingButton
                variant="contained"
                disabled={selected.length > 0 ? false : true}
                loading={loading}
                onClick={handleAddRole}
              >
                Add Role{selected.length > 0 ? `s (${selected.length})` : ""}
              </LoadingButton>
              {selected.length > 0 && (
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={() => setSelected([])}
                >
                  reset
                </Button>
              )}
            </Stack>
          </Collapse>
        </CardContent>
        <Tooltip title={!addRoleArea ? "Add role" : "Hide add role"}>
          <CardActionArea
            onClick={() => setAddRoleArea(!addRoleArea)}
            sx={{ bgcolor: "background.default" }}
          >
            <Grid justifyContent="center" container>
              <Grid item>{!addRoleArea ? <AddIcon /> : <RemoveIcon />}</Grid>
            </Grid>
          </CardActionArea>
        </Tooltip>
      </Card>
    </Grid>
  );
}

function ReloadBtn(props) {
  return (
    <Tooltip title={"Reload"}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => props.setLoading(true)}
      >
        <RefreshIcon />
      </Button>
    </Tooltip>
  );
}

export default function AccessControl() {
  const [loading, setLoading] = React.useState(true);
  const [pages, setPages] = React.useState([]),
    [roles, setRoles] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [final, setFinal] = React.useState([]);
  const [helpTip, sethelpTip] = React.useState(false);

  React.useEffect(() => {
    if (loading)
      get_pages().then((res) => {
        setPages(res);
        setFinal(res);
        get_roles().then((res) => {
          setRoles(res);
          setLoading(false);
        });
      });
  }, [loading]);

  const onComplete = () => {
    setLoading(true);
    setOpen(false);
  };

  return (
    <>
      <Typography variant="h4" color="primary" sx={{ mb: 2 }}>
        Access Control
      </Typography>

      <FloatingFormButton
        title="Create new page"
        open={open}
        setOpen={setOpen}
        disableConfirm
      >
        <DialogTitle>Add Page</DialogTitle>
        <AddPageForm onComplete={onComplete} pages={pages} />
      </FloatingFormButton>

      {loading ? (
        <LoadingSkeleton />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              pt: 2,
              mb: 2,
            }}
          >
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              <ReloadBtn setLoading={setLoading} />
              <Tooltip
                open={helpTip}
                onClose={() => sethelpTip(false)}
                onOpen={() => sethelpTip(true)}
                title={
                  <React.Fragment>
                    <Typography variant="h5">Help</Typography>
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          mb: 1,
                        }}
                      >
                        <SvgIcon sx={{ mr: 1 }} color="success">
                          <CheckCircleOutlineIcon />
                        </SvgIcon>
                        means that role is active
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          mb: 1,
                        }}
                      >
                        <SvgIcon sx={{ mr: 1 }}>
                          <CancelIcon />
                        </SvgIcon>
                        means that role is inactive
                      </Typography>
                      <Divider />
                      <Typography variant="body1">
                        If a role is inactive it will not show up for the user
                        therefore they will not have access to the page. To
                        change this go to{" "}
                        <Link
                          color="secondary"
                          component={RLink}
                          to="/admin/roles"
                        >
                          Role Manager
                        </Link>
                      </Typography>
                    </Box>
                  </React.Fragment>
                }
              >
                <IconButton sx={{ ml: 2 }} onClick={() => sethelpTip(!helpTip)}>
                  <QuestionMarkIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <SearchArea current={pages} setFinalArray={setFinal} />
          </Box>
          <Grid container spacing={2}>
            {final.map((role) => {
              return (
                <AccessCard
                  data={role}
                  key={`Role_${role.id}`}
                  allRoles={roles}
                  setLoading={setLoading}
                />
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
}

function AddRoleToPage(props) {
  const { selectedRoles, setSelectedRoles, allRoles, currentRoles } = props;
  const [roleList, setRoleList] = React.useState([]);

  React.useEffect(() => {
    const CheckRole = (rolen) => {
      const found = currentRoles.find((role) => role.name === rolen);
      return found;
    };

    const roles = allRoles.filter((role) => {
      return !CheckRole(role.name);
    });

    const newList = [];
    roles.forEach((role) => {
      newList.push(role.name);
    });
    setRoleList(newList);
  }, [allRoles, currentRoles]);

  return (
    <ChipSelect
      label="Select Role"
      value={selectedRoles}
      setValue={setSelectedRoles}
      options={roleList}
    />
  );
}

function SkeletonCard() {
  return (
    <Grid item xs={12} md={4} lg={3}>
      <Skeleton variant="rectangular" width={270} height={140} />
    </Grid>
  );
}

function LoadingSkeleton() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          pt: 2,
          mb: 2,
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <Skeleton width={80} height={80} />
        </Typography>
        <Skeleton width={220} />
      </Box>
      <Grid container spacing={2}>
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </Grid>
    </>
  );
}
