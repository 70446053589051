import React from "react";
import { Box } from "@mui/system";
import { Paper, Table, Typography } from "@mui/material";

export default function LabelPaper({ children, label, isTable, sx }) {
  return (
    <Paper variant="outlined">
      <Typography
        variant="h6"
        color="primary"
        backgroundColor="background.paper"
        sx={{
          position: "relative",
          mt: "-1.5rem",
          width: "fit-content",
          p: 1,
          ml: 2,
        }}
      >
        {label}
      </Typography>
      {isTable ? (
        <Box sx={{ mt: 1 }}>
          <Table sx={{ my: 2 }} padding={"checkbox"}>
            {children}
          </Table>
        </Box>
      ) : (
        <Box sx={{ mt: 1 }}>{children}</Box>
      )}
    </Paper>
  );
}
