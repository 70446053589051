import React from "react";

import {
  Button,
  Stack,
  Typography,
  TextField,
  Grow,
  TableBody,
  TableCell,
  TableRow,
  Skeleton,
  Popover,
  Alert,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";

export default function TieredText({
  label,
  name,
  value,
  submitChange,
  multiline,
  canDelete,
  customEditComponent,
  loading,
  alertText,
}) {
  const [hover, setHover] = React.useState(false),
    [edit, setEdit] = React.useState(false),
    [valueDisplay, setValueDisplay] = React.useState(value),
    [hasPopOver, setHasPopOver] = React.useState(false);

  const [currentValue, setCurrentValue] = React.useState(value),
    [disabled, setDisabled] = React.useState(true),
    [sending, setSending] = React.useState(false);

  React.useEffect(() => {
    if (currentValue && currentValue !== value) setDisabled(false);
    else setDisabled(true);
  }, [value, currentValue]);

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value, edit]);

  const hoverOn = (event) => {
    setHover(true);
    setAnchorEl(event.currentTarget);
  };

  const hoverOff = () => {
    setHover(false);
    setAnchorEl(null);
  };

  const saveClicked = () => {
    setSending(true);
    submitChange(name, currentValue)
      .then((res) => {
        if (res) {
          setEdit(false);
          hoverOff();
        }
      })
      .finally(() => {
        setSending(false);
      });
  };

  const deleteClicked = () => {
    canDelete(label);
  };

  React.useEffect(() => {
    if (!loading && value) {
      if (value.length > 20) {
        setValueDisplay(value.substring(0, 20) + "...");
        setHasPopOver(true);
      } else {
        setValueDisplay(value);
        setHasPopOver(false);
      }
    }
  }, [hover, value, loading]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  return (
    <>
      <TableBody onMouseEnter={hoverOn} onMouseLeave={hoverOff}>
        {edit ? (
          <TableRow>
            <TableCell colSpan={5} sx={{ py: 1, px: 2 }}>
              <>
                {customEditComponent ? (
                  customEditComponent(currentValue, setCurrentValue)
                ) : (
                  <TextField
                    label={label}
                    value={currentValue}
                    onChange={(e) => setCurrentValue(e.target.value)}
                    fullWidth
                    multiline={multiline}
                    rows={multiline ? "4" : "1"}
                  />
                )}
                {alertText && (
                  <Alert severity="info" sx={{ mt: 1 }}>
                    {alertText}
                  </Alert>
                )}
                <Stack
                  spacing={1}
                  direction={"row"}
                  sx={{ mt: 1 }}
                  justifyContent="flex-end"
                >
                  <LoadingButton
                    variant="contained"
                    color="success"
                    startIcon={<SaveIcon />}
                    disabled={disabled}
                    onClick={saveClicked}
                    loading={sending}
                  >
                    save
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={() => {
                      setEdit(false);
                      hoverOff();
                    }}
                    startIcon={<CancelIcon />}
                  >
                    cancel
                  </Button>
                </Stack>
              </>
            </TableCell>
          </TableRow>
        ) : (
          <TableRow>
            <TableCell
              colSpan={2}
              align="left"
              sx={{ pl: 2, pt: 1, verticalAlign: "top" }}
            >
              <Typography variant="body1" color="secondary">
                {label}
              </Typography>
            </TableCell>
            <TableCell
              colSpan={2}
              align="left"
              sx={{ wordBreak: "break-word" }}
            >
              {loading ? (
                <Skeleton variant="text" />
              ) : (
                <>
                  <Typography variant="body1">{valueDisplay}</Typography>
                  {hasPopOver && (
                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: "none",
                        maxWidth: { xs: "100vw", md: "70vw" },
                      }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      disableRestoreFocus
                    >
                      <Typography sx={{ p: 1 }}>{value}</Typography>
                    </Popover>
                  )}
                </>
              )}
            </TableCell>
            <TableCell sx={{ py: 1, verticalAlign: "top" }}>
              <Grow in={hover}>
                <Button
                  sx={{
                    marginLeft: canDelete ? "" : "auto",
                  }}
                  onClick={() => setEdit(true)}
                  startIcon={<EditIcon />}
                >
                  edit
                </Button>
              </Grow>
              {canDelete && (
                <Grow in={hover}>
                  <Button
                    sx={{
                      marginLeft: "auto",
                    }}
                    onClick={deleteClicked}
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                </Grow>
              )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </>
  );
}
