import React from "react";
import { TextField, Autocomplete } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";

export default function AsyncAutoComplete({
  getData,
  setValue,
  optionLabel,
  valueLabel,
  label,
  defaultOption,
  clear = false,
  setClear,
  autoCompleteProps,
  allowSearch = true,
  value,
  TextFieldProps,
  noOptions,
}) {
  const [selectValue, setSelectValue] = React.useState(null);
  const [valueList, setValueList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState("");

  React.useEffect(() => {
    if (clear) {
      setSelectValue(null);
      setClear(false);
    }
    if (!value) {
      setSelectValue(null);
    }
  }, [clear, setClear, value]);

  React.useEffect(() => {
    setSelectValue(defaultOption || null);
  }, [defaultOption]);

  const handleChange = (event, value, reason) => {
    if (reason === "clear") {
      setValue("");
      setSelectValue(value);
      return;
    }

    setSelectValue(value);
    setValue(value[valueLabel] || value);
  };

  React.useEffect(() => {
    if (!allowSearch) {
      return;
    }
    setLoading(true);

    const searchValues = () => {
      getData({ searchKey })
        .then((res) => {
          setValueList(["", ...res]);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const search = setTimeout(searchValues, 500);

    return () => {
      clearTimeout(search);
      setLoading(false);
    };
  }, [searchKey, getData, allowSearch]);

  React.useEffect(() => {
    if (noOptions && valueList.length === 1) noOptions(true);
  }, [valueList, noOptions]);

  return (
    <Autocomplete
      value={selectValue}
      onChange={handleChange}
      inputValue={searchKey}
      onInputChange={(event, newInputValue) => {
        setSearchKey(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => {
        return option[optionLabel] === value[optionLabel] || value === option;
      }}
      getOptionLabel={(option) => option[optionLabel] || option}
      options={valueList}
      loading={loading}
      {...autoCompleteProps}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          error={TextFieldProps?.required && selectValue === null}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="info" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
