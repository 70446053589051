//Technology Today specific Config

const config = {
  ValidityOptions: ["48 hours", "2 weeks", "1 month"],
  DeliveryOptions: [
    "Immediately upon processing",
    "48 hours from LPO",
    "72 hours from LPO",
    "4-6 weeks from LPO",
  ],
  VAT: 1.16,
  Currencies: [
    { name: "Ksh Kenyan Shillings KES", value: "KES" },
    { name: "$ Americans Dollars (USD)", value: "USD" },
  ],
  withHoldingTax: 0.02,
  DATABASE_SEPERATION_CHAR: ";",
};

export default config;
