import { Add, Remove } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  CardActionArea,
  Fade,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

export default function StoreChangeQty({ itemID, currentQty, onSubmit }) {
  const [qtyToAdd, setQtyToAdd] = React.useState(0);
  const [newQty, setNewQty] = React.useState(currentQty);
  const [add, setAdd] = React.useState(true);

  React.useEffect(() => {
    if (add) setNewQty(currentQty + parseFloat(qtyToAdd));
    else setNewQty(currentQty - parseFloat(qtyToAdd));
  }, [add, currentQty, qtyToAdd]);

  const handleSubmit = () => {
    onSubmit(itemID, newQty);
  };

  return (
    <Paper variant="outlined" sx={{ p: 2, mt: 2 }}>
      <Typography variant="h5" color="primary">
        Change Quantity
      </Typography>
      <Typography variant="overline">Quanity in stock: {currentQty}</Typography>
      <Stack spacing={2}>
        <TextField
          label="Quantity"
          variant="outlined"
          value={qtyToAdd}
          onChange={(e) => setQtyToAdd(e.target.value)}
          color="info"
          helperText={`new QTY:
            ${
              add
                ? currentQty + parseFloat(qtyToAdd)
                : currentQty - parseFloat(qtyToAdd)
            }`}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  bottom: 0,
                  height: "100%",
                  maxHeight: "100%",
                }}
              >
                <CardActionArea
                  onClick={() => setAdd(!add)}
                  sx={{
                    height: "100%",
                    pl: 0.5,
                    pr: 1,
                    bgcolor: (theme) =>
                      add
                        ? theme.palette.success.main + "99"
                        : theme.palette.warning.main + "99",
                    borderLeft: (theme) => `2px solid ${theme.palette.divider}`,
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"center"}
                  >
                    <Fade in={add}>
                      <Add sx={{ display: !add && "none" }} />
                    </Fade>
                    <Fade in={!add}>
                      <Remove sx={{ display: add && "none" }} />
                    </Fade>
                    <Typography sx={{ ml: 3 }}>
                      {add ? "Add" : "Remove"}
                    </Typography>
                  </Stack>
                </CardActionArea>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          variant="contained"
          color="success"
          disabled={!qtyToAdd || qtyToAdd < 1 || newQty < 1}
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
      </Stack>
    </Paper>
  );
}
