import {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Paper,
  Divider,
  Stack,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export default function TableLoader({ base = false }) {
  if (base)
    return (
      <Paper sx={{ p: 2 }}>
        <Typography color="inherit" variant="h3" component="div">
          <Skeleton width={200} />
        </Typography>
        <Stack spacing={2} divider={<Divider />}>
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
        </Stack>
      </Paper>
    );

  return (
    <>
      <TableHead></TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <Skeleton height={30} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton height={30} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton height={30} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton height={30} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton height={30} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton width="30%" sx={{ float: "right" }} />
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
}
