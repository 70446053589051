import React from "react";
import { DialogContent, DialogContentText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { createPage } from "../../CustomAxios/Protected/AdminManagment";
import { toast } from "react-toastify";
import { RoleRoutes } from "../../Pages";
import ChipSelect from "../common/ChipSelect";

export default function AddPageForm(props) {
  const [name, setName] = React.useState([]),
    [dis, setDis] = React.useState(true),
    [loading, setLoading] = React.useState(false),
    [pageList, setPageList] = React.useState([]);

  React.useEffect(() => {
    if (name.length > 0) setDis(false);
    else setDis(true);
  }, [name]);

  const handleCreatePage = () => {
    setLoading(true);
    createPage({ name: name }).then((res) => {
      if (res.status === "fail") {
        toast.warning(res.message);
        props.onComplete();
      } else {
        toast.success(res.message);
        props.onComplete();
      }
      setLoading(false);
    });
  };

  React.useEffect(() => {
    const checkPage = (page_name) => {
        const found = props.pages.find((page) => page.name === page_name);
        return found;
      },
      availablePages = RoleRoutes.filter((page) => !checkPage(page.name)),
      newPageList = availablePages.map((page) => page.name);

    setPageList(newPageList);
  }, [props.pages]);

  return (
    <>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Add a page name to control which roles have access to it.
        </DialogContentText>
        <ChipSelect
          label="Select Page"
          value={name}
          setValue={setName}
          options={pageList}
        />
        <DialogContentText color="secondary" sx={{ mt: 1 }}>
          *NB: If the select is empty there are no more pages that can be added.
        </DialogContentText>
      </DialogContent>

      <LoadingButton
        onClick={handleCreatePage}
        type="submit"
        variant="contained"
        color="primary"
        autoFocus={true}
        disabled={dis}
        loading={loading}
        fullWidth
      >
        Create Page{name.length > 0 ? `s (${name.length})` : ""}
      </LoadingButton>
    </>
  );
}
