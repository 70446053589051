import React from "react";
import Logo from "../../logo";

import {
  Typography,
  Button,
  Grid,
  Card,
  Box,
  Stack,
  Collapse,
} from "@mui/material";
import ThemeSlider from "../../Components/common/ThemeSlider";
import Divider from "@mui/material/Divider";
import { toast } from "react-toastify";
import DatabaseButtons from "../../Components/common/DatabaseButtons";

import QuizIcon from "@mui/icons-material/Quiz";
import { Check } from "../../CustomAxios/Main";
import config from "../../Config";

import LoadingPage from "./LoadingPage";
import { Link } from "react-router-dom";
import { Create, Login } from "@mui/icons-material";

export default function Main({ loading }) {
  const [debug, setDebug] = React.useState(false);

  React.useEffect(() => {
    setDebug(Check() === config.server.dev.url);
  }, []);

  const enterTime = 500;
  const exitTime = 250;

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ p: 2 }}
    >
      <Box sx={{ mb: 2 }}>
        <Logo sx={{ my: 5 }} />

        <Typography variant="body1" align="center" sx={{ mb: 3 }}>
          Technology Todays Employee Portal
        </Typography>
      </Box>

      <Collapse
        in={loading}
        timeout={{ appear: 0, enter: enterTime, exit: exitTime }}
      >
        <LoadingPage />
      </Collapse>

      <Collapse
        in={!loading}
        timeout={{ appear: 0, enter: enterTime, exit: exitTime }}
      >
        <Grid item xs={12}>
          <Card style={{ padding: "20px", minWidth: "60vw" }}>
            {debug && (
              <>
                <Stack
                  spacing={2}
                  direction="column"
                  sx={{ margin: "auto", maxWidth: "fit-content", mb: 2 }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      toast.success("success", { autoClose: 6000 });
                      toast.info(
                        "This is info, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                        { autoClose: 10000 }
                      );
                      toast.warning("Warning");
                      toast.error("Error");
                    }}
                    startIcon={<QuizIcon />}
                  >
                    Test Notifications
                  </Button>
                </Stack>
                <DatabaseButtons />
                <Divider sx={{ my: 3 }} />
              </>
            )}

            <Grid
              container
              item
              spacing={2}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <ThemeSlider buttonVariant />
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <Button
                    component={Link}
                    to="/signup"
                    startIcon={<Create />}
                    variant={"contained"}
                  >
                    Register
                  </Button>
                  <Button
                    component={Link}
                    to="/login"
                    startIcon={<Login />}
                    variant={"contained"}
                  >
                    Login
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Collapse>
    </Grid>
  );
}
