import * as React from "react";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
  TextField,
  MenuItem,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { sendNotification } from "../../CustomAxios/Protected/AdminManagment";

export default function SendNotification(props) {
  const [type, setType] = React.useState(""),
    [message, setMessage] = React.useState(""),
    [title, setTitle] = React.useState(""),
    [link, setLink] = React.useState(""),
    [disabled, setDisabled] = React.useState(false),
    [loading, setLoading] = React.useState(false);

  const types = [
    { value: "info", label: "Info" },
    { value: "success", label: "Success" },
    { value: "warning", label: "Warning" },
    { value: "error", label: "Error" },
  ];

  const handleSend = () => {
    const data = {
      userId: props.userId,
      nType: type,
      message: message,
      title: title,
      link: link,
    };
    setLoading(true);
    sendNotification(data).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        props.handleClose();
      } else toast.error(res.message);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    if (type && message && title) setDisabled(false);
    else setDisabled(true);
  }, [type, title, message]);

  return (
    <>
      <DialogTitle>Send Notification</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Send a notification to {props.email}
        </DialogContentText>
        <TextField
          id="ntype"
          select
          label="Notification Type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          helperText="Please select the message type"
          fullWidth
          margin="normal"
          color="secondary"
        >
          {types.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              required
              id="title"
              label="Notification Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              margin="normal"
              color="secondary"
            />
          </Grid>
          <Grid item>
            <TextField
              id="link"
              label="Notification Link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              margin="normal"
              color="secondary"
            />
          </Grid>
        </Grid>
        <TextField
          required
          id="message"
          label="Notification Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          multiline
          rows={4}
          fullWidth
          margin="normal"
          color="secondary"
        />
      </DialogContent>
      <DialogActions sx={{ bgcolor: "background.default" }}>
        <LoadingButton
          variant="contained"
          disabled={disabled}
          loading={loading}
          onClick={handleSend}
        >
          Send
        </LoadingButton>
        <Button onClick={props.handleClose}>Cancel</Button>
      </DialogActions>
    </>
  );
}
