import { Typography } from "@mui/material";
import React from "react";

export default function AmplifyReport() {
  return (
    <>
      <Typography variant="title">Amplify Report</Typography>
    </>
  );
}
