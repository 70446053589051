import { axiosAuth } from "../Protected";

// Token management
export function get_tokens() {
  return axiosAuth.get(`/protected/tokens`).then((res) => {
    return res.data;
  });
}

export function delete_tokens(data) {
  return axiosAuth.post(`/protected/tokens/revoke`, data).then((res) => {
    return res.data;
  });
}
