import React from "react";
import { Tooltip, IconButton, TextField } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";

// This is used to implement search on any array of objects
// It take an input of the initial array and the search string
// Use setFinalVal to update the final array
export default function SearchArea(props) {
  const currentArray = props.current;
  const setFinalArray = props.setFinalArray;
  const [searchVal, setSearchVal] = React.useState("");
  // This is the delay before the search is executed
  const delay = props.delay || 500;

  const setDefault = () => {
    setSearchVal("");
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchVal) {
        const term = searchVal.toString().toLocaleLowerCase();
        const filtered = currentArray.filter((val) => {
          for (let key in val) {
            if (val[key].toString().toLowerCase().includes(term)) {
              return true;
            }
          }
          return false;
        });
        setFinalArray(filtered);
      } else setFinalArray(currentArray);
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [searchVal, delay, currentArray, setFinalArray]);

  return (
    <div key="SearchArea">
      <TextField
        label="Search"
        variant="outlined"
        value={searchVal}
        color="secondary"
        onChange={(e) => setSearchVal(e.target.value)}
        InputProps={{
          endAdornment: (
            <Tooltip title={"Reset Search"}>
              <IconButton
                aria-label="Reset"
                color="secondary"
                onClick={() => setDefault()}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
    </div>
  );
}
