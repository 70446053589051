import { Close, Delete } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Box,
  CardActionArea,
  Collapse,
  Paper,
  Stack,
  Button,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
  deleteCompany,
  getCompanyByID,
  updateCompany,
} from "../../API/SalesAdmin";
import TieredText from "../common/TieredText";
import LabelPaper from "../../../Components/common/LabelPaper";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

export default function CompanyManager() {
  const [edited, setEdited] = React.useState(false);

  const urlDets = new URLSearchParams(useLocation().search);
  const companyID = urlDets.get("id");

  let history = useNavigate();

  const { startReload, origin } = useOutletContext();

  const goBack = (forceEdit = false) => {
    if (forceEdit || edited) startReload();
    history(origin);
  };

  const [companyDetails, setCompanyDetails] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (loading)
      getCompanyByID(companyID).then((res) => {
        setLoading(false);
        setCompanyDetails(res);
      });
  }, [companyID, loading]);

  const onSubmit = (label, value) => {
    const data = {
      companyID,
      name: label,
      value,
    };

    return updateCompany(data).then((res) => {
      if (res.success) {
        setEdited(true);
        setLoading(true);
        toast.success(res.message);
        return true;
      } else {
        toast.error(res.message);

        return false;
      }
    });
  };

  const handleChangeVetted = (e, newval) => {
    if (newval === null) return;
    const data = {
      companyID,
      name: "vetted",
      value: !companyDetails.vetted,
    };
    updateCompany(data).then((res) => {
      if (res.success) {
        setEdited(true);
        setLoading(true);
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <Dialog open={true}>
      <IconButton
        onClick={goBack}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          margin: 0.5,
        }}
      >
        <Close />
      </IconButton>
      <DialogTitle
        sx={{
          mr: 3,
        }}
      >
        Company Manager
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: 400,
        }}
      >
        <LabelPaper isTable>
          <TieredText
            label="Name"
            name="name"
            value={companyDetails.companyName}
            submitChange={onSubmit}
            loading={loading}
          />
          <TieredText
            label="KRA pin"
            name="KRA"
            value={companyDetails.kra}
            submitChange={onSubmit}
            loading={loading}
          />
          <TieredText
            label="Address"
            name="address"
            value={companyDetails.address}
            submitChange={onSubmit}
            loading={loading}
          />
          <TieredText
            label="Note"
            name="note"
            value={companyDetails.note}
            submitChange={onSubmit}
            loading={loading}
          />
          <TableRow>
            <TableCell colSpan={5} sx={{ pb: 1 }}>
              <ToggleButtonGroup
                value={companyDetails.vetted}
                color={companyDetails.vetted ? "success" : "warning"}
                onChange={handleChangeVetted}
                sx={{ pl: 2, pt: 2 }}
                exclusive
              >
                <ToggleButton value={true} disabled={loading}>
                  Vetted
                </ToggleButton>
                <ToggleButton value={false} disabled={loading}>
                  Not Vetted
                </ToggleButton>
              </ToggleButtonGroup>
            </TableCell>
          </TableRow>
        </LabelPaper>
      </DialogContent>

      <Stack direction="row" justifyContent="flex-end">
        <DeleteCompany coId={companyID} goBack={goBack} />
      </Stack>
    </Dialog>
  );
}

function DeleteCompany({ coId, goBack }) {
  const [open, setOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const onSubmit = () => {
    setDeleting(true);
    deleteCompany(coId)
      .then((res) => {
        if (res.success) {
          toast.success("Company Deleted");
          goBack(true);
        } else {
          toast.warning(
            "Company was not deleted. Either there was an error or there is a client registered to this company."
          );
        }
      })
      .finally(() => setDeleting(false));
  };

  return (
    <Box
      sx={{
        bgcolor: "error.main",
        mr: 3,
        mb: 2,
        borderRadius: 1,
        width: "fit-content",
      }}
    >
      <CardActionArea sx={{ p: 1.5 }} onClick={() => setOpen(!open)}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Delete />
          <Typography variant="body1" color="white">
            Delete Client
          </Typography>
        </Stack>
      </CardActionArea>
      <Collapse in={open}>
        <Paper
          elevation={0}
          variant="outlined"
          sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, p: 2 }}
        >
          <Typography variant="overline">Are you sure?</Typography>
          <Typography variant="body1" color="#9e9e9e">
            This action can not be undone
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ mt: 2 }}
          >
            <LoadingButton
              variant="contained"
              color="error"
              size="small"
              loading={deleting}
              onClick={onSubmit}
            >
              Delete
            </LoadingButton>
            <Button
              size="small"
              variant="outlined"
              color="warning"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Stack>
        </Paper>
      </Collapse>
    </Box>
  );
}
