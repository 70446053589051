import React from "react";
import {
  Stack,
  Typography,
  DialogTitle,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import { Outlet, Link } from "react-router-dom";

import AddClient from "../Components/Forms/AddClient";
import { getClientPages, getClients } from "../API/Sales";

import FloatingFormButton from "../../Components/common/FloatingFormButton";

import UniversalTable from "../../Components/common/UniversalTable";

import {
  LoadingProvider,
  LoadingContext,
} from "../Components/common/LoadingProvider";

import { MoreVert } from "@mui/icons-material";

export default function Main() {
  return (
    <LoadingProvider
      getFilterData={() => ({ currentPage: 1 })}
      getData={getClients}
      getPages={getClientPages}
    >
      <ClientsWrapper />
    </LoadingProvider>
  );
}

function ClientsWrapper() {
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loading,
    loadFiltered,
    data,
  } = React.useContext(LoadingContext);

  // Table components
  const Settings = ({ value }) => (
    <Tooltip title="Manage Client">
      <IconButton
        aria-label="settings"
        component={Link}
        to={`/clientManager/manage?i=${value}`}
        sx={{ float: "right" }}
      >
        <MoreVert />
      </IconButton>
    </Tooltip>
  );

  const headers = [
    {
      id: "companyName",
      label: "Company",
      searchable: true,
    },
    {
      id: "fullName",
      label: "Full Name",
      searchable: true,
    },
    {
      id: "email",
      label: "Email",
      searchable: true,
    },
    {
      id: "settings",
      label: "",
      component: ({ id }) => <Settings value={id} />,
    },
  ];
  // Customer Data
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>
        Client Manager
      </Typography>
      <Stack spacing={2} sx={{ my: 1 }}>
        <Box>
          <FloatingFormButton
            title="Add new client"
            open={open}
            setOpen={setOpen}
          >
            <DialogTitle>Add new client</DialogTitle>
            <AddClient
              onComplete={() => {
                setOpen(false);
                loadFiltered();
              }}
            />
          </FloatingFormButton>
        </Box>
      </Stack>

      <UniversalTable
        headers={headers}
        loading={loading}
        lazyloading={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
        setLoading={loadFiltered}
        data={data}
        name={"Clients"}
      />

      <Outlet context={{ loadFiltered, origin: "/clientManager" }} />
    </>
  );
}
