import React from "react";
import {
  Typography,
  Tooltip,
  IconButton,
  Link,
  Collapse,
  Slide,
  Alert,
  AlertTitle,
} from "@mui/material";
import {
  getStoreItems,
  getStorePages,
  getStoreItemsHistory,
  getStorePagesHistory,
  createStore,
  getStoreReport,
} from "../API/Cheques&Pos";

import UniversalTable from "../../Components/common/UniversalTable";

import {
  LoadingProvider,
  LoadingContext,
} from "../Components/common/LoadingProvider";

import StoreForm from "../Components/Forms/StoreForm";
import FloatingFormButton from "../../Components/common/FloatingFormButton";
import { Book, BookmarkAdd, MoreVert } from "@mui/icons-material";

import { Outlet, Link as Rlink } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

export default function Main() {
  return (
    <>
      <LoadingProvider
        getFilterData={() => ({ currentPage: 1 })}
        getData={getStoreItems}
        getPages={getStorePages}
      >
        <StoreItemsTable />
      </LoadingProvider>
      <LoadingProvider
        getFilterData={() => ({ currentPage: 1 })}
        getData={getStoreItemsHistory}
        getPages={getStorePagesHistory}
        initialLoading={false}
      >
        <StoreHistory />
      </LoadingProvider>
    </>
  );
}

function StoreItemsTable() {
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loadFiltered,
    loading,
    data,
  } = React.useContext(LoadingContext);

  const [openCreate, setOpenCreate] = React.useState(false);

  // Table components
  const Settings = ({ value }) => (
    <Tooltip title="Manage Item">
      <IconButton
        aria-label="settings"
        component={Rlink}
        to={`edit?item=${value.id}`}
        sx={{ float: "right" }}
      >
        <MoreVert />
      </IconButton>
    </Tooltip>
  );

  const reserve = [
    {
      id: "name",
      label: "Client",
    },
    {
      id: "quantity",
      label: "Quantity",
    },
    {
      id: "aquired",
      label: "Aquired",
    },
  ];

  const headers = [
    {
      id: "reserveList",
      subRow: true,
      label: "",
      iconColor: "info",
      headers: reserve,
      subTitle: "Reserve List",
      openIcon: <BookmarkAdd />,
    },
    {
      id: "partNumber",
      label: "Part Number",
      searchable: true,
    },
    {
      id: "description",
      label: "Description",
      searchable: true,
    },
    {
      id: "quantity",
      label: "Quantity",
      searchable: true,
    },
    {
      id: "total",
      label: "Cost",
      searchable: true,
    },
    {
      id: "settings",
      label: "",
      component: (value) => <Settings value={value} />,
    },
  ];

  const [genReport, setGenReport] = React.useState(false);

  const handleGenReport = () => {
    setGenReport(true);
    getStoreReport()
      .then((res) => {
        if (res.status === 200)
          toast.success("Report generated, download should start shortly.");
      })
      .finally(() => setGenReport(false));
  };

  const [changed, setChanged] = React.useState(false);

  const handleChanged = () => {
    setChanged(true);
  };

  const handleChangeReload = () => {
    setChanged(false);
    loadFiltered();
  };

  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>
        Store Manager
      </Typography>

      <LoadingButton
        onClick={handleGenReport}
        color="info"
        variant="outlined"
        startIcon={<Book />}
        loading={genReport}
      >
        Get report
      </LoadingButton>

      <FloatingFormButton
        title="Add Item"
        open={openCreate}
        setOpen={setOpenCreate}
      >
        <StoreForm
          title={"Create"}
          onComplete={() => {
            setOpenCreate(false);
            loadFiltered();
          }}
          onSubmit={createStore}
        />
      </FloatingFormButton>

      <UniversalTable
        headers={headers}
        loading={loading}
        lazyloading={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
        setLoading={loadFiltered}
        data={data}
        name={"Store items"}
      />

      <Slide direction="up" in={changed}>
        <Alert
          severity="info"
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            mr: 2,
            mb: 2,
            zIndex: 1301,
          }}
        >
          <AlertTitle>Changes Made</AlertTitle>
          Would you like to reload?{" "}
          <LoadingButton onClick={handleChangeReload} loading={loading}>
            Reload
          </LoadingButton>
        </Alert>
      </Slide>

      <Outlet context={{ handleChanged, origin: "/store" }} />
    </>
  );
}

function StoreHistory() {
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loading,
    loadFiltered,
    data,
  } = React.useContext(LoadingContext);

  const [showHistory, setShowHistory] = React.useState(false);

  const handleOpen = () => {
    setShowHistory((p) => !p);
    if (!showHistory) loadFiltered();
  };

  const headers = [
    {
      id: "poNumberID",
      label: "PO Number",
    },
    {
      id: "partNumber",
      label: "Part Number",
      searchable: true,
    },
    {
      id: "description",
      label: "Description",
      searchable: true,
    },
    {
      id: "quantity",
      label: "Quantity",
      searchable: true,
    },
    {
      id: "total",
      label: "Cost",
      searchable: true,
    },
  ];

  return (
    <>
      <Link onClick={handleOpen}>
        {showHistory ? "Hide" : "Show"} store item history
      </Link>
      <Collapse in={showHistory}>
        <UniversalTable
          headers={headers}
          loading={loading}
          lazyloading={loadingIndicator}
          currentPage={currentPage}
          totalPages={totalPages}
          setLoading={loadFiltered}
          data={data}
          name={"Store Item History"}
        />
      </Collapse>
    </>
  );
}
