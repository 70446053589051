import React from "react";

import UniversalTable from "../../Components/common/UniversalTable";
import { dateRange } from "../Components/common/FilterUtility";
import FilterArea from "../../Components/common/FilterArea";
import FloatingFormButton from "../../Components/common/FloatingFormButton";

import { chequeReport, getSuppliers } from "../API/Cheques&Pos";

import {
  Typography,
  Tooltip,
  IconButton,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
  AlertTitle,
  Collapse,
  Paper,
  CardActionArea,
  Box,
  Stack,
  TextField,
  Slide,
} from "@mui/material";

import {
  LoadingProvider,
  LoadingContext,
} from "../Components/common/LoadingProvider";

import { getChequePages, getCheques } from "../API/Cheques&Pos";
import { Outlet, Link } from "react-router-dom";
import {
  Assessment,
  Book,
  Download,
  EventBusy,
  ExpandMore,
  MoreVert,
  Warning,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import DateTimePicker from "../Components/common/DateTimePicker";
import { toast } from "react-toastify";
import BatchEditCheques from "../Components/Forms/BatchEditCheques";

export default function Main() {
  // advanced search
  const [supplier, setSupplier] = React.useState(""),
    [date, setDate] = React.useState("");

  //filter function
  const getFilterData = (filter) => {
    const from = new Date(date[1]);
    const to = new Date(date[2]);

    const data = filter
      ? {
          supplier,
          from:
            date[0] === "less" || date[0] === ""
              ? null
              : from.toLocaleString("en-GB", { timeZone: "UTC" }),
          to:
            date[0] === "greater" || date[0] === ""
              ? null
              : to.toLocaleString("en-GB", { timeZone: "UTC" }),
          currentPage: 1,
        }
      : {
          supplier: null,
          from: null,
          to: null,
          currentPage: 1,
        };
    return data;
  };
  return (
    <LoadingProvider
      getFilterData={getFilterData}
      getData={getCheques}
      getPages={getChequePages}
    >
      <LoadingWrapper
        setSupplier={setSupplier}
        supplier={supplier}
        setDate={setDate}
        date={date}
      />
    </LoadingProvider>
  );
}

function LoadingWrapper({ supplier, setSupplier, date, setDate }) {
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loading,
    data,
    gotAll,
    allData,
    setData,
    loadFiltered,
  } = React.useContext(LoadingContext);

  const [clear, setClear] = React.useState(false);

  // advanced search functions

  const handleAdvancedSearch = () => {
    if (gotAll) {
      const filtered = allData.filter(
        (data) =>
          [data.supplier.toLowerCase(), ""].includes(supplier.toLowerCase()) &&
          dateRange(date[1], date[2], data.dateWritten)
      );
      setData(filtered);
      return;
    }
    loadFiltered();
  };

  const clearAdvancedSearch = () => {
    setSupplier("");
    setDate(["", null, null]);
    setClear(true);
  };

  const [table, setTable] = React.useState("cheque");

  // Table components
  const ChipStatus = ({ status }) => {
    const colors = {
      waiting: "error",
      received: "success",
      banked: "warning",
      used: "success",
      cancelled: "warning",
    };
    return <Chip label={status} color={colors[status]} />;
  };

  function Settings({ values }) {
    return (
      <Tooltip title="Change Status">
        <IconButton
          aria-label="settings"
          component={Link}
          to={`edit?cur=${values.currency}&bank=${encodeURIComponent(
            values.bank
          )}&number=${values.chequeNumber}`}
          sx={{ float: "right" }}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
    );
  }
  const chequeInfo = [
    {
      id: "po",
      label: "Purchase Order",
      searchable: true,
    },
    {
      id: "dateSigned",
      label: "Date Signed",
      searchable: true,
      date: true,
    },
  ];

  const headers = [
    {
      id: "info",
      subRow: true,
      label: "",
      iconColor: "success",
      headers: chequeInfo,
      subTitle: "More info",
      openIcon: <Book />,
    },
    {
      id: "chequeNumber",
      label: "Cheque Number",
      searchable: true,
    },
    {
      id: "bank",
      label: "Bank",
      searchable: true,
    },
    {
      id: "po",
      label: "Purchase Order",
      searchable: true,
    },
    {
      id: "supplier",
      label: "Suppliers",
      searchable: true,
    },
    {
      id: "currencyAmount",
      label: "Amount",
      searchable: true,
    },
    {
      id: "status",
      label: "Status",
      component: (values) => <ChipStatus status={values.status} />,
    },
    {
      id: "dateWritten",
      label: "Dated",
      searchable: true,
      component: (values) => (
        <Chip
          color="info"
          icon={values.dateSame && <EventBusy />}
          label={values.dateWritten}
        />
      ),
    },
    {
      id: "dateSigned",
      label: "Date Signed",
      searchable: true,
      date: true,
    },
    {
      id: "settings",
      label: "",
      component: (values) => <Settings values={values} />,
    },
  ];

  const receivedHeaders = [
    {
      id: "info",
      subRow: true,
      label: "",
      iconColor: "success",
      headers: chequeInfo,
      subTitle: "More Info",
    },
    {
      id: "chequeNumber",
      label: "Cheque Number",
      searchable: true,
    },
    {
      id: "bank",
      label: "Bank",
      searchable: true,
    },
    {
      id: "po",
      label: "Purchase Order",
      searchable: true,
    },
    {
      id: "supplier",
      label: "Suppliers",
      searchable: true,
    },
    {
      id: "currencyAmount",
      label: "Amount",
      searchable: true,
    },
    {
      id: "receivedStatus",
      label: "Recieved",
      searchable: true,
      component: (values) => <ChipStatus status={values.receivedStatus} />,
    },
    {
      id: "dateWritten",
      label: "Dated",
      searchable: true,
      component: (values) => <Chip label={values.dateWritten} />,
    },
    {
      id: "dateSigned",
      label: "Date Signed",
      searchable: true,
      date: true,
    },
    {
      id: "settings",
      label: "",
      component: (values) => <Settings values={values} />,
    },
  ];

  const handleTableChange = (event, newTable) => {
    if (newTable !== null) setTable(newTable);
  };

  const [cancelledData, setCancelledData] = React.useState(data);

  React.useEffect(() => {
    setCancelledData(() => {
      let dataToUse = data.filter((row) => row.status === "cancelled");
      dataToUse.sort((a, b) => {
        return a.receivedStatus < b.receivedStatus ? 1 : -1;
      });
      return dataToUse;
    });
  }, [data]);

  const [dateAlert, setDateAlert] = React.useState(false);

  React.useEffect(() => {
    if (
      !localStorage.getItem("dateAlert") ||
      localStorage.getItem("dateAlert") === "true"
    ) {
      setDateAlert(true);
      localStorage.setItem("dateAlert", true);
    } else setDateAlert(false);
  }, [dateAlert]);

  const [reportAreaOpen, setReportAreaOpen] = React.useState(false);

  const [changed, setChanged] = React.useState(false);

  const handleChanged = () => {
    setChanged(true);
  };

  const handleChangeReload = () => {
    setChanged(false);
    loadFiltered();
  };

  const [batchOpen, setBatchOpen] = React.useState(false);

  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>
        Cheques Manager
      </Typography>

      <Collapse in={dateAlert}>
        <Alert
          severity="info"
          onClose={() => {
            setDateAlert(false);
            localStorage.setItem("dateAlert", false);
          }}
        >
          <AlertTitle>Cheque Dates</AlertTitle>
          Any cheques signed before 27/09/2023 will not have an accurate `Date
          Written`/`Dated` value.
        </Alert>
      </Collapse>

      <FloatingFormButton
        title="Batch Edit"
        open={batchOpen}
        setOpen={setBatchOpen}
        DialogFullScreen
      >
        <BatchEditCheques />
      </FloatingFormButton>

      <Paper variant="outlined" sx={{ my: 2 }}>
        <Paper
          elevation={1}
          sx={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <CardActionArea
            onClick={() => setReportAreaOpen(!reportAreaOpen)}
            sx={{ p: 2 }}
          >
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Stack direction={"row"} spacing={1} alignItems="center">
                <Assessment color="info" />
                <Typography variant="h6" color="info">
                  Cheque Report
                </Typography>
              </Stack>
              <ExpandMore
                sx={{
                  transform: reportAreaOpen ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "0.3s",
                }}
              />
            </Stack>
          </CardActionArea>
        </Paper>
        <Collapse in={reportAreaOpen}>
          <GenerateReport />
        </Collapse>
      </Paper>

      <FilterArea
        categories={[
          {
            label: "Supplier",
            type: "combo",
            value: supplier,
            setValue: setSupplier,
            getData: getSuppliers,
            optionLabel: "name",
            valueLabel: "name",
            clear,
            setClear,
          },

          {
            label: "Date Written",
            type: "date",
            options: [],
            value: date,
            setValue: setDate,
          },
        ]}
        startFilter={handleAdvancedSearch}
        clearFilter={clearAdvancedSearch}
        isLoading={loading}
      />

      <ToggleButtonGroup
        color={"primary"}
        value={table}
        exclusive
        onChange={handleTableChange}
        sx={{ mt: 2 }}
      >
        <ToggleButton value="cheque">All cheques</ToggleButton>
        <ToggleButton value="received">Cancelled Cheques</ToggleButton>
      </ToggleButtonGroup>

      {table === "cheque" && (
        <UniversalTable
          headers={headers}
          loading={loading}
          lazyloading={loadingIndicator}
          currentPage={currentPage}
          totalPages={totalPages}
          setLoading={loadFiltered}
          data={data}
          name={"All Cheques"}
        />
      )}
      {table === "received" && (
        <UniversalTable
          headers={receivedHeaders}
          loading={loading}
          lazyloading={loadingIndicator}
          currentPage={currentPage}
          totalPages={totalPages}
          setLoading={loadFiltered}
          data={cancelledData}
          name={"Cancelled Cheques"}
        />
      )}

      <Slide direction="up" in={changed}>
        <Alert
          severity="info"
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            mr: 10,
            mb: 2,
          }}
        >
          <AlertTitle>Changes Made</AlertTitle>
          Would you like to reload?{" "}
          <LoadingButton onClick={handleChangeReload} loading={loading}>
            Reload
          </LoadingButton>
        </Alert>
      </Slide>

      <Outlet context={{ handleChanged, origin: "/cheques" }} />
    </>
  );
}

function GenerateReport() {
  const [currency, setCurrency] = React.useState("ALL");
  const [useDate, setUseDate] = React.useState(false);
  const [startDate, setStartDate] = React.useState(undefined);
  const [endDate, setEndDate] = React.useState(undefined);
  const [dateBetween, setDateBetween] = React.useState(false);
  const [dateType, setDateType] = React.useState("dateWritten");
  const [sequential, setSequential] = React.useState(true);
  const [useChequeNumber, setUseChequeNumber] = React.useState(false);
  const [startChequeNumber, setStartChequeNumber] = React.useState(undefined);
  const [endChequeNumber, setEndChequeNumber] = React.useState(undefined);
  const [chequeNumberBetween, setChequeNumberBetween] = React.useState(false);
  const [banks, setBanks] = React.useState(["I&M"]);
  const [gettingReport, setGettingReport] = React.useState(false);

  const collapseRef = React.useRef(null);
  const collapseRef2 = React.useRef(null);

  React.useEffect(() => {
    if (useDate) collapseRef.current.style.width = "auto";
    else collapseRef.current.style.width = "0px";
  }, [useDate]);

  React.useEffect(() => {
    if (useChequeNumber) collapseRef2.current.style.width = "auto";
    else collapseRef2.current.style.width = "0px";
  }, [useChequeNumber]);

  const handelDownload = () => {
    let data = {
      currency,
      useDate,
      startDate: `${startDate.getDate()}/${
        startDate.getMonth() + 1
      }/${startDate.getFullYear()}`,
      endDate: `${endDate.getDate()}/${
        endDate.getMonth() + 1
      }/${endDate.getFullYear()}`,
      dateBetween,
      dateType,
      useChequeNumber,
      startChequeNumber,
      endChequeNumber,
      chequeNumberBetween,
      sequential,
      banks,
    };
    setGettingReport(true);
    chequeReport(data)
      .then((res) => {
        if (res.status === 200) toast.success("Report Generated");
      })
      .catch((err) => {
        toast.error("Error Generating Report");
      })
      .finally(() => {
        setGettingReport(false);
      });
  };

  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    let disabledVal = true;
    if (useDate) {
      if (dateBetween) {
        if (startDate && endDate) disabledVal = false;
      } else if (startDate) disabledVal = false;
    } else if (useChequeNumber) {
      if (chequeNumberBetween) {
        if (startChequeNumber && endChequeNumber) disabledVal = false;
      } else if (startChequeNumber) disabledVal = false;
    } else disabledVal = false;

    if (banks.length === 0) disabledVal = true;

    if (disabledVal) setDisabled(true);
    else setDisabled(false);
  }, [
    useDate,
    startDate,
    endDate,
    dateBetween,
    useChequeNumber,
    startChequeNumber,
    endChequeNumber,
    chequeNumberBetween,
    banks,
  ]);

  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="column" spacing={2} alignItems="flex-start">
        <ToggleButtonGroup
          value={banks}
          onChange={(e, newType) => {
            setBanks(newType);
          }}
          color="success"
        >
          <ToggleButton value="I&M">I&M</ToggleButton>
          <ToggleButton value="NCBA">NCBA</ToggleButton>
          <ToggleButton value="Paramount">Paramount</ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          value={currency}
          color="info"
          exclusive
          onChange={(e, newType) => {
            if (newType !== null) setCurrency(newType);
            if (newType === "ALL") {
              setUseDate(false);
              setUseChequeNumber(false);
            }
          }}
        >
          <ToggleButton value="ALL">All</ToggleButton>
          <ToggleButton value="KES">Kenya Shillings (KSH)</ToggleButton>
          <ToggleButton value="USD">American Dollars ($)</ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          value={sequential}
          exclusive
          onChange={(e, newType) => {
            if (newType !== null) setSequential(newType);
          }}
        >
          <ToggleButton value={true} color="warning">
            <Warning sx={{ mr: 1 }} /> Sequential
          </ToggleButton>
          <ToggleButton value={false}>Non-Sequential</ToggleButton>
        </ToggleButtonGroup>
        <Collapse in={sequential}>
          <Typography variant="caption">
            Please note large{" "}
            {useDate ? "date" : useChequeNumber ? "cheque" : ""} ranges for
            sequential cheques may cause an error
          </Typography>
        </Collapse>
        <Paper
          variant="outlined"
          sx={{ width: "fit-conent", border: useChequeNumber && "none" }}
        >
          <Collapse in={!useChequeNumber}>
            <ToggleButtonGroup
              value={useDate}
              exclusive
              onChange={(e, newType) => {
                if (newType !== null) setUseDate(newType);
                if (newType === false) {
                  setStartDate(undefined);
                  setEndDate(undefined);
                } else {
                  setStartDate(new Date());
                  setEndDate(new Date());
                }
              }}
            >
              <ToggleButton value={false}>All Dates</ToggleButton>
              <ToggleButton value={true}>Date Range</ToggleButton>
            </ToggleButtonGroup>
            <Collapse
              in={useDate}
              ref={collapseRef}
              sx={{
                transition: (theme) => theme.transitions.duration.complex,
              }}
            >
              <Stack
                direction="column"
                spacing={2}
                alignItems="flex-start"
                sx={{ mt: 2 }}
              >
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={2}
                  alignItems="flex-start"
                >
                  <ToggleButtonGroup
                    value={dateType}
                    exclusive
                    onChange={(e, newType) => {
                      if (newType !== null) setDateType(newType);
                    }}
                  >
                    <ToggleButton value="dateWritten">
                      Date Written
                    </ToggleButton>
                    <ToggleButton value="dateSigned">Date Signed</ToggleButton>
                  </ToggleButtonGroup>
                  <ToggleButtonGroup
                    value={dateBetween}
                    exclusive
                    sx={{ mt: 2 }}
                    onChange={(e, newType) => {
                      if (newType !== null) setDateBetween(newType);
                    }}
                  >
                    <ToggleButton value={false}>Start from a date</ToggleButton>
                    <ToggleButton value={true}>Between two dates</ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
                <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                  <DateTimePicker
                    label="Start Date"
                    value={startDate}
                    setChange={setStartDate}
                  />
                  <Collapse in={dateBetween}>
                    <DateTimePicker
                      label="End Date"
                      value={endDate}
                      setChange={setEndDate}
                    />
                  </Collapse>
                </Stack>
              </Stack>
            </Collapse>
          </Collapse>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            width: "fit-conent",
            border:
              (currency === "ALL" || useDate || banks.length !== 1) && "none",
          }}
        >
          <Collapse in={currency !== "ALL" && !useDate && banks.length === 1}>
            <ToggleButtonGroup
              value={useChequeNumber}
              exclusive
              onChange={(e, newType) => {
                if (newType !== null) setUseChequeNumber(newType);
              }}
            >
              <ToggleButton value={false}>All Cheques</ToggleButton>
              <ToggleButton value={true}>Cheque Range</ToggleButton>
            </ToggleButtonGroup>
            <Collapse in={useChequeNumber} ref={collapseRef2}>
              <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                <ToggleButtonGroup
                  value={chequeNumberBetween}
                  exclusive
                  onChange={(e, newType) => {
                    if (newType !== null) setChequeNumberBetween(newType);
                  }}
                >
                  <ToggleButton value={false}>
                    Start from a cheque number
                  </ToggleButton>
                  <ToggleButton value={true}>
                    Between two cheque numbers
                  </ToggleButton>
                </ToggleButtonGroup>
                <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                  <TextField
                    label="Start Cheque Number"
                    value={startChequeNumber}
                    onChange={(e) => setStartChequeNumber(e.target.value)}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                  <Collapse in={chequeNumberBetween}>
                    <TextField
                      label="End Cheque Number"
                      value={endChequeNumber}
                      onChange={(e) => setEndChequeNumber(e.target.value)}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </Collapse>
                </Stack>
              </Stack>
            </Collapse>
          </Collapse>
        </Paper>
        <LoadingButton
          startIcon={<Download />}
          variant="outlined"
          color="info"
          loading={gettingReport}
          onClick={handelDownload}
          disabled={disabled}
          sx={{
            alignSelf: "flex-end",
          }}
        >
          Download Report
        </LoadingButton>
      </Stack>
    </Box>
  );
}
