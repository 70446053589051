import React from "react";

import { Box, TextField, Stack, Zoom, Button } from "@mui/material";

import { SessionManager } from "react-session.manager.sk";
import { changeNames } from "../../CustomAxios/Protected/AccountManagment";
import { toast } from "react-toastify";

export default function Main(props) {
  const { setRefreshData, userInfo } = React.useContext(SessionManager),
    [firstName, setFirstName] = React.useState(userInfo?.firstName || ""),
    [lastName, setLastName] = React.useState(userInfo?.lastName || ""),
    [fname, setFname] = React.useState(""),
    [lname, setLname] = React.useState(""),
    [show, setShow] = React.useState(false),
    [reset, setReset] = React.useState(false);

  React.useEffect(() => {
    setFirstName(userInfo?.firstName);
    setLastName(userInfo?.lastName);
    setFname(userInfo?.firstName);
    setLname(userInfo?.lastName);
  }, [userInfo]);

  React.useEffect(() => {
    if ((fname !== firstName || lname !== lastName) && fname && lname)
      setShow(true);
    else setShow(false);
    if (fname !== firstName || lname !== lastName) setReset(true);
    else setReset(false);
  }, [fname, lname, firstName, lastName]);

  const handleReset = () => {
    setFname(userInfo?.firstName);
    setLname(userInfo?.lastName);
  };

  const handleNameChange = () => {
    changeNames({ first_name: fname, last_name: lname }).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        setRefreshData(true);
      } else toast.error(res.message);
    });
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <TextField
            label="First Name"
            value={fname}
            onChange={(e) => setFname(e.target.value)}
          />
          <TextField
            label="Last Name"
            value={lname}
            onChange={(e) => setLname(e.target.value)}
          />
        </Stack>
        <Zoom in={reset}>
          <Button variant="outlined" color="secondary" onClick={handleReset}>
            Reset
          </Button>
        </Zoom>
        <Zoom in={show}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNameChange}
          >
            Save Changes
          </Button>
        </Zoom>
      </Stack>
    </Box>
  );
}
