import React from "react";

import {
  getConfirmationErrors,
  getConfirmationPages,
  resolveConfirmationError,
} from "../../API/Cheques&Pos";

import UniversalTable from "../../../Components/common/UniversalTable";

import { Collapse, Chip, Link } from "@mui/material";

import { toast } from "react-toastify";

import { LoadingProvider, LoadingContext } from "./LoadingProvider";
import { LoadingButton } from "@mui/lab";

export default function Main() {
  return (
    <LoadingProvider
      getFilterData={() => ({ currentPage: 1 })}
      getData={getConfirmationErrors}
      getPages={getConfirmationPages}
    >
      <ConfirmationErrors />
    </LoadingProvider>
  );
}

function ConfirmationErrors() {
  //context data
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loading,
    loadFiltered,
    data,
  } = React.useContext(LoadingContext);

  // table components
  const ResolveButton = ({ id, title, status, color }) => {
    const [editing, setEditing] = React.useState(false);
    const updateError = () => {
      setEditing(true);
      resolveConfirmationError({ id, status })
        .then((res) => {
          setEditing(false);
          toast.success(res);
          loadFiltered();
        })
        .catch((err) => {
          setEditing(false);
          toast.error(
            "Something went wrong, contact an administartor if the problem persists"
          );
        });
    };
    return (
      <LoadingButton
        loading={editing}
        variant="outlined"
        onClick={updateError}
        color={color}
      >
        {title}
      </LoadingButton>
    );
  };

  const ChipStatus = ({ status }) => {
    const colors = {
      accept: "success",
      reject: "warning",
    };
    return <Chip label={status.toUpperCase()} color={colors[status]} />;
  };

  // Table headers
  const orderInfo = [
    {
      id: "id",
      label: "Order ID",
      searchable: true,
    },
    {
      id: "company",
      label: "Company",
      searchable: true,
    },
    {
      id: "manager",
      label: "Manager",
      searchable: true,
    },
    {
      id: "date",
      label: "Date",
      searchable: true,
    },
  ];

  const headers = [
    {
      id: "orderInfo",
      subRow: true,
      label: "",
      iconColor: "info",
      headers: orderInfo,
      subTitle: "Previous Order",
    },
    {
      id: "conflict",
      label: "Order",
      searchable: true,
    },

    {
      id: "manager",
      label: "Manager",
      searchable: true,
    },
    {
      id: "client",
      label: "Company",
      searchable: true,
    },
    {
      id: "type",
      label: "Type",
      searchable: true,
    },
    {
      id: "detail",
      label: "Detail",
      searchable: true,
    },
    {
      id: "date",
      label: "Date",
      searchable: true,
    },
    {
      id: "accept",
      label: "",
      component: (values) => (
        <ResolveButton
          id={values.id}
          title="Ignore"
          status="accept"
          color="info"
        />
      ),
    },
    {
      id: "reject",
      label: "",
      component: (values) => (
        <ResolveButton
          id={values.id}
          title="flag order"
          status="reject"
          color="warning"
        />
      ),
    },
  ];
  const resolvedHeaders = [
    {
      id: "orderInfo",
      subRow: true,
      label: "",
      iconColor: "info",
      headers: orderInfo,
      subTitle: "Previous Order",
    },
    {
      id: "conflict",
      label: "Order",
      searchable: true,
    },
    {
      id: "manager",
      label: "Manager",
      searchable: true,
    },
    {
      id: "client",
      label: "Amount",
      searchable: true,
    },
    {
      id: "type",
      label: "Type",
      searchable: true,
    },
    {
      id: "detail",
      label: "Detail",
      searchable: true,
    },
    {
      id: "date",
      label: "Date",
      searchable: true,
    },
    {
      id: "status",
      label: "Resolution",
      searchable: true,
      component: (values) => <ChipStatus status={values.status} />,
    },
  ];

  // Handle Table view
  const [showHistory, setShowHistory] = React.useState(false);

  const errList = data.filter((data) => data.status === "waiting");

  return (
    <>
      <Collapse in={errList.length > 0}>
        <UniversalTable
          headers={headers}
          loading={loading}
          lazyloading={loadingIndicator}
          currentPage={currentPage}
          totalPages={totalPages}
          setLoading={loadFiltered}
          data={errList}
          name={"Order Confirmation Errors"}
        />
      </Collapse>

      <Link
        onClick={() => setShowHistory((p) => !p)}
        sx={{ cursor: "pointer" }}
      >
        {showHistory ? "Hide" : "Show"} order confirmation error history
      </Link>

      <Collapse in={showHistory}>
        <UniversalTable
          headers={resolvedHeaders}
          loading={loading}
          setLoading={loadFiltered}
          data={data.filter((data) => data.status !== "waiting")}
          name={"Order confirmation error history"}
        />
      </Collapse>
    </>
  );
}
