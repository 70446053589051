import React from "react";
import { Avatar, Paper, Container, Typography } from "@mui/material";
import { Button, Dialog, DialogTitle, Chip } from "@mui/material";
import { Box } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import { SessionManager } from "react-session.manager.sk";
import ChangeNameForm from "../../Components/Forms/ChangeName";
import ChangePhoneNumberForm from "../../Components/Forms/ChangePhoneNumber";
import ChangePasswordForm from "../../Components/Forms/ChangePass";
import ChangeTitlleForm from "../../Components/Forms/ChangeTitle";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ChangePassword() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Change Password
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="ChangePass-Form"
        PaperProps={{
          elevation: 0,
        }}
      >
        <DialogTitle id="Login-Form">Change Password</DialogTitle>
        <ChangePasswordForm handleClose={handleClose} />
      </Dialog>
    </>
  );
}

function AccItem(props) {
  const [expanded, setExpanded] = React.useState(false);
  if (props.noExpand)
    return (
      <Accordion disableGutters={true}>
        <AccordionSummary style={{ cursor: "default" }}>
          <Typography sx={{ width: "33%", flexShrink: 0 }} color="secondary">
            {props.name}
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            {props.description}
          </Typography>
        </AccordionSummary>
      </Accordion>
    );
  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      disableGutters={true}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: "33%", flexShrink: 0 }} color="secondary">
          {props.name}
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          {props.description}
        </Typography>
      </AccordionSummary>
      <Paper elevation={0}>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Paper>
    </Accordion>
  );
}

export default function Main(props) {
  const { userInfo } = React.useContext(SessionManager);

  const fname = userInfo?.firstName;
  const lname = userInfo?.lastName;
  const email = userInfo?.email;
  const phone = userInfo?.phone;
  const title = userInfo?.title;
  const roles = userInfo?.roles;

  const initials = `${userInfo?.firstName?.charAt(
    0
  )}${userInfo?.lastName?.charAt(0)}`;

  return (
    <Paper sx={{ padding: 1 }}>
      <Typography variant="h4" color="primary" sx={{ mb: 1 }}>
        Profile
      </Typography>
      <Container
        sx={{ display: { xs: "flex", md: "block" }, justifyContent: "center" }}
      >
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 1,
            width: "fit-content",
            mx: "auto",
          }}
        >
          <Box
            component={Avatar}
            sx={{
              width: 80,
              height: 80,
              m: 2,
              fontSize: 40,
              bgcolor: "primary.main",
            }}
          >
            {initials}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
              m: 3,
            }}
          >
            <Typography variant="body1">{email}</Typography>
            <Typography variant="body1" mb={1}>
              {fname} {lname}
            </Typography>
            <ChangePassword />
          </Box>
        </Box>
      </Container>
      <Paper elevation={0} sx={{ p: 2, width: "fit-content", mt: 2, mx: 3 }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Roles
        </Typography>
        {roles?.length > 0 ? (
          roles.map((role) => (
            <Chip
              label={role}
              key={role}
              sx={{ mr: 1, mb: 1 }}
              color={"secondary"}
              variant="outlined"
            />
          ))
        ) : (
          <Button disabled>You do not have any roles</Button>
        )}
      </Paper>
      <Paper variant="outlined" sx={{ mt: 2 }}>
        <Typography variant="h6" color="secondary" m={1} p={1}>
          Basic Information
        </Typography>
        <AccItem name="Email" description={email} noExpand />
        <AccItem name="Name" description={`${fname} ${lname}`}>
          <ChangeNameForm />
        </AccItem>
        <AccItem name="Phone Number" description={phone}>
          <ChangePhoneNumberForm />
        </AccItem>
        <AccItem name="Job Title" description={title}>
          <ChangeTitlleForm />
        </AccItem>
      </Paper>
    </Paper>
  );
}
