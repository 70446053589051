import React from "react";
import { getItemReport } from "../../API/SalesAdmin";
import {
  Box,
  Button,
  CardActionArea,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import DateTimePicker from "./DateTimePicker";
import {
  ArrowUpward,
  CalendarMonth,
  CheckBox,
  CheckBoxOutlineBlank,
  CurrencyExchange,
  Delete,
  Search,
} from "@mui/icons-material";
import { getExchangeRate } from "../../API/Sales";
import NumberFormat from "../../../Components/common/NumberFormater";
import TagField from "./TagField";
import { toast } from "react-toastify";

export default function ItemReports() {
  const [loading, setLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState([]);

  const [useDate, setUseDate] = React.useState(false);
  const currentYear = new Date().getFullYear();
  const [startDate, setStartDate] = React.useState(
    `${new Date(currentYear, 0, 1)}`
  );
  const [endDate, setEndDate] = React.useState(
    `${new Date(currentYear + 1, 0, 1)}`
  );

  const [currency, setCurrency] = React.useState("KES");

  const [rate, setRate] = React.useState(0);

  const [items, setItems] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);

  const [areYouSure, setAreYouSure] = React.useState(false);
  const [ignoreMismatch, setIgnoreMismatch] = React.useState(true);

  React.useEffect(() => {
    getExchangeRate().then((res) => {
      const r1 = res.find((e) => e.name === "KES")?.rate;
      const r2 = res.find((e) => e.name === "USD")?.rate;

      const calc = r1 / r2;

      setRate(calc);
    });
  }, []);

  const handleSearch = () => {
    setLoading(true);
    getItemReport(searchTerm.join(), currency, rate)
      .then((res) => {
        if (res.status === "success") {
          setLoading(false);
          setItems(res.data);
        } else {
          setLoading(false);
          toast.error("An error occured while fetching data");
          setItems([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const removeItems = () => {
    let newItems = [...items];
    selectedItems.forEach((item) => {
      newItems = newItems.filter((i) => i.order.id !== item);
    });
    setItems(newItems);
    setSelectedItems([]);
    setAreYouSure(false);
  };

  const toggleSelected = (id) => {
    if (selectedItems.includes(id))
      setSelectedItems((prev) => prev.filter((item) => item !== id));
    else setSelectedItems((prev) => [...prev, id]);
  };

  const totalQty = React.useMemo(() => {
    const totalQty = items.reduce((a, b) => {
      const mainsQty = b.po
        .filter((po) => !po.secondary)
        .reduce((a, b) => a + b.quantity, 0);
      if (ignoreMismatch && mainsQty !== b.order.quantity) return a;
      return a + b.order.quantity;
    }, 0);
    return totalQty;
  }, [items, ignoreMismatch]);

  const averageSellingPrice = React.useMemo(() => {
    const average =
      items.reduce((a, b) => {
        const mainsQty = b.po
          .filter((po) => !po.secondary)
          .reduce((a, b) => a + b.quantity, 0);

        if (ignoreMismatch && mainsQty !== b.order.quantity) return a;

        if (b.order.currency === currency) return a + b.order.cost;
        if (b.order.currency === "USD") return a + b.order.cost * rate;
        return a + b.order.cost / rate;
      }, 0) / items.length;
    return average;
  }, [items, currency, rate, ignoreMismatch]);

  const averageBuyingPrice = React.useMemo(() => {
    const average =
      items.reduce((a, b) => {
        const mainsQty = b.po
          .filter((po) => !po.secondary)
          .reduce((a, b) => a + b.quantity, 0);

        if (ignoreMismatch && mainsQty !== b.order.quantity) return a;

        const mains = b.po.filter((po) => !po.secondary);
        const average =
          b.po.reduce((a, b) => {
            if (b.currency === currency) return a + b.cost * b.quantity;
            if (b.currency === "USD") return a + b.cost * b.quantity * rate;
            return a + (b.cost * b.quantity) / rate;
          }, 0) / mains.reduce((a, b) => a + b.quantity, 0);
        return a + average;
      }, 0) / items.length;
    return average;
  }, [items, currency, rate, ignoreMismatch]);

  const totalProfit = React.useMemo(() => {
    const totalProfit = items.reduce((a, b) => {
      const mainsQty = b.po
        .filter((po) => !po.secondary)
        .reduce((a, b) => a + b.quantity, 0);

      if (ignoreMismatch && mainsQty !== b.order.quantity) return a;

      const average =
        b.po.reduce((a, b) => {
          if (b.currency === currency) return a + b.cost * b.quantity;
          if (b.currency === "USD") return a + b.cost * b.quantity * rate;
          return a + (b.cost * b.quantity) / rate;
        }, 0) / mainsQty;

      const currencyCost = (() => {
        if (b.order.currency === currency) return b.order.cost;
        if (b.order.currency === "USD") return b.order.cost * rate;
        return b.order.cost / rate;
      })();
      return a + currencyCost - average;
    }, 0);
    return totalProfit;
  }, [items, currency, rate, ignoreMismatch]);

  const scrollAreaRef = React.useRef(null);
  const scrollToTopButton = React.useRef(null);

  const scrollToTop = () => {
    scrollAreaRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const checkScrollTop = () => {
    if (scrollAreaRef.current.scrollTop > 20) {
      scrollToTopButton.current.style.display = "block";
    } else scrollToTopButton.current.style.display = "none";
  };

  return (
    <Box sx={{ p: 2 }}>
      <Dialog open={areYouSure} onClose={() => setAreYouSure(false)}>
        <DialogTitle>
          Are you sure you want to delete all selected items?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setAreYouSure(false)}
            color="warning"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => removeItems()}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <form onSubmit={(e) => e.preventDefault()}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
          sx={{ my: 2 }}
        >
          <IconButton
            onClick={() => setUseDate((prev) => !prev)}
            color={useDate ? "info" : "inherit"}
          >
            <CalendarMonth />
          </IconButton>
          <IconButton
            sx={{
              aspectRatio: 1,
            }}
            onClick={() =>
              setCurrency((prev) => (prev === "KES" ? "USD" : "KES"))
            }
          >
            <Typography variant="body2">{currency}</Typography>
          </IconButton>
          <IconButton>
            <Stack alignItems="center">
              <CurrencyExchange />

              <Typography
                variant="overline"
                sx={{ position: "absolute", mt: 2.2 }}
              >
                {NumberFormat(rate.toFixed(2))}
              </Typography>
            </Stack>
          </IconButton>
          <IconButton
            sx={{
              aspectRatio: 1,
            }}
            onClick={() =>
              setIgnoreMismatch((prev) => (prev === true ? false : true))
            }
            color={ignoreMismatch ? "success" : "warning"}
          >
            <Typography
              variant="body2"
              sx={{
                ...(ignoreMismatch && {
                  textDecoration: "line-through",
                }),
              }}
            >
              QTY
            </Typography>
          </IconButton>
        </Stack>
        <Collapse in={useDate}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            sx={{ my: 2 }}
          >
            <DateTimePicker
              label={"Start Date"}
              value={startDate}
              setChange={setStartDate}
            />
            <DateTimePicker
              label={"End Date"}
              value={endDate}
              setChange={setEndDate}
            />
          </Stack>
        </Collapse>
        <TagField
          label={"Search"}
          value={searchTerm}
          setValue={setSearchTerm}
          TextFieldProps={{
            fullWidth: true,
            helperText:
              "Please separate each search term with a comma, eg. HP, Laptop, i7",
          }}
          seperator=","
        />
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{ my: 2 }}
        >
          <Fab
            variant="extended"
            onClick={handleSearch}
            type="submit"
            color="info"
            disabled={searchTerm.join().trim() === "" || loading}
          >
            <Zoom
              in={loading}
              timeout={{ appear: 500, enter: 200, exit: 200 }}
              sx={{ position: "absolute" }}
            >
              <CircularProgress size="2rem" />
            </Zoom>

            <Collapse
              in={!loading}
              timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
              sx={{ display: loading && "none" }}
              orientation="horizontal"
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Search />
                <Typography>Search</Typography>
              </Stack>
            </Collapse>
          </Fab>
        </Stack>
      </form>
      <Paper variant="outlined">
        <Paper sx={{ p: 2, borderRadius: 0 }}>
          <Collapse in={selectedItems.length > 0}>
            <Box>
              <Chip
                label={`Selected: ${selectedItems.length}`}
                variant="outlined"
                color="info"
                onDelete={() => setSelectedItems([])}
                sx={{ mr: 1 }}
              />
              <Tooltip title="Delete Selected">
                <IconButton onClick={() => setAreYouSure(true)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          </Collapse>
          <Box sx={{ pt: 1 }}>
            <Stack>
              <Typography variant="bobdy1">
                Total Quantity: {totalQty}
              </Typography>
              <Typography variant="bobdy1">
                Average Selling Price: {currency}{" "}
                {NumberFormat(averageSellingPrice.toFixed(2))}
              </Typography>
              <Typography variant="bobdy1">
                Average Buying Price: {currency}{" "}
                {NumberFormat(averageBuyingPrice.toFixed(2))}
              </Typography>
              <Typography variant="bobdy1">
                Total Profit: {currency} {NumberFormat(totalProfit.toFixed(2))}
              </Typography>
              {ignoreMismatch && (
                <Typography variant="overline">
                  (Mismatched Items not included in calculations)
                </Typography>
              )}
            </Stack>
          </Box>
        </Paper>
        <Box
          sx={{ px: 0.5, overflowY: "scroll", maxHeight: "65vh" }}
          ref={scrollAreaRef}
          onScroll={checkScrollTop}
        >
          {items.length > 0 && (
            <Stack
              direction="column"
              spacing={2}
              justifyContent="center"
              sx={{ my: 1 }}
            >
              {items.map((item, i) => (
                <ItemDisplay
                  key={`${new Date()}_${i}`}
                  item={item}
                  currency={currency}
                  rate={rate}
                  selectedItems={selectedItems}
                  toggleSelected={() => toggleSelected(item.order.id)}
                />
              ))}
            </Stack>
          )}
        </Box>
        <Paper
          sx={{
            p: 1,
            borderRadius: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Typography variant="overline">
            Total Items: {items.length}
          </Typography>

          <Tooltip title="Scroll to Top">
            <Fab
              size="small"
              onClick={scrollToTop}
              ref={scrollToTopButton}
              sx={{
                ml: 2,
              }}
            >
              <ArrowUpward />
            </Fab>
          </Tooltip>
        </Paper>
      </Paper>
    </Box>
  );
}

function ItemDisplay({ item, currency, rate, selectedItems, toggleSelected }) {
  const selected = selectedItems.includes(item.order.id);
  const [openPO, setOpenPO] = React.useState(false);

  const mainCount = React.useMemo(() => {
    const mains = item.po.filter((po) => !po.secondary);
    return mains.reduce((a, b) => a + b.quantity, 0);
  }, [item]);

  const poAverage = React.useMemo(() => {
    const average =
      item.po.reduce((a, b) => {
        if (b.currency === currency) return a + b.cost * b.quantity;
        if (b.currency === "USD") return a + b.cost * b.quantity * rate;
        return a + (b.cost * b.quantity) / rate;
      }, 0) / mainCount;
    return average;
  }, [item, currency, rate, mainCount]);

  const currencyCost = React.useMemo(() => {
    if (item.order.currency === currency) return item.order.cost;
    if (item.order.currency === "USD") return item.order.cost * rate;
    return item.order.cost / rate;
  }, [item, currency, rate]);

  return (
    <Paper
      sx={{
        position: "relative",
        ...(selected && {
          border: (theme) => `1px solid ${theme.palette.info.main}`,
          boxShadow: (theme) => theme.shadows[4],
        }),
      }}
    >
      <SvgIcon
        color="info"
        onClick={toggleSelected}
        sx={{
          position: "absolute",
          top: 20,
          left: 8,
        }}
      >
        {selected ? <CheckBox /> : <CheckBoxOutlineBlank />}
      </SvgIcon>

      <CardActionArea
        onClick={toggleSelected}
        sx={{
          borderRadius: 0,
          ...(selected && {
            backgroundColor: (theme) => theme.palette.info.main,
          }),
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 2 }}
        >
          <Box>
            <Typography
              variant="h6"
              sx={{
                pl: 3,
              }}
            >
              {item.order.partnumber}
            </Typography>
            <Typography variant="overline">
              {new Date(item.order.date).toLocaleDateString("en-GB")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              direction: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" mr={1}>
              QTY: {item.order.quantity}
            </Typography>
            {item.order.quantity !== mainCount && (
              <Chip label="MISMATCH" color="error" size="small" />
            )}
          </Box>
        </Stack>
      </CardActionArea>
      <Divider />
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={0}>
            <Typography variant="overline">
              {item.order.client} |{" "}
              <Button
                component="a"
                href={`/adminOrderManager/overview?i=${item.order.id}`}
                target="_blank"
                color="info"
              >
                {item.order.id}
              </Button>
            </Typography>
            <Typography variant="overline">{item.order.description}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <CardActionArea onClick={() => setOpenPO((prev) => !prev)}>
            {item.po.map((po, index) => (
              <Collapse
                in={openPO}
                key={`${new Date()}_${index}`}
                collapsedSize={25}
                timeout={500}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    mb: 0.5,
                    ...(po.secondary && {
                      borderLeft: (theme) =>
                        `4px solid ${theme.palette.info.main}`,
                      ml: 2,
                    }),
                  }}
                >
                  <Stack direction="column" spacing={0} sx={{ p: 1 }}>
                    <Typography>
                      {po.partnumber}{" "}
                      <Typography variant="overline">
                        | QTY: {po.quantity}
                      </Typography>
                    </Typography>

                    <Stack direction={"row"} spacing={1} alignItems="center">
                      <Button
                        component="a"
                        href={`/Pos/edit?pos=${po.id}`}
                        target="_blank"
                        color="info"
                      >
                        {po.id}
                      </Button>
                      <Typography variant="overline">{po.supplier}</Typography>
                    </Stack>

                    <Typography variant="overline">{po.description}</Typography>
                    <Divider />
                    <Typography variant="overline">
                      {po.currency} {NumberFormat(po.cost.toFixed(2))}
                    </Typography>
                  </Stack>
                </Paper>
              </Collapse>
            ))}
          </CardActionArea>
        </Grid>
      </Grid>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Chip
              label={`Selling @ ${item.order.currency}
              ${NumberFormat(item.order.cost.toFixed(2))}`}
              color="success"
              sx={{ mr: 1 }}
            />
            <Chip
              label={`Buying @ ${currency} ${NumberFormat(
                poAverage.toFixed(2)
              )}`}
              color="info"
            />
          </Box>
          <Typography variant="subtitle">
            Profit Per: {currency}{" "}
            {NumberFormat((currencyCost - poAverage).toFixed(2))}
          </Typography>
        </Stack>
      </Box>
    </Paper>
  );
}
