import React from "react";
import { useTheme } from "@emotion/react";
import { TextField, useMediaQuery } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { enGB } from "date-fns/locale";

export default function DateTimePicker({ label, value, setChange }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (newValue) => {
    setChange(newValue);
  };

  const [dateVal, setDateVal] = React.useState(
    typeof value === "string" ? Date.parse(value) : value
  );

  React.useEffect(() => {
    if (typeof value === "string") {
      let newD = Date.parse(value);
      setDateVal(newD);
    }
  }, [value, setChange]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      {isMobile ? (
        <MobileDatePicker
          label={label}
          inputFormat="dd/MM/yyyy"
          value={dateVal}
          onChange={handleChange}
          textField={(params) => <TextField {...params} />}
        />
      ) : (
        <DesktopDatePicker
          label={label}
          inputFormat="dd/MM/yyyy"
          value={dateVal}
          onChange={handleChange}
          textField={(params) => <TextField {...params} />}
        />
      )}
    </LocalizationProvider>
  );
}
