import React from "react";
import { Box, Grid, Paper, Stack, Tooltip, Typography } from "@mui/material";

import { getAccountManagerPerformance } from "../../API/SalesAdmin";
import DateTimePicker from "./DateTimePicker";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

export default function PerformanceReports({ accManager }) {
  const [fromDate, setFromDate] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  );
  const [toDate, setToDate] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 0)
  );

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (accManager) {
      getAccountManagerPerformance({
        accManagerID: accManager.id,
        startDate: fromDate,
        endDate: toDate,
      }).then((data) => {
        setData(data);
      });
    }
  }, [accManager, fromDate, toDate]);

  return (
    <Box p={2}>
      <Typography variant="h5" gutterBottom>
        Performance Report
      </Typography>
      <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
        <DateTimePicker label="From" value={fromDate} setChange={setFromDate} />
        <DateTimePicker label="To" value={toDate} setChange={setToDate} />
      </Stack>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Paper variant="outlined" sx={{ p: 2, mt: 1 }}>
            <Stack spacing={1}>
              <Typography variant="overline">
                For {fromDate.toDateString()} to {toDate.toDateString()}
              </Typography>
              <Typography>Quotes Done: {data?.quotes}</Typography>
              <Typography>New Orders: {data?.orders}</Typography>
              <Typography>Converted Quotes: {data?.quotesConverted}</Typography>
              <Typography>
                Conversion Rate: {(data?.conversionRate * 100).toFixed(2)}%
              </Typography>
              <Typography>New Clients: {data?.newClients}</Typography>
            </Stack>
          </Paper>
        </Grid>
        <Chart data={data} />
      </Grid>
      <Typography variant="h6" sx={{ mt: 2 }}>
        Items Breakdown
      </Typography>
      {data?.items?.brands && <ItemBreakDown data={data?.items?.brands} />}
      {data?.items?.categories && (
        <ItemBreakDown data={data?.items?.categories} />
      )}
    </Box>
  );
}

function Chart({ data }) {
  ChartJS.register(ArcElement, ChartTooltip, Legend);

  return (
    <Grid item xs={12} sm={6} sx={{ maxHeight: "40dvh" }}>
      <Doughnut
        data={{
          labels: ["Quotes", "Orders", "New Clients"],
          datasets: [
            {
              data: [data?.quotes, data?.orders, data?.newClients],
              backgroundColor: ["#2196f3", "#4caf50", "#ff9800"],
            },
          ],
        }}
      />
    </Grid>
  );
}

function ItemBreakDown({ data }) {
  const values = Object.values(data);
  const total = React.useMemo(
    () => values.reduce((a, b) => a + b, 0),
    [values]
  );

  const colorKeys = {
    HP: "#2196f3",
    DELL: "#4caf50",
    Lenovo: "#ff9800",
    Epson: "#f44336",
    Laptop: "#9c27b0",
    Desktop: "#3f51b5",
    Printer: "#009688",
    Toner: "#ff5722",
  };

  return (
    <Stack
      direction={"row"}
      sx={{ my: 2, borderRadius: "24px", border: "1px", minWidth: "100%" }}
    >
      {Object.keys(data).map(
        (key) =>
          key !== "Other" && (
            <Box
              key={key}
              sx={{
                flex: `0 0 0`,
                flexBasis: `calc(${(data[key] / total) * 100}% + 12px)`,
                p: 0.5,
                pl: 2,
                pr: 4,
                borderTopLeftRadius: "24px",
                borderBottomLeftRadius: "24px",
                ml: "-14px",
              }}
              bgcolor={colorKeys[key]}
            >
              <Typography>
                {key}:{data[key]}
              </Typography>
            </Box>
          )
      )}
      {data?.Other && (
        <Tooltip title="Items that are other brands or categories. Sometimes the brand is not listed in the item description">
          <Box
            sx={{
              flexGrow: 1,
              p: 0.5,
              pl: 2,
              pr: 4,
              bgcolor: "grey",
              borderRadius: "24px",
              ml: "-14px",
            }}
          >
            <Typography>Other:{data.Other}</Typography>
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
}
