import React from "react";

import {
  alpha,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { getSelloutByID } from "../API/tonerApi";
import {
  Article,
  Close,
  DateRange,
  Edit,
  Restore,
  Save,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import DateTimePicker from "../../../Components/common/DateTimePicker";
import { LoadingButton } from "@mui/lab";

export default function EditSellout() {
  const urlDets = new URLSearchParams(useLocation().search);
  const selloutID = urlDets.get("id");

  const { origin, loadNewData } = useOutletContext();

  let browseHistory = useNavigate();
  const goBack = React.useCallback(() => {
    browseHistory(origin);
  }, [browseHistory, origin]);

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({});
  const [newData, setNewData] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [error, setError] = React.useState(false);
  const [changed, setChanged] = React.useState(false);

  React.useEffect(() => {
    for (let key in newData) {
      if (newData[key] !== data[key]) {
        setChanged(true);
        break;
      }
      setChanged(false);
    }
  }, [newData, data]);

  React.useEffect(() => {
    const numberReg = new RegExp("^\\d+$");
    if (!numberReg.test(newData?.revenue)) {
      setErrors((prev) => ({ ...prev, revenue: true }));
    } else {
      setErrors((prev) => ({ ...prev, revenue: false }));
    }
  }, [newData]);

  React.useEffect(() => {
    for (let key in errors) {
      if (errors[key]) {
        setError(true);
        break;
      } else {
        setError(false);
      }
    }
  }, [errors]);

  React.useEffect(() => {
    if (!selloutID) goBack();
    if (loading)
      getSelloutByID(selloutID).then((res) => {
        if (res.status === "success") {
          console.log(res.data);
          setData(res.data);
          setNewData(res.data);
          setLoading(false);
        } else {
          toast.error(
            "Could not retrieve data, contact an administrator of the problem persists"
          );
          goBack();
        }
      });
  }, [loading, selloutID, goBack]);

  const saveChanges = () => {
    console.log(newData);
    loadNewData(newData);
    goBack();
  };

  if (loading)
    return (
      <Dialog open={true}>
        <Backdrop
          open={true}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            bgcolor: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(4px)",
          }}
        >
          <IconButton
            onClick={goBack}
            sx={{
              position: "absolute",
              top: 6,
              right: 6,
            }}
          >
            <Close />
          </IconButton>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    );

  return (
    <Dialog open={true} onClose={goBack} fullScreen>
      <IconButton
        onClick={goBack}
        sx={{
          position: "absolute",
          top: 6,
          right: 6,
        }}
      >
        <Close />
      </IconButton>
      <DialogTitle
        sx={{
          mr: 3,
        }}
      >
        <Stack direction="row" alignItems="center">
          <Article sx={{ mr: 2 }} />
          <EditItem
            label="Name"
            value={newData?.name}
            originalValue={data?.name}
            onChange={(value) => {
              setNewData({ ...newData, name: value });
            }}
            hideLabel
          />
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          bgcolor: "background.paper",
        }}
        dividers
      >
        <Stack spacing={1}>
          <EditItem
            label="Date"
            value={newData?.date}
            originalValue={data?.date}
            customLabel={
              <Stack direction="row" spacing={2} alignItems="center">
                <DateRange />
                <Typography variant="subtitle" gutterBottom>
                  {new Date(newData?.date).toLocaleDateString()}
                </Typography>
              </Stack>
            }
            customEdit={
              <DateTimePicker
                value={newData?.date}
                setChange={(date) => setNewData({ ...newData, date })}
              />
            }
          />
          <EditItem
            label="Revenue"
            value={newData?.revenue}
            originalValue={data?.revenue}
            onChange={(value) => {
              setNewData({ ...newData, revenue: value });
            }}
            error={errors.revenue}
            errorText="Revenue must be a number"
          />
        </Stack>
      </DialogContent>
      <Collapse in={changed} direction="up" unmountOnExit>
        <DialogActions>
          <Button
            startIcon={<Restore />}
            onClick={() => {
              setLoading(true);
            }}
            variant="outlined"
            color="warning"
            disabled={!changed}
          >
            Reset
          </Button>
          <LoadingButton
            loading={loading}
            onClick={saveChanges}
            disabled={!changed || error}
            color="primary"
            variant="contained"
            startIcon={<Save />}
          >
            Save Changes
          </LoadingButton>
        </DialogActions>
      </Collapse>
    </Dialog>
  );
}

const EditItem = ({
  label,
  value,
  originalValue,
  onChange,
  error,
  errorText = "",
  customLabel = false,
  customEdit = false,
  hideLabel = false,
}) => {
  const [hover, setHover] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [changed, setChanged] = React.useState(false);
  const [internalValue, setInternalValue] = React.useState(value);

  React.useEffect(() => {
    if (originalValue && value !== originalValue) setChanged(true);
    else setChanged(false);
  }, [value, originalValue]);

  return (
    <Box
      sx={{
        px: 2,
        py: 0.5,
        ...(changed && {
          bgcolor: (theme) => alpha(theme.palette.info.main, 0.2),
          borderRadius: 2,
          border: (theme) => `1px solid ${alpha(theme.palette.info.main, 0.5)}`,
        }),
        ...(error && {
          bgcolor: (theme) => alpha(theme.palette.error.main, 0.2),
          borderRadius: 2,
          border: (theme) =>
            `1px solid ${alpha(theme.palette.error.main, 0.5)}`,
        }),
      }}
    >
      <Collapse in={!edit}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {customLabel ? (
            customLabel
          ) : (
            <Typography variant="subtitle" gutterBottom>
              {!hideLabel && `${label}:`} {value}
            </Typography>
          )}
          <Fade in={hover}>
            <IconButton onClick={() => setEdit(true)}>
              <Edit />
            </IconButton>
          </Fade>
        </Stack>
        {error && (
          <Typography variant="caption" color="textSecondary">
            {errorText}
          </Typography>
        )}
      </Collapse>
      <Collapse in={edit} unmountOnExit>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 1 }}>
          {customEdit ? (
            customEdit
          ) : (
            <>
              <TextField
                label={label}
                value={internalValue}
                onChange={(e) => setInternalValue(e.target.value)}
                error={error}
                helperText={error ? errorText : ""}
              />
              <IconButton
                onClick={() => {
                  onChange(internalValue);
                  setEdit(false);
                }}
              >
                <Save />
              </IconButton>
            </>
          )}
          <IconButton onClick={() => setEdit(false)}>
            <Close />
          </IconButton>
        </Stack>
      </Collapse>
    </Box>
  );
};
