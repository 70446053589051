import React from "react";
import {
  Button,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  FormHelperText,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  changePassword,
  logout,
} from "../../CustomAxios/Protected/AccountManagment";
import { SessionManager } from "react-session.manager.sk";
import { PasswordValidator } from "../common/Validator";

export default function ChangePasswordForm(props) {
  const [oldPassword, setOldPassword] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [cpassword, setCPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorText, setEText] = React.useState("");
  const [errorText2, setEText2] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [dis, setDis] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const { setHeader, setLoggedin } = React.useContext(SessionManager);

  React.useEffect(() => {
    if (password || cpassword) {
      const Data = PasswordValidator(password, cpassword);
      setError(!Data.valid);
      setEText2(Data.message);
      setEText(Data.message2);
    }
  }, [password, cpassword]);

  const handleChangePass = (event) => {
    event.preventDefault();
    setLoading(true);
    changePassword({ old: oldPassword, password: password }).then((status) => {
      setLoading(false);
      if (status) logout(setHeader, setLoggedin);
    });
  };

  const closeChangePass = () => {
    props.handleClose();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (error || !oldPassword || !password) setDis(true);
    else setDis(false);
  }, [password, cpassword, error, oldPassword]);

  return (
    <form>
      <DialogContent>
        <Alert severity="warning">You will have to log in again.</Alert>
        <FormControl required fullWidth margin="normal">
          <InputLabel htmlFor="oldpassword">Current Password</InputLabel>
          <Input
            id="oldpassword"
            type={showPassword ? "text" : "password"}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </FormControl>

        <FormControl required fullWidth error={error} margin="normal">
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            id="password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && (
            <FormHelperText>
              {errorText2.map((val, index) => {
                return (
                  <span key={`passhelp_${index}`}>
                    {val}
                    <br />
                  </span>
                );
              })}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl required fullWidth error={error} margin="normal">
          <InputLabel htmlFor="Confirm-Password">Confirm Password</InputLabel>
          <Input
            id="Confirm-Password"
            type={showPassword ? "text" : "password"}
            value={cpassword}
            onChange={(e) => setCPassword(e.target.value)}
          />
          {error && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
        <Typography align="center">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            colour="secondary"
            size="large"
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </Typography>
      </DialogContent>
      <DialogActions sx={{ bgcolor: "background.default" }}>
        <Button onClick={closeChangePass} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleChangePass}
          type="submit"
          variant="contained"
          color="primary"
          autoFocus={true}
          disabled={dis}
          loading={loading}
        >
          Next
        </LoadingButton>
      </DialogActions>
    </form>
  );
}
