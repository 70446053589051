import React from "react";
import CreateLink from "../Components/common/CreateLink";
import { SessionManager } from "react-session.manager.sk";

import {
  NormalRoutes as NRoutes,
  RoleRoutes as RR,
  FooterRoutes as FR,
} from "../Pages";

// These are the links that appear in the appdrawer

export function BaseRoutes() {
  return (
    <>
      {NRoutes.map((route) => {
        if (route.link)
          return CreateLink(
            route.name,
            route.url,
            route.icon,
            route?.children && route.children
          );
        else return null;
      })}
    </>
  );
}

export function RoleRoutes() {
  const { userInfo } = React.useContext(SessionManager);
  const [pages, setPages] = React.useState([]);

  React.useEffect(() => {
    setPages(userInfo?.pages || []);
  }, [userInfo?.pages]);

  return (
    <>
      {RR.map((route) => {
        const inList = pages.includes(route.name);
        return (
          route.link &&
          CreateLink(
            route.name,
            route.url,
            route.icon,
            !inList,
            route?.children && route.children
          )
        );
      })}
    </>
  );
}

export function FooterRoutes() {
  return (
    <>
      {FR.map((route) => {
        if (route.link)
          return CreateLink(
            route.name,
            route.url,
            route.icon,
            false,
            route?.children && route.children
          );
        else return null;
      })}
    </>
  );
}
