import React from "react";
import { Box, Chip, Stack } from "@mui/material";
import { LoadingProvider, LoadingContext } from "./LoadingProvider";
import {
  getCustomerInvoicePages,
  getCustomerInvoices,
  getClients,
} from "../../API/Cheques&Pos";

import UniversalTable from "../../../Components/common/UniversalTable";
import FloatingFormButton from "../../../Components/common/FloatingFormButton";

import { convertToBool, dateRange } from "./FilterUtility";
import FilterArea from "../../../Components/common/FilterArea";
import NumberFormat from "../../../Components/common/NumberFormater";
import CreateInvoice from "../Forms/CreateInvoice";

export default function CustomerInvoices() {
  // advanced search
  const [invoice, setInvoice] = React.useState(""),
    [currency, setCurrency] = React.useState(""),
    [client, setClient] = React.useState(""),
    [orderID, setOrderID] = React.useState(""),
    [exempt, setExempt] = React.useState(""),
    [date, setDate] = React.useState("");
  //filter function
  const getFilterData = (filter) => {
    const from = new Date(date[1]);
    const to = new Date(date[2]);

    const data = filter
      ? {
          invoice,
          currency,
          client,
          orderID,
          exempt: convertToBool(exempt, "Yes", [true, false]),
          from:
            date[0] === "less" || date[0] === ""
              ? null
              : from.toLocaleString("en-GB", { timeZone: "UTC" }),
          to:
            date[0] === "greater" || date[0] === ""
              ? null
              : to.toLocaleString("en-GB", { timeZone: "UTC" }),
          currentPage: 1,
        }
      : {
          currentPage: 1,
        };

    return data;
  };

  const [CreateInvoiceOpen, setCreateInvoiceOpen] = React.useState(false);

  return (
    <Box>
      <LoadingProvider
        getFilterData={getFilterData}
        getData={getCustomerInvoices}
        getPages={getCustomerInvoicePages}
      >
        <FloatingFormButton
          title="Add New Customer Invoice"
          setOpen={setCreateInvoiceOpen}
          open={CreateInvoiceOpen}
          DialogFullScreen
        >
          <CreateInvoice />
        </FloatingFormButton>
        <CustomerInvoiceTable
          setInvoice={setInvoice}
          invoice={invoice}
          setCurrency={setCurrency}
          currency={currency}
          setOrderID={setOrderID}
          orderID={orderID}
          setClient={setClient}
          client={client}
          setExempt={setExempt}
          exempt={exempt}
          setDate={setDate}
          date={date}
        />
      </LoadingProvider>
    </Box>
  );
}

function CustomerInvoiceTable({
  setInvoice,
  invoice,
  setCurrency,
  currency,
  setOrderID,
  orderID,
  setClient,
  client,
  setExempt,
  exempt,
  setDate,
  date,
}) {
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loadFiltered,
    loading,
    data,
    setData,
    gotAll,
    allData,
  } = React.useContext(LoadingContext);

  const PriceWithVat = ({ value }) => {
    return (
      <Stack spacing={1} direction="row" alignItems={"center"}>
        <Box>{value.currency}</Box>
        <Box>{NumberFormat(value.total)}</Box>
        <Chip
          label={value.vatExempt ? "INC VAT" : "EXC VAT"}
          variant={value.vatExempt ? "default" : "outlined"}
        />
      </Stack>
    );
  };

  const items = [
    { id: "orderID", label: "Order ID" },
    { id: "partNumber", label: "Part Number" },
    { id: "description", label: "Description" },
    { id: "quantity", label: "Quantity" },
    {
      id: "price",
      label: "Price",
      component: (value) => NumberFormat(value.price),
    },
  ];

  const headers = [
    {
      id: "items",
      label: "",
      subRow: true,
      iconColor: "info",
      headers: items,
      subTitle: "Items",
    },
    { id: "invoiceID", label: "Invoice ID", searchable: true },
    { id: "clientName", label: "Client", searchable: true },
    {
      id: "Total",
      label: "Amount",
      searchable: true,
      component: (value) => <PriceWithVat value={value} />,
    },
    {
      id: "date",
      label: "Date",
      searchable: true,
      date: true,
    },
  ];

  //used to clear the textfield value for the autocomple component
  const [clear, setClear] = React.useState(false);

  // advanced search functions

  const handleAdvancedSearch = () => {
    if (gotAll) {
      const filtered = allData.filter(
        (data) =>
          [data.clientID, ""].includes(client) &&
          data.currency.includes(currency) &&
          data.invoiceID.includes(invoice) &&
          [data.vatExempt, null].includes(convertToBool(exempt, "Yes")) &&
          data.items.find((item) =>
            [item.orderID.toLowerCase(), ""].includes(orderID.toLowerCase())
          ) &&
          dateRange(date[1], date[2], data.date)
      );
      setData(filtered);
      return;
    }
    loadFiltered();
  };

  const clearAdvancedSearch = () => {
    setInvoice("");
    setCurrency("");
    setOrderID("");
    setClient("");
    setExempt("");
    setDate(["", null, null]);
    setClear(true);
  };

  return (
    <>
      <FilterArea
        categories={[
          {
            label: "Invoice",
            type: "search",
            value: invoice,
            setValue: setInvoice,
          },
          {
            label: "Order ID",
            type: "search",
            value: orderID,
            setValue: setOrderID,
          },
          {
            label: "Client",
            type: "combo",
            value: client,
            setValue: setClient,
            getData: getClients,
            optionLabel: "name",
            valueLabel: "id",
            clear,
            setClear,
          },
          {
            label: "Vat Exempt",
            type: "select",
            options: ["Yes", "No"],
            value: exempt,
            setValue: setExempt,
          },
          {
            label: "Currency",
            type: "select",
            options: ["KES", "USD"],
            value: currency,
            setValue: setCurrency,
          },

          {
            label: "Date Written",
            type: "date",
            options: [],
            value: date,
            setValue: setDate,
          },
        ]}
        startFilter={handleAdvancedSearch}
        clearFilter={clearAdvancedSearch}
        isLoading={loading}
      />

      <UniversalTable
        headers={headers}
        data={data}
        loading={loading}
        lazyloading={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
        setLoading={loadFiltered}
        name={"Customer Invoices"}
      />
    </>
  );
}
