import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { verify } from "../../CustomAxios/Protected/AccountManagment";
import { useNavigate } from "react-router";
import TokenInput from "../common/TokenInput";
import getDeviceFingerprint from "../common/FingerPrint";

export default function VerifyForm() {
  const urlDets = new URLSearchParams(useLocation().search);
  const email = urlDets.get("e");
  const key = urlDets.get("h");
  const [token, setToken] = React.useState("");
  const [dis, setDis] = React.useState(true);
  let history = useNavigate();

  React.useEffect(() => {
    if (token === "") setDis(true);
    else setDis(false);
  }, [token]);

  const verifySubmit = () => {
    verify(
      {
        email: email,
        token: token,
        key: key,
        deviceUID: getDeviceFingerprint(),
      },
      history
    );
  };
  return (
    <Dialog open={true}>
      <DialogTitle>Verify Email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          We need to verify your email adress before we can proceed.
        </DialogContentText>
        <DialogContentText>
          A token was sent to your email please use it to verify your account.
          If you can't see it please check your spam folder.
        </DialogContentText>
        <TokenInput
          onTokenChange={(e) => setToken(e)}
          PaperProps={{ mx: "auto" }}
        />
      </DialogContent>
      <DialogActions sx={{ bgcolor: "background.default" }}>
        <Button component={Link} to={"/"}>
          Cancel
        </Button>
        <Button
          onClick={verifySubmit}
          type="submit"
          variant="contained"
          color="primary"
          autoFocus={true}
          disabled={dis}
        >
          Verify
        </Button>
      </DialogActions>
    </Dialog>
  );
}
