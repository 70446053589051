import React from "react";
import {
  Collapse,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Article, Download, ExpandMore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  downloadSummary,
  getDailySummary,
  getRfqAccess,
  getWeeklySummary,
} from "../../API/Sales";
import { toast } from "react-toastify";
import { clearReports } from "../../API/SalesAdmin";
import { Link } from "react-router-dom";

export default function Reports() {
  const [canAccess, setCanAccess] = React.useState(false);
  const [dailyLoading, setDailyLoading] = React.useState(true),
    [weeklyLoading, setWeeklyLoading] = React.useState(true);

  React.useEffect(() => {
    getRfqAccess().then((res) => {
      if (res.status === "success") setCanAccess(true);
      else setCanAccess(false);
    });

    return () => {};
  }, []);

  if (!canAccess) {
    return null;
  }

  return (
    <Collapse in={!dailyLoading && !weeklyLoading}>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-start"
          alignItems={"center"}
          sx={{ mb: 2 }}
        >
          <SvgIcon color="primary">
            <Article />
          </SvgIcon>
          <Typography variant="h5" color="primary">
            My Summary
          </Typography>
        </Stack>

        <Fab
          color="primary"
          variant="extended"
          sx={{ mb: 2 }}
          component={Link}
          to="/rfqs"
        >
          <Add />
          Add new RFQ
        </Fab>

        <TodaysSummary loading={dailyLoading} setLoading={setDailyLoading} />

        <ThisWeeksSummary
          loading={weeklyLoading}
          setLoading={setWeeklyLoading}
        />
      </Paper>
    </Collapse>
  );
}

function Section({ title, children }) {
  return (
    <Grid item xs={12} md={6} sx={{ mb: 2 }}>
      <Paper variant="outlined">
        <Typography
          backgroundColor="background.paper"
          sx={{
            position: "relative",
            mt: "-1.2rem",
            width: "fit-content",
            p: 1,
            ml: 2,
          }}
        >
          {title}
        </Typography>
        {children}
      </Paper>
    </Grid>
  );
}

function DownloadReportButton({ type }) {
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setLoading(true);

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + "_" + mm + "_" + yyyy;
    const name =
      type === "daily"
        ? `Daily Summary_${formattedToday}`
        : `Weekly Summary_${formattedToday}`;

    downloadSummary(type, name)
      .catch(() => toast.error("Error downloading report"))
      .finally(() => {
        setLoading(false);
        clearReports();
      });
  };

  return (
    <LoadingButton
      startIcon={<Download />}
      onClick={handleClick}
      loading={loading}
    >
      Download Report
    </LoadingButton>
  );
}

export function RfqListItem({ rfq, index, rfqLength }) {
  const [open, setOpen] = React.useState(false);
  return (
    <ListItem
      divider={index !== rfqLength}
      sx={{
        flexDirection: "column",
        alignItems: "stretch",
        padding: 0,
      }}
    >
      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemText
          primary={rfq.client}
          secondary={
            <Stack direction="column" spacing={0}>
              <Typography variant="caption" color="text.secondary">
                ID {rfq.id}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Date Recieved {rfq.dateRecieved}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Date Created {rfq.date}
              </Typography>
            </Stack>
          }
          disableTypography
        />

        <Tooltip title={`${open ? "Hide" : "Show"} Description`}>
          <SvgIcon
            sx={{
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <ExpandMore />
          </SvgIcon>
        </Tooltip>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
          <Typography variant="overline" component={"h6"}>
            Description
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {rfq.description}
          </Typography>
        </Paper>
      </Collapse>
    </ListItem>
  );
}

function TodaysSummary({ loading, setLoading }) {
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    if (loading) {
      getDailySummary()
        .then((res) => {
          setData(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return () => {};
  }, [loading, setLoading]);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems={"center"}
        sx={{ mb: 2 }}
      >
        <Typography>Todays Summary</Typography>
        <DownloadReportButton type="daily" />
      </Stack>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Section title="RFQS recieved">
          <List>
            {data?.RFQS?.map((rfq, index) => (
              <RfqListItem
                key={rfq.id}
                rfq={rfq}
                index={index}
                rfqLength={data?.RFQS.length - 1}
              />
            ))}
          </List>
        </Section>
        <Section title="Quotations Done">
          <List>
            {data?.QUOTES?.map((rfq, index) => (
              <ListItem
                key={rfq.id}
                divider={index !== data?.QUOTES.length - 1}
              >
                <ListItemText primary={rfq.client} secondary={rfq.id} />
              </ListItem>
            ))}
          </List>
        </Section>
        <Section title="Orders Placed">
          <List>
            {data?.ORDERS?.map((rfq, index) => (
              <ListItem
                key={rfq.id}
                divider={index !== data?.ORDERS.length - 1}
              >
                <ListItemText primary={rfq.client} secondary={rfq.id} />
              </ListItem>
            ))}
          </List>
        </Section>
        <Section title="Pending Quotes">
          <List>
            {data?.PENDING?.map((rfq, index) => (
              <RfqListItem
                key={rfq.id}
                rfq={rfq}
                index={index}
                rfqLength={data?.PENDING.length - 1}
              />
            ))}
          </List>
        </Section>
      </Grid>
    </>
  );
}

function ThisWeeksSummary({ loading, setLoading }) {
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    if (loading) {
      getWeeklySummary()
        .then((res) => {
          setData(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return () => {};
  }, [loading, setLoading]);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems={"center"}
        sx={{ mb: 2 }}
      >
        <Typography gutterBottom>This Weeks Summary</Typography>
        <DownloadReportButton type="weekly" />
      </Stack>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Section title="RFQS recieved">
          <List>
            {data?.RFQS?.map((rfq, index) => (
              <RfqListItem
                key={rfq.id}
                rfq={rfq}
                index={index}
                rfqLength={data?.RFQS.length - 1}
              />
            ))}
          </List>
        </Section>
        <Section title="Quotations Done">
          <List>
            {data?.QUOTES?.map((rfq, index) => (
              <ListItem
                key={rfq.id}
                divider={index !== data?.QUOTES.length - 1}
              >
                <ListItemText
                  primary={rfq.client}
                  secondary={`${rfq.id} ${rfq.date}`}
                />
              </ListItem>
            ))}
          </List>
        </Section>
        <Section title="Orders Placed">
          <List>
            {data?.ORDERS?.map((rfq, index) => (
              <ListItem
                key={rfq.id}
                divider={index !== data?.ORDERS.length - 1}
              >
                <ListItemText
                  primary={rfq.client}
                  secondary={`${rfq.id} ${rfq.date}`}
                />
              </ListItem>
            ))}
          </List>
        </Section>
        <Section title="Pending Quotes">
          <List>
            {data?.PENDING?.map((rfq, index) => (
              <RfqListItem
                key={rfq.id}
                rfq={rfq}
                index={index}
                rfqLength={data?.PENDING.length - 1}
              />
            ))}
          </List>
        </Section>
      </Grid>
    </>
  );
}
