import React from "react";

import fetchData from "./FetchData";

export const LoadingContext = React.createContext();

export function LoadingProvider({
  getFilterData,
  getData,
  getPages,
  children,
  initialLoading = true,
}) {
  //data
  const [data, setData] = React.useState([]);

  // Lazy Loading
  const [currentPage, setCurrentPage] = React.useState(0),
    [loading, setLoading] = React.useState(true),
    [totalPages, setTotalPages] = React.useState(0),
    [filtering, setFiltering] = React.useState(initialLoading),
    [loadingIndicator, setLoadingIndicator] = React.useState(true);

  // used for client side filtering.
  const [gotAll, setGotAll] = React.useState(false),
    [allData, setAllData] = React.useState([]);

  // used for search
  const [filterData, setFilterData] = React.useState(
    getFilterData(false) || []
  );

  // gets all filtered data
  const loadFiltered = () => {
    setFilterData(getFilterData(true));
    setLoading(true);
    setFiltering(true);
    setGotAll(false);
    setAllData([]);
  };

  const stopLoading = () => {
    setLoading(false);
  };
  const finishedLoading = () => {
    setFiltering(false);
  };

  React.useEffect(() => {
    if (gotAll && allData.length === 0) {
      setAllData(data);
    }
  }, [gotAll, data, allData]);

  React.useEffect(() => {
    if (currentPage >= totalPages) {
      setLoadingIndicator(false);
      return;
    }
    setLoadingIndicator(true);
  }, [currentPage, totalPages]);

  React.useEffect(() => {
    const stop = { cancel: false };
    const controller = new AbortController();

    const processedData = {
      ...filterData,
    };
    for (let key in processedData) {
      if (processedData[key] === "") {
        processedData[key] = null;
      }
    }

    if (filtering)
      fetchData(
        getData,
        getPages,
        processedData,
        setData,
        setCurrentPage,
        setTotalPages,
        stopLoading,
        stop,
        controller,
        finishedLoading,
        setGotAll
      );

    // stops recursive lazy loading, and cancels any ongoing api requests.
    return () => {
      stop.cancel = true;
      controller.abort();
    };
  }, [filterData, filtering, getData, getPages, setData]);

  const value = {
    loadFiltered,
    gotAll,
    allData,
    setData,
    loading,
    data,
    loadingIndicator,
    currentPage,
    totalPages,
  };

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
}
