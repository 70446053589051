import React from "react";
import {
  Typography,
  Stack,
  Grid,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Tooltip,
  IconButton,
  Collapse,
  Paper,
  SvgIcon,
  CardActionArea,
  Chip,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import AddItemForm from "../Forms/AddPosItem";
import AddPendingForm from "../Forms/AddPendingItem";
import NumberFormater from "../../../Components/common/NumberFormater";

import ReferencePoTable from "../tables/ReferencePoTable";
import TransactionSummary from "./TransactionSummary";
import ItemInfo from "./ItemInfo";

import { getItemReference } from "../../API/Cheques&Pos";
import { TransitionGroup } from "react-transition-group";
import {
  ArrowDropDown,
  ArrowDropUp,
  Pending,
  PendingActions,
  Store,
  WarningAmber,
} from "@mui/icons-material";

export default function PosItem(props) {
  const {
    description,
    partNumber,
    quantity,
    price,
    id,
    pending,
    itemID,
    poItems,
    linkedItem,
  } = props.orderItem;

  const { orderID, currency, isManual } = props.order;
  const {
    setPosItems,
    posItems,
    setOrderItems,
    hide,
    pendingItems,
    setPendingItems,
    poCurrency,
    setExchangeRates,
    exchangeRates,
  } = props;

  const [showPending, setShowPending] = React.useState(false);
  const [showPendingItems, setShowPendingItems] = React.useState(true);
  const [showReference, setShowReference] = React.useState(false);
  const [showTransaction, setShowTransactions] = React.useState(false);
  const [showPendingList, setShowPendingList] = React.useState(true);
  const [showSecondary, setShowSecondary] = React.useState(false);

  const [referenceData, setReferenceData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (isManual) {
      setLoading(false);
      return;
    }

    if (loading) {
      getItemReference({ itemID }).then((res) => {
        setLoading(false);
        setReferenceData(res);
      });
    }
  }, [loading, referenceData, itemID, isManual]);

  const [posDescription, setPosDescription] = React.useState(""),
    [posPartNumber, setPosPartNumber] = React.useState(""),
    [posPrice, setPosPrice] = React.useState(0),
    [posQuantity, setPosQuantity] = React.useState(0);

  // only want this to run once
  React.useEffect(() => {
    if (linkedItem) {
      const items = posItems.find((object) => object.orderID === orderID).items;
      const posItem = items.find((item) => item.id === id);
      if (posItem) {
        setPosDescription(posItem.description);
        setPosPartNumber(posItem.partNumber);
        setPosPrice(posItem.price);
        setPosQuantity(posItem.quantity);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const otherMainItemsQuantity = React.useMemo(
    () =>
      poItems.reduce((accumulator, item) => {
        const isMain = item.pendingID === null;
        if (isMain) return item.quantity + accumulator;
        return accumulator + 0;
      }, 0),
    [poItems]
  );

  const getPosItems = () => {
    const items = posItems.find((object) => object.orderID === orderID).items;
    const filtered = items.filter((item) => item.itemID === itemID);

    return filtered;
  };

  const getSecondaryItems = React.useMemo(() => {
    const items = posItems.find((object) => object.orderID === orderID).items;
    const filtered = items.filter(
      (item) => item.itemID === itemID && item.isPending
    );
    return filtered;
  }, [itemID, orderID, posItems]);

  const getPendingItems = () => {
    const filtered = pendingItems.filter(
      (item) => item.itemID === id && !item.deleted
    );
    return filtered;
  };

  const profit =
    price -
    posPrice * (exchangeRates[currency].rate / exchangeRates[poCurrency].rate);

  const quantityColor = quantity >= posQuantity ? "white" : "red";

  const checkPosItem = () => {
    //check if a pos item is added
    const posObject = posItems.find((order) => order.orderID === orderID);

    const exists = posObject.items.findIndex((item) => item.id === id);
    return exists !== -1 ? true : false;
  };

  const checkPending = (id) => {
    const exists = getPosItems().findIndex((item) => item.id === id);

    return exists !== -1 ? true : false;
  };

  const updateItemDetail = (partNumber, description, price, quantity) => {
    setPosPartNumber(partNumber);
    setPosDescription(description);
    setPosPrice(price);
    setPosQuantity(quantity);
  };

  const addPosItem = ({
    partNumber,
    description,
    price,
    quantity,
    isStore,
    storeID,
    isPending,
    pendingID,
    posID,
  }) => {
    setPosItems((previous) => {
      const posObject = previous.find((object) => object.orderID === orderID);
      const filtered = posObject.items.filter((item) => {
        return String(item.id) !== String(posID);
      });
      posObject.items = filtered;
      posObject.items.push({
        po_item_id: null,
        partNumber,
        description,
        price: Number(price),
        quantity: Number(quantity),
        ...(isPending && { isPending }),
        ...(isStore && { isStore }),
        itemID,
        pendingID,
        storeID,
        deleteID: id,
        id: posID || id,
        // info for creating new order item.
        ...(isManual && {
          manual: { partNumber, quantity, price, description },
        }),
      });

      return [...previous];
    });

    if (posID === id || !posID)
      updateItemDetail(partNumber, description, price, quantity);
  };

  const deletePosItem = (posID = id) => {
    setPosItems((previous) => {
      const posObject = previous.find((object) => object.orderID === orderID);
      posObject.items = posObject.items.filter(
        (item) => String(item.id) !== String(posID)
      );
      return [...previous];
    });
    if (posID === id) updateItemDetail("", "", 0, 0);
  };

  const deleteOrderItem = () => {
    setPosItems((previous) => {
      const posObject = previous.find((object) => object.orderID === orderID);
      posObject.items = posObject.items.filter((item) => item.deleteID !== id);
      return [...previous];
    });

    setOrderItems((previous) => {
      const itemObject = previous.find((object) => object.orderID === orderID);
      itemObject.items = itemObject.items.filter((item) => item.id !== id);

      return [...previous];
    });
  };

  const addPending = (data) => {
    setShowPending(false);
    setShowPendingList(true);
    setPendingItems((previous) => {
      const pendingObject = previous.find(
        (object) => object.orderID === orderID
      );
      pendingObject.items.push({ ...data, itemID });
      return [...previous];
    });
  };

  const onComplete = (property, value, id) => {
    setPosItems((previous) => {
      const posObject = previous.find((item) => item.orderID === orderID);
      const posItem = posObject.items.find((item) => item.id === id);
      posItem[property] = value;

      return [...previous];
    });
  };

  const addSecondaryItems = ({
    partNumber,
    price,
    description,
    quantity,
    isStore,
    storeID,
  }) => {
    addPosItem({
      partNumber,
      description,
      price,
      quantity: quantity,
      isPending: true,
      ...(isStore && { isStore }),
      storeID,
      pendingID: -1,
      posID: Date.now(),
    });
    setShowSecondary(false);
  };

  // checks if the orderID contains the sub string MANUAL, so we can know
  // if the order was made "manually", used to remove functionality such as adding a pending item
  const checkIfManual = () => {
    return orderID.includes("MANUAL");
  };

  return (
    <Collapse in={hide}>
      <Dialog
        maxWidth={"lg"}
        open={showReference}
        onClose={() => setShowReference(false)}
        sx={{ p: 2 }}
      >
        <DialogTitle color={"primary"}>POS references</DialogTitle>
        <ReferencePoTable
          data={referenceData}
          loading={loading}
          setLoading={setLoading}
        />
      </Dialog>

      <Dialog open={showPending} onClose={() => setShowPending(false)}>
        <DialogTitle color={"primary"}>Add Pending Item</DialogTitle>
        <AddPendingForm
          onClose={() => setShowPending(false)}
          addPendingItem={addPending}
          defaultPartNumber={partNumber}
          defaultPrice={price}
          defaultDescription={description}
          defaultQuantity={quantity}
        />
      </Dialog>

      <Paper sx={{ p: 1, mb: 2 }}>
        <Stack alignItems="flex-end">
          <Tooltip title="Delete Order Item" color="primary">
            <IconButton onClick={deleteOrderItem}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>

        <Grid container direction="column">
          <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                px: 1,
              }}
            >
              <Stack>
                <Paper elevation={0}>
                  <Typography
                    varaint="h5"
                    sx={{ fontWeight: "bold", p: 2 }}
                    color="primary"
                    gutterBottom
                  >
                    Order Details
                  </Typography>

                  <ItemDisplay
                    partNumber={partNumber}
                    description={description}
                    price={price}
                    currency={currency}
                    quantity={quantity}
                    otherMainItemsQuantity={otherMainItemsQuantity}
                  />
                </Paper>

                <Stack sx={{ my: 1 }} spacing={1}>
                  {(loading || referenceData.length !== 0) && (
                    <Button
                      variant={"outlined"}
                      onClick={() => setShowReference(true)}
                    >
                      View reference pos for item
                    </Button>
                  )}
                  {pending?.length > 0 && (
                    <>
                      <Button
                        variant={"outlined"}
                        onClick={() =>
                          setShowPendingItems((previous) => !previous)
                        }
                        endIcon={
                          showPendingItems ? <ArrowDropUp /> : <ArrowDropDown />
                        }
                      >
                        {showPendingItems
                          ? "Hide Pending Items"
                          : "View Pending Items"}
                      </Button>
                      <Collapse in={showPendingItems}>
                        <AddedPendingArea
                          items={pending}
                          checkPending={checkPending}
                          getSecondaryItems={getSecondaryItems}
                          addPosItem={addPosItem}
                          poCurrency={poCurrency}
                          exchangeRates={exchangeRates}
                          setExchangeRates={setExchangeRates}
                        />
                      </Collapse>
                    </>
                  )}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{ height: "100%" }}
              >
                {checkPosItem() ? (
                  <Paper elevation={0}>
                    <Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography
                          varaint="h5"
                          sx={{ fontWeight: "bold", p: 2 }}
                          color="primary"
                          id={id}
                        >
                          Purchase Details
                        </Typography>
                        {checkPosItem() && (
                          <Tooltip title="Delete purchase item">
                            <IconButton
                              variant="outlined"
                              color="error"
                              onClick={() => deletePosItem(id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                      <ItemInfo
                        name="Part Number"
                        property="partNumber"
                        value={posPartNumber}
                        setValue={setPosPartNumber}
                        type="text"
                        currency={poCurrency}
                        id={id}
                        onComplete={onComplete}
                      />
                      <ItemInfo
                        name="Description"
                        property="description"
                        value={posDescription}
                        setValue={setPosDescription}
                        type="text"
                        currency={poCurrency}
                        id={id}
                        onComplete={onComplete}
                      />
                      <ItemInfo
                        name="Quantity"
                        property="quantity"
                        value={posQuantity}
                        setValue={setPosQuantity}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        currency={poCurrency}
                        id={id}
                        onComplete={onComplete}
                      />

                      <ItemInfo
                        name="Price"
                        property="price"
                        value={posPrice}
                        setValue={setPosPrice}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        currency={poCurrency}
                        id={id}
                        onComplete={onComplete}
                      />
                    </Stack>
                  </Paper>
                ) : (
                  <Paper variant="outlined" sx={{ py: 2, pb: 4 }}>
                    <Box
                      sx={{
                        mt: -2,
                        bgcolor: "primary.main",
                        p: 1,
                        width: "fit-content",
                        borderRadius: "4px 0 4px 0",
                        boxShadow: "4px 4px 4px 0px rgba(0,0,0,0.2)",
                      }}
                    >
                      <Typography sx={{ color: "white" }}>Main Item</Typography>
                    </Box>
                    <AddItemForm
                      defaultPartNumber={partNumber}
                      defaultPrice={price}
                      defaultDescription={description}
                      defaultQuantity={quantity}
                      onComplete={(data) => addPosItem(data)}
                      poCurrency={poCurrency}
                      exchangeRates={exchangeRates}
                      setExchangeRates={setExchangeRates}
                    />
                  </Paper>
                )}
                <Stack direction="column" spacing={2} sx={{ p: 1 }}>
                  {!checkIfManual() && (
                    <Button
                      variant={"outlined"}
                      onClick={() => setShowPending((previous) => !previous)}
                      startIcon={<AddIcon />}
                      endIcon={<Pending />}
                    >
                      Add Pending Item
                    </Button>
                  )}
                  {showSecondary ? (
                    <Paper variant="outlined" sx={{ pt: 2 }}>
                      <Box
                        sx={{
                          mt: -2,
                          p: 1,
                          width: "fit-content",
                          bgcolor: "secondary.main",
                          borderRadius: "4px 0 4px 0",
                          boxShadow: "4px 4px 4px 0px rgba(0,0,0,0.2)",
                        }}
                      >
                        <Typography sx={{ color: "white" }}>
                          Secondary Item
                        </Typography>
                      </Box>
                      <Stack spacing={3}>
                        <AddItemForm
                          defaultPartNumber={""}
                          defaultPrice={0}
                          defaultDescription={""}
                          defaultQuantity={0}
                          onComplete={addSecondaryItems}
                          poCurrency={poCurrency}
                          exchangeRates={exchangeRates}
                          setExchangeRates={setExchangeRates}
                        />
                        <Button onClick={() => setShowSecondary(false)}>
                          Cancel
                        </Button>
                      </Stack>
                    </Paper>
                  ) : (
                    <Button
                      variant={"outlined"}
                      color="secondary"
                      onClick={() => setShowSecondary(true)}
                      startIcon={<AddIcon />}
                      endIcon={<Store />}
                    >
                      Add Secondary Item
                    </Button>
                  )}
                </Stack>
                <Grid
                  item
                  sx={{
                    p: 1,
                    mt: 2,
                  }}
                >
                  {getSecondaryItems.length > 0 && (
                    <Paper elevation={0} sx={{ mb: 1 }}>
                      <Typography
                        variant="h5"
                        color={"primary"}
                        sx={{ pt: 2, pl: 2 }}
                      >
                        Secondary items
                      </Typography>
                      <TransitionGroup>
                        {getSecondaryItems.map((item, index) => (
                          <Collapse key={item.id}>
                            <SecondaryItemsArea
                              item={item}
                              isLast={getSecondaryItems.length === index + 1}
                              deletePosItem={deletePosItem}
                              poCurrency={poCurrency}
                              onComplete={onComplete}
                            />
                          </Collapse>
                        ))}
                      </TransitionGroup>
                    </Paper>
                  )}

                  {getPendingItems().length > 0 && (
                    <Box sx={{ mb: 2 }}>
                      <Button
                        startIcon={<PendingActions />}
                        endIcon={
                          showPendingList ? <ExpandLess /> : <ExpandMore />
                        }
                        onClick={() =>
                          setShowPendingList((previous) => !previous)
                        }
                      >
                        {showPendingList ? "hide" : "show"} Pending Items
                      </Button>

                      <Collapse in={showPendingList}>
                        <AddPending
                          items={getPendingItems()}
                          setPendingItems={setPendingItems}
                          orderID={orderID}
                        />
                      </Collapse>
                    </Box>
                  )}

                  <Box>
                    <Typography
                      display="inline"
                      varaint="h6"
                      sx={{ fontWeight: "bold" }}
                    >
                      Quantity:
                    </Typography>
                    <Typography
                      display="inline"
                      varaint="caption"
                      sx={{ ml: 1, mt: 1, color: quantityColor }}
                    >
                      {`${posQuantity} / ${quantity}`}
                    </Typography>
                  </Box>
                  <CardActionArea
                    onClick={() => setShowTransactions((previous) => !previous)}
                    sx={{ my: 0.5 }}
                  >
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Typography varaint="h6" sx={{ fontWeight: "bold" }}>
                        Profit:
                      </Typography>

                      <Typography
                        varaint="caption"
                        sx={{
                          color: (theme) =>
                            profit > 0
                              ? theme.palette.success.main
                              : theme.palette.error.main,
                        }}
                      >
                        {`${currency} ${NumberFormater(profit.toFixed(2))}`}
                      </Typography>
                      <Tooltip
                        title={`${
                          showTransaction ? "Hide" : "Show"
                        } Transaction History`}
                      >
                        <InfoIcon sx={{ fontSize: "1rem" }} />
                      </Tooltip>
                    </Stack>
                  </CardActionArea>
                  <Collapse in={showTransaction}>
                    <TransactionSummary
                      items={[...poItems, ...getPosItems()]}
                      sellingPrice={price * quantity}
                      currency={currency}
                      poCurrency={poCurrency}
                      setExchangeRates={setExchangeRates}
                      exchangeRates={exchangeRates}
                      unitPrice={price}
                      poPrice={posPrice}
                      quantity={quantity}
                    />
                  </Collapse>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Collapse>
  );
}

function ItemDisplay({
  partNumber,
  description,
  price,
  currency,
  quantity,
  otherMainItemsQuantity,
}) {
  const Detail = ({ name, value, children }) => (
    <Box>
      <Divider textAlign="left">
        <Typography varaint="h6" sx={{ fontWeight: "bold" }}>
          {name}
        </Typography>
      </Divider>
      <Typography sx={{ px: 2, py: 1 }}>
        {value} {children}
      </Typography>
    </Box>
  );

  return (
    <>
      <Detail name="Part Number" value={partNumber} />
      <Detail name="Description" value={description} />
      <Detail name="Quantity" value={quantity}>
        {otherMainItemsQuantity !== 0 && (
          <Chip
            label={`Already order ${otherMainItemsQuantity}`}
            sx={{ ml: 2 }}
          />
        )}
      </Detail>

      <Detail
        name="Price"
        value={`${currency} ${NumberFormater(price.toFixed(2))}`}
      />
    </>
  );
}

function AddPending({ items, setPendingItems, orderID }) {
  //context data

  const deleteItem = (itemIndex) => {
    setPendingItems((previous) => {
      const itemObject = previous.find((object) => object.orderID === orderID);
      itemObject.items = itemObject.items.filter(
        (_, index) => index !== itemIndex
      );

      return [...previous];
    });
  };

  const handleDelete = (itemIndex) => {
    if (items[itemIndex].edit) {
      setPendingItems((previous) => {
        const itemObject = previous.find(
          (object) => object.orderID === orderID
        );
        const item = itemObject.items.find(
          (item) => item.id === items[itemIndex].id
        );
        item.deleted = true;

        return [...previous];
      });
      return;
    }
    deleteItem(itemIndex);
  };

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Typography variant="h6">Pending items</Typography>
      <Typography variant="caption">Items that need to be ordered</Typography>
      <TransitionGroup style={{ width: "100%" }}>
        {(items || []).map((item, index) => (
          <Collapse key={index}>
            <ListItem
              key={index}
              secondaryAction={
                <Tooltip title="Delete Pending Item">
                  <span>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        handleDelete(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              }
            >
              <ListItemText
                primary={item.description}
                secondary={`QTY ${item.quantity}`}
              />
            </ListItem>
          </Collapse>
        ))}
      </TransitionGroup>
    </Paper>
  );
}

function AddedPendingArea({
  items,
  checkPending,
  getSecondaryItems,
  addPosItem,
  poCurrency,
  exchangeRates,
  setExchangeRates,
}) {
  const [open, setOpen] = React.useState(Array(items.length).fill(false));
  const [showForm, setShowForm] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const onComplete = ({
    partNumber,
    price,
    description,
    quantity,
    isStore,
    storeID,
  }) => {
    addPosItem({
      partNumber,
      description,
      price,
      quantity: items[index].quantity,
      isPending: true,
      ...(isStore && { isStore }),
      storeID,
      pendingID: items[index].pendingID,
      itemID: items[index].itemID,
      posID: items[index].id,
    });
    setShowForm(false);
  };

  const pendingCount =
    items.length -
    getSecondaryItems.filter((item) => item.pendingID !== undefined).length;

  return (
    <Paper elevation={0}>
      <Stack
        spacing={1}
        direction="row"
        alignItems={"center"}
        sx={{ pt: 2, pl: 2 }}
      >
        <SvgIcon color="primary">
          <WarningAmber />
        </SvgIcon>
        <Typography variant="h5" color={"primary"}>
          Pending Items {pendingCount > 0 && `(${pendingCount})`}
        </Typography>
      </Stack>

      {showForm && (
        <Stack spacing={2} sx={{ p: 2 }}>
          <AddItemForm
            defaultPartNumber={""}
            defaultPrice={1}
            defaultDescription={items[index].description}
            defaultQuantity={items[index].quantity}
            onComplete={onComplete}
            poCurrency={poCurrency}
            exchangeRates={exchangeRates}
            setExchangeRates={setExchangeRates}
          />
          <Button
            color="warning"
            variant="outlined"
            onClick={() => {
              setShowForm(false);
            }}
            sx={{ alignSelf: "flex-end" }}
          >
            Cancel Adding {`( ${items[index].description} )`}
          </Button>
        </Stack>
      )}
      {showForm === false && (
        <List>
          {items.map(
            (item, index) =>
              checkPending(item.id) === false && (
                <Box key={item.id}>
                  <ListItem
                    secondaryAction={
                      <Tooltip title="Add Pending Item">
                        <span>
                          <IconButton
                            disabled={checkPending(item.id)}
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              setIndex(index);
                              setShowForm(true);
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    }
                  >
                    <ListItemText primary={item.description} />
                    <Tooltip title={open ? "Collapse" : "Expand"}>
                      <IconButton
                        onClick={() =>
                          setOpen((previous) => {
                            previous[index] = !previous[index];
                            return [...previous];
                          })
                        }
                      >
                        {open[index] ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                  <Collapse in={open[index]}>
                    <Stack sx={{ pl: 3 }}>
                      <Box>
                        <Typography varaint="h6" sx={{ fontWeight: "bold" }}>
                          Name
                        </Typography>
                        <Typography
                          varaint="subtitle"
                          sx={{ ml: 1, mt: 0.5, mb: 0.5 }}
                        >
                          {item.description}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography varaint="h6" sx={{ fontWeight: "bold" }}>
                          Quantity
                        </Typography>
                        <Typography
                          varaint="subtitle"
                          sx={{ ml: 1, mt: 0.5, mb: 0.5 }}
                        >
                          {`${item.quantity}`}
                        </Typography>
                      </Box>
                    </Stack>
                  </Collapse>
                </Box>
              )
          )}
        </List>
      )}
    </Paper>
  );
}

function SecondaryItemsArea({
  item,
  isLast,
  deletePosItem,
  poCurrency,
  onComplete,
}) {
  const [posDescription, setPosDescription] = React.useState(item.description),
    [posPrice, setPosPrice] = React.useState(item.price),
    [posQuantity, setPosQuantity] = React.useState(item.quantity);

  React.useEffect(() => {
    setPosQuantity(item.quantity);
  }, [item]);

  return (
    <>
      <ListItem
        secondaryAction={
          <Tooltip title="Delete item">
            <span>
              <IconButton
                edge="end"
                aria-label="add"
                color={"warning"}
                onClick={() => {
                  deletePosItem(item.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        }
      >
        <ListItemText
          primary={
            <>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ pl: item.isStore ? 3 : 0 }}
              >
                {item.isStore && (
                  <Chip label="store" color="primary" variant="outlined" />
                )}
                <ItemInfo
                  name="Description"
                  property="description"
                  value={posDescription}
                  setValue={setPosDescription}
                  type="text"
                  currency={poCurrency}
                  id={item.id}
                  noTitle={true}
                  onComplete={onComplete}
                />
              </Stack>

              <Stack direction="row" alignItems="center" sx={{ pl: 3 }}>
                <Typography sx={{ fontSize: "16px" }}>QTY</Typography>
                <ItemInfo
                  name=""
                  property="quantity"
                  value={posQuantity}
                  setValue={setPosQuantity}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id={item.id}
                  noTitle
                  onComplete={onComplete}
                />
              </Stack>
              <Stack direction="row" alignItems="center" sx={{ pl: 3 }}>
                <Typography sx={{ fontSize: "16px" }}>Price</Typography>
                <ItemInfo
                  name=""
                  property="price"
                  value={posPrice}
                  setValue={setPosPrice}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  currency={poCurrency}
                  id={item.id}
                  noTitle
                  onComplete={onComplete}
                />
              </Stack>
              {!isLast && <Divider />}
            </>
          }
        />
      </ListItem>
    </>
  );
}
