import React from "react";

import {
  Typography,
  Stack,
  Button,
  Divider,
  TextField,
  Paper,
  CardActionArea,
  ListItem,
  ListItemText,
  List,
  Chip,
} from "@mui/material";

import NumberFormater from "../../../Components/common/NumberFormater";

export default function TransactionSummary({
  items,
  sellingPrice,
  currency,
  poCurrency,
  setExchangeRates,
  exchangeRates,
  unitPrice,
  poPrice,
  quantity,
}) {
  //context data

  const [open, setOpen] = React.useState(false);
  const [rate, setRate] = React.useState(
    exchangeRates.KES.rate / exchangeRates.USD.rate
  );

  React.useEffect(() => {
    setRate(exchangeRates.KES.rate / exchangeRates.USD.rate);
  }, [exchangeRates]);

  const changeExchangeRate = () => {
    setExchangeRates((previous) => {
      previous.KES.rate = rate * previous.USD.rate;

      return { ...previous };
    });

    setOpen(false);
  };

  const calculateSpent = () => {
    let spent = 0;
    if (items) {
      items.forEach((item) => {
        spent +=
          item.price *
          item.quantity *
          (exchangeRates[currency].rate /
            exchangeRates[item.currency || poCurrency].rate);
      });
    }
    return spent;
  };

  const profit = sellingPrice - calculateSpent();

  const [tProfitString, setTProfitString] = React.useState("");

  React.useEffect(() => {
    if (unitPrice && quantity) {
      setTProfitString(
        `${currency} ${NumberFormater(
          (
            unitPrice -
            poPrice *
              (exchangeRates[currency].rate / exchangeRates[poCurrency].rate)
          ).toFixed(2)
        )} X QTY ${quantity} `
      );
    } else {
      setTProfitString("");
    }
  }, [unitPrice, quantity, poPrice, currency, poCurrency, exchangeRates]);

  const ItemBreakDown = ({ item, index }) => {
    const [exchangeString, setExchangeString] = React.useState("");

    React.useEffect(() => {
      if (currency !== (item.currency || poCurrency)) {
        setExchangeString(`
        (${currency}   ${NumberFormater(
          (
            item.price *
            (exchangeRates[currency].rate /
              exchangeRates[item.currency || poCurrency].rate)
          ).toFixed(2)
        )})`);
      } else {
        setExchangeString("");
      }
    }, [item]);

    return (
      <ListItem key={index}>
        <ListItemText
          primary={
            <>
              {item.description}
              {!item.pendingID && (
                <Chip label="Main" color="info" sx={{ ml: 1 }} />
              )}
              {item.storeID && (
                <Chip
                  label="Store"
                  variant="outlined"
                  color="primary"
                  sx={{ ml: 1 }}
                />
              )}
            </>
          }
          secondary={
            <Stack>
              <Typography
                sx={{ fontSize: "14px", color: "text.secondary" }}
              >{`QTY ${item.quantity}`}</Typography>
              <Typography sx={{ fontSize: "14px", color: "text.secondary" }}>
                {`${poCurrency} ${NumberFormater(
                  (
                    item.price *
                    (exchangeRates[poCurrency].rate /
                      exchangeRates[item.currency || poCurrency].rate)
                  ).toFixed(2)
                )} ${exchangeString}`}
              </Typography>
            </Stack>
          }
          sx={{ maxWidth: "75%" }}
          disableTypography
        />
        <ListItemText
          primary={` ${currency} 
        ${NumberFormater(
          (
            item.price *
            (exchangeRates[currency].rate /
              exchangeRates[item.currency || poCurrency].rate) *
            item.quantity
          ).toFixed(2)
        )}`}
          sx={{ textAlign: "right" }}
        />
      </ListItem>
    );
  };

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Typography display="inline" variant="h4" sx={{ mb: 1.5 }}>
        Transaction Summary
      </Typography>
      <Typography display="inline" variant="caption" sx={{ mb: 1.5 }}>
        {` (Vat not included)`}
      </Typography>
      {open ? (
        <Stack alignItems="center" direction="row" spacing={2}>
          <TextField
            value={rate}
            type="number"
            onWheel={(e) => e.target.blur()}
            onChange={(e) => setRate(e.target.value)}
          />
          <Button variant="contained" onClick={changeExchangeRate}>
            Convert
          </Button>
        </Stack>
      ) : (
        <CardActionArea onClick={() => setOpen(true)}>
          <Typography color="primary">{` 1 USD = ${
            exchangeRates.KES.rate / exchangeRates.USD.rate
          } KSH [Click here to change rate]`}</Typography>
        </CardActionArea>
      )}

      <ListItem>
        <ListItemText primary={"ITEM"} />
        <ListItemText primary={"Total"} sx={{ textAlign: "right" }} />
      </ListItem>
      <List>
        {(items || []).map((item, index) => (
          <ItemBreakDown key={index} item={item} index={index} />
        ))}

        <Divider />
        <ListItem>
          <ListItemText
            primary={"Selling Price"}
            secondary={
              <Stack>
                {quantity && (
                  <>
                    <Typography
                      sx={{ fontSize: "14px", color: "text.secondary" }}
                    >{`QTY ${quantity}`}</Typography>
                    <Typography
                      sx={{ fontSize: "14px", color: "text.secondary" }}
                    >{` ${currency} ${NumberFormater(
                      (sellingPrice / quantity).toFixed(2)
                    )}`}</Typography>
                  </>
                )}
              </Stack>
            }
            disableTypography
          />
          <Typography sx={{ fontWeight: "bold" }}>
            {` ${currency} ${NumberFormater(sellingPrice.toFixed(2))}`}
          </Typography>
        </ListItem>

        <Divider sx={{ mb: 0.5 }} />
        <Divider />
        {unitPrice && (
          <ListItem>
            <ListItemText primary="Unit Profit" sx={{ fontWeight: "bold" }} />
            <Typography sx={{ fontWeight: "bold" }}>
              {`${currency} ${NumberFormater(
                (
                  unitPrice -
                  poPrice *
                    (exchangeRates[currency].rate /
                      exchangeRates[poCurrency].rate)
                ).toFixed(2)
              )}`}
            </Typography>
          </ListItem>
        )}
        <ListItem>
          <ListItemText
            primary={"Total Profit"}
            sx={{ fontWeight: "bold" }}
            secondary={tProfitString}
          />
          <Typography sx={{ fontWeight: "bold" }}>
            {`${currency} ${NumberFormater(profit.toFixed(2))}`}
          </Typography>
        </ListItem>
      </List>
    </Paper>
  );
}
