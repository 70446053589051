import React from "react";
import {
  Typography,
  Link,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import { SessionManager } from "react-session.manager.sk";
import Main from "./Pages/Main/Main";
import AppBarDrawer from "./Components/common/AppBarDrawer";

import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import VerifyForm from "./Components/Forms/verify";

import { ping } from "./CustomAxios/Main";
import Login from "./Components/Forms/login";
import CreateAccountForm from "./Components/Forms/createAC";
import { AvailableRoutes } from "./Pages";
import config from "./Config";

export function Copyright({ wrap = false }) {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      sx={{ my: 1 }}
    >
      {"Copyright © "}
      {wrap && <br />}
      <Link color="inherit" href="https://www.tec-today.com/" target="_blank">
        Technology Today Ltd.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function App() {
  const { isLoggedIn, loadingUser } = React.useContext(SessionManager);
  const location = useLocation();

  const routes = AvailableRoutes();

  const [tabId, setTabId] = React.useState(null);

  window.onload = () => {
    setTabId(Date.now());
  };

  window.onbeforeunload = () => {
    const tabs = JSON.parse(localStorage.getItem("tabs")) || {};
    delete tabs[tabId];
    localStorage.setItem("tabs", JSON.stringify(tabs));
  };

  React.useEffect(() => {
    let title = routes.find((route) => route.path === location.pathname);
    if (title) document.title = `${title?.name} | ${config.appName}`;
    else document.title = `${config.appName}`;
  }, [location, routes, location.pathname]);

  React.useEffect(() => {
    const handleStorage = () => {
      const tabs = JSON.parse(localStorage.getItem("tabs")) || {};

      if (tabId !== null && !tabs[tabId]) {
        tabs[tabId] = true; // Mark the tab as opened
        localStorage.setItem("tabs", JSON.stringify(tabs));
      }
    };

    // Event listener for changes in localStorage (tab events)
    window.addEventListener("storage", handleStorage);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, [tabId]); // Empty dependency array ensures effect runs only once

  return (
    <>
      <LoadingScreen loadingUser={loadingUser} />
      {isLoggedIn ? (
        <AppBarDrawer />
      ) : (
        <>
          <Routes>
            <Route path="/" element={<LandingPage />}>
              <Route path={"/verify"} element={<VerifyForm />} />
              <Route path="/login" element={<Login />}>
                <Route
                  path={"/login/registerNewDevice"}
                  element={<VerifyForm />}
                />
              </Route>
              <Route path={"/signup"} element={<CreateAccountForm />} />
            </Route>
            <Route path="*" element={<LandingPage />} />
          </Routes>
        </>
      )}
    </>
  );
}

function LandingPage() {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (loading) ping().then((res) => setLoading(false));
  }, [loading]);

  return (
    <>
      <Outlet />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Main loading={loading} />
        <Box pt={4}>
          <Copyright />
          <Typography variant="body2" color="textSecondary" align="center">
            Version {config.appVersion}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

function LoadingScreen({ loadingUser }) {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (loadingUser) setLoading(true);
    else setLoading(false);
  }, [loadingUser]);

  return (
    <Backdrop
      open={loading}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
}

export default App;
