import React from "react";

import { Box, Stack, Zoom, Button } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";

import { SessionManager } from "react-session.manager.sk";
import { changePhoneNumber } from "../../CustomAxios/Protected/AccountManagment";
import config from "../../Config";

export default function Main(props) {
  const { setRefreshData, userInfo } = React.useContext(SessionManager),
    [Telephone, setTelephone] = React.useState(userInfo?.phone || ""),
    [phone, setPhone] = React.useState(userInfo?.phone || ""),
    [show, setShow] = React.useState(false),
    [reset, setReset] = React.useState(false);

  React.useEffect(() => {
    setTelephone(userInfo?.phone);
    setPhone(userInfo?.phone);
  }, [userInfo]);

  React.useEffect(() => {
    if (phone !== Telephone && phone) setShow(true);
    else setShow(false);
    if (phone !== Telephone) setReset(true);
    else setReset(false);
  }, [phone, Telephone]);

  const handleReset = () => {
    setPhone(userInfo?.phone);
  };

  const handlePhoneNumberChange = () => {
    changePhoneNumber({ phone_number: phone }).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        setRefreshData(true);
      } else toast.error(res.message);
    });
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <PhoneInput
            country={config.phoneNumber.Default}
            value={phone}
            onChange={(e) => setPhone(e)}
            specialLabel="Phone Number"
            required
          />
        </Stack>
        <Zoom in={reset}>
          <Button variant="outlined" color="secondary" onClick={handleReset}>
            Reset
          </Button>
        </Zoom>
        <Zoom in={show}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePhoneNumberChange}
          >
            Save Changes
          </Button>
        </Zoom>
      </Stack>
    </Box>
  );
}
