import config from "../../Config";

export function PasswordValidator(password, confirm) {
  var valid = true;
  let message = [];
  let message2 = "";

  if (password !== confirm) {
    message2 = "Passwords do not match";
    valid = false;
  }

  if (!config.server.PasswordComplexity) {
    return { valid: valid, message: message, message2: message2 };
  }

  const lowerCheck = new RegExp("[a-z]");
  const upperCheck = new RegExp("[A-Z]");
  const numCheck = new RegExp("[0-9]");
  const specialCheck = new RegExp("[^A-Za-z0-9]");

  if (password.length < 8) {
    message.push("Password length needs to be greater than 8 characters.");
    valid = false;
  }
  if (!lowerCheck.test(password)) {
    message.push("Your password needs at least one lower case character.");
    valid = false;
  }
  if (!upperCheck.test(password)) {
    message.push("Your password needs at least one upper case character.");
    valid = false;
  }
  if (!numCheck.test(password)) {
    message.push("Your password needs at least one number.");
    valid = false;
  }
  if (!specialCheck.test(password)) {
    message.push(
      "Your password needs at least one special character eg. !@#$%^&*()_+"
    );
    valid = false;
  }

  return { valid: valid, message: message, message2: message2 };
}
