import {
  Dialog,
  DialogTitle,
  Fab,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import AddSupplierForm from "../Components/Forms/AddSupplier";
import {
  LoadingContext,
  LoadingProvider,
} from "../Components/common/LoadingProvider";
import AddIcon from "@mui/icons-material/Add";
import { getAllSuppliers, getSupplierPages } from "../API/Cheques&Pos";
import { Delete, Edit } from "@mui/icons-material";
import { Outlet, Link as Rlink } from "react-router-dom";
import UniversalTable from "../../Components/common/UniversalTable";
import DescriptionBubble from "../Components/common/DescriptionBubble";
import MoreTableButton from "../Components/common/MoreTableButton";

export default function Main() {
  return (
    <>
      <Typography variant="h5" color="primary" gutterBottom>
        Suppliers
      </Typography>
      <LoadingProvider
        getFilterData={() => ({ currentPage: 1 })}
        getData={getAllSuppliers}
        getPages={getSupplierPages}
      >
        <SupplierWrapper />
      </LoadingProvider>
    </>
  );
}

function SupplierWrapper() {
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loading,
    loadFiltered,
    data,
  } = React.useContext(LoadingContext);

  const [addSupplier, setAddSupplier] = React.useState(false);

  const closeAddSupplier = () => {
    setAddSupplier(false);
  };
  const completeAddSupplier = (data) => {
    setAddSupplier(false);
    loadFiltered();
  };

  const MoreOptions = ({ value }) => (
    <MoreTableButton id={value.quote_no}>
      <List dense>
        <ListItem disablePadding>
          <ListItemButton component={Rlink} to={`edit?id=${value.id}`}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Rlink} to={`delete?id=${value.id}`}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </ListItemButton>
        </ListItem>
      </List>
    </MoreTableButton>
  );

  const headers = [
    { id: "name", label: "Supplier", searchable: true },
    { id: "phone", label: "Phone", searchable: true },
    { id: "address", label: "Address", searchable: true },
    { id: "paymentDays", label: "Payment Days" },
    {
      id: "pin",
      label: "KRA pin",
      searchable: true,
    },
    {
      id: "note",
      label: "Note",
      component: (value) => (
        <DescriptionBubble
          bubbleID={`note_bubble_${value.name}`}
          text={value.note}
        />
      ),
    },
    {
      id: "settings",
      label: "",
      component: (value) => <MoreOptions value={value} />,
      cellProps: { align: "right" },
    },
  ];

  return (
    <>
      <Fab
        color="primary"
        variant="extended"
        onClick={() => setAddSupplier(true)}
        sx={{ mb: 2 }}
      >
        <AddIcon /> Add new supplier
      </Fab>

      <Dialog open={addSupplier} onClose={closeAddSupplier}>
        <DialogTitle>Add new supplier</DialogTitle>
        <AddSupplierForm
          onClose={closeAddSupplier}
          onComplete={completeAddSupplier}
        />
      </Dialog>

      <UniversalTable
        headers={headers}
        loading={loading}
        lazyloading={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
        setLoading={loadFiltered}
        data={data}
        name={"Suppliers"}
      />

      <Outlet context={{ loadFiltered, origin: "/suppliers" }} />
    </>
  );
}
