import React from "react";
import { Box } from "@mui/material";
import { LoadingProvider, LoadingContext } from "./LoadingProvider";
import {
  getSupplierInvoicePages,
  getSupplierInvoices,
} from "../../API/Cheques&Pos";

import UniversalTable from "../../../Components/common/UniversalTable";
import FloatingFormButton from "../../../Components/common/FloatingFormButton";

export default function CustomerInvoices() {
  return (
    <Box>
      <LoadingProvider
        getFilterData={() => ({ currentPage: 1 })}
        getData={getSupplierInvoices}
        getPages={getSupplierInvoicePages}
      >
        <FloatingFormButton title="Add New Supplier Invoice">
          Placeholder
        </FloatingFormButton>
        <CustomerInvoiceTable />
      </LoadingProvider>
    </Box>
  );
}

function CustomerInvoiceTable() {
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loadFiltered,
    loading,
    data,
  } = React.useContext(LoadingContext);

  const headers = [{ id: "invoiceID", label: "Invoice ID", searchable: true }];

  return (
    <>
      <UniversalTable
        headers={headers}
        data={data}
        loading={loading}
        lazyloading={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
        setLoading={loadFiltered}
        name={"Suppplier Invoices"}
      />
    </>
  );
}
