export function dateRange(low, high, date) {
  const lowRange = low || -Infinity;
  const highRange = high || Infinity;
  const rowDate = date ? Date.parse(date) : 0;

  return lowRange <= rowDate && rowDate <= highRange;
}

export function convertToBool(value, truth) {
  if (value === "") return null;
  return value === truth;
}
