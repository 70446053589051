import React from "react";

import { Typography, Stack } from "@mui/material";

import "../../Components/Styles/LoadingPage.css";

export default function MovingText() {
  const text = "Loading...";

  return (
    <Stack
      direction="row"
      spacing={0}
      justifyContent="center"
      className="loading_text"
    >
      {text.split("").map((letter, index) => (
        <Typography
          key={index}
          variant="h3"
          color="primary"
          sx={{
            animation: "loading_text 1.4s infinite alternate",
            animationDelay: `${index / 10}s`,
          }}
        >
          {letter}
        </Typography>
      ))}
    </Stack>
  );
}
