import { useMatch, Link, useLocation } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { SvgIcon } from "@mui/material";
import config from "../../Config";

export default function CreateLink(
  name,
  url,
  icon,
  shouldHide,
  children = null
) {
  let match = useMatch({ path: url, end: true });
  let currentPath = useLocation().pathname;

  if (children) {
    for (let i = 0; i < children.length; i++) {
      if (
        currentPath.includes(children[i].path) &&
        "/" + currentPath.split("/")[1] === url
      )
        match = true;
    }
  }

  if (shouldHide) return null;

  return (
    <ListItem
      button
      component={Link}
      to={url}
      key={name}
      selected={match ? true : false}
      sx={{
        color: (theme) =>
          config.theme.LinkColor.custom
            ? config.theme.LinkColor.primary
              ? theme.palette.primary.main
              : theme.palette.secondary.main
            : null,
      }}
    >
      <ListItemIcon
        style={{
          color: "inherit",
        }}
      >
        <SvgIcon component={icon} />
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );
}
